import React from "react";
import {KLabel} from "@kopjra/uikit";

export interface KCreditProps {
    remaining: number
    total?: number;
    infinite: boolean;
    labelText?: string | JSX.Element;
}

export const KCredit: React.FC<KCreditProps> = ({ remaining, total, infinite, labelText}) => {
    let credit;
    if (infinite) {
        credit = <>
            <span className="bigNumberColored"><i className="fa fa-md fa-infinity"/></span>
            <br/>
            <KLabel text={labelText ? labelText : ""}/>
        </>
    } else {
        if (total) {
            credit = <>
                <span className="bigNumberGray">{`${remaining}/`}</span>
                <span className="bigNumberLightGray">{total}</span>
                <br/>
                <KLabel text={labelText ? labelText : ""}/>
            </>
        } else {
            credit = <>
                <span className="bigNumberColored">{remaining}</span>
                <br/>
                <KLabel text={labelText ? labelText : ""}/>
            </>
        }
    }
    return credit;
};
