import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {unregister} from "@kopjra/uikit";
import {routerTools, Routes} from "../../utils/router";
import {
    doGetDomains,
    doGetCurrentSearch,
    doAddSearchManualUrls,
    doUploadSearchNotebook
} from "../../actions/thunks/detection/domainsArea";
import {DispatchProps, DomainsArea, InnerProps, StateProps} from "../../components/detection/DomainsArea";
import {resetResultsState} from "../../actions/detection/resultsArea";
import {Notebook} from "../../types/extra";

function mapStateToProps({domainsArea}: RootState): StateProps {
    return {
        currentSearch: domainsArea.currentSearch,
        domains: domainsArea.list,
        total: domainsArea.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentSearch: (searchId: string) => dispatch(doGetCurrentSearch(searchId)),
        onGetDomains: (searchId, query) => dispatch(doGetDomains(searchId, query)),
        onOpenDomain: (productType: string, searchId: string, domainId: string) => {
            dispatch(unregister("results/list"));
            dispatch(resetResultsState());
            routerTools.push(Routes.RESULTS(productType, searchId, domainId));
        },
        onAddManualUrls: (searchId: string, urls: string[]) => dispatch(doAddSearchManualUrls(searchId, urls)),
        onUploadSearchNotebook: (searchId: string, notebook: Notebook) => dispatch(doUploadSearchNotebook(searchId, notebook))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainsArea);
