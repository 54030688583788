import {apiCall, HttpMethod} from "../index";
import {Well} from "../../types/takedown/wellsState";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {getWellDomainResults} from "./wellDomainResultsArea";
import {transformToWell} from "./wells";


export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetWellDomainsParams extends GetParams {
    name?: string;
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetWellDomainsResponse = GetListResponse<WellDomain>;

export function transformToWellDomain(obj: any): WellDomain {
    const wellDomain: WellDomain = {
        name: obj.name,
        nUrls: obj.nUrls,
        nUrlsRemoved: obj.nUrlsRemoved,
        nMailsToRead: obj.nMailsToRead,
        lastNotice: obj.lastNotice ? new Date(obj.lastNotice) : undefined,
        noticeForm: obj.noticeForm,
        noticeable: obj.noticeable
    };
    return wellDomain;
}

export async function getWell(id: string): Promise<Well> {
    const res = await apiCall({urlComponent: `/wells/${id}`});
    const json = await res.json();
    const well = transformToWell(json);
    return well;
}

export async function getWellDomains(wellId: string, query: GetWellDomainsParams): Promise<GetWellDomainsResponse> {
    const res = await apiCall({urlComponent: `/wells/${wellId}/domains`, query});
    const json = await res.json();
    const wellDomains = await json.results.map((o: any) => transformToWellDomain(o));
    return {
        results: wellDomains,
        total: json.total,
    };
}

export async function getCurrentFormResults(wellId: string, domainName: string): Promise<string> {
    const wellResultWithCount = await getWellDomainResults(wellId, {top: 1}, domainName);
    const allResults = await getWellDomainResults(wellId, {top: wellResultWithCount.total, onlyNoticeable: true}, domainName);
    let formResults = "";
    allResults.results.forEach(r => formResults += (r.url + "\n"));
    return formResults;
}

export async function setIndexingState(wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, stateDate?: string, requestId?: string): Promise<void> {
    let body = {urls: urlsArray, searchEngine: searchEngine, indexedState: indexingState};
    if (stateDate) {
        body = Object.assign(body, {stateDate: stateDate})
    }
    if (requestId) {
        body = Object.assign(body, {requestId: requestId})
    }
    await apiCall({
        method: HttpMethod.PATCH,
        urlComponent: `/wells/${wellId}/domains/delisting`,
        body: body
    });
}

export async function requestDomainsIndexing(wellId: string, domains: WellDomain[], all: boolean, searchEngine: string, queryParams?: GetWellDomainsParams): Promise<void> {
    const body: any = {
        domains: domains.map(d => d.name),
        all: all,
        searchEngine: searchEngine
    };
    if (queryParams?.name) {
        body.queryParameters = {name: queryParams.name};
    }
    await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/wells/${wellId}/domains/indexing`,
        body: body
    });
}
