import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FreeTextFilter,
    GetParams, GlobalActionType,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT, KCheck, KDate,
    KForm,
    KInput, KLabel,
    KModal,
    KModalSize,
    KModalVariant, KSelect, KSELECT_SIZE,
    KSpace,
    KTableLoader,
    Table
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {useParams} from "react-router-dom";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {OSDSpinner} from "../OSDSpinner";
import {IndexingState, NoticeType, tableGetParams} from "../../utils/commons";
import {useState} from "react";
import {ManualUrls} from "./ManualUrls";
import {User} from "../../types/main";


export interface WellDomainsProps {
    currentUser?: User;
    wellDomains?: WellDomain[];
    total: number;
    currentFormResults?: string;
    onGetCurrentFormResults: (wellId: string, domainName: string) => Promise<void>;
    onGetWellDomains: (wellId: string, query: GetParams) => Promise<void>;
    onOpenWellDomain: (productType: string, wellId: string, domainName: string) => void;
    onSendNotice: (wellId: string, domainName: string) => Promise<void>;
    onOpenMails: (productType: string, wellId: string, domainName: string) => void;
    onAddManualUrls: (wellId: string, urls: string[], autoScreen: boolean, query: GetParams) => Promise<void>;
    onSetIndexingState: (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, stateDate?: string, requestId?: string) => Promise<void>;
    onRequestDomainsIndexing: (wellId: string, domains: WellDomain[], all: boolean, searchEngine: string, queryParams: GetParams) => Promise<void>;
}

export const WellDomains: React.FC<WellDomainsProps> = (
    {
        currentUser, wellDomains, total, currentFormResults,
        onGetCurrentFormResults, onGetWellDomains, onOpenWellDomain, onSendNotice, onOpenMails,
        onAddManualUrls, onSetIndexingState, onRequestDomainsIndexing
    }) => {
    // @ts-ignore
    const {productType, wellId} = useParams();
    const superUser = currentUser?.scope?.includes("wi:unlimitedcredits");

    const [opened, setOpened] = useState(false);
    const [openedAdd, setOpenedAdd] = useState(false);
    const [openedIndexing, setOpenedIndexing] = useState<boolean>(false);
    const [currentNoticeForm, setCurrentNoticeForm] = useState("");

    const wellDomainsRetriever = async (wellId: string, query: GetParams) => {
        await onGetWellDomains(wellId, query);
    };

    const actions: ActionType<WellDomain>[] = [
        {
            name: (datum: WellDomain) => {
                if(datum.noticeable) {
                    return <div><i className="fal fa-gavel action-icon"/>&nbsp;<Translate value="wells.domains.table.notice"/></div>;
                } else {
                    return <><i className="fal fa-folder-open action-icon"/>&nbsp;<Translate value="domains.table.actions.view"/></>;
                }
            },
            handler: async (datum: WellDomain) => {
                if (datum.noticeable) {
                    if (datum.noticeForm) {
                        // currentFormResults = undefined;
                        onGetCurrentFormResults(wellId, datum.name);
                        setCurrentNoticeForm(datum.noticeForm);
                        setOpened(true);
                    } else {
                        const confirmBoxConf = {
                            noText: I18n.t("kptable.confirm.no"),
                            yesText: I18n.t("kptable.confirm.yes"),
                            dark: false,
                            message: I18n.t("wells.domains.table.noticeConfirm")
                        };
                        const send = await confirmBox(confirmBoxConf);
                        if (send) {
                            await onSendNotice(wellId, datum.name);
                        }
                    }
                } else {
                    onOpenWellDomain(productType, wellId, datum.name);
                }
            },
            collapsable: false,
        },
        {
            name: <><i className="fal fa-folder-open action-icon"/>&nbsp;<Translate
                value="domains.table.actions.view"/></>,
            handler: async (datum: WellDomain) => {
                onOpenWellDomain(productType, wellId, datum.name);
            },
            tooltip: <Translate value="wells.domains.table.mailtip"/>,
            collapsable: true
        },
        {
            name: <><i className="fal fa-inbox action-icon"/>&nbsp;<Translate value="wells.domains.table.inbox"/></>,
            handler: async (datum: WellDomain) => {
                await onOpenMails(productType, wellId, datum.name);
            },
            shouldRender: (datum: WellDomain) => !datum.noticeForm,
            collapsable: true
        }
    ];

    const globalActions: GlobalActionType[] = [
        {
            name: <><i className="fal fa-plus"/>&nbsp;<Translate value="wells.domains.table.insertUrls"/></>,
            handler: async () => {
                await setOpenedAdd(true);
            },
            bulk: false,
        }
    ];

    if (superUser) {
        globalActions.push(
            {
                name: <><i className="fal fa-spider-web"/>&nbsp;<Translate value="wells.domains.table.updateIndexing"/></>,
                handler: async () => {
                    await setOpenedIndexing(true);
                },
                bulk: false,
            },
            {
                name: <><div className="far fa-spider"/>&nbsp;<Translate value="wells.domains.table.requestIndexing"/></>,
                handler: async (data: object[], config) => {
                    const all = config.globalCheckedAll;
                    const confirmBoxConf = {
                        noText: I18n.t("bing"),
                        yesText: I18n.t("google"),
                        dark: false,
                        message: I18n.t("wells.domains.table.indexingConfirm"),
                    };
                    const res = await confirmBox(confirmBoxConf);
                    const se = res ? "google" : "bing";
                    await onRequestDomainsIndexing(wellId as string, data as WellDomain[], all, se, config.queryParams);
                },
                bulk: true
            },
        )
    }

    const rowAction = async (datum: WellDomain) => {
        onOpenWellDomain(productType, wellId, datum.name);
    };

    function renderURLs(datum: WellDomain) {
        return `${datum.nUrlsRemoved} / ${datum.nUrls}`;
    }

    function renderNoticeType(datum: WellDomain) {
        return datum.noticeForm ? NoticeType.FORM : NoticeType.MAIL;
    }

    const filters = [
        new FreeTextFilter("name", I18n.t("wells.domains.table.name"))
    ];

    return (
        <>
            {wellDomains ? (
                <Row>
                    <Col md={12} className="text-center">
                        <Table
                            checkboxes={true}
                            filterDefinition={filters}
                            globalActions={globalActions}
                            globalWaiter={<OSDSpinner size={200}/>}
                            waiter={<OSDSpinner size={100} variant="dark"/>}
                            id={`wellDomains/list`}
                            total_count={total}
                            loaderFunc={(q: GetParams) => wellDomainsRetriever(wellId as string, q)}
                            data={wellDomains}
                            hideColumnSelector={true}
                            hideFilters={false}
                            keyField={"name"}
                            hidePager={false}
                            rowAction={rowAction}
                        >
                            <Column colid="name" classes="text-start" name="wells.domains.table.name"
                                    type={DataType.GENERIC} sort={"name"}/>
                            <Column colid="nUrlsRemoved" classes="text-center" name="wells.domains.table.nUrlsRemoved"
                                    type={DataType.GENERIC} sort={"nUrlsRemoved"} render={renderURLs}/>
                            <Column colid="noticeForm" classes="text-center" name="wells.domains.table.noticeType"
                                    type={DataType.GENERIC} sort={"noticeForm"} render={renderNoticeType}/>
                            <Column colid="nMailsToRead" classes="text-center" name="wells.domains.table.nMailsToRead"
                                    type={DataType.GENERIC}/>
                            <Column colid="lastNotice" classes="text-end" name="wells.domains.table.lastNotice"
                                    type={DataType.DATE} sort={"lastNotice"}/>
                            <Column colid="actions" classes="text-end" name="" type={DataType.ACTIONS}
                                    actions={actions} collapsed/>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <KTableLoader/>
            )}
            <KModal size={KModalSize.lg} show={opened} variant={KModalVariant.secondary} onHide={() => setOpened(false)}
                    header={I18n.t("wells.table.notice")} footer={<></>}>
                <Row><Col className="text-center"><h4><Translate value={"wells.domains.onlyForm"}/></h4></Col></Row>
                <KSpace/>
                <Row><Col className="text-center"><KButton text={I18n.t("wells.domains.openForm")} onClick={() => {
                    window.open(currentNoticeForm, "_blank")
                }}/></Col></Row>
                <KSpace spaces={2}/>
                <Row><Col className="text-center"><h4><Translate value={"wells.domains.copyLinks"}/></h4></Col></Row>
                <KSpace/>
                <Row><Col className="text-center">
                    {currentFormResults ? (
                        <KInput as="textarea" id={"formUrls"} name={"formUrls"} value={currentFormResults}
                                addedTextAreaHeight={200} readOnly={true}/>
                    ) : (
                        <OSDSpinner size={50}/>
                    )}
                </Col></Row>
            </KModal>
            <KModal size={KModalSize.lg} variant={KModalVariant.secondary} show={openedAdd}
                    onHide={() => setOpenedAdd(false)} header={I18n.t("wells.static.manualResults")} footer={<></>}>
                <KForm onSubmit={async (values) => {
                    const urlsArray = values?.urls ? (values.urls as string).trim().split("\n") : [];
                    const autoScreen = !!values?.autoScreen;
                    const params = tableGetParams("wellDomains/list");
                    onAddManualUrls(wellId, urlsArray, autoScreen, params).catch();
                    setOpenedAdd(false);
                }}>
                    <ManualUrls/>
                    <KCheck id={"autoScreen"} label={"screenshot"} type={"checkbox"} />
                    <KSpace spaces={2}/>
                    <Row>
                        <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                             text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                 value={`wells.static.cancel`}/></>}
                                             onClick={() => setOpenedAdd(false)}/>
                        </Col>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                             text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate
                                                 value={`wells.static.confirm`}/></>}/>
                        </Col>
                    </Row>
                </KForm>
            </KModal>
            <KModal variant={KModalVariant.secondary} size={KModalSize.xl} show={openedIndexing}
                    onHide={() => setOpenedIndexing(false)}
                    header={I18n.t("wells.domains.table.changeIndexingTitle")} footer={<></>}>
                <Row><Col className={"text-center"}><KLabel text={<Translate value={"wells.domains.table.changeIndexingMessage"}/>}/></Col></Row>
                <KSpace/>
                <KForm onSubmit={async (values) => {
                    const urlsArray = values?.urls
                        ? (values.urls as string).trim().split("\n")
                            .map(u => {
                                if (!u.startsWith("https://") && !u.startsWith("http://")) { u = `https://${u}`; } return u;
                            })
                        : [];
                    await onSetIndexingState(wellId, urlsArray, values.searchEngine as string, values.indexingState as string, values.stateDate as string, values.requestId as string).catch();
                    setOpenedIndexing(false);
                }}>
                    <ManualUrls/>
                    <KSpace spaces={2}/>
                    <Row>
                        <Col><KSelect label={<Translate value="wells.domains.table.searchEngine"/>}
                                      required={true} id={"searchEngine"} size={KSELECT_SIZE.sm}
                                      placeholder={<Translate value="wells.static.select"/>}
                                      options={["Google", "Bing"].map(se => {return {value: se.toLowerCase(), label: se};})}/>
                        </Col>
                        <Col><KSelect label={<Translate value="wells.domains.table.indexingState"/>}
                                      required={true} id={"indexingState"} size={KSELECT_SIZE.sm}
                                      placeholder={<Translate value="wells.static.select"/>}
                                      options={Object.values(IndexingState).map(s => {
                                          const l = `${s.replaceAll("_", "").toLowerCase()}State`;
                                          return {value: s, label: I18n.t(`wells.static.${l}`)};
                                      })}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col><KInput label={<Translate value="wells.domains.table.requestId"/>} id={"requestId"} /></Col>
                        <Col><KLabel text={<Translate value="wells.domains.table.stateDate"/>}/><KSpace/><KDate id={`stateDate`} /></Col>
                    </Row>
                    <KSpace spaces={2}/>
                    <Row>
                        <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                             text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                 value={`wells.static.cancel`}/></>}
                                             onClick={() => {setOpenedIndexing(false)}}/>
                        </Col>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                             text={<><i className="fal fa-chevron-double-right"/>&nbsp;
                                                 <Translate value={`wells.static.confirm`}/></>}/></Col>
                    </Row>
                </KForm>
            </KModal>
        </>
    );
};
