import {Domain} from "./domainsState";
import { Search } from "./searchState";

export enum NotificationURL {
    "YOUTUBE" = "https://www.youtube.com/copyright_complaint_form",
    "DAILYMOTION" = "https://faq.dailymotion.com/hc/en-us/requests/new?ticket_form_id=136048#new-request",
    "VIMEO" = "https://vimeo.com/dmca/claim",
}

export enum ValidationState {
    "VIOLATION_AUTO" = "VIOLATION_AUTO",
    "VIOLATION" = "VIOLATION",
    "NO_VIOLATION" = "NO_VIOLATION"
}

export enum DomainAnalysisValidationState {
    "ACCEPTED" = "ACCEPTED",
    "REJECTED" = "REJECTED",
    "NOT_VALIDATED" = "NOT_VALIDATED"
}

export interface Result {
    id: string;
    url: string;
    title: string;
    searchId: string;
    domainId: string;
    validation: ValidationState;
    description?: string;
    channel?: string;
    channelURL?: string;
    channelFollowers?: number;
    publishedAt?: Date;
    views?: number;
    duration?: number;
    notes?: string;
    createdAt?: Date;
    // CrawlResult field
    visited?: boolean;
}

export interface ResultsAreaState {
    currentSearch?: Search;
    currentDomain?: Domain;
    list?: Result[];
    total: number;
    forceUpdate: boolean;
}
