import {connect} from "react-redux";
import {TDBreadcrumbs, DispatchProps, InnerProps, StateProps} from "../../components/takedown/TDBreadcrumbs";
import {routerTools, Routes} from "../../utils/router";
import {RootState} from "../../types";

function mapStateToProps({main}: RootState): StateProps {
    return {};
}

function mapDispatchToProps(): DispatchProps {
    return {
        onOpenDomains: (productType: string, wellId: string) => {
            routerTools.push(Routes.WELL_DOMAINS(productType, wellId));
        }
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(TDBreadcrumbs);
