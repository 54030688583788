import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {getWell} from "../../../api/takedown/wellDomainsArea";
import {NewWell, WellUpdate} from "../../../types/takedown/wellsState";
import {
    doUpdateWellInfo,
    gotAvailableSearches,
    gotCompiledNoticeTemplate,
    gotCurrentWellStaticInfo
} from "../../takedown/wellCreation";
import {createWell, getCompiledNoticeTemplate, updateWellStaticInfo, getPOADownloadUrl} from "../../../api/takedown/wells";
import {getAvailableSearches} from "../../../api/detection/searches";
import {GetParams, setAlert} from "@kopjra/uikit";
import {transformToAvailableSearchParams} from "../../../utils/commons";
import {I18n} from "react-redux-i18n";
import {routerTools, Routes} from "../../../utils/router";
import {ProductType} from "../../../types/extra";


export const doGetCurrentWellStaticInfo = (wellId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentWellStaticInfo(await getWell(wellId)));
};

export const doGetCompiledNoticeTemplate = (well: WellUpdate): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCompiledNoticeTemplate(await getCompiledNoticeTemplate(well)));
};

export const doUpdateWellStaticInfo = (well: WellUpdate): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const updatedWell = await updateWellStaticInfo(well);
    if (updatedWell) {
        dispatch(setAlert(I18n.t("alerts.infos.updateWell"), "info"));
        dispatch(doUpdateWellInfo(updatedWell));
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.updateWell"), "error"));
    }
};

export const doGetAvailableSearches = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotAvailableSearches(await getAvailableSearches(transformToAvailableSearchParams(query))));
}

export const doCreateNewWell = (newWell: NewWell, urls: string[]): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    if (newWell.searchIds.length === 0 && urls.length === 0) {
        dispatch(setAlert(I18n.t("alerts.errors.newWellCreation"), "error"));
    } else {
        const createdWell = await createWell(newWell, urls);
        if (createdWell) {
            routerTools.push(Routes.WELL_DOMAINS(ProductType.TAKEDOWN, createdWell.id));
        } else {
            dispatch(setAlert(I18n.t("alerts.errors.newWellError"), "error"));
        }
    }
}

export const doDownloadPOA = (): ThunkResult<Promise<void>> => async () => {
    const url = await getPOADownloadUrl();
    if (url) {
        window.open(url);
    }
}
