import {GetParams, setAlert} from "@kopjra/uikit";
import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {gotCurrentWellDomain, gotNResultsWillChange, gotWellDomainResults} from "../../takedown/wellDomainResultsArea";
import {
    createScreenshotArchiveLink,
    deleteWellDomainResults,
    getNResultsWillChange, getScreenshotUrl,
    getWellDomain,
    getWellDomainResults, GetWellDomainResultsParams,
    requestIndexing, triggerScreenshots,
    updateTakedownState, updateWellResultNotes, uploadWellNotebook
} from "../../../api/takedown/wellDomainResultsArea";
import {
    tableGetParams,
    transformToTakedownStateParams,
    transformToWellDomainResultsParams,
    transformToWellResultsIndexingParams
} from "../../../utils/commons";
import {I18n} from "react-redux-i18n";
import {WellDomainResult} from "../../../types/takedown/wellDomainResultsAreaState";
import {Notebook} from "../../../types/extra";

export const doGetWellDomainResults = (wellId: string, query: GetParams, domainName?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotWellDomainResults(await getWellDomainResults(wellId, transformToWellDomainResultsParams(query), domainName)))
};

export const doGetCurrentWellDomain = (wellId: string, domainName: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentWellDomain(await getWellDomain(wellId, domainName)));
};

export const doGetNResultsWillChange = (wellId: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams, domainName?: string,): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await getNResultsWillChange(wellId, data, newState, all, transformToTakedownStateParams(query), domainName);
    dispatch(gotNResultsWillChange(outcome));
}

export const doUpdateTakedownState = (wellId: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams, domainName?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await updateTakedownState(wellId, data, newState, all, transformToTakedownStateParams(query), domainName);
    if (!outcome) {
        const msg = I18n.t("alerts.errors.changeTDState");
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
}

export const doRequestIndexing = (wellId: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: GetParams, domainName?: string): ThunkResult<Promise<void>> => async () => {
    await requestIndexing(wellId, data, all, searchEngine, transformToWellResultsIndexingParams(queryParams as GetParams), domainName);
}

export const doCreateScreenshotArchiveLink = (wellId: string, domainName?: string): ThunkResult<Promise<string>> => async (dispatch: Dispatch<RootAction>) => {
    const signedUrl: string = await createScreenshotArchiveLink(wellId, domainName);
    if (!signedUrl) {
        const msg = I18n.t("alerts.errors.screenshotArchive");
        dispatch(setAlert(msg, "error"));
    }
    return signedUrl;
};

export const doRequestScreenshots = (wellId: string, data: WellDomainResult[], all: boolean, queryParams?: GetParams, domainName?: string): ThunkResult<Promise<void>> => async () => {
    await triggerScreenshots(wellId, data, all, transformToWellResultsIndexingParams(queryParams as GetParams), domainName);
}

export const doRequestScreenshotUrl = (wellId: string, url: string, domainName?: string): ThunkResult<Promise<void>> => async () => {
    const screenUrl = await getScreenshotUrl(wellId, url, domainName);
    if (screenUrl) {
        window.open(screenUrl, "_blank");
    }
}

export const doDeleteWellDomainResults = (wellId: string, urls: string[], all: boolean, queryParams: GetWellDomainResultsParams, domainName?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await deleteWellDomainResults(wellId, urls, all, queryParams, domainName);
    if (outcome) {
        const msg = I18n.t("alerts.infos.wellResultsDeleted");
        dispatch(setAlert(msg, "info"));
    } else {
        const msg = I18n.t("alerts.errors.wellResultsDeleted");
        dispatch(setAlert(msg, "error"));
    }
}

export const doUpdateWellResultNotes = (wellId: string, url: string, notes?: string, domain?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const ok = await updateWellResultNotes(wellId, url, notes, domain);
    if (ok) {
        dispatch(gotWellDomainResults(await getWellDomainResults(wellId, transformToWellDomainResultsParams(tableGetParams("wellResults/list")), domain)));
    }
}

export const doUploadWellNotebook = (wellId: string, notebook: Notebook): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const ok = await uploadWellNotebook(wellId, notebook);
    const msg = ok ? I18n.t("alerts.infos.notebookUploaded") : I18n.t("alerts.errors.notebookUploaded");
    dispatch(setAlert(msg, ok ? "info" : "error"));
    if (ok) {
        dispatch(gotWellDomainResults(await getWellDomainResults(wellId, transformToWellDomainResultsParams(tableGetParams("wellResults/list")))));
    }
}
