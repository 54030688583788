import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {WellDomainsState} from "../../types/takedown/wellDomainsState";
import {
    gotCurrentFormResults,
    gotCurrentWell,
    gotWellDomains,
    resetWellsState
} from "../../actions/takedown/wellDomainsArea";


const initialState: WellDomainsState = {
    total: 0
};

export const wellDomainsAreaReducer = createReducer(initialState)
    .handleAction(gotCurrentWell, (state, action) => update(state, {
        currentWell: {$set: action.payload}
    }))
    .handleAction(gotWellDomains, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    })).handleAction(gotCurrentFormResults, (state, action) => update(state, {
        currentFormResults: {$set: action.payload}
    })).handleAction(resetWellsState, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0},
        currentWell: {$set: undefined}
    }));
