import {extractGetParamsFromState, GetParams, ParamFilter, SortingDirection} from "@kopjra/uikit";
import {GetAvailableSearchParams, GetSearchParams} from "../api/detection/searches";
import {store} from "../index";
import {GetResultParams} from "../api/detection/resultsArea";
import {GetDomainsParams} from "../api/detection/domainsArea";
import {GetDomainsInfoParams} from "../api/analysis/domainInfo";
import {GetWellsParams} from "../api/takedown/wells";
import {GetWellDomainsParams} from "../api/takedown/wellDomainsArea";
import {GetWellDomainResultsParams, PostWellResultsIndexingParams} from "../api/takedown/wellDomainResultsArea";
import {DomainSearchGetParams} from "../api/analysis/domainsSearches";
import {DomainAnalysisGetParams} from "../api/analysis/domainSearchResults";


export function createURLSearchParams(obj: any): URLSearchParams {
    const usp = new URLSearchParams();
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined && obj[key] !== null) {
            usp.append(key, obj[key]);
        }
    }
    return usp;
}

export function createLinkToDownload(name: string, url: string) {
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("download", `${name}`);
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function tableGetParams(id: string): GetParams {
    return extractGetParamsFromState(store.getState(), id);
}

export function transformToDomainInfoParams(query: GetParams): GetDomainsInfoParams {
    const result: GetDomainsInfoParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const domainNamePf = query.filter.find((pf: ParamFilter) => (pf.name === "domainName"));
        result.domainName = domainNamePf ? domainNamePf.value as string : undefined;
    }
    return result;
}

export function transformToDomainSearchesParams(query: DomainSearchGetParams): DomainSearchGetParams {
    const result: DomainSearchGetParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const queryPf = query.filter.find((pf: ParamFilter) => (pf.name === "query"));
        result.query = queryPf ? queryPf.value as string : undefined;
        const tagsPf = query.filter.find((pf: ParamFilter) => (pf.name === "tags"));
        result.tags = tagsPf ? tagsPf.value as string[] : undefined;
    }
    return result;
}

export function transformToDomainSearchResultsParams(query: DomainAnalysisGetParams): DomainAnalysisGetParams {
    const result: DomainAnalysisGetParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const domainPf = query.filter.find((pf: ParamFilter) => (pf.name === "domain"));
        result.domain = domainPf ? domainPf.value as string : undefined;
        const typosquatPf = query.filter.find((pf: ParamFilter) => (pf.name === "typosquat"));
        result.typosquat = typosquatPf ? typosquatPf.value as string : undefined;
    }
    return result;
}

export function transformToAvailableSearchParams(query: GetParams): GetAvailableSearchParams {
    const result: GetAvailableSearchParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const queryPf = query.filter.find((pf: ParamFilter) => (pf.name === "query"));
        result.query = queryPf ? queryPf.value : undefined;
    }
    return result;
}

export function transformToSearchParams(query: GetParams): GetSearchParams {
    const result: GetSearchParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const queryPf = query.filter.find((pf: ParamFilter) => (pf.name === "query"));
        result.query = queryPf ? queryPf.value as string : undefined;
        const webTypePf = query.filter.find((pf: ParamFilter) => (pf.name === "webType"));
        result.webType = webTypePf ? webTypePf.value as string : undefined;
        const tagsPf = query.filter.find((pf: ParamFilter) => (pf.name === "tags"));
        result.tags = tagsPf ? tagsPf.value as string[] : undefined;
    }
    return result;
}

export function transformToResultParams(query: GetParams): GetResultParams {
    const result: GetResultParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const titlePf = query.filter.find((pf: ParamFilter) => (pf.name === "title"));
        result.title = titlePf ? titlePf.value as string : undefined;
        const urlPf = query.filter.find((pf: ParamFilter) => (pf.name === "url"));
        result.url = urlPf ? urlPf.value as string : undefined;
        const validationPf = query.filter.find((pf: ParamFilter) => (pf.name === "validation"));
        result.validation = validationPf ? validationPf.value as string : undefined;
        const domainIdPf = query.filter.find((pf: ParamFilter) => (pf.name === "domainId"));
        result.domainId = domainIdPf ? domainIdPf.value as string : undefined;
        // CrawlSearch params
        const htmlQueryPf = query.filter.find((pf: ParamFilter) => (pf.name === "htmlQuery"));
        result.htmlQuery = htmlQueryPf ? htmlQueryPf.value as string : undefined;
        const strictHtmlQueryPf = query.filter.find((pf: ParamFilter) => (pf.name === "strictHtmlQuery"));
        result.strictHtmlQuery = strictHtmlQueryPf ? JSON.parse(strictHtmlQueryPf.value as string) : undefined;
        const visitedPf = query.filter.find((pf: ParamFilter) => (pf.name === "visited"));
        result.visited = visitedPf ? JSON.parse(visitedPf.value as string) : undefined;
        const notesPf = query.filter.find((pf: ParamFilter) => (pf.name === "notes"));
        result.notes = notesPf ? notesPf.value as string : undefined;
    }
    return result;
}

export function transformToDomainsParams(query: GetParams): GetDomainsParams {
    const result: GetDomainsParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? namePf.value as string : undefined;
        const importancePf = query.filter.find((pf: ParamFilter) => (pf.name === "importance"));
        result.importance = importancePf ? Number(importancePf.value) : undefined;
        const channelPf = query.filter.find((pf: ParamFilter) => (pf.name === "channel"));
        result.channel = channelPf ? channelPf.value as string : undefined;
    }
    return result;
}

export function transformToWellParams(query: GetParams): GetWellsParams {
    const result: GetWellsParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    } else {
        result.sort = "-created";
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? namePf.value as string : undefined;
        const tagsPf = query.filter.find((pf: ParamFilter) => (pf.name === "tags"));
        result.tags = tagsPf ? (typeof tagsPf.value === "string" ? tagsPf.value : tagsPf.value.join(",")) : undefined;
        const archivedPf = query.filter.find((pf: ParamFilter) => (pf.name === "archived"));
        result.archived = archivedPf ? (archivedPf.value as string) === "true" : false;
    } else {
        result.archived = false;
    }
    return result;
}

export function transformToWellDomainsParams(query: GetParams): GetWellDomainsParams {
    const result: GetWellDomainsParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    } else {
        result.sort = "name";
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? namePf.value as string : undefined;
    }
    return result;
}

export function transformToWellDomainResultsParams(query: GetParams): GetWellDomainResultsParams {
    const result: GetWellDomainResultsParams = {
        top: query.top,
        skip: query.skip
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    } else {
        result.sort = "url";
    }
    if (query.filter && query.filter.length > 0) {
        const urlPf = query.filter.find((pf: ParamFilter) => (pf.name === "url"));
        result.url = urlPf ? urlPf.value as string : undefined;
        const takedownStatePf = query.filter.find((pf: ParamFilter) => (pf.name === "takedownState"));
        result.takedownState = takedownStatePf ? takedownStatePf.value as string : undefined;
        const indexedGooglePf = query.filter.find((pf: ParamFilter) => (pf.name === "indexedGoogle"));
        result.indexedGoogle = indexedGooglePf ? indexedGooglePf.value as string : undefined;
        const indexedBingPf = query.filter.find((pf: ParamFilter) => (pf.name === "indexedBing"));
        result.indexedBing = indexedBingPf ? indexedBingPf.value as string : undefined;
        const notesPf = query.filter.find((pf: ParamFilter) => (pf.name === "notes"));
        result.notes = notesPf ? notesPf.value as string : undefined;
    }
    return result;
}

export function transformToTakedownStateParams(query: GetParams): any {
    const result: any = {};
    if (query.filter && query.filter.length > 0) {
        const urlPf = query.filter.find((pf: ParamFilter) => (pf.name === "url"));
        result.url = urlPf ? urlPf.value : undefined;
        const takedownStatePf = query.filter.find((pf: ParamFilter) => (pf.name === "takedownState"));
        result.takedownState = takedownStatePf ? takedownStatePf.value : undefined;
        const indexedGooglePf = query.filter.find((pf: ParamFilter) => (pf.name === "indexedGoogle"));
        result.indexedGoogle = indexedGooglePf ? indexedGooglePf.value as string : undefined;
        const indexedBingPf = query.filter.find((pf: ParamFilter) => (pf.name === "indexedBing"));
        result.indexedBing = indexedBingPf ? indexedBingPf.value as string : undefined;
        const notesPf = query.filter.find((pf: ParamFilter) => (pf.name === "notes"));
        result.notes = notesPf ? notesPf.value as string : undefined;
    }
    return result;
}

export function transformToWellResultsIndexingParams(query: GetParams): PostWellResultsIndexingParams {
    const result: PostWellResultsIndexingParams = {
        top: 0,
        skip: 0
    };
    if (query.filter && query.filter.length > 0) {
        const urlPf = query.filter.find((pf: ParamFilter) => (pf.name === "url"));
        result.url = urlPf ? urlPf.value as string : undefined;
        const takedownStatePf = query.filter.find((pf: ParamFilter) => (pf.name === "takedownState"));
        result.takedownState = takedownStatePf ? takedownStatePf.value as string : undefined;
        const indexedGooglePf = query.filter.find((pf: ParamFilter) => (pf.name === "indexedGoogle"));
        result.indexedGoogle = indexedGooglePf ? indexedGooglePf.value as string : undefined;
        const indexedBingPf = query.filter.find((pf: ParamFilter) => (pf.name === "indexedBing"));
        result.indexedBing = indexedBingPf ? indexedBingPf.value as string : undefined;
        const notesPf = query.filter.find((pf: ParamFilter) => (pf.name === "notes"));
        result.notes = notesPf ? notesPf.value as string : undefined;
    }
    return result;
}

export function secondsToHms(seconds: number): string {
    if (!seconds) {
        return "";
    }
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);

    const hDisplay = h > 0 ? h + "h " : "";
    const mDisplay = m > 0 ? m + "m " : "";
    const sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay;
}

export function numberToDottedNumber(num: number): string {
    if (num === -1) {
        return "";
    } else {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
}

export function getEmbedUrl(url: string): string {
    const site = url.includes("youtube.com") ? "yt" : url.includes("dailymotion.com") ? "dm" : url.includes("vimeo.com") ? "vm" : "";
    const urlParts = site === "yt" ? url.split("?v=") : url.split("/");
    const code = urlParts[urlParts.length - 1];
    let newUrl = "";
    switch (site) {
        case "yt":
            newUrl = `https://www.youtube.com/embed/${code}`;
            break;
        case "dm":
            newUrl = `https://www.dailymotion.com/embed/video/${code}`;
            break;
        case "vm":
            newUrl = `https://player.vimeo.com/video/${code}`;
            break;
        default:
            break;
    }
    return newUrl;
}

export function convertDate(date?: Date | string) {
    const curDate = date ? (typeof date === "string" ? new Date(date) : date) : new Date();
    const d = curDate.getDate();
    const m = curDate.getMonth() + 1;
    const y = curDate.getFullYear();
    return d + "/" + m + "/" + y;
}

export const AttachmentType = {
    TRADEMARK_REGISTRATION: "TRADEMARK_REGISTRATION",
    PATENT_REGISTRATION: "PATENT_REGISTRATION",
    DESIGN_PATENT_REGISTRATION: "DESIGN_PATENT_REGISTRATION",
    MEDIA_CONTENT: "MEDIA_CONTENT",
    POWER_OF_ATTORNEY: "POWER_OF_ATTORNEY"
};

export const InfringementType = {
    COPYRIGHT: "COPYRIGHT",
    TRADEMARK: "TRADEMARK",
    PATENT: "PATENT",
    DESIGN_PATENT: "DESIGN_PATENT",
    COUNTERFEITING: "COUNTERFEITING"
};

export const TakedownState = {
    NOT_NOTICED: "NOT_NOTICED",
    NOTICED: "NOTICED",
    REMOVED: "REMOVED",
    REMOVED_REDIRECT: "REMOVED_REDIRECT",
    NOT_REMOVED: "NOT_REMOVED",
    EXCEEDED_RETRIES: "EXCEEDED_RETRIES",
    UNNOTICEABLE: "UNNOTICEABLE"
};

export const IndexingState = {
    NOT_REQUESTED: "NOT_REQUESTED",
    INDEXED: "INDEXED",
    NOT_INDEXED: "NOT_INDEXED",
    DELISTED: "DELISTED",
    NOT_DELISTED: "NOT_DELISTED",
    DELISTING_WAITING: "DELISTING_WAITING"
};

export const TakedownStateMap = {
    [TakedownState.NOT_NOTICED]: [TakedownState.NOTICED],
    [TakedownState.NOTICED]: [TakedownState.REMOVED, TakedownState.REMOVED_REDIRECT, TakedownState.NOT_REMOVED, TakedownState.NOT_NOTICED],
    [TakedownState.REMOVED]: [TakedownState.NOT_REMOVED],
    [TakedownState.REMOVED_REDIRECT]: [TakedownState.REMOVED, TakedownState.NOT_REMOVED],
    [TakedownState.NOT_REMOVED]: [TakedownState.NOTICED],
    [TakedownState.EXCEEDED_RETRIES]: [],
    [TakedownState.UNNOTICEABLE]: []
}

export const NoticeType = {
    MAIL: "MAIL",
    FORM: "FORM"
};

export function triggerCSVDownload(content: string, filename: string): void {
    const blob = new Blob([content], {type: "text/csv"});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
