import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {CreditsState} from "../../types/detection/creditsState"
import {
    gotCreditsState,
    resetSearchCreditsState
} from "../../actions/detection/searchCredits"


const initialState: CreditsState = {
    available: -2,
    total: -2,
    infinite: false
};

export const searchCreditsReducer = createReducer(initialState)
    .handleAction(gotCreditsState, (state, action) => update(state, {
        available: {$set: action.payload.available},
        total: {$set: action.payload.total},
        infinite: {$set: action.payload.infinite}
    }))
    .handleAction(resetSearchCreditsState, (state) => update(state, {
        available: {$set: -1},
        infinite: {$set: false}
    }));
