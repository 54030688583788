import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {GetParams, setAlert} from "@kopjra/uikit";
import {gotCurrentSearch, gotDomains} from "../../detection/domainsArea";
import {getDomains} from "../../../api/detection/domainsArea";
import {transformToDomainsParams} from "../../../utils/commons";
import {addSearchManualUrls, getSearch, uploadSearchNotebook} from "../../../api/detection/searches";
import {I18n} from "react-redux-i18n";
import {Notebook} from "../../../types/extra";


export const doGetCurrentSearch = (searchId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentSearch(await getSearch(searchId)));
};

export const doGetDomains = (searchId: string, query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotDomains(await getDomains(searchId, transformToDomainsParams(query))));
};

export const doAddSearchManualUrls = (searchId: string, urls: string[]): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    if (urls.length < 1) {
        dispatch(setAlert(I18n.t("alerts.errors.addManualUrlsAtLeast"), "error"));
    } else {
        const nInsertUrls = await addSearchManualUrls(searchId, urls);
        dispatch(setAlert(`${I18n.t("alerts.infos.addManualUrls")}: ${nInsertUrls}`, "info"));
    }
}

export const doUploadSearchNotebook = (searchId: string, notebook: Notebook): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const ok = await uploadSearchNotebook(searchId, notebook);
    const msg = ok ? I18n.t("alerts.infos.notebookUploaded") : I18n.t("alerts.errors.notebookUploaded");
    dispatch(setAlert(msg, ok ? "info" : "error"));
}
