import * as React from "react";
import { Image } from "react-bootstrap";
import lightAnim from "../resources/imgs/osd_icon_path_animated.svg";

export interface StateProps {
}

export interface DispatchProps {
}

export interface Props extends StateProps, DispatchProps {
    size: number;
    variant?: "dark" | "light" | undefined;
}

export const OSDSpinner: React.FC<Props> = ({ size, variant = undefined }) => {
    return <object type="image/svg+xml" data={lightAnim} style={{width: size}}><Image src={lightAnim} height={size}/></object>;
};