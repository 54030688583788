import * as React from "react";
import {useEffect, useState} from "react";
import {
    Column,
    DataType,
    KAccordion,
    KCard,
    KContainer,
    KLabel,
    KModal,
    KModalSize,
    KModalVariant,
    KSpace,
    KSpinner,
    KSpinnerSize,
    KTableLoader,
    Table
} from "@kopjra/uikit";
import {Localize, Translate} from "react-redux-i18n";
import {useParams} from "react-router-dom";
import {OSDSpinner} from "../OSDSpinner";
import {WellDomainMail} from "../../types/takedown/wellMailsState";
import {Col, Row} from "react-bootstrap";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {WellDomainDetails} from "./WellDomainDetails";
import TDBreadcrumbs from "../../containers/takedown/TDBreadcrumbs";


export interface StateProps {
    currentWellDomain?: WellDomain;
    wellDomainMails?: WellDomainMail[];
    currentMailContent?: string;
    currentMailResponses?: WellDomainMail[];
}

export interface DispatchProps {
    onGetCurrentWellDomain: (wellId: string, domainName: string) => Promise<void>;
    onGetWellDomainMails: (wellId: string, domainName: string) => Promise<void>;
    onGetCurrentMailContent: (mailId: string) => Promise<void>;
    onGetCurrentMailResponses: (wellId: string, domainName: string, conversationId: string) => Promise<void>;
    onSetEmailOpened: (emailId: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const WellMails: React.FC<Props> = (
    {
        currentWellDomain,
        wellDomainMails,
        currentMailContent,
        currentMailResponses,
        onGetCurrentWellDomain, onGetWellDomainMails,
        onGetCurrentMailContent, onGetCurrentMailResponses,
        onSetEmailOpened
    }
) => {
    type EmailEntry = {title: JSX.Element, body: JSX.Element | string};
    // @ts-ignore
    const {wellId, domainName} = useParams();
    const [opened, setOpened] = useState(false);
    const [emailList, setEmailList] = useState<EmailEntry[]>([]);
    const [currentEmailIndexes, setCurrentEmailIndexes] = useState<number[]>([]);
    const [newIndex, setNewIndex] = useState<number>(-1);

    const rowAction = async (datum: WellDomainMail) => {
        await onGetCurrentMailResponses(wellId, domainName, datum.conversationId).then(() => {
            if (currentMailResponses) {
                const list = currentMailResponses.map(cm => getEmailEntry(cm.readByUser, cm.from, cm.to, cm.created));
                setEmailList(list);
            }
        });
        setOpened(true);
    };

    function getEmailEntry(readByUser: boolean, from: string, to: string, date: Date, body?: JSX.Element): EmailEntry {
        return {
            title: <span style={!readByUser ? {fontWeight: 700, color: "black"} : {}}>
                <KLabel text={<Translate value="wells.mails.from"/>} />
                <KSpace v={true} spaces={2}/>
                <span >{from.includes("@webintelligence.kopjra.com") ? <Translate value="wells.mails.you"/> : from}</span>
                <KSpace v={true} spaces={4}/>
                <KLabel text={<Translate value="wells.mails.dest"/>} />
                <KSpace v={true} spaces={2}/>
                <span >{to}</span>
                <KSpace v={true} spaces={4}/>
                <KLabel text={<Translate value="wells.mails.date"/>} />
                <KSpace v={true} spaces={2}/>
                <span><Localize value={date} dateFormat="kptable.date.long"/></span>
            </span>,
            body: body ? <div className={"noticeText"}>{body}</div> : <KSpinner size={KSpinnerSize.nm} />
        }
    }

    useEffect(() => {
        if (currentMailResponses && emailList.length > 0 && newIndex >= 0) {
            const newList: EmailEntry[] = emailList.map(e => e);
            const newBody = <span style={{fontSize: 8}}><p><div dangerouslySetInnerHTML={{__html: currentMailContent || ""}}/></p></span>;
            newList[newIndex] = getEmailEntry(true, currentMailResponses[newIndex].from, currentMailResponses[newIndex].to, currentMailResponses[newIndex].created, newBody);
            setEmailList(newList);
            if (!currentMailResponses[newIndex].readByUser) {
                onSetEmailOpened(currentMailResponses[newIndex].id).then();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMailContent]);

    useEffect(() => {
        if (currentMailResponses) {
            const list = currentMailResponses.map(cm => getEmailEntry(cm.readByUser, cm.from, cm.to, cm.created));
            setEmailList(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMailResponses]);

    useEffect(() => {
        onGetCurrentWellDomain(wellId, domainName).catch(e => console.warn("Get current well domain error", e));
        onGetWellDomainMails(wellId as string, domainName as string).catch((e) => console.warn("Get well domain mails error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <KContainer>
                <TDBreadcrumbs type={"emails"} wellId={wellId}/>
                <KCard header={<Translate value={"wells.mails.title"}/>}>
                    <Row><Col>
                        <WellDomainDetails currentWellDomain={currentWellDomain}/>
                    </Col></Row>
                    <KSpace/>
                    {wellDomainMails ? (
                        <Row>
                            <Col>
                                <Table
                                    checkboxes={false}
                                    globalWaiter={<OSDSpinner size={200}/>}
                                    waiter={<OSDSpinner size={100} variant="dark"/>}
                                    id={`wellMails/list`}
                                    total_count={wellDomainMails.length}
                                    loaderFunc={() => onGetWellDomainMails(wellId as string, domainName as string).catch((e) => console.warn("Get well domain mails error", e))}
                                    data={wellDomainMails}
                                    hideColumnSelector={true}
                                    hideFilters={true}
                                    keyField={"id"}
                                    hidePager={true}
                                    rowAction={rowAction}
                                >
                                    <Column colid="subject" classes="text-start" name="wells.mails.subject" type={DataType.GENERIC} sort={"subject"} />
                                    <Column colid="to" classes="text-center" name="wells.mails.to" type={DataType.GENERIC} sort={"to"} />
                                    <Column colid="noticedUrls" classes="text-center" name="wells.mails.noticedUrls" type={DataType.GENERIC} sort={"noticedUrls"} colspan={2}/>
                                    <Column colid="created" classes="text-start" name="wells.mails.dateSent" type={DataType.DATETIME} sort={"created"} colspan={2}/>
                                    <Column colid="lastReceivedDate" classes="text-start" name="wells.mails.lastReceivedDate" type={DataType.DATETIME} sort={"lastReceivedDate"} colspan={2}/>
                                </Table>
                            </Col>
                        </Row>
                        ) : (
                            <KTableLoader />
                        )}
                </KCard>
            </KContainer>
            <KModal size={KModalSize.xl} show={opened} variant={KModalVariant.secondary} onHide={() => setOpened(false)} header={<Translate value={"wells.mails.received"}/>}
                    footer={<></>}>
                {currentMailResponses ? (
                    <KAccordion elements={emailList}
                                onChangeOpened={(indexes: number[]) => {
                                    if (indexes.length > currentEmailIndexes.length) {
                                        const differenceArray = indexes.filter(x => !currentEmailIndexes.includes(x));
                                        setNewIndex(differenceArray[0]);
                                        onGetCurrentMailContent(currentMailResponses[differenceArray[0]].id).then();
                                    }
                                    setCurrentEmailIndexes(indexes);
                                }}
                    />
                ):(
                    <OSDSpinner size={100}/>
                )}
            </KModal>
        </>
    );
}
