import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, ResultsArea} from "../../components/detection/ResultsArea";
import {RootState, ThunkDispatch} from "../../types";
import {
    doGetCurrentDomain,
    doGetCurrentSearch,
    doGetResults,
    doUpdateNotes,
    doValidateResults
} from "../../actions/thunks/detection/resultsArea";
import { Result } from "../../types/detection/resultsAreaState";
import {GetParams} from "@kopjra/uikit";
import {routerTools, Routes} from "../../utils/router";
import {resetResultUGCState} from "../../actions/detection/resultUGC";


function mapStateToProps({resultsArea}: RootState): StateProps {
    return {
        currentSearch: resultsArea.currentSearch,
        currentDomain: resultsArea.currentDomain,
        results: resultsArea.list,
        total: resultsArea.total,
        forceUpdate: resultsArea.forceUpdate
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetResults: (searchId: string, domainId: string, query) => dispatch(doGetResults(searchId, domainId, query)),
        onValidateResults: (searchId: string, results: Array<Result>, domainId?: string, updateAll?: string, queryParams?: GetParams) => dispatch(doValidateResults(searchId, results, domainId, updateAll, queryParams)),
        onOpenResult: (productType: string, searchId: string, domainId: string, resultId: string) => {
            dispatch(resetResultUGCState());
            routerTools.push(Routes.RESULT(productType, searchId, domainId, resultId));
        },
        onGetCurrentDomain: (searchId: string, domainId: string) => dispatch(doGetCurrentDomain(searchId, domainId)),
        onGetCurrentSearch: (searchId: string) => dispatch(doGetCurrentSearch(searchId)),
        onUpdateNotes: (searchId: string, domainId: string, resultId: string, query: GetParams, notes?: string) => dispatch(doUpdateNotes(searchId, domainId, resultId, query, notes))
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(ResultsArea);
