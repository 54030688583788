import React, {useEffect} from "react";
import {KApp, KErrorPage} from "@kopjra/uikit";
import Toolbar from "../containers/Toolbar";
import {Route, Switch} from "react-router-dom";
import {ProtectedRoute, Routes} from "../utils/router";
import {Translate} from "react-redux-i18n";
import UserProfile from "../containers/UserProfile";
import {useAuth0} from "@auth0/auth0-react";
import {initGetTokenFunction} from "../api";
import {BigWaiter} from "./BigWaiter";
import {ManageSearches} from "./detection/ManageSearches";
import ResultsArea from "../containers/detection/ResultsArea";
import CrawlArea from "../containers/detection/CrawlArea";
import DomainsArea from "../containers/detection/DomainsArea";
import StatsArea from "../containers/detection/StatsArea";
import Home from "../containers/Home";
import ResultUGC from "../containers/detection/ResultUGC";
import {ManageDomainsInfo} from "./analysis/ManageDomainsInfo";
import {ManageDomainsSearches} from "./analysis/ManageDomainsSearches";
import DomainInfoDetails from "../containers/analysis/DomainInfoDetails";
import { Dashboard } from "./Dashboard";
import WellDomainsArea from "../containers/takedown/WellDomainsArea";
import WellDomainResultsArea from "../containers/takedown/WellDomainResultsArea";
import {ManageWells} from "./takedown/ManageWells";
import WellStaticInfo from "../containers/takedown/WellCreation";
import WellMails from "../containers/takedown/WellMails";
import WellFlatResultsArea from "../containers/takedown/WellFlatResultsArea";
import {User} from "../types/main";
import Analysis from "../containers/analysis/Analysis";
import DomainSearchDetails from "../containers/analysis/DomainAnalysisArea";
export interface StateProps {
    currentUser?: User;
}

export interface DispatchProps {
    onGetCurrentUser: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const App: React.FC<Props> = ({currentUser, onGetCurrentUser}) => {
    const {getAccessTokenSilently, isLoading, isAuthenticated} = useAuth0();
    useEffect(() => {
        initGetTokenFunction(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (!isLoading && isAuthenticated && !currentUser) {
            onGetCurrentUser().catch(console.log);
        }
    }, [isLoading, isAuthenticated, currentUser, onGetCurrentUser]);

    function getComponent(isLoading: boolean): JSX.Element {
        if (isLoading) {
            return <BigWaiter/>;
        } else {
            return <KApp>
                <Toolbar/>
                <Switch>
                    <ProtectedRoute exact={true} path={Routes.HOME} component={Home}/>
                    <ProtectedRoute exact={true} path={Routes.USER_ACCOUNT} component={UserProfile}/>
                    <ProtectedRoute exact={true} path={Routes.DASHBOARD()} component={Dashboard}/>
                    <ProtectedRoute exact={true} path={Routes.SEARCHES()} component={ManageSearches}/>
                    <ProtectedRoute exact={true} path={Routes.ANALYSIS()} component={Analysis}/>
                    <ProtectedRoute exact={true} path={Routes.DOMAINS_INFO()} component={ManageDomainsInfo}/>
                    <ProtectedRoute exact={true} path={Routes.DOMAINS_SEARCHES()} component={ManageDomainsSearches}/>
                    <ProtectedRoute exact={true} path={Routes.STATS()} component={StatsArea}/>
                    <ProtectedRoute exact={true} path={Routes.DOMAINS()} component={DomainsArea}/>
                    <ProtectedRoute exact={true} path={Routes.RESULTS()} component={ResultsArea}/>
                    <ProtectedRoute exact={true} path={Routes.CRAWL_RESULTS()} component={CrawlArea}/>
                    <ProtectedRoute exact={true} path={Routes.RESULT()} component={ResultUGC}/>
                    <ProtectedRoute exact={true} path={Routes.DOMAIN_INFO()} component={DomainInfoDetails}/>
                    <ProtectedRoute exact={true} path={Routes.DOMAIN_SEARCH()} component={DomainSearchDetails}/>
                    <ProtectedRoute exact={true} path={Routes.WELLS()} component={ManageWells}/>
                    <ProtectedRoute exact={true} path={Routes.WELL_DOMAINS()} component={WellDomainsArea}/>
                    <ProtectedRoute exact={true} path={Routes.WELL_DOMAIN_RESULTS()} component={WellDomainResultsArea}/>
                    <ProtectedRoute exact={true} path={Routes.WELL_FLAT_RESULTS()} component={WellFlatResultsArea}/>
                    <ProtectedRoute exact={true} path={Routes.WELL_UPDATE()} component={WellStaticInfo}/>
                    <ProtectedRoute exact={true} path={Routes.WELL_CREATE()} component={WellStaticInfo}/>
                    <ProtectedRoute exact={true} path={Routes.WELL_MAILS()} component={WellMails}/>
                    <Route path={Routes.ALL} render={() => <KErrorPage title={<Translate value="error.page"/>} code={"404"} message={<Translate value="error.notFound"/>}/>}/>
                </Switch>
            </KApp>
        }
    }

    return getComponent(isLoading);
}
