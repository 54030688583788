import {Search} from "./searchState";

export enum DomainValidationState {
    "COMPLETED" = "COMPLETED",
    "IN_VALIDATION" = "IN_VALIDATION",
    "NOT_STARTED" = "NOT_STARTED"
}

export interface Domain {
    id: string;
    name: string;
    potentialInfringements: number;
    channel: string;
    importance: number;
    validationStatus: string;
    nResultsValidated: number;
}

export interface DomainsState {
    currentSearch?: Search;
    list?: Domain[];
    total: number;
}
