import * as React from "react";
import {ReactChild, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType, FixedSelectionFilter, FreeTextFilter,
    GetParams, GlobalActionType,
    KCard,
    KLabel, KSelect, KSELECT_SIZE,
    KSpace, KSpinner,
    KTableLoader,
    SortingDirection,
    Table, tooltipate,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {OSDSpinner} from "../OSDSpinner";
import {tableGetParams} from "../../utils/commons";
import {DomainSearch} from "../../types/analysis/domainSearchesState";
import {SearchState} from "../../types/detection/searchState";
import {DomainValidationState} from "../../types/detection/domainsState";
import {DomainSearchGetParams} from "../../api/analysis/domainsSearches";


export interface StateProps {
    domainSearches?: DomainSearch[];
    domainSearchesTags?: string[];
    total: number;
    locale: string;
}

export interface DispatchProps {
    onGetDomainSearches: (query: DomainSearchGetParams) => Promise<void>;
    onOpenDomainSearch: (domainSearchId: string) => void;
    onDeleteDomainSearches: (domainSearchIds: string[], deleteAll: boolean, queryParams?: DomainSearchGetParams) => Promise<void>;
    onUpdateDomainSearch(domainSearchId: string): Promise<void>;
    onAddDomainSearchTag: (searchId: string, tag: string) => Promise<void>;
    onDeleteDomainSearchTag: (searchId: string, tag: string) => Promise<void>;
    onGetDomainsSearchesTags: () => Promise<void>;
    onReportGeneration: (domainSearchIds: string[], reportType: string, language: string, all: boolean, queryParams?: any) => Promise<void>;
}

export interface InnerProps {
    reduced?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainSearches: React.FC<Props> = ({
                                                 domainSearches, total, domainSearchesTags, locale,
                                                    onAddDomainSearchTag, onDeleteDomainSearchTag, onGetDomainSearches, onOpenDomainSearch, onReportGeneration,
                                                    onDeleteDomainSearches, onUpdateDomainSearch, onGetDomainsSearchesTags, reduced = true
                                             }) => {

    const queryParams: GetParams = {
        top: 5,
        skip: 0,
        sort: "createdAt",
        direction: SortingDirection.DOWN,
    };

    const domainSearchesRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetDomainSearches(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get domains info error", e));
    };

    useEffect(() => {
        onGetDomainsSearchesTags().catch(() => console.warn("Get domain searches tags error"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        domainSearchesRetriever(reduced ? queryParams : tableGetParams("domainsSearches/list/full"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rowAction = async (datum: DomainSearch) => {
        if (datum.status !== SearchState.STARTED && datum.status !== SearchState.REFRESHING) {
            onOpenDomainSearch(datum.id);
        }
    };

    function renderNDomains(search: DomainSearch): ReactChild {
        if (search.status === SearchState.STARTED || search.status === SearchState.REFRESHING) {
            return <KSpinner/>;
        } else if (search.status === SearchState.COMPLETED || search.status === SearchState.COMPLETED_FORCED) {
            return <div>{search.nDomains}</div>
        } else {
            return <div className="fal fa-times"/>
        }
    }

    function renderTags(domainSearch: DomainSearch): ReactChild {
        return <div className={"table-select"} onClick={(e) => e.stopPropagation()}>
            <KSelect id={`${domainSearch.id}`} creatable multi searchable
                     options={domainSearchesTags!.map(t => {
                         return {label: t, value: t};
                     })}
                     value={domainSearch.tags}
                     onChange={async (_val, updated) => {
                         if (updated && updated.length > 0) {
                             if (updated[0].operation === "added") {
                                 await onAddDomainSearchTag(domainSearch.id, updated[0].value);
                             } else {
                                 await onDeleteDomainSearchTag(domainSearch.id, updated[0].value);
                             }
                         }
                     }}
                     size={KSELECT_SIZE.nm}
            /></div>;
    }

    function renderValidationStatus(search: DomainSearch): ReactChild {
        const colorMap = new Map([
            [`${DomainValidationState.COMPLETED}`, "#2BD18F"],
            [`${DomainValidationState.IN_VALIDATION}`, "#FFD754"],
            [`${DomainValidationState.NOT_STARTED}`, "#DFE7F5"],
        ]);
        const tipMap = new Map([
            [`${DomainValidationState.COMPLETED}`, "domains.table.validationTipCompleted"],
            [`${DomainValidationState.IN_VALIDATION}`, "domains.table.validationTipProgress"],
            [`${DomainValidationState.NOT_STARTED}`, "domains.table.validationTipNotStarted"],
        ]);
        const statusIndicator = <i className="fa fa-circle" style={{color: colorMap.get(search.validationStatus)}}/>;
        return tooltipate(statusIndicator, <Translate value={tipMap.get(search.validationStatus) || ""}/>);
    }

    const actions: ActionType<DomainSearch>[] = [
        {
            name: <><i className="fal fa-folder-open action-icon"/><Translate value="domainSearches.table.actions.view"/></>,
            handler: rowAction,
            collapsable: true,
            shouldRender: (datum: DomainSearch) => datum.status !== SearchState.STARTED && datum.status !== SearchState.REFRESHING,
        },
        {
            name: <><i className="fal fa-arrows-rotate action-icon">&nbsp;</i><Translate
                value={"domainSearches.table.actions.update"}/></>,
            handler: async (datum: DomainSearch) => {
                await onUpdateDomainSearch(datum.id);
            },
            shouldRender: (datum: DomainSearch) => datum.status !== SearchState.STARTED && datum.status !== SearchState.REFRESHING,
            collapsable: true,
            confirmation: true
        },
        {
            name: <><i className="fal fa-file-csv action-icon">&nbsp;</i><Translate
                value={"searches.table.actions.csv"}/></>,
            handler: async (datum: DomainSearch) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("domainSearches.table.actions.downloadReportCSV")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    await onReportGeneration([datum.id], "csv", locale, false);
                }
            },
            shouldRender: (datum: DomainSearch) => (datum.status === SearchState.COMPLETED) || (datum.status === SearchState.COMPLETED_FORCED),
            collapsable: true
        },
    ];

    const globalActions: GlobalActionType<DomainSearch>[] = [
        {
            name: <div className="fal fa-trash action-icon" style={{color: "#EF615E"}}/>,
            handler: async (data: DomainSearch[], config: any) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.deleteMessage")
                };
                const deleteSearches = await confirmBox(confirmBoxConf);
                if (deleteSearches) {
                    const searchIds = data.map(s => s.id);
                    await onDeleteDomainSearches(searchIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
    ];

    return (
        <KCard header={<Translate value={`domainSearches.card.title${reduced ? "Last" : "Complete"}`}/>}>
            {domainSearches && domainSearchesTags ? (
                <>
                    {reduced && domainSearches.length > 0 ? (
                        <>
                            <Row>
                                <Col className="text-center" md={12}>
                                    <span className="bigNumberGray">{total}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center" md={12}>
                                    <KLabel text={<Translate value="domainSearches.dashboard.totalSearches"/>}/>
                                </Col>
                            </Row>
                            <KSpace/>
                        </>
                    ) : (
                        <></>
                    )}
                    {!reduced || (reduced && domainSearches.length > 0) ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <Table
                                    filterDefinition={[
                                        new FreeTextFilter("query", I18n.t("domainSearches.table.query")),
                                        new FixedSelectionFilter("tags", I18n.t("searches.table.filters.tags"), domainSearchesTags.map(st => {
                                            return {label: st, value: st};
                                        }), true),
                                    ]}
                                    checkboxes={!reduced}
                                    globalActions={reduced ? [] : globalActions}
                                    globalWaiter={<OSDSpinner size={200}/>}
                                    waiter={<OSDSpinner size={100} variant="dark"/>}
                                    id={`domainsSearches/list/${reduced ? "reduced" : "full"}`}
                                    total_count={total}
                                    loaderFunc={(q: GetParams) => domainSearchesRetriever(q, reduced ? queryParams : undefined)}
                                    loadInterval={10000}
                                    data={domainSearches}
                                    hideColumnSelector={true}
                                    hideFilters={reduced}
                                    keyField={"id"}
                                    hidePager={reduced}
                                    rowAction={rowAction}
                                >
                                    <Column colid="query" classes="text-start" name="domainSearches.table.query"
                                            type={DataType.GENERIC} sort={!reduced ? "query" : undefined} />
                                    <Column colid="type" classes="text-start" name="domainSearches.table.type"
                                            type={DataType.GENERIC} sort={!reduced ? "type" : undefined} />
                                    <Column colid="nDomains" classes="text-center" name="domainSearches.table.nDomains"
                                            type={DataType.GENERIC} sort={!reduced ? "nDomains" : undefined} render={renderNDomains}/>
                                    <Column colid="validationStatus" classes="text-center" name="domainSearches.table.validation"
                                            type={DataType.GENERIC} sort={!reduced ? "validationStatus" : undefined} render={renderValidationStatus}/>
                                    <Column colid="createdAt" classes="text-center" name="domainSearches.table.created"
                                            type={DataType.DATE} sort={!reduced ? "createdAt" : undefined} />
                                    <Column colid="refreshed" classes={"text-center"} name={"domainSearches.table.updated"}
                                            type={DataType.DATE} sort={!reduced ? "refreshed" : undefined}/>
                                    <Column colid="tags" classes="text-center" name={"domainSearches.table.tags"}
                                            render={renderTags} type={DataType.GENERIC} visible={!reduced}/>
                                    <Column colid="actions" classes="text-start" name="" type={DataType.ACTIONS}
                                            actions={actions} collapsed={true} colspan={2}/>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <KLabel text={<Translate value="domainSearches.table.noSearches" />} />
                    )}
                </>
            ) : (
                <KTableLoader/>
            )}
        </KCard>
    );
};
