export function setWebType(webType: string): void {
    localStorage.setItem("webType", webType);
}

export function getWebType(): string {
    return localStorage.getItem("webType") as string;
}

export const DomainSearchType = {
    REGISTERED: "REGISTERED",
    FAVICON: "FAVICON",
    SUBDOMAINS: "SUBDOMAINS",
    ADS: "ADS",
    TYPOSQUAT: "TYPOSQUAT"
}

export const SearchType = {
    DEEP: "DEEP",
    UGC: "UGC",
    CLEAR: "CLEAR",
    CUSTOM: "CUSTOM",
    CRAWL: "CRAWL"
}

export const ProductType = {
    DETECTION:  "detection",
    ANALYSIS:  "analysis",
    TAKEDOWN: "takedown",
    DOMAIN_INFO: "domainInfo",
    DOMAIN_SEARCHES: "domainSearches"
}

export const UGCSources = {
    YOUTUBE: "YOUTUBE",
    DAILYMOTION: "DAILYMOTION",
    VIMEO: "VIMEO"
};

export const ClearLanguages = {
    it: "it",
    es: "es",
    fr: "fr",
    de: "de",
    uk: "uk",
    us: "us"
};

export type Notebook = {url: string, notes: string}[];
