import React from "react";
import {KCard, KContainer, KUserProfile} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {Col, Row} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {UserProfileData} from "../types";

export interface StateProps {
}

export interface DispatchProps {
    onUpdateUserProfile: (up: UserProfileData) => Promise<void>;
    onResendEmail: () => Promise<void>;
    onResetPassword: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const UserProfile: React.FC<Props> = ({onResendEmail, onResetPassword, onUpdateUserProfile}: Props) => {
    const {user, isAuthenticated} = useAuth0();

    return isAuthenticated ? (
        <KContainer>
            <Row>
                <Col md={6}>
                    <KCard header={<Translate value="user.profile"/>}>
                        <KUserProfile user={{
                            picture: user?.picture || "https://kopjra-public.s3.eu-west-1.amazonaws.com/user-circle-solid.svg",
                            email_verified: true,
                            email: user?.email || "",
                            name: `${user?.firstname} ${user?.lastname}`,
                            sub: "",
                        }} onResetPassword={onResetPassword} onResendEmail={onResendEmail} onUpdateUserProfile={onUpdateUserProfile}/>
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    ): null;
}
