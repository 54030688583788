import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {SearchesState, Search} from "../../types/detection/searchState";
import {
    addedSearchTag,
    cancelSearch, deletedSearchTag,
    doSearch,
    gotSearches, gotSearchesTags,
    gotTotalPotentialInfringements,
    resetSearchesState
} from "../../actions/detection/searches";


const initialState: SearchesState = {
    total: 0,
    totalPotentialInfringements: 0
};

export const searchesReducer = createReducer(initialState)
    .handleAction(gotSearches, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(doSearch, (state, action) => update(state, {
        list: {$set: [action.payload, ...state.list as Search[]]},
        total: {$set: (state.total += 1)},
    }))
    .handleAction(gotTotalPotentialInfringements, (state, action) => update(state, {
        totalPotentialInfringements: {$set: action.payload}
    }))
    .handleAction(gotSearchesTags, (state, action) => update(state, {
        searchesTags: {$set: action.payload}
    }))
    .handleAction(cancelSearch, (state, action) => {
        if (state.list) {
            const searchId = action.payload;
            const newState = state.list.filter(search => search.id !== searchId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total -= 1)}
            })
        } else {
            return state;
        }
    })
    .handleAction(resetSearchesState, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0},
        credits: {$set: undefined},
        totalPotentialInfringements: {$set: 0}
    }))
    .handleAction(addedSearchTag, (state, action) => {
        const index = (state.list || []).findIndex(s => s.id === action.payload.searchId);
        if (index > -1) {
            return update(state, {
                list: {
                    [index]: {
                        tags: {$push: [action.payload.tag]}
                    }
                }
            })
        } else {
            return state;
        }
    })
    .handleAction(deletedSearchTag, (state, action) => {
        const index = (state.list || []).findIndex(s => s.id === action.payload.searchId);
        if (index > -1) {
            const tagIndex = ((state.list || [])[index].tags || []).indexOf(action.payload.tag);
            return update(state, {
                list: {
                    [index]: {
                        tags: {$splice: [[tagIndex, 1]]}
                    }
                }
            })
        } else {
            return state;
        }
    });
