import {UserProfileData} from "../types";

export async function resendEmail() {
}

export async function resetPassword() {
}

export async function updateUserProfile(up: UserProfileData) {
}
