import {ThunkResult} from "../../types";
import {setCurrentUser} from "../main";
import {getCurrentUser} from "../../api/main";

export const doGetCurrentUser = (): ThunkResult<Promise<void>> => async (dispatch) => {
    const user = await getCurrentUser();
    if (user) {
        dispatch(setCurrentUser(user));
    }
}
