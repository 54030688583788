import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Localize, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {KLabel, KSpace} from "@kopjra/uikit";
import {Well} from "../../types/takedown/wellsState";
import {KCredit} from "../KCredit";
import {useParams} from "react-router-dom";

export type WellDetailsProps = {
    currentWell?: Well;
    type: "domains" | "results";
    onUpdateWell: (productType: string, wellId: string) => void;
};

export const WellDetails: React.FC<WellDetailsProps> = ({currentWell, type, onUpdateWell}) => {
    // @ts-ignore
    const {productType, wellId} = useParams();
    const wellDetailsForDomains = <>
        <Row>
            <Col sm={12} md={4}>
                <Row>
                    <Col sm={3} md={3}><KLabel text={<Translate value="wells.table.name"/>}/></Col>
                    <Col sm={9}><span style={{fontSize: 12}}>{currentWell && currentWell.baseInfo.name}</span></Col>
                </Row>
                <Row>
                    <Col sm={3} md={3}><KLabel text={<Translate value="wells.table.infringementType"/>}/></Col>
                    <Col sm={9}><span style={{fontSize: 12}}>{currentWell && currentWell.baseInfo.infringementType}</span></Col>
                </Row>
                <Row>
                    <Col sm={3} md={3}><KLabel text={<Translate value="wells.table.created"/>}/></Col>
                    <Col sm={9}><span style={{fontSize: 12}}>{currentWell ? (<Localize value={currentWell.created} dateFormat="kptable.date.short"/>) : (<span/>)}</span></Col>
                </Row>
                <KSpace/>
                <Row>
                    <Col><div style={{color: "#5198F4", cursor: "pointer", fontSize: 12}} onClick={() => onUpdateWell(productType, wellId)}><i className="fal fa-edit"/>&nbsp;<Translate value={`wells.static.updateWell`}/></div></Col>
                </Row>
            </Col>
            <Col className="text-center">
                <KCredit remaining={(currentWell && currentWell.nUrlsNoticed) || 0} total={(currentWell && currentWell.nUrls) || 0}
                         infinite={false} labelText={<Translate value={`wells.static.resultsNoticed`}/>}/>
            </Col>
            <Col className="text-center">
                <span className="bigNumberColored">{`${currentWell ? Math.round((currentWell.nUrlsRemoved / currentWell.nUrls) * 100) : 0}%`}</span>
                <br/>
                <KLabel text={<Translate value={`wells.overview.globalTakedownPercent`}/>}/>
            </Col>
            <Col className="text-center">
                <span className="bigNumberGray">{currentWell && currentWell.nWellMailsToRead}</span>
                <br/>
                <KLabel text={<Translate value={`wells.static.mailsToRead`}/>}/>
            </Col>
        </Row>
    </>;

    const wellDetailsForResults = wellDetailsForDomains;

    const renderDetails = type === "domains" ? wellDetailsForDomains : wellDetailsForResults;
    return (
        renderDetails
    );
};
