import {apiCall, HttpMethod} from "../index";
import {WellDomainResult} from "../../types/takedown/wellDomainResultsAreaState";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {transformToWellDomain} from "./wellDomainsArea";
import {Notebook} from "../../types/extra";


export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetWellDomainResultsResponse = GetListResponse<WellDomainResult>;

export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetWellDomainResultsParams extends GetParams {
    domainName?: string;
    url?: string;
    takedownState?: string;
    indexedGoogle?: string;
    indexedBing?: string;
    onlyNoticeable?: boolean;
    notes?: string;
}

export interface PostWellResultsIndexingParams extends GetParams {
    url?: string;
    takedownState?: string;
    indexedGoogle?: string;
    indexedBing?: string;
    notes?: string;
}

export function transformToWellDomainResult(obj: any): WellDomainResult {
    const wellDomainResult: WellDomainResult = {
        url: obj.url,
        takedownState: obj.takedownState,
        takedownStateLastUpdate: obj.takedownStateLastUpdate,
        noticeAttempts: obj.noticeAttempts,
        lastNotice: obj.lastNotice,
        screenshotAvailable: obj.screenshotLocation?.available,
        indexedGoogle: obj.indexedGoogle,
        indexedGoogleLastUpdate: obj.indexedGoogleLastUpdate,
        googleDelistingRequestId: obj.googleDelistingRequestId,
        indexedBing: obj.indexedBing,
        indexedBingLastUpdate: obj.indexedBingLastUpdate,
        bingDelistingRequestId: obj.bingDelistingRequestId,
        notes: obj.notes,
    };
    return wellDomainResult;
}

export async function getWellDomainResults(wellId: string, query: GetWellDomainResultsParams, domainName?: string): Promise<GetWellDomainResultsResponse> {
    if (domainName) {
        query.domainName = domainName;
    }
    const res = await apiCall({urlComponent: `/wells/${wellId}/results`, query});
    const json = await res.json();
    const results = await json.results.map((o: any) => transformToWellDomainResult(o));

    return {
        results: results,
        total: json.total,
    };
}

export async function getWellDomain(wellId: string, domainName?: string): Promise<WellDomain> {
    const res = await apiCall({urlComponent: `/wells/${wellId}/domains/${domainName}`});
    const json = await res.json();
    const domain = transformToWellDomain(json);
    return domain;
}

export async function getNResultsWillChange(wellId: string, data: WellDomainResult[], newState: string, all: boolean, queryParameters: any, domainName?: string): Promise<number> {
    const res = await apiCall({
        method: HttpMethod.PATCH,
        urlComponent: `/wells/${wellId}/results/number`,
        body: {
            domainName: domainName,
            urls: data.map(r => r.url),
            newTakedownState: newState,
            all: all,
            queryParameters
        }
    });
    const outcome = res.status < 400 ? ((await res.json()).n) : 0;
    return outcome;
}

export async function updateTakedownState(wellId: string, data: WellDomainResult[], newState: string, all: boolean, queryParameters?: any, domainName?: string): Promise<boolean> {
    const res = await apiCall({
        method: HttpMethod.PATCH,
        urlComponent: `/wells/${wellId}/results`,
        body: {
            domainName: domainName,
            urls: data.map(r => r.url),
            newTakedownState: newState,
            all: all,
            queryParameters
        }
    });
    const outcome = res.status < 400;
    return outcome;
}

export async function requestIndexing(wellId: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: PostWellResultsIndexingParams, domainName?: string): Promise<void> {
    const body: any = {
        domainName: domainName,
        urls: data.map(r => r.url),
        all: all,
        searchEngine: searchEngine
    };
    body.queryParameters = {
        url: queryParams?.url,
        takedownState: queryParams?.takedownState,
        indexedGoogle: queryParams?.indexedGoogle,
        indexedBing: queryParams?.indexedBing
    };
    await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/wells/${wellId}/results/indexing`,
        body: body
    });
}

export async function createScreenshotArchiveLink(wellId: string, domainName?: string): Promise<string> {
    const body = domainName ? {domainName: domainName} : undefined;
    const res = await apiCall({
        urlComponent: `/wells/${wellId}/results/screenshots/archive:generatePublicUrl`,
        method: HttpMethod.POST,
        body: body
    });

    return res.status === 200 ? (await res.json()).signedUrl : "";
}

export async function triggerScreenshots(wellId: string, data: WellDomainResult[], all: boolean, queryParams?: PostWellResultsIndexingParams, domainName?: string): Promise<void> {
    const body: any = {
        domainName: domainName,
        urls: data.map(r => r.url),
        all: all,
        queryParameters: {}
    };
    if (queryParams?.url) {
        body.queryParameters.url = queryParams.url;
    }
    if (queryParams?.takedownState) {
        body.queryParameters.takedownState = queryParams.takedownState;
    }
    await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/wells/${wellId}/results/screenshot`,
        body: body
    });
}

export async function getScreenshotUrl(wellId: string, url: string, domainName?: string): Promise<string> {
    const res = await apiCall({
        method: HttpMethod.GET,
        urlComponent: `/wells/${wellId}/results/screenshot`,
        query: {url: url, domainName: domainName}
    });
    const tempUrl = res.status === 200 ? (await res.json()).url : "";
    return tempUrl;
}

export async function getNUrlsDeletable(wellId: string, urls: string[], deleteAll: boolean, domainName?: string, queryParams?: GetWellDomainResultsParams): Promise<number> {
    const deleteMessage = {
        domainName: domainName,
        urls: urls,
        all: deleteAll,
        queryParameters: {url: queryParams?.url, takedownState: queryParams?.takedownState}
    };
    const res = await apiCall({
        urlComponent: `/wells/${wellId}/results/nDeletable`,
        method: HttpMethod.PATCH,
        body: deleteMessage
    });
    const n = res.status === 200 ? (await res.json()).n : 0;
    return n;
}

export async function deleteWellDomainResults(wellId: string, urls: string[], deleteAll: boolean, queryParams?: GetWellDomainResultsParams, domainName?: string): Promise<boolean> {
    const deleteMessage = {
        domainName: domainName,
        urls: urls,
        all: deleteAll,
        queryParameters: {url: queryParams?.url, takedownState: queryParams?.takedownState}
    };
    const res = await apiCall({
        urlComponent: `/wells/${wellId}/results`,
        method: HttpMethod.DELETE,
        body: deleteMessage
    });
    const outcome = res.status < 400;
    return outcome;
}

export async function updateWellResultNotes(wellId: string, url: string, notes?: string, domainName?: string): Promise<boolean> {
    const res = await apiCall({
        method: HttpMethod.PATCH,
        urlComponent: `/wells/${wellId}/results/notes`,
        body: {domainName: domainName, url: url, notes: notes}
    });
    const outcome = res.status < 400;
    return outcome;
}

export const uploadWellNotebook = async (wellId: string, notebook: Notebook): Promise<boolean> => {
    const res = await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/wells/${wellId}/results/notebook`,
        body: {notebook}
    });
    return res.status < 400;
}
