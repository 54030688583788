import {connect} from "react-redux";
import {DispatchProps, InnerProps, Searches, StateProps} from "../../components/detection/Searches";
import {RootState, ThunkDispatch} from "../../types";
import {
    doAddSearchTag,
    doDeleteSearches,
    doDeleteSearchTag,
    doGetSearches,
    doGetSearchesTags,
    doGetTotalPotentialInfringements,
    doReportGeneration, doStopOrResumeCrawlSearch,
    doUpdateSearch
} from "../../actions/thunks/detection/searches";
import {routerTools, Routes} from "../../utils/router";
import {resetStatsState} from "../../actions/detection/statsArea";
import {GetParams} from "../../api/detection/searches";
import {ProductType, SearchType} from "../../types/extra";
import {resetResultsState} from "../../actions/detection/resultsArea";
import {unregister} from "@kopjra/uikit";


function mapStateToProps({searches, i18n}: RootState): StateProps {
    return {
        searches: searches.list,
        searchesTags: searches.searchesTags,
        total: searches.total,
        totalPotentialInfringements: searches.totalPotentialInfringements,
        locale: i18n.locale,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSearches: (query) => dispatch(doGetSearches(query)),
        onGetTotalPotentialInfringements: () => dispatch(doGetTotalPotentialInfringements()),
        onOpenSearch: (searchId: string, webType: string) => {
            if (webType === SearchType.CRAWL) {
                dispatch(unregister("results/list"));
                dispatch(resetResultsState());
                routerTools.push(Routes.CRAWL_RESULTS(ProductType.DETECTION, searchId));
            } else {
                dispatch(resetStatsState());
                routerTools.push(Routes.STATS(ProductType.DETECTION, searchId));
            }
        },
        onDeleteSearches: (searchIds: string[], deleteAll: boolean, queryParams?: GetParams) => dispatch(doDeleteSearches(searchIds, deleteAll, queryParams)),
        onReportGeneration: (searchIds: string[], reportType: string, language: string, all: boolean, queryParams?: any) => dispatch(doReportGeneration(searchIds, reportType, language, all, queryParams)),
        onGetSearchesTags: () => dispatch(doGetSearchesTags()),
        onAddSearchTag: (searchId: string, tag: string) => dispatch(doAddSearchTag(searchId, tag)),
        onDeleteSearchTag: (searchId: string, tag: string) => dispatch(doDeleteSearchTag(searchId, tag)),
        onStopOrResumeCrawlSearch: (searchId: string, state: string) => dispatch(doStopOrResumeCrawlSearch(searchId, state)),
        onUpdateSearch: (searchId: string, params: GetParams, refreshIntervalDays?: number) => dispatch(doUpdateSearch(searchId, params, refreshIntervalDays))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Searches);
