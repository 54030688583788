import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {
    gotCurrentMailContent,
    gotCurrentMailResponses,
    gotWellDomainMails
} from "../../takedown/wellMails";
import {getMailContent, getMailResponses, getWellDomainMails, setMailOpened} from "../../../api/takedown/wellMails";


export const doGetWellDomainMails = (wellId: string, domainName: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotWellDomainMails(await getWellDomainMails(wellId, domainName)));
};

export const doGetCurrentMailContent = (mailId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentMailContent(await getMailContent(mailId)));
};

export const doGetCurrentMailResponses = (wellId: string, domainName: string, conversationId: string) => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentMailResponses(await getMailResponses(wellId, domainName, conversationId)));
};

export const doSetEmailOpened = (emailId: string) => async () => {
    await setMailOpened(emailId);
}
