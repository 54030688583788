import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KCard, KSpace, KSpinner} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {KCredit} from "../KCredit";
import {useEffect} from "react";
import {CreditsState} from "../../types/detection/creditsState";
import {DomainSearchNew} from "./DomainSearchNew";
import {NewDomainSearch} from "../../types/analysis/domainSearchesState";


export interface StateProps {
    credits: CreditsState;
}

export interface DispatchProps {
    onGetCreditsState: () => Promise<void>;
    onDomainSearch: (newDomainSearch: NewDomainSearch) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainsSearchesOverview: React.FC<Props> = ({credits, onGetCreditsState, onDomainSearch}) => {
    useEffect(() => {
        onGetCreditsState().catch((e) => console.warn("Get Credits Stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KCard header={<Translate value="domainSearches.card.title"/>}>
            <Row md={12}>
                <Col md={12} className="text-center">
                    {credits.infinite || credits.available > -1 ? (
                        <KCredit remaining={credits.available}
                                 total={credits.total}
                                 infinite={credits.infinite}
                                 labelText={<Translate value="credits.genericSearches"/>}/>
                    ) : (
                        <KSpinner/>
                    )}
                </Col>
            </Row>
            <KSpace spaces={2}/>
            <Row md={12}>
                <Col className={"text-start"}><DomainSearchNew onNewDomainSearch={onDomainSearch} /></Col>
            </Row>
        </KCard>
    );
};
