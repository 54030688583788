import * as React from "react";
import {I18n, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {
    ActionType,
    Column, DataType,
    FreeTextFilter, GetParams,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KForm,
    KLabel,
    KSpace,
    KStepProgress,
    Table
} from "@kopjra/uikit";
import {Search} from "../../types/detection/searchState";
import {ReactChild, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {OSDSpinner} from "../OSDSpinner";
import {ManualUrls} from "./ManualUrls";
import {SearchType} from "../../types/extra";


export type SearchBindingProps = {
    availableSearches: Search[];
    total: number;
    onSubmit: (searchIds: string[], urls: string[]) => void;
    onBack: () => void;
    onGetAvailableSearches: (query: GetParams) => Promise<void>;
};

export const SearchBinding: React.FC<SearchBindingProps> = ({
                                                                availableSearches,
                                                                total,
                                                                onSubmit,
                                                                onBack,
                                                                onGetAvailableSearches
                                                            }) => {
    const [searchIds] = useState<string[]>([]);
    const actions: ActionType<Search>[] = [
        {
            name: (datum: Search) => searchIds.indexOf(datum.id) === -1
                ? <><i className="fal fa-folder-plus"/>&nbsp;&nbsp;<Translate value="searches.table.actions.add"/></>
                : <div style={{color: "#EF615E"}}><i className="fal fa-folder-times"/>&nbsp;&nbsp;<Translate
                    value="searches.table.actions.delete"/></div>,
            handler: async (datum: Search) => {
                searchIds.indexOf(datum.id) === -1
                    ? searchIds.push(datum.id)
                    : searchIds.splice(searchIds.indexOf(datum.id), 1);
            }
        }
    ];

    function renderQuery(datum: object): ReactChild {
        const search = datum as Search;
        return search.webType === SearchType.CRAWL ? search.name!! : search.query;
    }

    const states = [
        <Translate value={"wells.static.takedownInfo"}/>, <Translate value={"wells.static.rightsOwnerInfo"}/>,
        <Translate value={"wells.static.attachmentsInfo"}/>, <Translate value={"wells.static.templatePreview"}/>,
        <Translate value={"wells.static.manualUrls"}/>
    ];

    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={4} states={states}/></Col></Row>
            <KSpace spaces={4}/>
            <Row><Col lg={10} md={12}>
                <KCard header={<Translate value={"wells.static.manualUrls"}/>}>
                    <KForm onSubmit={(values) => {
                        const urlsArray = values?.urls
                            ? (values.urls as string).trim().split("\n").map(u => {
                                if (!u.startsWith("https://") && !u.startsWith("http://")) {
                                    u = `https://${u}`;
                                }
                                return u;
                            })
                            : [];
                        onSubmit(searchIds, urlsArray);
                    }}>
                        {availableSearches.length > 0 ? (
                            <>
                                <Row><Col><KLabel text={<Translate value="wells.static.bindingSearches"/>}/></Col></Row>
                                <KSpace spaces={2}/>
                                <Row>
                                    <Col>
                                        <Table
                                            checkboxes={false}
                                            filterDefinition={[
                                                new FreeTextFilter("name", I18n.t("searches.table.filters.query")),
                                            ]}
                                            globalActions={[]}
                                            globalWaiter={<OSDSpinner size={200}/>}
                                            waiter={<OSDSpinner size={100} variant="dark"/>}
                                            id={`availableSearches/list/full`}
                                            total_count={total}
                                            loaderFunc={(q: GetParams) => onGetAvailableSearches(q)}
                                            data={availableSearches}
                                            hideColumnSelector={true}
                                            hideFilters={true}
                                            keyField={"id"}
                                            hidePager={false}
                                        >
                                            <Column colid="query" classes="text-start" name="searches.table.query"
                                                    type={DataType.GENERIC} colspan={4} render={renderQuery}/>
                                            <Column colid="created" classes="text-end" name="searches.table.created"
                                                    type={DataType.DATETIME} colspan={2}/>
                                            <Column colid="webType" classes="text-start" name="searches.table.type"
                                                    type={DataType.GENERIC} colspan={2}/>
                                            <Column colid="resultNumberValidated" classes="text-center"
                                                    name="searches.table.validation" type={DataType.GENERIC}
                                                    colspan={3}/>
                                            <Column colid="actions" classes="text-start" name="" type={DataType.ACTIONS}
                                                    actions={actions} colspan={2}/>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <KLabel text={<Translate value="wells.static.noSearches" />} />
                        )}
                        <KSpace spaces={6}/>
                        <Row><Col><KLabel text={<Translate value="wells.static.manualResults"/>}/></Col></Row>
                        <KSpace spaces={2}/>
                        <ManualUrls/>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col md={3}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                                 text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                     value={`wells.static.back`}/></>}
                                                 onClick={() => {
                                                     onBack()
                                                 }}/>
                            </Col>
                            <Col md={3}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                                 text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate
                                                     value={`wells.static.confirm`}/></>}/>
                            </Col>
                        </Row>
                    </KForm>
                </KCard>
            </Col></Row>
        </>
    );
}
