import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {DelegateInfo, RightsOwnerInfo, WellAttachment} from "../../types/takedown/wellsState";
import {
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard, KCheck, KFileUpload,
    KForm,
    KInput, KLabel,
    KSpace, KStepProgress
} from "@kopjra/uikit";
import {OSDSpinner} from "../OSDSpinner";
import {useState} from "react";
import {AttachmentType} from "../../utils/commons";

export type WellRightsOwnerProps = {
    wellId?: string;
    rightsOwnerInfo?: RightsOwnerInfo;
    delegateInfo?: DelegateInfo;
    onSubmit: (deletePOA: boolean, rightsOwnerInfo?: RightsOwnerInfo, delegateInfo?: DelegateInfo) => void;
    onBack: () => void;
    onDownloadPOA: () => Promise<void>;
};

export const WellInfoRightsOwner: React.FC<WellRightsOwnerProps> = (
    {
        wellId, rightsOwnerInfo,
        delegateInfo, onSubmit, onBack, onDownloadPOA
    }) => {
    const states = [
        <Translate value={"wells.static.takedownInfo"}/>, <Translate value={"wells.static.rightsOwnerInfo"}/>,
        <Translate value={"wells.static.attachmentsInfo"}/>, <Translate value={"wells.static.templatePreview"}/>
    ];
    if (!wellId) {
        states.push(<Translate value={"wells.static.manualUrls"}/>);
    }

    const [delegateChecked, setDelegateChecked] = useState<boolean>(!!delegateInfo?.name);
    const [innerPOA, setInnerPOA] = useState<WellAttachment | undefined>(delegateInfo?.powerOfAttorney);
    const [deletePOA, setDeletePOA] = useState<boolean>(false);

    const mainCardTitle = wellId
        ? <><Translate value={"wells.table.edit"}/>&nbsp;<Translate value={"wells.static.rightsOwnerInfo"}/></>
        : <Translate value={"wells.static.rightsOwnerInfo"}/>;
    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={1} states={states}/></Col></Row>
            <KSpace spaces={4}/>
            <Row><Col lg={7} md={12}>
                <KCard header={mainCardTitle}>
                    {(rightsOwnerInfo && wellId) || !wellId ? (
                        <KForm onSubmit={values => {
                            const newRightsOwnerInfo: RightsOwnerInfo = {
                                name: values.ownerName as string,
                                address: values.ownerAddress as string,
                                phone: values.ownerPhone as string,
                                website: values.ownerWebsite as string
                            };
                            const newDelegateInfo: DelegateInfo | undefined = delegateChecked ? {
                                name: values.delegateName as string,
                                address: values.delegateAddress as string,
                                phone: values.delegatePhone as string,
                                website: values.delegateWebsite as string,
                                powerOfAttorney: innerPOA
                            } : undefined;
                            onSubmit(deletePOA, newRightsOwnerInfo, newDelegateInfo);
                        }}>
                            <Col>
                                <Row>
                                    <Col><KInput label={<Translate value="wells.table.ownerName"/>} required={true}
                                                 id={"ownerName"} defaultValue={rightsOwnerInfo?.name}
                                                 placeholder={I18n.t("wells.static.defaultOwnerName")}/></Col>
                                </Row>
                                <Row>
                                    <Col><KInput label={<Translate value="wells.table.ownerAddress"/>} required={true}
                                                 id={"ownerAddress"} defaultValue={rightsOwnerInfo?.address}
                                                 placeholder={I18n.t("wells.static.defaultOwnerAddress")}/></Col>
                                </Row>
                                <Row>
                                    <Col><KInput label={<Translate value="wells.table.ownerPhone"/>} required={true}
                                                 id={"ownerPhone"} defaultValue={rightsOwnerInfo?.phone}
                                                 placeholder={I18n.t("wells.static.defaultOwnerPhone")}/></Col>
                                </Row>
                                <Row>
                                    <Col><KInput label={<Translate value="wells.table.ownerWebsite"/>}
                                                 id={"ownerWebsite"} defaultValue={rightsOwnerInfo?.website}
                                                 placeholder={I18n.t("wells.static.defaultOwnerWebsite")}/></Col>
                                </Row>
                                <KSpace spaces={2}/>
                                <Row>
                                    <Col className={"text-start"}>
                                        <KLabel text={<Translate value="wells.static.delegateMessage"/>}/>
                                        <KSpace v={true} spaces={2}/>
                                        <KCheck id="delegate" inline label={<Translate value="generic.yes"/>}
                                                name="rights" required type="radio"
                                                defaultChecked={!!delegateInfo?.name}
                                                onChange={e => e.target.checked ? setDelegateChecked(true) : undefined}
                                        />
                                        <KCheck id="owner" inline label={<Translate value="generic.no"/>} name="rights"
                                                required type="radio" defaultChecked={!delegateInfo?.name}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setDelegateChecked(false);
                                                        if (delegateInfo?.powerOfAttorney?.id) {
                                                            setDeletePOA(true);
                                                        }
                                                    }
                                                }}
                                        />
                                    </Col>
                                </Row>
                                {delegateChecked ? (
                                    <>
                                        <Row>
                                            <Col><KInput label={<Translate value="wells.table.ownerName"/>}
                                                         required={true} id={"delegateName"}
                                                         defaultValue={delegateInfo?.name}
                                                         placeholder={I18n.t("wells.static.defaultOwnerName")}/></Col>
                                        </Row>
                                        <Row>
                                            <Col><KInput label={<Translate value="wells.table.ownerAddress"/>}
                                                         required={true} id={"delegateAddress"}
                                                         defaultValue={delegateInfo?.address}
                                                         placeholder={I18n.t("wells.static.defaultOwnerAddress")}/></Col>
                                        </Row>
                                        <Row>
                                            <Col><KInput label={<Translate value="wells.table.ownerPhone"/>}
                                                         required={true} id={"delegatePhone"}
                                                         defaultValue={delegateInfo?.phone}
                                                         placeholder={I18n.t("wells.static.defaultOwnerPhone")}/></Col>
                                        </Row>
                                        <Row>
                                            <Col><KInput label={<Translate value="wells.table.ownerWebsite"/>}
                                                         id={"delegateWebsite"} defaultValue={delegateInfo?.website}
                                                         placeholder={I18n.t("wells.static.defaultOwnerWebsite")}/></Col>
                                        </Row>
                                        <Row>
                                            <Col className={"text-start"}>
                                                <KLabel text={"POWER OF ATTORNEY"}/>
                                                <p>(<span style={{color: "#5198f4", cursor: "pointer"}}
                                                          onClick={async () => {
                                                              await onDownloadPOA();
                                                          }}>
                                                    <Translate value="wells.static.downloadPOA"/>
                                                </span>)</p>
                                            </Col>
                                            <Col className={"text-start"} md={8}>
                                                <KFileUpload showUploadedFilename={true}
                                                             required={delegateChecked && !delegateInfo?.powerOfAttorney?.document}
                                                             id={"powerOfAttorney"}
                                                             label={<Translate value="wells.static.loadPDF"/>}
                                                             onChange={async f => {
                                                                 if (innerPOA) {
                                                                     const copy = {...innerPOA};
                                                                     copy.document = f;
                                                                     setInnerPOA(copy);
                                                                 } else {
                                                                     setInnerPOA({
                                                                         type: AttachmentType.POWER_OF_ATTORNEY,
                                                                         document: f
                                                                     });
                                                                 }
                                                             }}
                                                             accept={"application/pdf"}
                                                             valueFilename={typeof innerPOA?.document === "object" ? innerPOA?.document.name : delegateInfo?.powerOfAttorney?.document as string}
                                                             requiredErrorMessage={delegateChecked && !delegateInfo?.powerOfAttorney?.document ?
                                                                 <Translate
                                                                     value={`wells.static.insertPdf`}/> : undefined}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Col>
                            <KSpace spaces={2}/>
                            <Row>
                                <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                                     text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                         value={`wells.static.back`}/></>}
                                                     onClick={() => {
                                                         onBack()
                                                     }}/>
                                </Col>
                                <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                                     text={<><i className="fal fa-chevron-double-right"/>&nbsp;
                                                         <Translate value={`wells.static.confirm`}/></>}/></Col>

                            </Row>
                        </KForm>
                    ) : (
                        <OSDSpinner size={200}/>
                    )}
                </KCard>
            </Col></Row>
        </>
    );
}
