import { CreditsState } from "../detection/creditsState";

export enum DomainInfoState {
    "REQUESTED" = "REQUESTED",
    "FOUND" = "FOUND",
    "NOT_FOUND" = "NOT_FOUND",
}

export interface NewDomainInfoSearch {
    domainName: string;
}

export interface Registrar {
    name: string;
    IANAID: string;
    whoisEmail: string;
    url: string;
    whoisServerUrl: string;
}

export interface Registrant {
    name: string;
    organization: string;
    address: string;
    emails: string[];
    url: string;
}

export interface Hoster {
    asn: string;
    name: string;
    organization: string;
    address: string;
    emails: string[];
    rirName: string;
    rirCountryCode: string;
    rirWhoisServer: string;
}

export interface Registry {
    tldName: string;
    organizationName: string;
    organizationAddress: string;
    administrativeEmail: string;
    technicalEmail: string;
    registrationServicesURL: string;
    whoisServer: string;
}

export interface Ads {
    adServers: string[];
    adultAdServers: string[];
    trackerServers: string[];
}

export interface DomainInfo {
    id: string;
    domainName: string;
    userId: string;
    created: Date;
    state: DomainInfoState;
    domainIP: string;
    registrar: Registrar;
    registrant: Registrant;
    hoster: Hoster;
    registry: Registry;
    indexing: boolean;
    ads: Ads;
    importance: number;
}

export interface DomainsInfoState {
    list?: DomainInfo[];
    total: number;
    credits?: CreditsState;
}
