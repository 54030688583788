import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { I18n, Localize, Translate } from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import { Result } from "../../types/detection/resultsAreaState";
import { KCard, KContainer, KLabel, KModal, KModalSize, KModalVariant } from "@kopjra/uikit";
import {getEmbedUrl, numberToDottedNumber, secondsToHms} from "../../utils/commons";
import ReactPlayer from "react-player";
import {OSDSpinner} from "../OSDSpinner";


export interface StateProps {
    currentResult?: Result;
}

export interface DispatchProps {
    onGetCurrentResult: (searchId: string, domainId: string, resultId: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const ResultUGC: React.FC<Props> = ({ currentResult, onGetCurrentResult }) => {
    // @ts-ignore
    const { searchId, domainId, resultId } = useParams();

    const [opened, setOpened] = useState(false);

    useEffect(() => {
        onGetCurrentResult(searchId as string, domainId as string, resultId as string).catch((e) => console.warn("Get result error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer>
            {currentResult ? (
                <>
                    <KCard header={<Translate value={"resultUGC.cardTitle"} />}>
                        <Row>
                            <Col className="text-start" md={6}>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.title" />} /></Col>
                                    <Col><span style={{ fontSize: 12 }}>{currentResult.title}</span></Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.channel" />} /></Col>
                                    <Col><a href={currentResult?.channelURL} target={"_blank"} rel="noopener noreferrer">
                                        <span style={{ fontSize: 12 }}>{currentResult.channel}</span>
                                    </a></Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.channelFollowers" />} /></Col>
                                    <Col><span style={{ fontSize: 12 }}>
                                    {currentResult.channelFollowers as number >= 0
                                        ? (currentResult.channelFollowers as number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                        : ""}
                                </span></Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.url" />} /></Col>
                                    <Col><span style={{ fontSize: 12 }}>{currentResult.url}</span></Col>
                                </Row>
                            </Col>
                            <Col className="text-start" md={6}>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.views" />} /></Col>
                                    <Col>
                                    <span style={{ fontSize: 12 }}>
                                        {currentResult.views as number >= 0 ? numberToDottedNumber(currentResult.views || 0) : ""}
                                    </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.duration" />} /></Col>
                                    <Col>
                                    <span style={{ fontSize: 12 }}>
                                        {currentResult.duration as number >= 0 ? secondsToHms(currentResult.duration || 0) : ""}
                                    </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.publishedAt" />} /></Col>
                                    <Col><span style={{ fontSize: 12 }}>{currentResult.publishedAt ? (
                                        <Localize value={currentResult.publishedAt} dateFormat="kptable.date.short" />
                                    ) : (<span />)}
                                </span></Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="resultUGC.description" />} /></Col>
                                    <Col>
                                    <span onClick={() => setOpened(true)}
                                          style={{ fontSize: 12, cursor: "pointer" }}>
                                        {<><span style={{ color: "#5198f4" }}><Translate value="resultUGC.descriptionShow" /></span></>}
                                    </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 30 }}>
                            <Col>
                                <div className='player-wrapper'>
                                    <ReactPlayer url={`${getEmbedUrl(currentResult.url)}`}
                                                 config={{
                                                     youtube: { playerVars: { controls: 1 } },
                                                     dailymotion: { params: { controls: true } },
                                                     vimeo: { playerOptions: {} }
                                                 }}
                                                 className="react-player"
                                                 width="100%"
                                                 height="100%"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </KCard>
                    <KModal show={opened} variant={KModalVariant.secondary} size={KModalSize.lg} onHide={() => setOpened(false)} header={I18n.t("resultUGC.description")} footer={<></>}>
                        <Row><Col className="text-center"></Col>{currentResult.description}</Row>
                    </KModal>
                </>
            ) : (
                <OSDSpinner size={100} variant={"light"} />
            )}
        </KContainer>
    );
};
