import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {WellAttachment} from "../../types/takedown/wellsState";
import {
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KColSeparator,
    KDate, KFileUpload,
    KForm,
    KInput,
    KLabel,
    KSelect,
    KSELECT_SIZE,
    KSpace,
    KStepProgress
} from "@kopjra/uikit";
import {OSDSpinner} from "../OSDSpinner";
import {AttachmentType} from "../../utils/commons";
import {useState} from "react";

export type WellAttachmentsProps = {
    wellId?: string;
    attachments?: WellAttachment[];
    onSubmit: (attachments: WellAttachment[], deletedAttachmentsIds: string[]) => void;
    onBack: () => void;
};

export const WellAttachments: React.FC<WellAttachmentsProps> = (
    {
        wellId, attachments,
        onSubmit, onBack
    }) => {
    const states = [
        <Translate value={"wells.static.takedownInfo"}/>, <Translate value={"wells.static.rightsOwnerInfo"}/>,
        <Translate value={"wells.static.attachmentsInfo"}/>, <Translate value={"wells.static.templatePreview"}/>
    ];

    if (!wellId) {
        states.push(<Translate value={"wells.static.manualUrls"}/>);
    }

    const [innerAttachments, setInnerAttachments] = useState<Partial<WellAttachment>[] | undefined>(attachments);
    const [deletedAttachmentsIds] = useState<string[]>([]);

    function addAttachment(id: number, renderAdd: boolean, attachmentData?: WellAttachment): JSX.Element {
        return <>
            <Row>
                <Col className={"attachment-wrapper"}><KSelect
                    label={<Translate value="wells.attachments.attachmentType"/>}
                    id={`attachmentType${id}`} size={KSELECT_SIZE.lg} clearable={false}
                    placeholder={<Translate value="wells.attachments.selectAttachmentType"/>}
                    defaultValue={attachmentData?.type}
                    options={[AttachmentType.TRADEMARK_REGISTRATION, AttachmentType.PATENT_REGISTRATION,
                        AttachmentType.DESIGN_PATENT_REGISTRATION, AttachmentType.MEDIA_CONTENT].map(t => {
                        const l = `${t.toLowerCase().split("_")[0]}Type`;
                        return {value: t, label: I18n.t(`wells.attachments.${l}`)};
                    })}
                    onChange={val => {
                        if (innerAttachments) {
                            innerAttachments[id].type = val as string;
                        }
                    }}
                    required={true}
                />
                    <div className={"attachmentDeleteButton"} style={{color: "#EF615E", cursor: "pointer"}}
                         onClick={() => {
                             const curAttachments = innerAttachments ? [...innerAttachments] : [];
                             const attachmentToDelete = curAttachments[id];
                             if (attachmentToDelete.id) {
                                 deletedAttachmentsIds.push(attachmentToDelete.id);
                             }
                             curAttachments.splice(id, 1);
                             setInnerAttachments(curAttachments);
                         }}>
                        <i className="fal fa-trash"/>&nbsp;&nbsp;<Translate
                        value="searches.table.actions.delete"/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={2} className={"text-start"}><KLabel
                    text={<Translate value="wells.attachments.document"/>}/></Col>
                <Col className={"text-start"}>
                    <div style={{cursor: "pointer"}}>
                        <KFileUpload showUploadedFilename={true} id={"document"}
                                     label={<Translate value="wells.attachments.loadDocument"/>}
                                     onChange={async f => {
                                         if (innerAttachments) {
                                             innerAttachments[id].document = f;
                                         }
                                     }}
                                     accept={"application/pdf"}
                                     valueFilename={typeof attachmentData?.document === "object" ? attachmentData.document.name : attachmentData?.document}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col><KInput label={<Translate value="wells.attachments.url"/>}
                             id={`url${id}`} value={attachmentData?.url}
                             placeholder={I18n.t("wells.attachments.insertUrl")}
                             onChange={(e) => {
                                 if (innerAttachments) {
                                     innerAttachments[id].url = e.currentTarget.value;
                                 }
                             }}
                /></Col>
            </Row>
            <Row>
                <Col><KInput label={<Translate value="wells.attachments.registrationNumber"/>}
                             id={`registrationNumber${id}`} value={attachmentData?.registrationNumber}
                             placeholder={I18n.t("wells.attachments.insertRegistrationNumber")}
                             onChange={(e) => {
                                 if (innerAttachments) {
                                     innerAttachments[id].registrationNumber = e.currentTarget.value;
                                 }
                             }}
                /></Col>
            </Row>
            <Row>
                <Col><KInput label={<Translate value="wells.attachments.registrationEntity"/>}
                             id={`registrationEntity${id}`} value={attachmentData?.registrationEntity}
                             placeholder={I18n.t("wells.attachments.insertRegistrationEntity")}
                             onChange={(e) => {
                                 if (innerAttachments) {
                                     innerAttachments[id].registrationEntity = e.currentTarget.value;
                                 }
                             }}
                /></Col>
            </Row>
            <Row>
                <Col><KInput label={<Translate value="wells.attachments.registrationZone"/>}
                             id={`registrationZone${id}`} value={attachmentData?.registrationZone}
                             placeholder={I18n.t("wells.attachments.insertRegistrationZone")}
                             onChange={(e) => {
                                 if (innerAttachments) {
                                     innerAttachments[id].registrationZone = e.currentTarget.value;
                                 }
                             }}
                /></Col>
            </Row>
            <Row>
                <Col><KInput label={<Translate value="wells.attachments.registrationClass"/>}
                             id={`registrationClass${id}`} value={attachmentData?.registrationClass}
                             placeholder={I18n.t("wells.attachments.insertRegistrationClass")}
                             onChange={(e) => {
                                 if (innerAttachments) {
                                     innerAttachments[id].registrationClass = e.currentTarget.value;
                                 }
                             }}
                /></Col>
            </Row>
            <Row>
                <Col md={4} className={"text-start"}><KLabel
                    text={<Translate value="wells.attachments.registrationDate"/>}/></Col>
                <Col className={"text-start"}><KDate id={`registrationDate${id}`}
                                                    date={attachmentData?.registrationDate}
                                                    onChange={(val: Date | undefined) => {
                                                        if (innerAttachments) {
                                                            innerAttachments[id].registrationDate = val as Date;
                                                        }
                                                    }}/></Col>
            </Row>
            <Row>
                <Col md={4} className={"text-start"}><KLabel
                    text={<Translate value="wells.attachments.expirationDate"/>}/></Col>
                <Col className={"text-start"}><KDate id={`expirationDate${id}`}
                                                    date={attachmentData?.expirationDate}
                                                    onChange={(val: Date | undefined) => {
                                                        if (innerAttachments) {
                                                            innerAttachments[id].expirationDate = val as Date;
                                                        }
                                                    }}/></Col>
            </Row>
            <KSpace/>
            <Row><Col><KColSeparator forceHorizontal={true}/></Col></Row>
            {renderAdd ? (
                <>
                    <KSpace/>
                    <Row><Col>
                        <div style={{color: "#5198F4", cursor: "pointer"}} onClick={() => {
                            const curAttachments = innerAttachments ? [...innerAttachments] : [];
                            curAttachments.push({});
                            setInnerAttachments(curAttachments);
                        }}>
                            <i className="fal fa-plus"/>&nbsp;&nbsp;<Translate value="wells.attachments.add"/>
                        </div>
                    </Col></Row>
                </>
            ) : (
                <></>
            )}
        </>
    }

    const mainCardTitle = wellId
        ? <><Translate value={"wells.table.edit"}/>&nbsp;<Translate value={"wells.static.attachmentsInfo"}/></>
        : <Translate value={"wells.static.attachmentsInfo"}/>;

    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={2} states={states}/></Col></Row>
            <KSpace spaces={4}/>
            <Row><Col lg={7} md={12}>
                <KCard header={mainCardTitle}>
                    {(attachments && wellId) || !wellId ? (
                        <KForm onSubmit={() => {
                            onSubmit(innerAttachments as WellAttachment[] || [], deletedAttachmentsIds);
                        }}>
                            {innerAttachments && innerAttachments.length > 0 ? (
                                <>
                                    {innerAttachments.map((att, i) => {
                                        return addAttachment(i, i === innerAttachments.length - 1 || false, att as WellAttachment)
                                    })}
                                </>
                            ) : (
                                <Row><Col><KSelect label={<Translate value="wells.attachments.attachmentType"/>}
                                                   id={`attachmentTypeInitial`} size={KSELECT_SIZE.lg} clearable={false}
                                                   placeholder={<Translate
                                                       value="wells.attachments.selectAttachmentType"/>}
                                                   options={[AttachmentType.TRADEMARK_REGISTRATION, AttachmentType.PATENT_REGISTRATION,
                                                       AttachmentType.DESIGN_PATENT_REGISTRATION, AttachmentType.MEDIA_CONTENT].map(t => {
                                                       const l = `${t.toLowerCase().split("_")[0]}Type`;
                                                       return {value: t, label: I18n.t(`wells.attachments.${l}`)};
                                                   })}
                                                   onChange={value => {
                                                       setInnerAttachments([{type: value as string}]);
                                                   }}
                                /></Col></Row>
                            )}
                            <KSpace spaces={2}/>
                            <Row>
                                <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                                     text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                         value={`wells.static.back`}/></>}
                                                     onClick={() => {
                                                         onBack()
                                                     }}/>
                                </Col>
                                <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                                     text={<><i className="fal fa-chevron-double-right"/>&nbsp;
                                                         <Translate value={`wells.static.confirm`}/></>}/></Col>

                            </Row>
                        </KForm>
                    ) : (
                        <OSDSpinner size={200}/>
                    )}
                </KCard>
            </Col></Row>
        </>
    );
}
