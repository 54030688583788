import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, DomainSearches} from "../../components/analysis/DomainSearches";
import {RootState, ThunkDispatch} from "../../types";
import {unregister} from "@kopjra/uikit";
import {
    doAddDomainSearchTag,
    doDeleteDomainSearches, doDeleteDomainSearchTag,
    doGetDomainSearches, doGetDomainsSearchesTags,
    doUpdateDomainSearch, downloadCsv
} from "../../actions/thunks/analysis/domainsSearches";
import {routerTools, Routes} from "../../utils/router";
import {resetDomainSearch} from "../../actions/analysis/domainSearchDetails";
import {resetDomainSearchResults} from "../../actions/analysis/domainSearchResults";
import {DomainSearchGetParams} from "../../api/analysis/domainsSearches";


function mapStateToProps({domainsSearches: domainSearches, i18n}: RootState): StateProps {
    return {
        domainSearches: domainSearches.list,
        total: domainSearches.total,
        domainSearchesTags: domainSearches.domainSearchesTags,
        locale: i18n.locale,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetDomainSearches: (params: DomainSearchGetParams) => dispatch(doGetDomainSearches(params)),
        onOpenDomainSearch: (id: string) => {
            dispatch(resetDomainSearch());
            dispatch(resetDomainSearchResults());
            dispatch(unregister("domainSearchResults/list"));
            routerTools.push(Routes.DOMAIN_SEARCH(id));
        },
        onDeleteDomainSearches: (ids: string[], deleteAll: boolean, params?: DomainSearchGetParams) => dispatch(doDeleteDomainSearches(ids, deleteAll, params)),
        onUpdateDomainSearch: (domainSearchId: string) => dispatch(doUpdateDomainSearch(domainSearchId)),
        onAddDomainSearchTag: (domainSearchId: string, tag: string) => dispatch(doAddDomainSearchTag(domainSearchId, tag)),
        onDeleteDomainSearchTag: (domainSearchId: string, tag: string) => dispatch(doDeleteDomainSearchTag(domainSearchId, tag)),
        onGetDomainsSearchesTags: () => dispatch(doGetDomainsSearchesTags()),
        onReportGeneration: (domainSearchIds: string[], reportType: string, language: string, all: boolean, queryParams?: any) => dispatch(downloadCsv(domainSearchIds, reportType, language, all, queryParams))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainSearches);
