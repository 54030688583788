import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {MainState} from "../types/main";
import {setCurrentUser, setMainObjective} from "../actions/main";

const initialState: MainState = {
    objective: "none",
};

export const mainReducer = createReducer(initialState)
    .handleAction(setMainObjective, (state, action) => update(state, {
        objective: {$set: action.payload.objective},
    }))
    .handleAction(setCurrentUser, (state, action) => update(state, {
        currentUser: {$set: action.payload},
    }));
