import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {ResultUGC} from "../../types/detection/resultUGC";
import {gotCurrentResult, resetResultUGCState} from "../../actions/detection/resultUGC";

const initialState: ResultUGC = {
};

export const resultUGCReducer = createReducer(initialState)
    .handleAction(gotCurrentResult, (state, action) => update(state, {
        currentResult: {$set: action.payload}
    }))
    .handleAction(resetResultUGCState, (state) => update(state, {
        currentResult: {$set: undefined}
    }));
