import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {DispatchProps, InnerProps, StateProps, DomainSearchResults} from "../../components/analysis/DomainSearchResults";
import {doGetDomainSearchResults, doValidateDomainsAnalysis} from "../../actions/thunks/analysis/domainSearchResults";
import {DomainAnalysis} from "../../types/analysis/domainSearchResultsState";
import {DomainAnalysisGetParams} from "../../api/analysis/domainSearchResults";

function mapStateToProps({domainSearchResults}: RootState): StateProps {
    return {
        domainSearchResults: domainSearchResults.list,
        total: domainSearchResults.total,
        forceUpdate: domainSearchResults.forceUpdate
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetDomainSearchResults: (domainSearchId: string, domainSearchType: string, query: DomainAnalysisGetParams) => dispatch(doGetDomainSearchResults(domainSearchId, domainSearchType, query)),
        onValidateDomainsAnalysis: (domainSearchId: string, domains: DomainAnalysis[], updateAll?: string, queryParams?: DomainAnalysisGetParams) => dispatch(doValidateDomainsAnalysis(domainSearchId, domains, updateAll, queryParams))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainSearchResults);
