import React, { useEffect } from "react";
import { IMAGES, KButton, KCard, KContainer, KSpace } from "@kopjra/uikit";
import { Col, Row } from "react-bootstrap";
import { ProductType } from "../types/extra";
import { Translate } from "react-redux-i18n";
import { CreditsState } from "../types/detection/creditsState";

export interface StateProps {
    credits: CreditsState;
}

export interface DispatchProps {
    onGetCreditsState: () => Promise<void>;
    openDashboard: (webType: string) => void;
    openAnalysis: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Home: React.FC<Props> = ({ credits, onGetCreditsState, openDashboard, openAnalysis }) => {
    useEffect(() => {
        onGetCreditsState().catch((e) => console.warn("Get Credits Stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function detectionProductCard(): JSX.Element {
        return <KCard fillHeight>
            <h5><img style={{ width: 86 }} src={IMAGES.detection.still.large.default} alt={"detection logo"} /></h5>
            <h5>DETECTION</h5>
            <KSpace />
            <h6><Translate value={`home.detection`} /></h6>
            <KSpace />
            <div className="mt-auto"><KButton
                text={<Translate value={"home.signin"} />}
                onClick={() => openDashboard(ProductType.DETECTION)} /></div>
        </KCard>
    }

    function domainDNAProductCard(): JSX.Element {
        return <KCard fillHeight>
            <KSpace spaces={2}/>
            <h5><img style={{ width: 86 }} src={IMAGES.analysis.still.large.default} alt={"analysis logo"} /></h5>
            <KSpace />
            <h5>ANALYSIS</h5>
            <KSpace />
            <h6><Translate value={`home.analysis`} /></h6>
            <KSpace />
            <div className="mt-auto"><KButton
                text={<Translate value={"home.signin"} />}
                onClick={() => openAnalysis()} /></div>
        </KCard>
    }

    function takedownProductCard(usable: boolean): JSX.Element {
        return <KCard fillHeight>
            <h5><img style={{ width: 86 }} src={IMAGES.takedown.still.large.default} alt={"takedown logo"} /></h5>
            <h5>TAKEDOWN</h5>
            <KSpace />
            <h6><Translate value={`home.takedown`} /></h6>
            <KSpace />
            <div className="mt-auto"><KButton
                text={<Translate value={"home.signin"} />}
                disabled={!usable}
                onClick={() => openDashboard(ProductType.TAKEDOWN)} /></div>
        </KCard>
    }

    return (
        <KContainer marginTop={50}>
            <Row md={12}><img src={IMAGES["web-intelligence"].still.large.default} style={{ width: 150, margin: "auto" }}
                alt={"wi logo"} /></Row>
            <Row md={12}><h3 style={{ margin: "auto" }}>WEB INTELLIGENCE</h3></Row>
            <Row md={12}><h4 style={{ margin: "auto" }}><Translate value={"home.mainDescription1"} /></h4></Row>
            <Row md={12}><h4 style={{ margin: "auto" }}><Translate value={"home.mainDescription2"} /></h4></Row>
            <KSpace spaces={3} />
            <Row md={12}>
                <Col md={3}>
                    {detectionProductCard()}
                </Col>
                <Col md={3}>
                    {domainDNAProductCard()}
                </Col>
                <Col md={3}>
                    {takedownProductCard(credits.total === -1 || credits.total > 25)}
                </Col>
            </Row>
        </KContainer>
    );
}
