import * as React from "react";
import {useEffect, useState} from "react";
import {
    GetParams,
    KContainer
} from "@kopjra/uikit";
import {useParams} from "react-router-dom";
import {
    WellUpdate,
    Well,
    NewWell,
    WellBaseInfo,
    RightsOwnerInfo,
    DelegateInfo,
    WellAttachment
} from "../../types/takedown/wellsState";
import {WellInfo} from "./WellInfo";
import {TemplatePreview} from "./TemplatePreview";
import {Search} from "../../types/detection/searchState";
import {SearchBinding} from "./SearchBinding";
import {tableGetParams} from "../../utils/commons";
import {WellInfoRightsOwner} from "./WellInfoRightsOwner";
import {WellAttachments} from "./WellAttachments";
import {User} from "../../types/main";


export interface StateProps {
    currentUser?: User;
    currentWellStaticInfo?: Well;
    noticeTemplateCompiled?: string;
    availableSearches?: Search[];
    total: number;
}

export interface DispatchProps {
    onGetCurrentUser: () => Promise<void>;
    onGetCurrentWellStaticInfo: (wellId: string) => Promise<void>;
    onGetCompiledNoticeTemplate: (wellInfo: WellUpdate) => Promise<void>;
    onGetAvailableSearches: (query: GetParams) => Promise<void>;
    onUpdateWellStaticInfo: (wellInfo: WellUpdate) => Promise<void>;
    onCreateWell: (wellInfo: NewWell, urls: string[]) => Promise<void>;
    onDownloadPOA: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

enum STEPS {"fillInfo", "rightsOwner", "attachments", "templatePreview", "searchBinding"}

const backMap = {
    [STEPS.fillInfo]: undefined,
    [STEPS.rightsOwner]: STEPS.fillInfo,
    [STEPS.attachments]: STEPS.rightsOwner,
    [STEPS.templatePreview]: STEPS.attachments,
    [STEPS.searchBinding]: STEPS.templatePreview
}

export const WellCreation: React.FC<Props> = (
    {
        currentUser,
        currentWellStaticInfo,
        noticeTemplateCompiled,
        availableSearches,
        total,
        onGetCurrentUser,
        onGetCurrentWellStaticInfo,
        onGetAvailableSearches,
        onUpdateWellStaticInfo,
        onGetCompiledNoticeTemplate,
        onCreateWell,
        onDownloadPOA
    }
) => {
    // @ts-ignore
    const {wellId} = useParams();
    const [baseInfo, setBaseInfo] = useState<WellBaseInfo>();
    const [rightsOwnerInfo, setRightsOwnerInfo] = useState<RightsOwnerInfo>();
    const [delegateInfo, setDelegateInfo] = useState<DelegateInfo>();
    const [attachments, setAttachments] = useState<WellAttachment[]>();
    const [deletedAttachmentsIds, setDeletedAttachmentsIds] = useState<string[]>([]);
    const [deletePOA, setDeletePOA] = useState<boolean>(false);
    const [step, setStep] = useState(STEPS.fillInfo);

    const availableSearchesRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetAvailableSearches(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get available searches error", e));
    };

    useEffect(() => {
        if (!currentUser) { onGetCurrentUser().catch(() => console.warn("Get current user error")); }
        if (wellId) {
            onGetCurrentWellStaticInfo(wellId as string).catch((e) => console.warn("Get current well static info error", e));
        } else {
            availableSearchesRetriever(tableGetParams("availableSearches/list/full"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
            const backListener = (event: PopStateEvent) => {
                event.preventDefault();
                const prevStep = backMap[step];
                if (prevStep) {
                    setStep(prevStep);
                } else {
                    window.history.back();
                }
            }
            window.addEventListener("popstate", backListener);
            return () => window.removeEventListener("popstate", backListener);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    function renderComponent(step: STEPS): JSX.Element {
        let component;
        switch (step) {
            case STEPS.fillInfo:
                component = <WellInfo wellId={wellId} currentUser={currentUser} baseInfo={baseInfo || currentWellStaticInfo?.baseInfo}
                                      onSubmit={(newBaseInfo) => {
                                          setStep(STEPS.rightsOwner);
                                          setBaseInfo(newBaseInfo);
                                      }}
                                      onBack={() => window.history.back()}
                />;
                break;
            case STEPS.rightsOwner:
                component = <WellInfoRightsOwner
                    wellId={wellId}
                    rightsOwnerInfo={rightsOwnerInfo || currentWellStaticInfo?.rightsOwnerInfo}
                    delegateInfo={delegateInfo || currentWellStaticInfo?.delegateInfo}
                    onSubmit={(deletePOA, newRightsOwnerInfo, newDelegateInfo) => {
                        if (baseInfo) {
                            const newBaseInfo = {...baseInfo};
                            newBaseInfo.name = newBaseInfo.name + newRightsOwnerInfo?.name;
                            setBaseInfo(newBaseInfo);
                        }
                        setStep(STEPS.attachments);
                        setDeletePOA(deletePOA);
                        setRightsOwnerInfo(newRightsOwnerInfo);
                        setDelegateInfo(newDelegateInfo);
                    }}
                    onBack={() => setStep(backMap[step])}
                    onDownloadPOA={onDownloadPOA}
                />;
                break;
            case STEPS.attachments:
                component = <WellAttachments
                    wellId={wellId}
                    attachments={attachments || currentWellStaticInfo?.attachments}
                    onSubmit={(attachments, deletedAttachmentsIds) => {
                        const templateData: WellUpdate = Object.assign({id: "0"}, {attachments: []}, {baseInfo: baseInfo as WellBaseInfo}, {rightsOwnerInfo: rightsOwnerInfo as RightsOwnerInfo}, {delegateInfo: delegateInfo as DelegateInfo});
                        onGetCompiledNoticeTemplate(templateData).then();
                        setStep(STEPS.templatePreview);
                        setAttachments(attachments);
                        setDeletedAttachmentsIds(deletedAttachmentsIds);
                    }}
                    onBack={() => setStep(backMap[step])}
                />;
                break;
            case STEPS.templatePreview:
                component = <TemplatePreview wellId={wellId} noticeTemplateCompiled={noticeTemplateCompiled}
                                             onBack={() => setStep(backMap[step])}
                                             onSubmit={async () => {
                                                 if (wellId) {
                                                     if (deletePOA && currentWellStaticInfo?.delegateInfo?.powerOfAttorney?.id) {
                                                         deletedAttachmentsIds.push(currentWellStaticInfo.delegateInfo.powerOfAttorney.id);
                                                     }
                                                     const updatedData: WellUpdate = Object.assign(
                                                         {id: wellId},
                                                         {attachments: attachments},
                                                         {baseInfo: baseInfo as WellBaseInfo},
                                                         {rightsOwnerInfo: rightsOwnerInfo as RightsOwnerInfo},
                                                         {delegateInfo: delegateInfo as DelegateInfo},
                                                         {deletedAttachmentsIds: deletedAttachmentsIds}
                                                     );
                                                     await onUpdateWellStaticInfo(updatedData);
                                                     console.log(updatedData);
                                                     window.history.back();
                                                 } else {
                                                     setStep(STEPS.searchBinding);
                                                 }
                                             }}
                />
                break;
            case STEPS.searchBinding:
                component = <SearchBinding availableSearches={availableSearches as Search[]}
                                           onBack={() => setStep(backMap[step])}
                                           onSubmit={async (searchIds: string[], urls: string[]) => {
                                               if (deletePOA && currentWellStaticInfo?.delegateInfo?.powerOfAttorney?.id) {
                                                   deletedAttachmentsIds.push(currentWellStaticInfo.delegateInfo.powerOfAttorney.id);
                                               }
                                               const newWell: NewWell = Object.assign(
                                                   {baseInfo: baseInfo as WellBaseInfo},
                                                   {rightsOwnerInfo: rightsOwnerInfo as RightsOwnerInfo},
                                                   {delegateInfo: delegateInfo as DelegateInfo},
                                                   {searchIds: searchIds},
                                                   {attachments: attachments || []}
                                               );
                                               await onCreateWell(newWell, urls);
                                           }}
                                           onGetAvailableSearches={availableSearchesRetriever}
                                           total={total}
                />
                break;
            default:
                component = <></>;
                break;
        }
        return component;
    }

    return (
        <KContainer>
            {renderComponent(step)}
        </KContainer>
    );
}
