import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {
    addedWellTag,
    cancelWell,
    deletedWellTag,
    gotWells,
    gotWellsGlobalStats,
    gotWellsTags
} from "../../takedown/wells";
import {
    addWellTag, archiveWell,
    deleteWells, deleteWellTag,
    getWells,
    getWellsGlobalStats,
    getWellsTags,
    triggerTDReport
} from "../../../api/takedown/wells";
import {GetParams, setAlert} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";
import {transformToWellParams} from "../../../utils/commons";


export const doGetWellsGlobalStats = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotWellsGlobalStats(await getWellsGlobalStats()));
};

export const doGetWells = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotWells(await getWells(transformToWellParams(query))));
};

export const doDeleteWells = (wellIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await deleteWells(wellIds, deleteAll, queryParams);
    if (outcome) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteWell"), "info"));
        for (const id of wellIds) {
            dispatch(cancelWell(id));
        }
    } else {
        const msg = I18n.t("alerts.errors.deleteWell");
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
};

export const doTriggerTDReport = (wellIds: string[], reportType: string, language: string, all: boolean, filters?: any): ThunkResult<Promise<void>> => async () => {
    await triggerTDReport(wellIds, reportType, language, all, filters ? transformToWellParams(filters) : undefined);
}

export const doGetWellsTags = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotWellsTags(await getWellsTags()));
}

export const doArchiveWell = (wellId: string, archived: boolean): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const archivedWell = await archiveWell(wellId, archived);
    if (archivedWell) {
        dispatch(cancelWell(wellId));
    }
}

export const doAddWellTag = (wellId: string, tag: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(addedWellTag(wellId, tag));
    const ok = await addWellTag(wellId, tag);
    if (ok) {
        dispatch(gotWellsTags(await getWellsTags()));
    }
}

export const doDeleteWellTag = (wellId: string, tag: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(deletedWellTag(wellId, tag));
    const ok = await deleteWellTag(wellId, tag);
    if (ok) {
        dispatch(gotWellsTags(await getWellsTags()));
    }
}
