import {CreditsState} from "../../types/detection/creditsState";
import {apiCall} from "../index";

export async function getCredits(): Promise<CreditsState> {
    const creditsState = {available: 0, total: 0, infinite: false};
    const res = await apiCall({urlComponent: `/credits`});
    const credits = await res.json();
    creditsState.available = credits.available;
    creditsState.total = credits.total;
    if (credits.available === -1 && credits.total === -1) {
        creditsState.infinite = true;
    }
    return creditsState;
}
