import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {transformToResultParams} from "../../../utils/commons";
import {
    gotResults,
    validateResults,
    gotCurrentDomain,
    gotCurrentSearch
} from "../../detection/resultsArea";
import {getResults, validate} from "../../../api/detection/resultsArea";
import {Result} from "../../../types/detection/resultsAreaState";
import {GetParams} from "@kopjra/uikit";
import {getDomain} from "../../../api/detection/domainsArea";
import {getSearch, updateNotes} from "../../../api/detection/searches";


export const doGetResults = (searchId: string, domainId: string, query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    if (query.filter) {
        query.filter.push({name: "domainId", value: domainId});
    } else {
        query.filter = [{name: "domainId", value: domainId}];
    }
    dispatch(gotResults(await getResults(searchId, transformToResultParams(query))));
};

export const doValidateResults = (searchId: string, results: Array<Result>, domainId?: string, updateAll?: string, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    let validateStatusCode;
    if (queryParams) {
        validateStatusCode = await validate(searchId, results, domainId, updateAll, transformToResultParams(queryParams as GetParams));
    } else {
        validateStatusCode = await validate(searchId, results, domainId, updateAll);
    }

    if(validateStatusCode < 400) {
        dispatch(validateResults(results));
    }
};

export const doGetCurrentDomain = (searchId: string, domainId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentDomain(await getDomain(searchId, domainId)));
};

export const doGetCurrentSearch = (searchId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentSearch(await getSearch(searchId)));
};

export const doUpdateNotes = (searchId: string, domainId: string, resultId: string, query: GetParams, notes?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const ok = await updateNotes(searchId, domainId, resultId, notes);
    if (ok) {
        if (query.filter) {
            query.filter.push({name: "domainId", value: domainId});
        } else {
            query.filter = [{name: "domainId", value: domainId}];
        }
        dispatch(gotResults(await getResults(searchId, transformToResultParams(query))));
    }
}
