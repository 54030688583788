import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {WellDomainMailsState} from "../../types/takedown/wellMailsState";
import {
    gotCurrentMailContent,
    gotCurrentMailResponses,
    gotWellDomainMails,
    resetCurrentMailResponses,
    resetEmailsState
} from "../../actions/takedown/wellMails";
import {gotCurrentWellDomain} from "../../actions/takedown/wellDomainResultsArea";

const initialState: WellDomainMailsState = {
};

export const wellDomainMailsReducer = createReducer(initialState)
    .handleAction(gotCurrentWellDomain, (state, action) => update(state, {
        currentWellDomain: {$set: action.payload}
    }))
    .handleAction(gotWellDomainMails, (state, action) => update(state, {
        wellDomainMails: {$set: action.payload}
    }))
    .handleAction(gotCurrentMailResponses, (state, action) => update(state, {
        currentMailResponses: {$set: action.payload}
    }))
    .handleAction(gotCurrentMailContent, (state, action) => update(state, {
        currentMailContent: {$set: action.payload}
    }))
    .handleAction(resetEmailsState, (state) => update(state, {
        currentWellDomain: {$set: undefined},
        wellDomainMails: {$set: undefined}
    }))
    .handleAction(resetCurrentMailResponses, (state) => update(state, {
        currentMailResponses: {$set: undefined}
    }));
