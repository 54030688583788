import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {
    DispatchProps,
    InnerProps,
    StateProps,
    WellDomainResultsArea
} from "../../components/takedown/WellDomainResultsArea";
import {
    doCreateScreenshotArchiveLink,
    doDeleteWellDomainResults,
    doGetCurrentWellDomain, doGetNResultsWillChange,
    doGetWellDomainResults, doRequestIndexing, doRequestScreenshots, doRequestScreenshotUrl,
    doUpdateTakedownState, doUpdateWellResultNotes
} from "../../actions/thunks/takedown/wellDomainResultsArea";
import {WellDomainResult} from "../../types/takedown/wellDomainResultsAreaState";
import {GetParams} from "@kopjra/uikit";
import {resetNResultsWillChange} from "../../actions/takedown/wellDomainResultsArea";
import {GetWellDomainResultsParams} from "../../api/takedown/wellDomainResultsArea";
import {
    doGetCurrentWell,
    doSetIndexingState
} from "../../actions/thunks/takedown/wellDomainsArea";


function mapStateToProps({wellDomainResults, main}: RootState): StateProps {
    return {
        currentUser: main.currentUser,
        currentWellDomain: wellDomainResults.currentWellDomain,
        wellDomainResults: wellDomainResults.list,
        total: wellDomainResults.total,
        curNResultsWillChange: wellDomainResults.curNResultsWillChange
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetWellDomainResults: (wellId: string, domainName: string, query) => dispatch(doGetWellDomainResults(wellId, query, domainName)),
        onGetCurrentWellDomain: (wellId: string, domainName: string) => dispatch(doGetCurrentWellDomain(wellId, domainName)),
        onGetNResultsWillChange: (wellId: string, domainName: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => dispatch(doGetNResultsWillChange(wellId, data, newState, all, query, domainName)),
        resetNResultsWillChange: () => dispatch(resetNResultsWillChange()),
        onUpdateTakedownState: async (wellId: string, domainName: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => {
            await dispatch(doUpdateTakedownState(wellId, data, newState, all, query, domainName));
            dispatch(doGetWellDomainResults(wellId, query, domainName));
            console.log(domainName);
            return dispatch(doGetCurrentWellDomain(wellId, domainName));
        },
        onRequestIndexing: (wellId: string, domainName: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: GetParams) => dispatch(doRequestIndexing(wellId, data, all, searchEngine, queryParams, domainName)),
        onDeleteWellUrls: async (wellId: string, domainName: string, urls: string[], all: boolean, queryParams: GetWellDomainResultsParams) => {
            await dispatch(doDeleteWellDomainResults(wellId, urls, all, queryParams, domainName));
            dispatch(doGetWellDomainResults(wellId, queryParams, domainName));
            return dispatch(doGetCurrentWellDomain(wellId, domainName));
        },
        onCreateScreenshotArchiveLink: (wellId: string, domainName?: string) => dispatch(doCreateScreenshotArchiveLink(wellId, domainName)),
        onRequestScreenshot: (wellId: string, domainName: string, data: WellDomainResult[], all: boolean, queryParams?: GetParams) => dispatch(doRequestScreenshots(wellId, data, all, queryParams, domainName)),
        getScreenDownloadUrl: (wellId: string, domainName: string, url: string) => dispatch(doRequestScreenshotUrl(wellId, url, domainName)),
        onSetIndexingState: async (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, query: GetParams, stateDate?: string, requestId?: string, domainName?: string) => {
            await dispatch(doSetIndexingState(wellId, urlsArray, searchEngine, indexingState, stateDate, requestId));
            dispatch(doGetCurrentWell(wellId));
            console.log(domainName);
            return dispatch(doGetWellDomainResults(wellId, query, domainName));
        },
        onUpdateNotes: async (wellId: string, domain: string, url: string, notes?: string) => dispatch(doUpdateWellResultNotes(wellId, url, notes, domain)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(WellDomainResultsArea);
