import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, UserProfile} from "../components/UserProfile";
import {RootState, ThunkDispatch} from "../types";
import {doResendEmail, doResetPassword, doUpdateUserProfile} from "../actions/thunks/userProfile";

function mapStateToProps({main}: RootState): StateProps {
    return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onResetPassword: () => dispatch(doResetPassword()),
        onUpdateUserProfile: (up) => dispatch(doUpdateUserProfile(up)),
        onResendEmail: () => dispatch(doResendEmail()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(UserProfile);
