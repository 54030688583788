import * as React from "react";
import {Card, Col, ProgressBar, Row, Table} from "react-bootstrap";
import {ClearStats} from "../../types/detection/statsAreaState";
import {Doughnut} from "react-chartjs-2";
import {ChartData} from "chart.js";
import {KCard, KLabel, KSpace} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {numberToDottedNumber} from "../../utils/commons";
import {useParams} from "react-router-dom";
import {ReactChild} from "react";


export type SearchStatsClearProps = {
    stats?: ClearStats;
    validationPercent?: number;
    onOpenDomain: (productType: string, searchId: string, domainId: string) => void;
};

export const SearchStatsClear: React.FC<SearchStatsClearProps> = ({stats, validationPercent, onOpenDomain}) => {
    // @ts-ignore
    const {productType, searchId} = useParams();

    const domains = stats?.domainsRankByInfringements.map( d => { return {name: d.name, results: d.potentialInfringements}; }) || [];
    let domainsRankByResults: Array<{name: string; results: number}>;
    if (domains.length > 3) {
        const others = domains.slice(3, domains.length + 1);
        const othersSum = others.reduce( (acc, d) => acc + d.results, 0);
        const top3 = domains.slice(0, 3);
        top3.push({name: "others", results: othersSum});
        domainsRankByResults = top3;
    } else {
        domainsRankByResults = domains;
    }
    const sourcesColours: string[] = ["#363D49", "#6E7682", "#B8C1D2", "#DFE7F5"].slice(0, domainsRankByResults.length + 1);
    const sourcesLabels: string[] = domainsRankByResults.map(d => d.name);
    const sourcesValues: number[] = domainsRankByResults.map(d => d.results);

    const sourcesData: ChartData = {
        labels: sourcesLabels,
        datasets: [{data: sourcesValues, backgroundColor: sourcesColours}]
    };
    const validationData: ChartData = {
        labels: [I18n.t("stats.validationPercentCompleted"), I18n.t("stats.validationPercentNotCompleted")],
        datasets: [{data: [validationPercent, (100-(validationPercent || 0))], backgroundColor: ["#2BD18F", "#DFE7F5"]}]
    };

    function renderImportance(val: number): ReactChild {
        val = val > 0 ? val : 1;
        return <div style={{position: "absolute", top: "50%", transform: "translateY(-50%)"}}><ProgressBar now={val} max={5} className={`kProgressImportance${val}`} /></div>
    }

    return (
        <>
            <Row>
                <Col md={6}>
                    <KCard>
                        <KSpace/>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.clear.chartSites"}/>}/></Col></Row>
                        <Row><Doughnut height={100} data={sourcesData} legend={{align: "center", position: "right"}} options={{cutoutPercentage: 70, layout: {padding: 30}, responsive: true}}/></Row>
                    </KCard>
                </Col>
                <Col md={6}>
                    <KCard>
                        <KSpace/>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.chartValidation"}/>}/></Col></Row>
                        <Row><Doughnut height={100} data={validationData} legend={{align: "center", position: "right"}} options={{cutoutPercentage: 70, layout: {padding: 30}, responsive: true}}/></Row>
                    </KCard>
                </Col>
            </Row>
            <KSpace/>
            <Row>
                <Col className={"text-start"} md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.clear.topTenDomains"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.clear.domainLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.clear.resultsLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.domainsRankByInfringements.map( d => { return (
                                <tr key={d.id}>
                                    <td onClick={() => onOpenDomain(productType, searchId, d.id)}>{d.name}</td>
                                    <td className="text-end">{numberToDottedNumber(d.potentialInfringements || 0)}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col className={"text-start"} md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.clear.topTenImportance"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.clear.domainLabel"}/></th>
                                <th className="text-start"><Translate value={"stats.clear.importanceLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.domainsRankByImportance.map( d => { return (
                                <tr key={d.id}>
                                    <td onClick={() => onOpenDomain(productType, searchId, d.id)}>{d.name}</td>
                                    <td className="text-end" style={{position: "relative"}}>{renderImportance(d.importance)}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
