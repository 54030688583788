import {Result} from "../../types/detection/resultsAreaState";
import {apiCall, HttpMethod} from "../index";

export interface ValidateResultOperation {
    op: string;
    path: string;
    value: string;
}

export interface ValidationMessage {
    toUpdate: Array<ValidateResultOperation>;
    updateAll?: string;
    queryParameters?: GetResultParams;
}

export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetResultParams extends GetParams {
    title?: string;
    url?: string;
    validation?: string;
    domainId?: string;
    htmlQuery?: string,
    strictHtmlQuery?: boolean,
    visited?: boolean,
    notes?: string,
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetResultsResponse = GetListResponse<Result>;

export function transformToResult(obj: any): Result {
    const result: Result = {
        id: obj.id,
        title: obj.title,
        url: obj.url,
        searchId: obj.searchId,
        domainId: obj.domainId,
        validation: obj.validation,
        description: obj.description,
        channel: obj.channel,
        channelURL: obj.channelURL,
        channelFollowers: obj.channelFollowers,
        publishedAt: obj.publishedAt,
        views: obj.views,
        duration: obj.duration,
        notes: obj.notes,
        createdAt: obj.createdAt ? obj.createdAt : undefined,
        // CrawlResult field
        visited: obj.visited ? obj.visited : undefined,
    };
    return result;
}

export async function getResults(searchId: string, query: GetResultParams): Promise<GetResultsResponse> {
    const res = await apiCall({urlComponent: `/searches/${searchId}/results`, query});
    const json = await res.json();
    const results = await json.results.map((o: any) => transformToResult(o));

    return {
        results: results,
        total: json.total,
    };
}

export async function getResult(searchId: string, domainId: string, resultId: string): Promise<Result> {
    const res = await apiCall({urlComponent: `/searches/${searchId}/domains/${domainId}/results/${resultId}`});
    const json = await res.json();
    const result = transformToResult(json);
    return result;
}

export async function validate(searchId: string, results: Array<Result>, domainId?: string, updateAll?: string, queryParams?: GetResultParams): Promise<number> {
    let validationMessage: ValidationMessage;
    let realDomainId = domainId;

    if (updateAll) {
        validationMessage = {toUpdate: [], updateAll: updateAll, queryParameters: {url: queryParams?.url, title: queryParams?.title, validation: queryParams?.validation}};
    } else {
        const resultsToUpdate: Array<ValidateResultOperation> = results.map( result => {
            return {op: "replace", path: `${result.id}/validation`, value: result.validation}
        });
        validationMessage = {toUpdate: resultsToUpdate};
    }

    if (domainId === undefined) {
        const resDomain = await apiCall({urlComponent: `/searches/${searchId}/domains`, method: HttpMethod.GET});
        if (!resDomain.ok) {
            return resDomain.status;
        }

        const resObj = (await resDomain.json());
        if (resObj.results.length > 0) {
            realDomainId = resObj.results[0].id;
        }
    }

    const res = await apiCall({urlComponent: `/searches/${searchId}/domains/${realDomainId}/results:bulkValidation`, method: HttpMethod.PATCH, body: validationMessage});
    return res.status;
}
