import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {GetParams, KCard, KContainer, KSpace} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {tableGetParams} from "../../utils/commons";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {WellDomainDetails} from "./WellDomainDetails";
import {WellDomainResult} from "../../types/takedown/wellDomainResultsAreaState";
import {WellDomainResults} from "./WellDomainResults";
import TDBreadcrumbs from "../../containers/takedown/TDBreadcrumbs";
import {User} from "../../types/main";
import {GetWellDomainResultsParams} from "../../api/takedown/wellDomainResultsArea";


export interface StateProps {
    currentUser?: User;
    currentWellDomain?: WellDomain;
    wellDomainResults?: WellDomainResult[];
    total: number;
    curNResultsWillChange?: number;
}

export interface DispatchProps {
    onGetWellDomainResults: (wellId: string, domainName: string, query: GetParams) => Promise<void>;
    onGetCurrentWellDomain: (wellId: string, domainName: string) => Promise<void>;
    onGetNResultsWillChange: (wellId: string, domainName: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => Promise<void>;
    onUpdateTakedownState: (wellId: string, domainName: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => Promise<void>;
    resetNResultsWillChange: () => void;
    onRequestIndexing: (wellId: string, domainName: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: GetParams) => Promise<void>;
    onDeleteWellUrls: (wellId: string, domainName: string, urls: string[], all: boolean, queryParams: GetWellDomainResultsParams) => Promise<void>;
    onCreateScreenshotArchiveLink: (wellId: string, domainName?: string) => Promise<string>;
    onRequestScreenshot: (wellId: string, domainName: string, data: WellDomainResult[], all: boolean, queryParams?: GetParams) => Promise<void>;
    getScreenDownloadUrl: (wellId: string, domainName: string, url: string) => Promise<void>;
    onSetIndexingState: (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, query: GetParams, stateDate?: string, requestId?: string, domainName?: string) => Promise<void>;
    onUpdateNotes: (wellId: string, domain: string, url: string, notes?: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const WellDomainResultsArea: React.FC<Props> = (
    {
        currentUser,
        currentWellDomain,
        wellDomainResults,
        total,
        curNResultsWillChange,
        onGetCurrentWellDomain,
        onGetWellDomainResults,
        onGetNResultsWillChange,
        resetNResultsWillChange,
        onUpdateTakedownState,
        onRequestIndexing,
        onDeleteWellUrls,
        onCreateScreenshotArchiveLink,
        onRequestScreenshot,
        getScreenDownloadUrl,
        onSetIndexingState,
        onUpdateNotes
    }
) => {
    // @ts-ignore
    const {wellId, domainName} = useParams();
    const queryParams = tableGetParams("wellResults/list");

    useEffect(() => {
        onGetCurrentWellDomain(wellId as string, domainName as string).catch((e) => console.warn("Get current well domain error", e));
        onGetWellDomainResults(wellId as string, domainName as string, queryParams).catch((e) => console.warn("Get well domain results error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer className="bigger">
            <TDBreadcrumbs type={"results"} wellId={wellId}/>
            <KCard header={<Translate value={"wells.results.title"}/>}>
                <Row>
                    <Col md={12}>
                        <WellDomainDetails currentWellDomain={currentWellDomain}/>
                    </Col>
                </Row>
                <KSpace/>
                <Row>
                    <Col md={12}>
                        <WellDomainResults currentUser={currentUser} wellDomainResults={wellDomainResults}
                                           total={total} currentWellDomain={currentWellDomain} curNResultsWillChange={curNResultsWillChange}
                                           onGetWellDomainResults={onGetWellDomainResults}
                                           onGetNResultsWillChange={onGetNResultsWillChange} resetNResultsWillChange={resetNResultsWillChange}
                                           onUpdateTakedownState={onUpdateTakedownState}
                                           onRequestIndexing={onRequestIndexing}
                                           onDeleteWellUrls={onDeleteWellUrls}
                                           onCreateScreenshotArchiveLink={onCreateScreenshotArchiveLink}
                                           onRequestScreenshot={onRequestScreenshot}
                                           getScreenDownloadUrl={getScreenDownloadUrl}
                                           onSetIndexingState={onSetIndexingState}
                                           onUpdateNotes={onUpdateNotes}
                         />
                    </Col>
                </Row>
            </KCard>
        </KContainer>
    );
};
