import * as React from "react";
import { Col, Row } from "react-bootstrap";
import {I18n, Localize, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { Search } from "../../types/detection/searchState";
import { KLabel, KSpace } from "@kopjra/uikit";
import { KCredit } from "../KCredit";


export type SearchDetailsDeepProps = {
    currentSearch?: Search;
    type: "stats" | "domains" | "results";
};

export const SearchDetailsDeep: React.FC<SearchDetailsDeepProps> = ({ currentSearch, type }) => {
    const searchDetailsForStats = <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{currentSearch && `"${currentSearch.query}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={4}>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.updated" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch?.refreshed ? (<Localize value={currentSearch.refreshed} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.webType}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.categories" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.categories ? currentSearch.categories.map(c => I18n.t(`categories.${c}Label`)).join(", ") : ""}</span></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <Row>
                    <Col><span className="bigNumberGray">{currentSearch && currentSearch.domainNumber}</span></Col>
                </Row>
                <Row>
                    <Col><KLabel text={<Translate value="domains.totalDomains" />} /></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <Row>
                    <Col><span className="bigNumberGray">{currentSearch && currentSearch.resultNumber}</span></Col>
                </Row>
                <Row>
                    <Col><KLabel text={<Translate value="domains.violations" />} /></Col>
                </Row>
            </Col>
        </Row>
    </>;

    const searchDetailsForDomains = <>
        <Row>
            <Col className="text-center">
            <span style={{ fontSize: 22, fontWeight: "bold" }}>{currentSearch && `"${currentSearch.query}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.updated" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch?.refreshed ? (<Localize value={currentSearch.refreshed} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={3}><KLabel text={<Translate value="searches.table.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.webType}</span></Col>
                </Row>
                <Row>
                    <Col md={3}><KLabel text={<Translate value="searches.table.categories" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.categories ? currentSearch.categories.map(c => I18n.t(`categories.${c}Label`)).join(", ") : ""}</span></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <Row>
                    <Col><span className="bigNumberGray">{currentSearch && currentSearch.domainNumber}</span></Col>
                </Row>
                <Row>
                    <Col><KLabel text={<Translate value="domains.totalDomains" />} /></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <KCredit remaining={currentSearch ? currentSearch.resultNumberValidated : 0}
                    total={currentSearch ? currentSearch.resultNumber : 0}
                    infinite={false}
                    labelText={<Translate value="domains.validated" />} />
            </Col>
        </Row>
    </>;

    const searchesDetailsForResults = <>
        <Col className="text-start" md={4}>
            <Row>
                <Col className="text-center">
                    <span style={{ fontSize: 22, fontWeight: "bold" }}>"{currentSearch && currentSearch.query}"</span>
                </Col>
            </Row>
            <Row>
                <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
            </Row>
            <Row>
                <Col md={4}><KLabel text={<Translate value="searches.table.updated" />} /></Col>
                <Col><span style={{ fontSize: 12 }}>{currentSearch?.refreshed ? (<Localize value={currentSearch.refreshed} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
            </Row>
            <Row>
                <Col md={4}><KLabel text={<Translate value="searches.table.categories" />} /></Col>
                <Col md={4}><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.categories ? currentSearch.categories.map(c => I18n.t(`categories.${c}Label`)).join(", ") : ""}</span></Col>
            </Row>
        </Col>
    </>;

    const renderDetails = (type === "stats") ? searchDetailsForStats : ((type === "domains") ? searchDetailsForDomains : searchesDetailsForResults);
    return (
        renderDetails
    );
};
