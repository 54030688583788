import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KCard, KSearchbar, KSpace, KSpinner} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {NewDomainInfoSearch} from "../../types/analysis/domainsInfoState";
import {KCredit} from "../KCredit";
import {CreditsState} from "../../types/detection/creditsState";
import {useEffect} from "react";


export interface StateProps {
    credits: CreditsState;
}

export interface DispatchProps {
    onNewDomainInfoSearch: (newDomain: NewDomainInfoSearch) => Promise<void>;
    onGetCreditsState: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DNAOverview: React.FC<Props> = ({credits, onGetCreditsState, onNewDomainInfoSearch}) => {
    useEffect(() => {
        onGetCreditsState().catch((e) => console.warn("Get Credits Stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const domainInputRegex = "^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$";

    const searchbar =
        <KSearchbar searchInput={{
                        placeholder: I18n.t("domainInfo.dashboard.placeholderSearchbar"),
                        buttonText: <Translate value={"credits.newSearch"}/>,
                        errorMessage: I18n.t("domainInfo.dashboard.inputErrorSearchbar"),
                        pattern: domainInputRegex
        }}
                    onNewSearch={(searchValues) => onNewDomainInfoSearch({
                        domainName: searchValues.query})}
        />;

    return (
        <KCard header={<Translate value="credits.title"/>}>
            <Row md={12}>
                <Col md={12} className="text-center">
                    {credits.infinite || credits.available > -1 ? (
                        <KCredit remaining={credits.available}
                                 total={credits.total}
                                 infinite={credits.infinite}
                                 labelText={<Translate value="credits.genericSearches"/>}/>
                    ) : (
                        <KSpinner/>
                    )}
                </Col>
            </Row>
            <KSpace spaces={2}/>
            <Row md={12}>
                <Col>{searchbar}</Col>
            </Row>
        </KCard>
    );
};
