import {createAction} from "typesafe-actions";
import {Search} from "../../types/detection/searchState";
import {GetSearchesResponse} from "../../api/detection/searches";

export const gotSearches = createAction("searches/GOT", (response: GetSearchesResponse) => response)();
export const doSearch = createAction("searches/NEW", (search: Search) => search)();
export const gotTotalPotentialInfringements = createAction("searches/GOT_TOT_POT_INF", (tot_infr: number) => tot_infr)();
export const gotSearchesTags = createAction("searches/GOT_TAGS", (seearchesTags: string[]) => seearchesTags)();
export const cancelSearch = createAction("searches/DELETE", (searchId: string) => searchId)();
export const addedSearchTag = createAction("searches/ADD_SEARCH_TAG", (searchId: string, tag: string) => ({searchId, tag}))();
export const deletedSearchTag = createAction("searches/DELETE_SEARCH_TAG", (searchId: string, tag: string) => ({searchId, tag}))();
export const updateState = createAction("crawls/STATE", (searchId: string, state: string) => ({searchId,state}))();

export const resetSearchesState = createAction("searches/RESET")();
