import {createAction} from "typesafe-actions";
import {Well} from "../../types/takedown/wellsState";
import {GetSearchesResponse} from "../../api/detection/searches";


export const gotCurrentWellStaticInfo = createAction("wellCreation/GET_CURRENT_WELL_STATIC_INFO", (well: Well) => well)();
export const gotCompiledNoticeTemplate = createAction("wellCreation/GET_COMPILED_NOTICE_TEMPLATE", (template: string) => template)();
export const doUpdateWellInfo = createAction("wellCreation/UPDATE_WELL_STATIC_INFO", (well: Well) => well)();
export const resetWellStaticInfoState = createAction("wellCreation/RESET")();
export const gotAvailableSearches = createAction("wellCreation/GET_AVAILABLE_SEARCHES", (response: GetSearchesResponse) => response)();
export const doCreateWell = createAction("wellCreation/CREATE_WELL", (well: Well) => well)();
