import {DomainAnalysis} from "../../types/analysis/domainSearchResultsState";
import {apiCall, HttpMethod} from "../index";
import {ValidateResultOperation, ValidationMessage} from "../detection/resultsArea";
import {GetParams} from "@kopjra/uikit";

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetDomainSearchResultsResponse = GetListResponse<DomainAnalysis>;

export interface DomainAnalysisGetParams extends GetParams {
    validation?: string;
    domain?: string;
    typosquat?: string;
    description?: string;
}

export function transformToDomainSearchResult(obj: any): DomainAnalysis {
    const result: DomainAnalysis = {
        id: obj.id,
        domain: obj.domain,
        createdAt: new Date(obj.createdAt),
        validation: obj.validation,
        online: obj.online,
        registrationDate: obj.registrationDate ? new Date(obj.registrationDate) : undefined,
        ip: obj.ip,
        typosquat: obj.typosquat,
        adId: obj.adId,
        pageName: obj.pageName,
        adSearchPageUrl: obj.adSearchPageUrl,
        titles: obj.titles,
        languages: obj.languages,
        snapshotUrl: obj.snapshotUrl,
        creationTime: obj.creationTime,
        deliveryStartTime: obj.deliveryStartTime,
        deliveryEndTime: obj.deliveryEndTime,
    };
    return result;
}

export async function getDomainSearchResults(domainSearchId: string, query: DomainAnalysisGetParams): Promise<GetDomainSearchResultsResponse> {
    const res = await apiCall({urlComponent: `/domainSearches/${domainSearchId}/results`, query});
    const json = await res.json();
    const domains: DomainAnalysis[] = json.results.map((o: any) => transformToDomainSearchResult(o));
    return {
        results: domains,
        total: json.total,
    };
}

export async function validateDomainsAnalysis(domainSearchId: string, domains: DomainAnalysis[], updateAll?: string, queryParams?: DomainAnalysisGetParams): Promise<number> {
    let validationMessage: ValidationMessage;
    if (updateAll) {
        validationMessage = {toUpdate: [], updateAll: updateAll, queryParameters: {validation: queryParams?.validation}};
    } else {
        const domainsToUpdate: Array<ValidateResultOperation> = domains.map((domain) => {
            return {op: "replace", path: `${domain.id}/validation`, value: domain.validation};
        });
        validationMessage = {toUpdate: domainsToUpdate};
    }
    const res = await apiCall({urlComponent: `/domainSearches/${domainSearchId}/results:bulkValidation`, method: HttpMethod.PATCH, body: validationMessage});
    return res.status;
}

export interface OnlineInfo {
    online?: boolean;
}

export async function onlineInfo(domain: string): Promise<OnlineInfo> {
    let info: OnlineInfo = {online: false};
    try {
        const url = domain.startsWith("www.") ? `https://${domain}` : `https://www.${domain}`;
        const res = await fetch(url, {mode: "no-cors", signal: AbortSignal.timeout(5000)});
        info.online = res.status < 400;
    } catch {}
    return info;
}
