import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {GetParams, setAlert} from "@kopjra/uikit";
import {
    doSendTakedownNotice,
    gotCurrentFormResults,
    gotCurrentWell,
    gotWellDomains
} from "../../takedown/wellDomainsArea";
import {
    getCurrentFormResults,
    getWell,
    getWellDomains,
    requestDomainsIndexing,
    setIndexingState
} from "../../../api/takedown/wellDomainsArea";
import {sendNotice} from "../../../api/takedown/wells";
import {transformToWellDomainsParams} from "../../../utils/commons";
import {I18n} from "react-redux-i18n";
import {doManualSearch} from "../../../api/detection/searches";
import {WellDomain} from "../../../types/takedown/wellDomainsState";


export const doGetCurrentWell = (wellId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentWell(await getWell(wellId)));
};

export const doGetWellDomains = (wellId: string, query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotWellDomains(await getWellDomains(wellId, transformToWellDomainsParams(query))));
};

export const doSendNotice = (wellId: string, domainName: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const success = await sendNotice(wellId, domainName);
    if (success) {
        dispatch(setAlert(I18n.t("alerts.infos.notice"), "info"));
        dispatch(doSendTakedownNotice(success));
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.notice"), "error"));
    }
};

export const doAddManualUrls = (wellId: string, urls: string[], autoScreen: boolean): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    if (urls.length < 1) {
        dispatch(setAlert(I18n.t("alerts.errors.addManualUrlsAtLeast"), "error"));
    } else {
        const nInsertUrls = await doManualSearch(wellId, urls, autoScreen);
        dispatch(setAlert(`${I18n.t("alerts.infos.addManualUrls")}: ${nInsertUrls}`, "info"));
    }
};

export const doGetCurrentFormResults = (wellId: string, domainName: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentFormResults(""));
    dispatch(gotCurrentFormResults(await getCurrentFormResults(wellId, domainName)));
}

export const doSetIndexingState = (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, stateDate?: string, requestId?: string): ThunkResult<Promise<void>> => async () => {
    await setIndexingState(wellId, urlsArray, searchEngine, indexingState, stateDate, requestId);
}

export const doRequestDomainsIndexing = (wellId: string, domains: WellDomain[], all: boolean, searchEngine: string, queryParams: GetParams): ThunkResult<Promise<void>> => async () => {
    await requestDomainsIndexing(wellId, domains, all, searchEngine, queryParams);
}
