import React from "react";
import {
    KButton,
    KBUTTON_SIZE,
    KBUTTON_VARIANT,
    KNavbar,
    KNavBrandFixed,
    KNavDropdown,
    KNavDropdownItem,
    KNavLangSelector,
    KNavLink,
    noop
} from "@kopjra/uikit";
import { translations } from "../locale";
import { Translate } from "react-redux-i18n";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, useRouter } from "../utils/router";
import { ProductType } from "../types/extra";


export interface StateProps {
    locale: string;
}

export interface DispatchProps {
    onChangeLocale: (locale: string) => void;
    onGoHome: () => void;
    onGoDashboard: (productType: string) => void;
    onGoAccount: (productType: string) => void;
    onGoSearches: (productType: string) => void;
    onGoDomainInfo: (productType: string) => void;
    onGoDomainSearch: (productType: string) => void;
    onGoTDProcesses: (productType: string) => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Toolbar: React.FC<Props> = ({ locale, onChangeLocale, onGoHome, onGoDashboard, onGoAccount, onGoSearches, onGoDomainInfo, onGoDomainSearch, onGoTDProcesses }) => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const { location } = useRouter();
    // @ts-ignore
    const productType = location.pathname.split("/")[1];
    const locationDetection = productType === ProductType.DETECTION;
    const locationDomainInfo = productType === ProductType.DOMAIN_INFO;
    const locationDomainSearch = productType === ProductType.DOMAIN_SEARCHES;
    const locationTakedown = productType === ProductType.TAKEDOWN;
    const brandIcon = locationDetection ? "detection" : locationDomainInfo || locationDomainSearch ? "analysis" : locationTakedown ? "takedown" : "web-intelligence";
    const appName = locationDetection ? "Detection" : locationDomainInfo || locationDomainSearch ? "Analysis" : locationTakedown ? "Takedown" : "Web Intelligence";
    const manageLink = locationDomainInfo ? <KNavLink active={location.pathname === Routes.DOMAINS_INFO(productType)} onClick={() => { onGoDomainInfo(productType) }} element={<Translate value="menu.domains" />} /> :
        locationDomainSearch ? <KNavLink active={location.pathname === Routes.DOMAINS_SEARCHES(productType)} onClick={() => { onGoDomainSearch(productType) }} element={<Translate value="menu.domainSearches" />} /> :
        locationTakedown ? <KNavLink active={location.pathname === Routes.WELLS(productType)} onClick={() => { onGoTDProcesses(productType) }} element={<Translate value="menu.tdprocesses" />} /> :
            <KNavLink active={location.pathname === Routes.SEARCHES(productType)} onClick={() => { onGoSearches(productType) }} element={<Translate value="menu.searches" />} />

    return (
        <KNavbar
            brand={<KNavBrandFixed iconPartial={brandIcon} appName={appName} />}
            rightMost={
                <KNavLangSelector onChangeLocale={(loc) => onChangeLocale(loc)} locale={locale} translations={translations} alignRight={true} />
            }
        >
            {location.pathname !== Routes.HOME ? (
                <KNavLink active={false} onClick={() => onGoHome()} element={<Translate value="menu.home" />} />
            ) : (<></>)}
            {location.pathname !== Routes.HOME && location.pathname !== Routes.USER_ACCOUNT && location.pathname !== Routes.ANALYSIS() ? (
                <KNavLink active={location.pathname === Routes.DASHBOARD(productType)} onClick={() => onGoDashboard(productType)} element={<Translate value="menu.dashboard" />} />
            ) : (<></>)}
            {location.pathname !== Routes.HOME && location.pathname !== Routes.USER_ACCOUNT && location.pathname !== Routes.ANALYSIS() ? (
                manageLink
            ) : (<></>)}

            <KNavDropdown id={"user.profile-dropdown"} title={<Translate value="user.profile" />}>
                {!isAuthenticated ? (
                    <>
                        <div style={{ padding: 20, width: "100%" }}>
                            <KButton text={<span><i className="fal fag fa-sign-in" /> <Translate value="user.login" /></span>} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.sm}
                                onClick={() => loginWithRedirect()} fill={true} />
                        </div>
                        <KNavDropdownItem onClick={() => noop()}>
                            <Translate value="user.support" />
                        </KNavDropdownItem>
                    </>
                ) : (
                    <>
                        <KNavDropdownItem onClick={() => onGoAccount(productType)}>
                            <Translate value="user.account" />
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => { window.open("mailto:support@kopjra.com") }}>
                            <Translate value="user.support" />
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => logout({ returnTo: "https://webintelligence.kopjra.com" })}>
                            <Translate value="user.logout" />
                        </KNavDropdownItem>
                    </>
                )}
            </KNavDropdown>
        </KNavbar>
    );
}
