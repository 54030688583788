import React from "react";
import {IMAGES, KButton, KCard, KContainer, KSpace} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";

export interface StateProps {}

export interface DispatchProps {
    openDomainInfo: () => void;
    openDomainSearches: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Analysis: React.FC<Props> = ({openDomainInfo, openDomainSearches}) => {
    function domainInfoCard(): JSX.Element {
        return <KCard fillHeight>
            <h5><i style={{color: "#5198F4FF"}} className="fa-thin fa-3x fa-dna"/></h5>
            <h5>Domain DNA</h5>
            <KSpace/>
            <h6><Translate value={`home.dna`}/></h6>
            <KSpace/>
            <div className="mt-auto"><KButton
                text={<Translate value={"home.signin"}/>}
                onClick={() => openDomainInfo()}/></div>
        </KCard>
    }

    function domainSearchesCard(): JSX.Element {
        return <KCard fillHeight>
            <h5><i style={{color: "#5198F4FF"}} className="fa-thin fa-3x fa-browser"/></h5>
            <h5>Domain Search</h5>
            <KSpace/>
            <h6><Translate value={`home.domainSearches`}/></h6>
            <KSpace/>
            <div className="mt-auto"><KButton
                text={<Translate value={"home.signin"}/>}
                onClick={() => openDomainSearches()}/></div>
        </KCard>
    }

    return (
        <KContainer marginTop={50}>
            <Row md={12}><img src={IMAGES["analysis"].still.large.default} style={{ width: 150, margin: "auto" }} alt={"analysis logo"} /></Row>
            <Row md={12}><h3 style={{ margin: "auto" }}>ANALYSIS</h3></Row>
            <Row md={12}><h4 style={{ margin: "auto" }}><Translate value={"analysis.mainDescription1"} /></h4></Row>
            <KSpace spaces={3} />
            <Row>
                <Col md={3}>
                    {domainInfoCard()}
                </Col>
                <Col md={3}>
                    {domainSearchesCard()}
                </Col>
            </Row>
        </KContainer>
    );
}
