import * as React from "react";
import {ReactChild, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KCard,
    KLabel,
    KSelect,
    KSELECT_SIZE,
    KSpace,
    KSpinner,
    KTableLoader,
    promptBox,
    SortingDirection,
    Table,
    tooltipate
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {OSDSpinner} from "../OSDSpinner";
import {Search, SearchState} from "../../types/detection/searchState";
import {tableGetParams} from "../../utils/commons";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {SearchType} from "../../types/extra";


export interface StateProps {
    searches?: Search[];
    searchesTags?: string[];
    total: number;
    totalPotentialInfringements: number;
    locale: string;
}

export interface DispatchProps {
    onGetSearches: (query: GetParams) => Promise<void>;
    onGetTotalPotentialInfringements: () => Promise<void>;
    onOpenSearch: (searchId: string, webType: string) => void;
    onDeleteSearches: (searchIds: string[], deleteAll: boolean, queryParams?: GetParams) => void;
    onReportGeneration: (searchIds: string[], reportType: string, language: string, all: boolean, queryParams?: any) => Promise<void>;
    onGetSearchesTags: () => Promise<void>;
    onAddSearchTag: (searchId: string, tag: string) => Promise<void>;
    onDeleteSearchTag: (searchId: string, tag: string) => Promise<void>;
    onStopOrResumeCrawlSearch: (searchId: string, state: string) => Promise<void>;
    onUpdateSearch(searchId: string, params: GetParams, refreshIntervalDays?: number): Promise<void>;
}

export interface InnerProps {
    reduced?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Searches: React.FC<Props> = ({
                                              searches,
                                              searchesTags,
                                              total,
                                              totalPotentialInfringements,
                                              locale,
                                              onGetSearches,
                                              onGetTotalPotentialInfringements,
                                              onOpenSearch,
                                              onDeleteSearches,
                                              onReportGeneration,
                                              onGetSearchesTags,
                                              onAddSearchTag,
                                              onDeleteSearchTag,
                                              onStopOrResumeCrawlSearch,
                                              onUpdateSearch,
                                              reduced = true
                                          }) => {
    const queryParams: GetParams = {
        top: 5,
        skip: 0,
        sort: "created",
        direction: SortingDirection.DOWN
    };

    const searchesRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetSearches(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get searches error", e));
        if (reduced) await onGetTotalPotentialInfringements().catch((e) => console.warn("Get total infringements error", e));
    };

    useEffect(() => {
        onGetSearchesTags().catch(() => console.warn("Get searches tags error"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        searchesRetriever(reduced ? queryParams : tableGetParams("searches/list/full"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderSearchResults(search: Search): ReactChild {
        function getCircularProgress(percent: number): JSX.Element {
            const value = percent > 100 ? 100 : percent;
            const circularProgress = <div style={{width: 24, height: 24, marginLeft: "auto", marginRight: "auto"}}>
                        <CircularProgressbar value={value} text={""} strokeWidth={12}
                                             styles={buildStyles({
                                                 textColor: "#5198F4",
                                                 pathColor: "#5198F4"
                                             })}
                        /></div>;
                    const tooltip = I18n.t("searches.status.started");
                    return tooltipate(
                        circularProgress,
                        tooltip
                    );
        }

        let component = <div className="fal fa-times"/>;
        let percent = search.completionPercent ? search.completionPercent : 0;

        switch (search.webType) {
            case SearchType.UGC:
            case SearchType.CLEAR:
                component = <KSpinner/>;
                break;
            case SearchType.CRAWL:
                if (search.state === SearchState.STARTED && search.visitedUrls && search.maxVisitedUrls) {
                    const percent = Math.floor(search.visitedUrls * 100 / search.maxVisitedUrls);
                    component = getCircularProgress(percent);
                } else {
                    component = <KSpinner/>;
                }
                break;
            case SearchType.DEEP:
                if (search.state === SearchState.STARTED && (percent < 100 && percent > 1)) {
                    const percent = search.completionPercent ? search.completionPercent : 0;
                    component = getCircularProgress(percent);
                } else {
                    component = <KSpinner/>;
                }
                break;
            case SearchType.CUSTOM:
                component = <div>{search.resultNumber}</div>;
                break;
            default:
                break;
        }

        switch (search.state) {
            case SearchState.REFRESHING:
                if (search.webType !== SearchType.DEEP || percent === 0) {
                    component = <KSpinner/>;
                }
                break;
            case SearchState.STOPPED:
            case SearchState.COMPLETED:
            case SearchState.COMPLETED_FORCED:
                component = <div>{search.resultNumber}</div>;
                break;
            case SearchState.ERROR:
                component = <div className="fal fa-times"/>;
                break;
            default:
                break;
        }

        return component;
    }

    function renderRefreshIntervalDays(search: Search): ReactChild {
        if (search.refreshIntervalDays && search.refreshIntervalDays >= 0) {
            return <div>{search.refreshIntervalDays}</div>;
        } else {
            return <></>;
        }
    }

    function renderSearchType(datum: object): ReactChild {
        const search = datum as Search;
        return search.webType === SearchType.UGC ? "VIDEOS" : search.webType;
    }

    function renderQuery(datum: object): ReactChild {
        const search = datum as Search;
        return search.webType === SearchType.CRAWL ? search.name!! : search.query;
    }

    function renderTags(datum: object): ReactChild {
        const search = datum as Search;
        return <div className={"table-select"} onClick={(e) => e.stopPropagation()}>
            <KSelect id={`${search.id}`} creatable multi searchable
                     options={searchesTags!.map(t => {
                         return {label: t, value: t};
                     })}
                     value={search.tags}
                     onChange={async (_val, updated) => {
                         if (updated && updated.length > 0) {
                             if (updated[0].operation === "added") {
                                 await onAddSearchTag(search.id, updated[0].value);
                             } else {
                                 await onDeleteSearchTag(search.id, updated[0].value);
                             }
                         }
                     }}
                     size={KSELECT_SIZE.nm}
            /></div>;
    }

    const changeStatus = async (datum: Search) => {
        const nameAction = datum.state === SearchState.STARTED ?
            "stopSearch" :
            datum.state === SearchState.STOPPED ? "restartSearch" : "unknown";

        const confirmBoxConf = {
            noText: I18n.t("generic.no"),
            yesText: I18n.t("generic.yes"),
            dark: false,
            message: I18n.t(`searches.table.actions.${nameAction}`)
        };
        const changeStatus = await confirmBox(confirmBoxConf);
        if (changeStatus) {
            await onStopOrResumeCrawlSearch(datum.id, datum.state);
        }
    };

    const rowAction = async (datum: Search) => {
        if (datum.state !== SearchState.INITIALIZING && datum.state !== SearchState.STARTED && datum.state !== SearchState.REFRESHING) {
            onOpenSearch(datum.id, datum.webType);
        }
    };

    const actions: ActionType<Search>[] = [
        {
            name: <><i className="fal fa-folder-open action-icon"/><Translate value="searches.table.actions.view"/></>,
            handler: rowAction,
            shouldRender: (datum: Search) => (datum.webType === SearchType.CUSTOM) || (datum.state === SearchState.COMPLETED) || (datum.state === SearchState.COMPLETED_FORCED),
            collapsable: true
        },
        {
            name: <><i className="fal fa-play action-icon"/><Translate
                value="searches.table.actions.restart"/></>,
            handler: changeStatus,
            shouldRender: (datum: Search) => (datum.webType === SearchType.CRAWL) && (datum.state === SearchState.STOPPED),
            collapsable: false
        },
        {
            name: <><i className="fal fa-stop action-icon"/><Translate
                value="searches.table.actions.stop"/></>,
            handler: changeStatus,
            shouldRender: (datum: Search) => (datum.webType === SearchType.CRAWL) && (datum.state === SearchState.STARTED),
            collapsable: false
        },
        {
            name: <><i className="fal fa-file-pdf action-icon">&nbsp;</i><Translate
                value={"searches.table.actions.pdf"}/></>,
            handler: async (datum: Search) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.generateReportPDF")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    await onReportGeneration([datum.id], "pdf", locale, false);
                }
            },
            shouldRender: (datum: Search) => (datum.webType !== SearchType.CRAWL) && ((datum.webType === SearchType.CUSTOM) || (datum.state === SearchState.COMPLETED) || (datum.state === SearchState.COMPLETED_FORCED)),
            collapsable: true
        },
        {
            name: <><i className="fal fa-file-csv action-icon">&nbsp;</i><Translate
                value={"searches.table.actions.csv"}/></>,
            handler: async (datum: Search) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.generateReportCSV")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    await onReportGeneration([datum.id], "csv", locale, false);
                }
            },
            shouldRender: (datum: Search) => (datum.webType !== SearchType.CRAWL) && ((datum.webType === SearchType.CUSTOM) || (datum.state === SearchState.COMPLETED) || (datum.state === SearchState.COMPLETED_FORCED)),
            collapsable: true
        },
        {
            name: <><i className="fal fa-arrows-rotate action-icon">&nbsp;</i><Translate
                value={"searches.table.actions.update"}/></>,
            handler: async (datum: Search) => {
                await onUpdateSearch(datum.id, tableGetParams("searches/list/full"));
            },
            shouldRender: (datum: Search) => (datum.webType !== SearchType.CRAWL) && ((datum.webType !== SearchType.CUSTOM && (datum.state === SearchState.COMPLETED || datum.state === SearchState.COMPLETED_FORCED))),
            collapsable: true,
            confirmation: true
        },
        {
            name: <><i className="fal fa-clock-rotate-left action-icon">&nbsp;</i><Translate
                value={"searches.table.actions.planning"}/></>,
            handler: async (datum: Search) => {
                const refreshIntervalDays = await promptBox({
                    message: I18n.t("searches.table.actions.planningMessage"),
                    type: "number", minValue: 0, maxValue: 30, confirmText: I18n.t("searches.table.actions.planning"),
                    errorMessage: I18n.t("searches.table.actions.errorInterval"), value: 0,
                    cancelText: I18n.t("searches.table.actions.cancel")
                });
                if (refreshIntervalDays !== undefined) {
                    await onUpdateSearch(datum.id, tableGetParams("searches/list/full"), refreshIntervalDays as number);
                }
            },
            shouldRender: (datum: Search) => (datum.webType !== SearchType.CRAWL) && (datum.webType !== SearchType.CUSTOM && (datum.state === SearchState.COMPLETED || datum.state === SearchState.COMPLETED_FORCED)),
            collapsable: true
        }
    ];

    const globalActions: GlobalActionType[] = [
        {
            name: <div className="fal fa-trash action-icon" style={{color: "#EF615E"}}/>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.deleteMessage")
                };
                const deleteSearches = await confirmBox(confirmBoxConf);
                if (deleteSearches) {
                    const searchIds = (data as Search[]).map(s => s.id);
                    onDeleteSearches(searchIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <>
                <div className="fa-thin fa-file-zipper action-icon"/>
                CSV</>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.globalReportCSV")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    const searchIds = (data as Search[]).map(s => s.id);
                    await onReportGeneration(searchIds, "csv", locale, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <>
                <div className="fa-thin fa-file-zipper action-icon"/>
                PDF</>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.globalReportPDF")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    const searchIds = (data as Search[]).map(s => s.id);
                    await onReportGeneration(searchIds, "pdf", locale, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        }
    ];

    const typeMap = [
        {label: SearchType.DEEP, value: SearchType.DEEP},
        {label: SearchType.CLEAR, value: SearchType.CLEAR},
        {label: SearchType.UGC, value: SearchType.UGC},
        {label: SearchType.CUSTOM, value: SearchType.CUSTOM},
        {label: SearchType.CRAWL, value: SearchType.CRAWL}
    ];

    return (
        <KCard header={<Translate value={`searches.title${reduced ? "LastFive" : "Complete"}`}/>}>
            {searches && searchesTags ? (
                <>
                    {reduced && searches.length > 0 ? (
                        <>
                            <Row>
                                <Col className="text-center" md={6}>
                                    <span className="bigNumberGray">{total}</span>
                                </Col>
                                <Col className="text-center" md={6}>
                                    <span className="bigNumberGray">{totalPotentialInfringements}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center" md={6}>
                                    <KLabel text={<Translate value="searches.totalSearches"/>}/>
                                </Col>
                                <Col className="text-center" md={6}>
                                    <KLabel text={<Translate value="searches.totalPotentialInfringements"/>}/>
                                </Col>
                            </Row>
                            <KSpace/>
                        </>
                    ) : (
                        <></>
                    )}
                    {!reduced || (reduced && searches.length > 0) ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <Table
                                    checkboxes={!reduced}
                                    filterDefinition={[
                                        new FreeTextFilter("query", I18n.t("searches.table.filters.query")),
                                        new FixedSelectionFilter("webType", I18n.t("searches.table.filters.type"), typeMap),
                                        new FixedSelectionFilter("tags", I18n.t("searches.table.filters.tags"), searchesTags.map(st => {
                                            return {label: st, value: st};
                                        }), true),
                                    ]}
                                    globalActions={reduced ? [] : globalActions}
                                    globalWaiter={<OSDSpinner size={200}/>}
                                    waiter={<OSDSpinner size={100} variant="dark"/>}
                                    id={`searches/list/${reduced ? "reduced" : "full"}`}
                                    total_count={total}
                                    loaderFunc={(q: GetParams) => searchesRetriever(q, reduced ? queryParams : undefined)}
                                    loadInterval={10000}
                                    data={searches}
                                    hideColumnSelector={true}
                                    hideFilters={reduced}
                                    keyField={"id"}
                                    hidePager={reduced}
                                    rowAction={rowAction}
                                >
                                    <Column colid="query" classes="text-start" name="searches.table.query"
                                            type={DataType.GENERIC} sort={!reduced ? "query" : undefined}
                                            render={renderQuery}/>
                                    <Column colid="webType" classes="text-center" name="searches.table.type"
                                            type={DataType.GENERIC} sort={!reduced ? "webType" : undefined}
                                            render={renderSearchType}/>
                                    <Column colid="created" classes="text-end" name="searches.table.created"
                                            type={DataType.DATETIME} sort={!reduced ? "created" : undefined}/>
                                    <Column colid="refreshed" classes="text-end" name="searches.table.updated"
                                            type={DataType.DATETIME} sort={!reduced ? "refreshed" : undefined}/>
                                    <Column colid="refreshIntervalDays" classes="text-center"
                                            name="searches.table.refreshIntervalDays"
                                            type={DataType.GENERIC} sort={!reduced ? "refreshIntervalDays" : undefined}
                                            render={renderRefreshIntervalDays}
                                    />
                                    <Column colid="resultNumber" classes="text-center"
                                            name="searches.table.resultNumber"
                                            sort={!reduced ? "resultNumber" : undefined}
                                            render={renderSearchResults} type={DataType.GENERIC}/>
                                    <Column colid="tags" classes="text-center" name="searches.table.tags"
                                            render={renderTags} type={DataType.GENERIC} visible={!reduced}/>
                                    <Column colid="actions" classes="text-start" name="" type={DataType.ACTIONS}
                                            actions={actions} colspan={2} collapsed={true}/>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <KLabel text={<Translate value="searches.table.noSearches"/>}/>
                    )}
                </>
            ) : (
                <KTableLoader/>
            )}
        </KCard>
    );
};
