import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, DNAOverview} from "../../components/analysis/DNAOverview";
import {RootState, ThunkDispatch} from "../../types";
import {NewDomainInfoSearch} from "../../types/analysis/domainsInfoState";
import {doNewDomainInfoSearch} from "../../actions/thunks/analysis/domainsInfo";
import {doGetPaidCredits} from "../../actions/thunks/detection/statsArea";

function mapStateToProps({searchCredits}: RootState): StateProps {
    return {
        credits: searchCredits
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onNewDomainInfoSearch: (newDomain: NewDomainInfoSearch) => dispatch(doNewDomainInfoSearch(newDomain)),
        onGetCreditsState: () => dispatch(doGetPaidCredits())
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DNAOverview);
