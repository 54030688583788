import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { GetParams, KCard, KContainer, KSpace } from "@kopjra/uikit";
import { Translate } from "react-redux-i18n";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Search } from "../../types/detection/searchState";
import { SearchDetailsDeep } from "./SearchDetailsDeep";
import { Domain } from "../../types/detection/domainsState";
import { Domains } from "./Domains";
import { tableGetParams } from "../../utils/commons";
import {Notebook, SearchType} from "../../types/extra";
import { SearchDetailsUGC } from "./SearchDetailsUGC";
import OSDBreadcrumbs from "../../containers/detection/OSDBreadcrumbs";
import { SearchDetailsClear } from "./SearchDetailsClear";
import { OSDSpinner } from "../OSDSpinner";



export interface StateProps {
    currentSearch?: Search;
    domains?: Domain[];
    total: number;
}

export interface DispatchProps {
    onGetCurrentSearch: (searchId: string) => Promise<void>;
    onGetDomains: (searchId: string, query: GetParams) => Promise<void>;
    onOpenDomain: (productType: string, searchId: string, domainId: string) => void;
    onAddManualUrls: (searchId: string, urls: string[]) => Promise<void>;
    onUploadSearchNotebook: (searchId: string, notebook: Notebook) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainsArea: React.FC<Props> = (
    {
        currentSearch, domains, total,
        onGetCurrentSearch, onGetDomains, onOpenDomain, onAddManualUrls, onUploadSearchNotebook
    }
) => {
    // @ts-ignore
    const { searchId } = useParams();
    const queryParams = tableGetParams("domains/list");

    useEffect(() => {
        onGetCurrentSearch(searchId as string).catch((e) => console.warn("Get current search error", e));
        onGetDomains(searchId as string, queryParams).catch((e) => console.warn("Get domains error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const detailsDeep = <SearchDetailsDeep currentSearch={currentSearch} type={"domains"} />;
    const detailsUGC = <SearchDetailsUGC currentSearch={currentSearch} type={"domains"} />;
    const detailsClear = <SearchDetailsClear currentSearch={currentSearch} type={"domains"} />;

    return (
        <KContainer>
            <OSDBreadcrumbs type={"domains"} searchId={searchId} />
            <KCard header={<Translate value={"domains.title"} />}>
                <Row className="text-start">
                    <Col md={12}>
                        {currentSearch ? (
                            currentSearch.webType === SearchType.DEEP ? detailsDeep :
                                currentSearch.webType === SearchType.UGC ? detailsUGC :
                                    currentSearch.webType === SearchType.CLEAR ? detailsClear : <></>
                        ) : (
                            <OSDSpinner size={100} variant={"light"} />
                        )}
                    </Col>
                </Row>
                <KSpace />
                <Row>
                    <Col md={12}>
                        {currentSearch ? (
                            <Domains domains={domains} total={total} searchType={currentSearch.webType} onGetDomains={onGetDomains} onOpenDomain={onOpenDomain} onAddManualUrls={onAddManualUrls} onUploadSearchNotebook={onUploadSearchNotebook}/>
                        ) : (
                            <OSDSpinner size={100} variant={"light"} />
                        )}
                    </Col>
                </Row>
            </KCard>
        </KContainer>
    );
};
