const en = {
    kptable: {
        filters: "Filters",
        apply: "Apply",
        show_columns: "Show columns",
        pager: {
            first: "First",
            last: "Last",
        },
        actions: "Actions",
        date: {
            short: "MM/DD/YYYY",
            long: "MM/DD/YYYY hh:mm a",
        },
        confirm: {
            title: "Confirmation",
            yes: "Yes",
            no: "No",
        },
        show: "Show",
        globalSelectionWarn1: "All results on this page have been selected. ",
        globalSelectionWarn2: "All search results have been selected. ",
        globalSelectionWarnAll: "Select all search results",
        globalSelectionWarnNotAll: "Cancel selection",
        globalSelectionFilterWarn1: "(Any active filters will be considered)",
        globalSelectionFilterWarn2: "(Any active filters are considered)",
    },
    locale: {
        en: "EN",
        it: "IT",
    },
    user: {
        profile: "User Profile",
        account: "Account",
        logout: "Logout",
        login: "Login",
        support: "Support",
        name: "Name",
        email: "Email",
        verified: "Email verified",
    },
    generic: {
        yes: "Yes",
        no: "No",
        save: "Save",
    },
    error: {
        page: "Error",
        forbidden: "Access Denied",
        notFound: "Page not found",
        notAuthenticated: "Not authenticated"
    },
    alerts: {
        infos: {
            newSearch: "Search started",
            newSearchCreation: "Creating search...",
            deleteSearches: "Searches deleted",
            stopCrawlSearch: "Search stopped",
            restartCrawlSearch: "Search restarted",
            newReport: "Generating report... It will be sent to your mail while available",
            searchConversion: "Converting to advanced search... You will be redirected to the dashboard",
            newDomainInfo: "Searching domain...",
            deleteDomainInfo: "Domain deleted",
            updateWell: "Takedown updated",
            deleteWell: "Process deleted",
            notice: "Notice acknowledged",
            addManualUrls: "Results added",
            wellResultsDeleted: "Urls deleted",
            searchRefreshPlan: "Search update planned",
            searchRefreshNow: "Search update started",
        },
        errors: {
            newSearch: "Error creating search",
            noCategories: "Choose at least one category",
            deleteSearches: "Error deleting searches",
            stopCrawlSearch: "Error stopping search",
            restartCrawlSearch: "Error restarting search",
            notOnionUrl: "Error not valid url with tor proxy",
            newReport: "Error generating report",
            searchConversion: "Error converting search",
            newDomainInfo: "Error searching domain",
            deleteDomainInfo: "Error deleting domain",
            deleteWell: "Error deleting process",
            notice: "Error sending notice",
            newWellCreation: "You must add at least a detection search or a url",
            newWellError: "Error during takedown creation, retry",
            updateWell: "Error during takedown update, retry",
            addManualUrls: "Error during results insertion",
            addManualUrlsAtLeast: "Insert at least one result",
            changeTDState: "Error during takedown state update, retry",
            wellResultsDeleted: "Error deleting urls, retry",
            searchRefresh: "Error updating search, retry",
            screenshotArchive: "Error creating screenshot archive",
        }
    },
    breadcrumbs: {
        overview: "SEARCH OVERVIEW",
        domains: "DOMAINS",
        results: "RESULTS",
        emails: "EMAILS"
    },
    menu: {
        dashboard: "Dashboard",
        searches: "Manage searches",
        domainSearches: "Manage searches",
        domains: "Manage domains",
        tdprocesses: "Takedown processes"
    },
    credits: {
        title: "New search",
        tdTitle: "New takedown",
        baseSearches: "Available basic searches",
        genericSearches: "Available searches",
        newSearch: "New search",
        placeholderSearch: "Type the title of the content you want to search",
        typeBaseSearch: "Base",
        typeAdvancedSearch: "Advanced",
        addCredits: "Add Credits",
        inputErrorMessage: "Type at least a 3 character query",
        inputErrorMessageDomain: "Type a valid domain",
        checksErrorMessageDeep: "Select at least one category",
        checksErrorMessageUGC: "Select at least one site",
        checksErrorMessageClear: "Select at least one language",
        clearLabel: "Type at most 10 domains in which to search the contento",
        clearError: "Type correctly the domain list",
        clearHelp: "Use comma to type more domains"
    },
    categories: {
        cinemaLabel: "Cinema",
        publishingLabel: "Publishing",
        musicLabel: "Music",
        radioLabel: "Radio",
        softwareLabel: "Software",
        sportLabel: "Sport",
        tvLabel: "TV",
        videogamesLabel: "Videogames",
        elearningLabel: "Elearning",
        pornLabel: "Porn",
        codeLabel: "Code"
    },
    searches: {
        status: {
            started: "Search started"
        },
        browserAutomationYes: "Browser",
        browserAutomationNo: "HTTP",
        titleComplete: "Searches",
        titleLastFive: "Last Searches",
        totalSearches: "Total searches",
        totalPotentialInfringements: "Total results",
        table: {
            noSearches: "There are no active searches. Execute a search to view the results",
            created: "Created",
            updated: "Updated",
            visitedUrls: "Visited urls",
            totalUrls: "Urls number",
            maxVisitedUrls: "Visitable ulrls",
            authenticated: "Authenticated",
            browserAutomation: "Method",
            torProxy: "Tor Proxy",
            refreshIntervalDays: "Planning (days)",
            query: "Search",
            language: "Language",
            languages: "Languages",
            resultNumber: "Results",
            state: "State",
            stateFree: "Base",
            statePaid: "Advanced",
            type: "Type",
            categories: "Categories",
            sources: "UGC Sites",
            validation: "Validated results",
            actions: {
                stopSearch: "Do you really want to stop the search? You can take it back later.",
                restartSearch: "Do you really want to restart the search?",
                view: "Open",
                viewtip: "Domains of the search",
                delete: "Delete",
                deleteTip: "Delete search",
                deleteMessage: "Do you really want to delete all selected searches?",
                add: "Add",
                details: "Result details",
                generateReportCSV: "Do you want to generate the CSV report? It will be sent to your email as soon as possible",
                generateReportPDF: "Do you want to generate the PDF report? It will be sent to your email as soon as possible",
                globalReportPDF: "Do you want to generate an archive with all reports in PDF format related to the selected items? It will be sent to your email as soon as possible",
                globalReportCSV: "Do you want to generate a single CSV file with all data related to the selected items? It will be sent to your email as soon as possible",
                pdf: "PDF",
                csv: "CSV",
                update: "Update",
                planning: "Plan",
                planningMessage: "Choose the monitoring frequency in days, choose 0 to disable monitoring (min: 0, max: 30)",
                cancel: "Cancel",
                errorInterval: "Insert a number between 0 and 30"
            },
            filters: {
                query: "Search",
                type: "Type",
                tags: "Tags"
            }
        },
        new: {
            query: "Search terms",
            name: "Search name",
            maxVisitedUrls: "Number of urls to visit",
            browserAutomation: "Do you want to use the browser?",
            torProxy: "Do you want to browse under TOR network?",
            login: "Do you want to automate the login?",
            loginUrl: "Url that contains login form",
            username: "Username",
            password: "Password",
            categories: "Content types",
            sites: "UGC Sites",
            languages: "Languages",
            domains: "Domains",
            url: "Url",
            placeholders: {
                query: "Insert search terms",
                name: "Insert name terms",
                maxVisitedUrls: "Insert max number of urls",
                loginUrl: "Insert login url",
                username: "Insert username for the authentication",
                password: "Insert password for the authentication",
                categories: "Select one or more content types",
                sites: "Select one or more ugc sites",
                languages: "Select one or more languages",
                domains: "Insert a domain in each row, max 10 (e.g. multiplayer.it)",
                url: "Insert a url (e.g. https://www.google.it)"
            }
        },
        clear: {
            languages: {
                it: "Italy",
                es: "Spain",
                fr: "France",
                de: "Germany",
                uk: "United Kingdom",
                us: "United States"
            }
        },
    },
    results: {
        title: "Domain results validation",
        titleCrawl: "Results validation",
        convert: "Convert to advanced",
        table: {
            preview: "Preview",
            title: "Title",
            views: "Views",
            duration: "Duration",
            url: "Url",
            violation: "Validation",
            publishedAt: "Upload date",
            created: "Created",
            visited: "Visited",
            notes: "Notes",
            notesLabel: "Notes",
            notesCSV: "CSV Notes",
            notebookLabel: "CSV Notes (only 2 columns: url, notes)",
            filters: {
                url: "Url",
                title: "Title",
                infringement: "Validation",
                infringementYes: "Accepted",
                infringementNo: "Rejected",
                infringementNotValidated: "Not validated",
                visited: "Visited",
                visitedYes: "Yes",
                visitedNo: "No",
                htmlQuery: "HTML",
                strictHtmlQuery: "Strict",
                strictYes: "Yes",
                strictNo: "No",
                notes: "Notes",
            }
        },
    },
    resultUGC: {
        cardTitle: "Result details",
        title: "Title",
        channel: "Channel",
        channelFollowers: "Channel subscribed",
        description: "Description",
        descriptionShow: "Show",
        url: "URL",
        views: "Views",
        duration: "Duration",
        publishedAt: "Published at"
    },
    domains: {
        faviconSimilarSites: "Favicon similar sites",
        title: "Search domains",
        date: "Date",
        violations: "Results",
        validated: "Validated results",
        totalDomains: "Total domains",
        table: {
            name: "Domain",
            importance: "Importance",
            potentialInfringements: "Results",
            resultNumber: "Results",
            channel: "Channel",
            validation: "Validation status",
            validationTipCompleted: "Completed",
            validationTipProgress: "In validation",
            validationTipNotStarted: "Not started",
            actions: {
                view: "Results",
                viewtip: "Results of the domain",
                delete: "Delete",
                deleteTip: "Delete domain",
                deleteMessage: "Do you really want to delete this domain with all its results?"
            },
        },
        searchDomains: "Explore and validate"
    },

    searchConversion: {
        header: "Search conversion",
        rememberCategory: "Choose categories to start an advanced search",
        message: "Do you want to convert your search to Advanced? A credit for advanced searches will be used",
        yes: "Yes",
        no: "No",
        remainingCredits: "There are %{count} available credits for advanced searches, do you want to use one to convert this search?",
        infinite: "infinite",
        nocredits: "No more credits for advanced searches are available, do you want to buy some credits on the Kopjra store?",
        nocreditsRedirect: " (You will be redirected to the dashboard)"
    },
    reportGeneration: {
        title: "Report export",
        reportType: "Report type",
        reportTypeMetrics: "Only metrics",
        reportTypeFull: "Metrics + urls",
        reportTypeCSV: "CSV",
        reportLanguage: "Language",
        sendTo: "Send report to (account mail already included)",
        mailPlaceholder: "Es.: vittoria@kopjra.com, lucio@legaltechforum.com...",
        mailTip: "Use comma to insert multiple mails",
        mailError: "Insert at least a valid email address",
        confirm: {
            send: "Send",
            cancel: "Cancel"
        },
        confirmMessage1: "Report exported and sent with success.",
        confirmMessage2: "You will shortly receive an email",
        confirmMessage3: "with the attached document."
    },
    stats: {
        searchOverview: "Search overview",
        validationPercentCompleted: "Validated results (%)",
        validationPercentNotCompleted: "Non validated results (%)",
        chartValidation: "Validation status",
        deep: {
            chartChannels: "Domain distribution per channel",
            topTenCyberlockersImportance: "Top cyberlockers for importance",
            topTenCyberlockersInfringements: "Top cyberlockers for results number",
            topTenTorrentsImportance: "Top torrents for importance",
            topTenTorrentsInfringements: "Top torrents for results number",
            domainLabel: "Domain",
            importanceLabel: "Importance",
            infringementsLabel: "Results"
        },
        ugc: {
            chartUGC: "Results distribution UGC sites",
            topTenViews: "Top results for views",
            topTenDuration: "Top results for duration",
            topTenRecent: "Top results for recent upload date",
            topTenChannels: "Top channels for uploaded videos",
            titleLabel: "Title",
            viewsLabel: "Views",
            durationLabel: "Duration",
            dateLabel: "Date",
            channelLabel: "Channel",
            uploadedLabel: "Uploaded"
        },
        clear: {
            chartSites: "Results distribution web sites",
            topTenDomains: "Top domains for results",
            topTenImportance: "Top domains for results",
            domainLabel: "Domain",
            importanceLabel: "Importance",
            resultsLabel: "Results"
        }
    },
    home: {
        mainDescription1: "Welcome to Web Intelligence",
        mainDescription2: "Sign in into applications or buy credits to use them",
        signin: "Sign in",
        detection: "Complex OSINT (Open Source Intelligence) activities such as infringement detection on clear, deep and dark web.",
        dna: "Extraction of relevant information about a domain such as domain owners, hosting providers and CDNs (Content Delivery Networks).",
        domainSearches: "Execute various types of searches starting from a domain name or a keyword (registered domains, typosquatting, favicons, subdomains, advertising).",
        takedown: "Infringement takedown with massive sending of N&TD (Notice & Takedown) and delisting requests to related subjects.",
        contactus: "Need a tailored solution? Contact us",
        contactSubject: "Tailored solution: [SOLUTION]",
        contactBody: "Hello,\nWe would like a tailored solution for [SOLUTION],\n",
    },
    analysis: {
        mainDescription1: "Find relevant information related to a domain name.",
    },
    domainSearches: {
        card: {
            title: "New domain search",
            titleLast: "Last domain searches",
            titleComplete: "Domain searches",
        },
        dashboard: {
            query: "Search terms",
            domain: "Domain",
            placeholderQuery: "Type a query of at least 3 characters",
            placeholderDomain: "Type a domain (eg. google.com)",
            totalSearches: "Total domain searches",
        },
        table: {
            query: "Domain",
            type: "Type",
            nDomains: "N. domains",
            validation: "Validation",
            created: "Created",
            updated: "Updated",
            tags: "Tags",
            actions: {
                view: "Open",
                update: "Update",
                delete: "Delete",
                deleteMessage: "Do you really want to delete this domain search?",
                downloadReportCSV: "Do you want to download the CSV report?",
            },
            noSearches: "There are no active domain searches. Execute a search to view the results",
        },
    },
    domainAnalysis: {
        card: {
            title: "Search overview",
            total: "Total domains",
            validated: "Validated Domains"
        },
        table: {
            domain: "Domain",
            typosquat: "Typosquat",
            ip: "IP",
            registration: "Registration",
            adId: "Ad ID",
            page: "Page",
            title: "Title",
            description: "Description",
            languages: "Languages",
            creation: "Ad creation",
            deliveryStart: "Start delivery",
            deliveryEnd: "End delivery",
            added: "Added",
            validation: "Validation",
        }
    },
    domainInfo: {
        dashboard: {
            remainingCredits: "Web Intelligence Credits",
            placeholderSearchbar: "Type a domain (Eg. google.it)",
            inputErrorSearchbar: "Type a correct domain",
            titleLastFive: "Last domains",
            titleComplete: "Domains",
            totalDomains: "Total domains",
            errorTooltip: "Domain not exists, not active or not found"
        },
        table: {
            name: "Domain",
            date: "Date",
            state: "State",
            stateTooltip: "Searching domain (It takes up to 15 minutes)",
            actions: {
                viewTip: "Open domain",
                deleteTip: "Delete domain",
                deleteMessage: "Do you really want to delete this domain?"
            }
        },
        details: {
            main: {
                title: "Main",
                domain: "Domain",
                ip: "IP",
                date: "Date",
                indexing: "Google Indexing",
                importance: "Importance"
            },
            registrar: {
                title: "Registrar",
                name: "Name",
                ianaID: "IANA ID",
                url: "URL",
                whoisServerUrl: "Whois Server URL",
                whoisEmail: "Whois Email"
            },
            registrant: {
                title: "Registrant",
                name: "Name",
                organization: "Organization",
                address: "Address",
                url: "URL",
                emails: "Emails"
            },
            hoster: {
                title: "Hoster",
                name: "Name",
                organization: "Organization",
                address: "Address",
                asn: "ASN",
                rirName: "RIR Name",
                rirCountryCode: "RIR Country Code",
                rirWhoisServer: "RIR Whois Server",
                emails: "Emails"
            },
            registry: {
                title: "Registry",
                name: "TLD Name",
                organization: "Organization",
                organizationAddress: "Organization Address",
                whoisServer: "Whois Server",
                administrativeEmail: "Administrative Email",
                technicalEmail: "Technical Email",
                registrationServicesURL: "Registration Services URL"
            },
            indexing: {
                title: "Indexing",
                google: "Google",
                bing: "Bing",
                yahoo: "Yahoo",
                yandex: "Yandex"
            },
            advertising: {
                title: "Advertising",
                adServers: "Ad Servers",
                adultServers: "Adult Ad Servers",
                trackerServers: "Tracker Servers"
            }
        }
    },
    wells: {
        overview: {
            totalUrls: "Total Results",
            globalTakedownPercent: "Removed Results",
            createWell: "New takedown",
            titleComplete: "Takedowns",
            titleLastFive: "Last takedowns",
            totalWells: "Total takedowns",
            noSearches: "There are no active Takedowns. Create a Takedown to send notices",
        },
        attachments: {
            attachmentType: "Attachment type",
            registrationNumber: "Registration number",
            registrationEntity: "Registration Entity",
            registrationZone: "Registration Zone",
            registrationClass: "Registration Class",
            registrationDate: "Registration Date",
            expirationDate: "Expiration Date",
            url: "Attachment url",
            selectAttachmentType: "Select attachment type",
            trademarkType: "Trademark Registration",
            patentType: "Patent Registration",
            designType: "Design Patent Registration",
            mediaType: "Media Content",
            insertRegistrationNumber: "Insert registration number",
            insertRegistrationEntity: "Insert registration Entity",
            insertRegistrationZone: "Insert registration Zone",
            insertRegistrationClass: "Insert registration Class",
            insertRegistrationDate: "Insert registration Date",
            insertExpirationDate: "Insert expiration Date",
            insertUrl: "Insert url",
            document: "Document",
            loadDocument: "Load document",
            add: "Add attachment"
        },
        static: {
            createdWell: "Takedown created successfully",
            bindingTitle: "Searches selection",
            bindingSearches: "Detection searches",
            manualResults: "Manual results",
            manualPlaceholder: "Insert a single url in each row\ne.g.\nhttps://www.google.it\nhttp://mega.io",
            updatedWell: "Takedown info updated successfully",
            updateWell: "UPDATE",
            manualUrls: "Add results",
            results: "Results",
            resultsNoticed: "Noticed results",
            mailsToRead: "Total notice responses not read",
            takedownInfo: "Takedown info",
            delegateMessage: "Are you a delegate for the takedown?",
            rightsOwnerInfo: "Rights Owner",
            attachmentsInfo: "Email Attachments",
            templatePreview: "Notice text preview",
            defaultName: "Type name of takedown",
            defaultAsset: "Type the asset",
            defaultUrl: "Type the url",
            defaultCustomSenderEmail: "Type an email address used to send notices",
            defaultInfringementType: "Select the infringement type",
            copyrightType: "Copyright Infringement",
            trademarkType: "Trademark Infringement",
            patentType: "Patent Infringement",
            designpatentType: "Design Patent Infringement",
            counterfeitingType: "Counterfeiting",
            defaultOwnerName: "Type the name and surname/organization",
            defaultOwnerAddress: "Type the address",
            defaultOwnerPhone: "Type the phone number",
            defaultOwnerWebsite: "Type the website",
            select: "Select...",
            confirm: "Proceed",
            cancel: "Cancel",
            back: "Back",
            textareaError: "Insert single url in a row e.g. http://www.google.it",
            addManualUrls: "Insert",
            insertPdf: "Load a pdf file",
            noSearches: "There are no active Detection searches with validated results. Validate search results in order to add searches to a Takedown",
            notnoticedState: "Not noticed",
            noticedState: "Noticed",
            removedState: "Removed",
            removedredirectState: "Removed with redirect",
            notremovedState: "Non removed",
            exceededretriesState: "Max retries reached",
            unnoticeableState: "Not noticeable",
            loadPDF: "Load PDF",
            downloadPOA: "Download DOCX template",
            deletableUrlsMessage: "Only urls not related to detection searches can be deleted. Yuo're going to delete %{nUrls} urls, continue?",
            notDeletableUrlsMessage: "Warning! No one of the selected urls can be deleted because they refers to detection searches",
            indexedState: "Indexed",
            notindexedState: "Not indexed",
            delistedState: "Delisted",
            notdelistedState: "Not delisted",
            delistingwaitingState: "Delisting waiting",
            notrequestedState: "Not requested",
            notes: "Notes",
        },
        table: {
            name: "Name",
            infringementType: "Type",
            nUrls: "URLs number",
            created: "Date",
            ownerName: "Name and surname/organization",
            ownerAddress: "Address",
            ownerPhone: "Phone",
            ownerWebsite: "Website",
            asset: "Asset",
            assetUrl: "Asset url",
            customSenderEmail: "Custom email sender",
            edit: "Edit",
            deleteMessage: "Do you really want to delete this takedown? Detection searches will remain, but you'll lose urls added manually and all related info",
            flatResults: "Results",
            archive: "Archive",
            unarchive: "Unarchive",
            archived: "Archived",
            notArchived: "Not archived",
        },
        domains: {
            onlyForm: "This domain can only be noticed by a notice form.\nClick the button to open the form in another tab",
            openForm: "Open form",
            copyLinks: "Once you have opened the notice form copy all results to insert them into the form",
            openCopyLinks: "Copy results",
            title: "Takedown domains",
            table: {
                name: "Name",
                title: "Title",
                created: "Creation date",
                nUrls: "URLs",
                nUrlsRemoved: "Removed URLs",
                nMailsToRead: "Emails not read",
                lastNotice: "Last notice",
                noticeType: "Notice type",
                view: "Open",
                viewtip: "View domain",
                notice: "Notice",
                noticetip: "Send notice",
                formConfirm: "This domain is only noticeable via notice form. Do you want to open the related form in a new page?",
                noticeConfirm: "You're sending a notice to the selected domain, the mail will include all domain urls that haven't reached max number of notice retries. Continue?",
                indexingConfirm: "On which search engine you want to request the indexing state of all selected elements?",
                screenshotConfirm: "Do you want to generate screenshots for all selected urls?",
                screenshotArchiveConfirm: "Do you want to download the archive containing all the generated screenshots?",
                screenshotArchive: "Zip screenshots",
                inbox: "Notices",
                mailtip: "Mails related to domain",
                insertUrls: "Add results",
                changeIndexingTitle: "Update indexing state",
                changeIndexingMessage: "Insert a urls list related to a Takedown of which you want to change the indexing state",
                searchEngine: "Search engine",
                indexingState: "Indexing state",
                stateDate: "Date",
                requestId: "Request ID",
                updateIndexing: "Update indexing state",
                requestIndexing: "Request indexing state"
            },
        },
        results: {
            title: "Domain URLs",
            flatTitle: "Takedown URLs",
            table: {
                url: "URL",
                takedownState: "Takedown state",
                noticeAttempts: "Notice attempts",
                lastNotice: "Last notice",
                changeStateTitle: "Takedown state update",
                changeStateConfirm1: "You are changing the takedown state of all selected results to the same state.",
                changeStateConfirm2: "Please note that only results that can change into the selected state will be updated",
                changeStateConfirm3: "Urls number that will be changed:",
                created: "Insertion date"
            }
        },
        mails: {
            title: "Sent notices",
            subject: "Subject",
            to: "Recipient",
            noticedUrls: "Noticed results",
            dateSent: "Sent date",
            lastReceivedDate: "Last reponse date",
            received: "Email responses",
            from: "From",
            dest: "To",
            date: "Date",
            you: "Web Intelligence"
        }
    },
};

export default en;
