import {apiCall} from "../index";
import {ClearStats, DeepStats, SearchStatistics, UGCStats} from "../../types/detection/statsAreaState";
import {transformToDomain} from "./domainsArea";
import {SearchType} from "../../types/extra";
import {transformToResult} from "./resultsArea";

export async function getSearchStats(searchId: string): Promise<SearchStatistics> {
    const res = await apiCall({urlComponent: `/searches/${searchId}/stats`});
    const json = await res.json();
    switch (json.webType) {
        case SearchType.DEEP:
            return {
                channelsDistribution: json.stats.channelsDistribution,
                cyberlockersRankByImportance: json.stats.cyberlockersRankByImportance.map((e: any) => transformToDomain(e)),
                cyberlockersRankByInfringements: json.stats.cyberlockersRankByInfringements.map((e: any) => transformToDomain(e)),
                torrentsRankByImportance: json.stats.torrentsRankByImportance.map((e: any) => transformToDomain(e)),
                torrentsRankByInfringements: json.stats.torrentsRankByInfringements.map((e: any) => transformToDomain(e))
            } as DeepStats;
        case SearchType.UGC:
            return {
                totalViews: json.stats.totalViews,
                totalDuration: json.stats.totalDuration,
                domainsRankByInfringements: json.stats.domainsRankByInfringements.map((e: any) => transformToDomain(e)),
                resultsRankByViews: json.stats.resultsRankByViews.map((e: any) => transformToResult(e)),
                resultsRankByDuration: json.stats.resultsRankByDuration.map((e: any) => transformToResult(e)),
                resultsRankByDate: json.stats.resultsRankByDate.map((e: any) => transformToResult(e)),
                channelsRankByUploaded: json.stats.channelsRankByUploaded.map((e: any) => { return {channel: e.channel, channelURL: e.channelURL, uploadedVideos: e.uploadedVideos}})
            } as UGCStats;
        case SearchType.CLEAR:
            return {
                domainsRankByInfringements: json.stats.domainsRankByInfringements.map((e: any) => transformToDomain(e)),
                domainsRankByImportance: json.stats.domainsRankByImportance.map((e: any) => transformToDomain(e))
            } as ClearStats;
        default:
            return {} as SearchStatistics
    }
}
