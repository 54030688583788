import * as React from "react";
import {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {GetParams, KCard, KContainer, KSpace} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useParams} from "react-router-dom";
import {Well} from "../../types/takedown/wellsState";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {tableGetParams} from "../../utils/commons";
import {WellDetails} from "./WellDetails";
import {WellDomains} from "./WellDomains";
import {User} from "../../types/main";


export interface StateProps {
    currentUser?: User;
    currentWell?: Well;
    wellDomains?: WellDomain[];
    total: number;
    currentFormResults?: string;
}

export interface DispatchProps {
    onGetCurrentWell: (wellId: string) => Promise<void>;
    onGetWellDomains: (wellId: string, query: GetParams) => Promise<void>;
    onOpenWellDomain: (productType: string, wellId: string, domainName: string) => void;
    onSendNotice: (wellId: string, domainName: string) => Promise<void>;
    onOpenMails: (productType: string, wellId: string, domainName: string) => void;
    onUpdateWell: (productType: string, wellId: string) => void;
    onAddManualUrls: (wellId: string, urls: string[], autoScreen: boolean, query: GetParams) => Promise<void>;
    onGetCurrentFormResults: (wellId: string, domainName: string) => Promise<void>;
    onSetIndexingState: (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, stateDate?: string, requestId?: string) => Promise<void>;
    onRequestDomainsIndexing: (wellId: string, domains: WellDomain[], all: boolean, searchEngine: string, queryParams: GetParams) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const WellDomainsArea: React.FC<Props> = (
    {
        currentUser, currentWell, wellDomains, total,
        currentFormResults, onGetCurrentFormResults, onGetCurrentWell, onGetWellDomains,
        onOpenWellDomain, onSendNotice, onUpdateWell, onOpenMails, onAddManualUrls, onSetIndexingState,
        onRequestDomainsIndexing
    }
) => {
    // @ts-ignore
    const {wellId} = useParams();
    const queryParams = tableGetParams("wellDomains/list");

    useEffect(() => {
        onGetCurrentWell(wellId as string).catch((e) => console.warn("Get current well error", e));
        onGetWellDomains(wellId as string, queryParams).catch((e) => console.warn("Get well domains error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer>
            <KCard header={<Translate value={"wells.domains.title"}/>}>
                <Row className="text-start">
                    <Col><WellDetails currentWell={currentWell} type={"domains"} onUpdateWell={onUpdateWell}/></Col>
                </Row>
                <KSpace spaces={2}/>
                <Row>
                    <Col md={12}>
                        <WellDomains currentUser={currentUser} wellDomains={wellDomains} total={total} currentFormResults={currentFormResults} onGetWellDomains={onGetWellDomains}
                                     onOpenWellDomain={onOpenWellDomain} onSendNotice={onSendNotice} onOpenMails={onOpenMails} onAddManualUrls={onAddManualUrls}
                                     onSetIndexingState={onSetIndexingState} onRequestDomainsIndexing={onRequestDomainsIndexing}
                                     onGetCurrentFormResults={async (wellId: string, domainName: string) => {
                                         onGetCurrentFormResults(wellId, domainName).catch((e) => console.warn("Get current form results error", e));
                                     }}
                        />
                    </Col>
                </Row>
            </KCard>
        </KContainer>
    );
};
