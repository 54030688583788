import * as React from "react";
import { ReactChild, useEffect } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import {I18n, Localize, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import {KAccordion, KCard, KContainer, KLabel, KSpace} from "@kopjra/uikit";
import { DomainInfo } from "../../types/analysis/domainsInfoState";


export interface StateProps {
    currentDomainInfo?: DomainInfo;
}

export interface DispatchProps {
    onGetCurrentDomainInfo: (domainInfoId: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainInfoDetails: React.FC<Props> = ({ currentDomainInfo, onGetCurrentDomainInfo }) => {
    // @ts-ignore
    const { domainInfoId } = useParams();

    const domainInfo = [
        {
            title: "Registrar",
            body: <div className={"analysisSubject"}>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrar.name" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrar?.name}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrar.ianaID" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrar?.IANAID}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrar.url" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrar?.url}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrar.whoisServerUrl" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrar?.whoisServerUrl}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrar.whoisEmail" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrar?.whoisEmail}</p></Row>
            </div>
        },
        {
            title: "Registrant",
            body: <div className={"analysisSubject"}>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrant.name" />}/></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrant?.name}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrant.organization" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrant?.organization}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrant.address" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrant?.address}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrant.url" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registrant?.url}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registrant.emails" />} /></Row>
                <Row className={"text-start"}><Col>{currentDomainInfo?.registrant?.emails.map(e => <p>{e}</p>)}</Col></Row>
            </div>
        },
        {
            title: "Registry",
            body: <div className={"analysisSubject"}>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.name" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.tldName}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.organization" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.organizationName}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.organizationAddress" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.organizationAddress}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.whoisServer" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.whoisServer}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.administrativeEmail" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.administrativeEmail}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.technicalEmail" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.technicalEmail}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.registry.registrationServicesURL" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.registry?.registrationServicesURL}</p></Row>
            </div>
        },
        {
            title: "Hoster",
            body: <div className={"analysisSubject"}>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.name" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.name}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.organization" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.organization}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.address" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.address}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.asn" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.asn}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.rirName" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.rirName}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.rirCountryCode" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.rirCountryCode}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.rirWhoisServer" />} /></Row>
                <Row className={"text-start"}><p>{currentDomainInfo?.hoster?.rirWhoisServer}</p></Row>
                <Row className={"text-start"}><KLabel text={<Translate value="domainInfo.details.hoster.emails" />} /></Row>
                <Row className={"text-start"}><p style={{ wordBreak: "break-all" }}>{currentDomainInfo?.hoster?.emails.join(", ")}</p></Row>
            </div>
        },
        {
            title: I18n.t("domainInfo.details.advertising.title"),
            body: currentDomainInfo?.ads
                ? <div className={"analysisSubject"}>
                    <Row><Col>
                        <p><KLabel text={I18n.t("domainInfo.details.advertising.adServers")}/></p>
                        {currentDomainInfo.ads.adServers?.map(ad => <p>{ad}</p>)}
                    </Col></Row>
                    <Row><Col>
                        <p><KLabel text={I18n.t("domainInfo.details.advertising.adultServers")}/></p>
                        {currentDomainInfo.ads.adultAdServers?.map(ad => <p>{ad}</p>)}
                    </Col></Row>
                    <Row><Col>
                        <p><KLabel text={I18n.t("domainInfo.details.advertising.trackerServers")}/></p>
                        {currentDomainInfo.ads.trackerServers?.map(ad => <p>{ad}</p>)}
                    </Col></Row>
                </div>
                : <></>
        }
    ];

    useEffect(() => {
        onGetCurrentDomainInfo(domainInfoId as string).catch((e) => console.warn("Get domain info error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderImportance(val: number): ReactChild {
        val = val > 0 ? val : 1;
        return <div style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}><ProgressBar now={val} max={5} className={`kProgressImportance${val}`} /></div>
    }

    return (
        <KContainer>
            <Row className={"text-start"}><h3>{currentDomainInfo?.domainName}</h3></Row>
            <KSpace spaces={2} />
            <Row className={"text-start"}>
                <Col md={{ offset: 2, span: 2 }}><KLabel text={<Translate value="domainInfo.details.main.ip" />} /></Col>
                <Col md={3}><h6 className={"text-start"}>{currentDomainInfo?.domainIP}</h6></Col>
            </Row>
            <Row className={"text-start"}>
                <Col md={{ offset: 2, span: 2 }}><KLabel text={<Translate value="domainInfo.details.main.date" />} /></Col>
                <Col md={3}>
                    <h6 className={"text-start"}>{currentDomainInfo?.created ? (
                        <Localize value={currentDomainInfo?.created} dateFormat="kptable.date.long" />
                    ) : (<p />)}
                    </h6>
                </Col>
            </Row>
            <Row className={"text-start"} style={{ marginBottom: "23px" }}>
                <Col md={{ offset: 2, span: 2 }}><KLabel text={<Translate value="domainInfo.details.main.importance" />} /></Col>
                <Col md={3} style={{ position: "relative" }}>
                    <h6 className={"text-start"}>{currentDomainInfo && currentDomainInfo.importance > 0 ? renderImportance(currentDomainInfo.importance) : ""}</h6>
                </Col>
            </Row>
            <Row className={"text-start"}>
                <Col md={{ offset: 2, span: 2 }}><KLabel text={<Translate value="domainInfo.details.main.indexing" />} /></Col>
                <Col md={3}><h6 className={"text-start"}>
                    {currentDomainInfo?.indexing !== undefined ? (currentDomainInfo?.indexing ?
                        <Translate value="generic.yes" /> : <Translate value="generic.no" />) : ""}
                </h6></Col>
            </Row>
            <KSpace spaces={2} />
            <Row className={"text-start"}><Col sm={12} md={9} lg={6}><span className={"analysisCard"}><KCard><span className={"analysisAccordion"}><KAccordion elements={domainInfo} /></span></KCard></span></Col></Row>
        </KContainer>
    );
};
