import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {DomainAnalysis, DomainSearchResultsState} from "../../types/analysis/domainSearchResultsState";
import {
    gotDomainSearchResults, resetDomainSearchResults,
    updateDomainOnlineStatus, validatedAnalysisDomains
} from "../../actions/analysis/domainSearchResults";


const initialState: DomainSearchResultsState = {
    total: 0,
    forceUpdate: false
};

export const domainSearchResultsReducer = createReducer(initialState)
    .handleAction(gotDomainSearchResults, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(updateDomainOnlineStatus, (state, action) => {
        const index = state.list?.findIndex((domain: DomainAnalysis) => domain.id === action.payload.id);
        if (index === -1 || index === undefined) {
            return state;
        } else {
            return update(state, {
                list: {
                    [index]: {
                        online: {$set: action.payload.online},
                    }
                }
            })
        }
    })
    .handleAction(resetDomainSearchResults, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0},
    }))
    .handleAction(validatedAnalysisDomains, (state, action) => {
        const domains: Array<DomainAnalysis> = action.payload;
        const validationValue = domains[0].validation;
        const stateIds: any = {};
        if (state.list) {
            for (const domain of domains) {
                const id = state.list.findIndex( r => r.id === domain.id);
                if (id !== -1) {
                    stateIds[id] = {validation: {$set: validationValue}};
                }
            }
            return update(state, {
                list: stateIds,
                forceUpdate: {$set: !state.forceUpdate}
            });
        } else {
            return state;
        }
    });
