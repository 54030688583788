import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {DomainInfo, DomainsInfoState} from "../../types/analysis/domainsInfoState";
import {cancelDomainInfo, doDomainInfoSearch, gotDomainsInfo, resetDomainsInfoState} from "../../actions/analysis/domainsInfo";


const initialState: DomainsInfoState = {
    total: 0
};

export const domainsInfoReducer = createReducer(initialState)
    .handleAction(gotDomainsInfo, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(doDomainInfoSearch, (state, action) => update(state, {
        list: {$set: [action.payload, ...state.list as DomainInfo[]]},
        total: {$set: (state.total+=1)},
    }))
    .handleAction(cancelDomainInfo, (state, action) => {
        if (state.list) {
            const domainInfoId = action.payload;
            const newState = state.list.filter( domainInfo => domainInfo.id !== domainInfoId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    })
    .handleAction(resetDomainsInfoState, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0},
        credits: {$set: undefined},
    }));
