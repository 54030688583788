import * as React from "react";
import {Card, Col, ProgressBar, Row, Table} from "react-bootstrap";
import {DeepStats} from "../../types/detection/statsAreaState";
import {Doughnut} from "react-chartjs-2";
import {ChartData} from "chart.js";
import {KCard, KLabel, KSpace} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {ReactChild} from "react";
import {useParams} from "react-router-dom";


export type SearchStatsDeepProps = {
    stats?: DeepStats;
    validationPercent?: number;
    onOpenDomain: (productType: string, searchId: string, domainId: string) => void;
};

export const SearchStatsDeep: React.FC<SearchStatsDeepProps> = ({stats, validationPercent, onOpenDomain}) => {
    // @ts-ignore
    const {productType, searchId} = useParams();

    const channelsData: ChartData = {
        labels: ["cyberlocker", "torrent"],
        datasets: [{data: [stats?.channelsDistribution.linker, stats?.channelsDistribution.torrent], backgroundColor: ["#6E7682", "#363D49"]}]
    };
    const validationData: ChartData = {
        labels: [I18n.t("stats.validationPercentCompleted"), I18n.t("stats.validationPercentNotCompleted")],
        datasets: [{data: [validationPercent, (100-(validationPercent || 0))], backgroundColor: ["#2BD18F", "#DFE7F5"]}]
    };

    function renderImportance(val: number): ReactChild {
        val = val > 0 ? val : 1;
        return <div style={{position: "absolute", top: "50%", transform: "translateY(-50%)"}}><ProgressBar now={val} max={5} className={`kProgressImportance${val}`} /></div>
    }

    return (
        <>
            <Row>
                <Col md={6}>
                    <KCard>
                        <KSpace/>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.deep.chartChannels"}/>}/></Col></Row>
                        <Row><Doughnut height={100} data={channelsData} legend={{align: "center", position: "right"}} options={{cutoutPercentage: 70, layout: {padding: 30}, responsive: true}}/></Row>
                    </KCard>
                </Col>
                <Col md={6}>
                    <KCard>
                        <KSpace/>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.chartValidation"}/>}/></Col></Row>
                        <Row><Doughnut height={100} data={validationData} legend={{align: "center", position: "right"}} options={{cutoutPercentage: 70, layout: {padding: 30}, responsive: true}}/></Row>
                    </KCard>
                </Col>
            </Row>
            <KSpace/>
            <Row>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.deep.topTenCyberlockersImportance"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                                <tr>
                                    <th><Translate value={"stats.deep.domainLabel"}/></th>
                                    <th className="text-start"><Translate value={"stats.deep.importanceLabel"}/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {stats?.cyberlockersRankByImportance.map( d => { return (
                                    <tr key={d.id}>
                                        <td onClick={() => onOpenDomain(productType, searchId, d.id)}>{d.name}</td>
                                        <td className="text-end" style={{position: "relative"}}>{renderImportance(d.importance)}</td>
                                    </tr>
                                )})}
                                <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.deep.topTenCyberlockersInfringements"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.deep.domainLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.deep.infringementsLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.cyberlockersRankByInfringements.map( d => { return (
                                <tr key={d.id}>
                                    <td onClick={() => onOpenDomain(productType, searchId, d.id)}>{d.name}</td>
                                    <td className="text-end">{d.potentialInfringements}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.deep.topTenTorrentsImportance"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.deep.domainLabel"}/></th>
                                <th className="text-start"><Translate value={"stats.deep.importanceLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.torrentsRankByImportance.map( d => { return (
                                <tr key={d.id}>
                                    <td onClick={() => onOpenDomain(productType, searchId, d.id)}>{d.name}</td>
                                    <td className="text-end" style={{position: "relative"}}>{renderImportance(d.importance)}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.deep.topTenTorrentsInfringements"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.deep.domainLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.deep.infringementsLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.torrentsRankByInfringements.map( d => { return (
                                <tr key={d.id}>
                                    <td onClick={() => onOpenDomain(productType, searchId, d.id)}>{d.name}</td>
                                    <td className="text-end">{d.potentialInfringements}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
