import * as React from "react";
import { Translate } from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";


export interface StateProps {
}

export interface DispatchProps {
    onOpenOverview: (productType: string, searchId: string) => void;
    onOpenDomains: (productType: string, searchId: string) => void;
}

export interface InnerProps {
    type: "overview" | "domains" | "results";
    searchId?: string;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const OSDBreadcrumbs: React.FC<Props> = (
    {
        type, searchId,
        onOpenOverview, onOpenDomains
    }) => {
    // @ts-ignore
    const { productType } = useParams();
    const separator = <div style={{ color: "#8C9AB1", paddingLeft: "3em", paddingRight: "3em", display: "inline-block" }}>{"/"}</div>;

    function createBreadcrumb(textId: string, active: boolean, onClickFunction: any): JSX.Element {
        const color = active ? "#5198F4" : "#8C9AB1";
        const cursor = active ? "text" : "pointer";
        return <div style={{ color: color, cursor: cursor, display: "inline-block" }} onClick={onClickFunction}>
            <div style={{ color: color }}><Translate value={textId} /></div>
        </div>
    }

    const overviewBreadcrumbActive = createBreadcrumb("breadcrumbs.overview", true, () => { });
    const overviewBreadcrumbNotActive = createBreadcrumb("breadcrumbs.overview", false, () => onOpenOverview(productType, searchId || ""));
    const domainsBreadcrumbActive = createBreadcrumb("breadcrumbs.domains", true, () => { });
    const domainsBreadcrumbNotActive = createBreadcrumb("breadcrumbs.domains", false, () => onOpenDomains(productType, searchId || ""));
    const resultsBreadcrumbActive = createBreadcrumb("breadcrumbs.results", true, () => { });

    const overviewPath = <>
        {overviewBreadcrumbActive}
        {separator}
        {domainsBreadcrumbNotActive}
    </>

    const domainsPath = <>
        {overviewBreadcrumbNotActive}
        {separator}
        {domainsBreadcrumbActive}
    </>

    const resultsPath = <>
        {overviewBreadcrumbNotActive}
        {separator}
        {domainsBreadcrumbNotActive}
        {separator}
        {resultsBreadcrumbActive}
    </>

    const breadcrumbs = type === "overview" ? overviewPath : type === "domains" ? domainsPath : type === "results" ? resultsPath : <></>;

    return (
        <div style={{ textAlign: "center", paddingBottom: "3em", fontSize: "14px" }}>{breadcrumbs}</div>
    );
};
