import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {DomainSearch, DomainSearchesState} from "../../types/analysis/domainSearchesState";
import {
    addedDomainSearchTag,
    cancelDomainSearch, deletedDomainSearchTag,
    doDomainSearch,
    gotDomainSearches, gotDomainSearchesTags,
    resetDomainsSearchesState
} from "../../actions/analysis/domainsSearches";


const initialState: DomainSearchesState = {
    total: 0
};

export const domainsSearchesReducer = createReducer(initialState)
    .handleAction(gotDomainSearches, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(doDomainSearch, (state, action) => update(state, {
        list: {$set: [action.payload, ...state.list as DomainSearch[]]},
        total: {$set: (state.total+=1)},
    }))
    .handleAction(cancelDomainSearch, (state, action) => {
        if (state.list) {
            const domainSearchId = action.payload;
            const newState = state.list.filter( domainSearch => domainSearch.id !== domainSearchId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    })
    .handleAction(resetDomainsSearchesState, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0},
        credits: {$set: undefined},
    }))
    .handleAction(addedDomainSearchTag, (state, action) => {
        const index = (state.list || []).findIndex(s => s.id === action.payload.domainSearchId);
        if (index > -1) {
            return update(state, {
                list: {
                    [index]: {
                        tags: {$push: [action.payload.tag]}
                    }
                }
            })
        } else {
            return state;
        }
    })
    .handleAction(deletedDomainSearchTag, (state, action) => {
        const index = (state.list || []).findIndex(s => s.id === action.payload.domainSearchId);
        if (index > -1) {
            const tagIndex = ((state.list || [])[index].tags || []).indexOf(action.payload.tag);
            return update(state, {
                list: {
                    [index]: {
                        tags: {$splice: [[tagIndex, 1]]}
                    }
                }
            })
        } else {
            return state;
        }
    })
    .handleAction(gotDomainSearchesTags, (state, action) => update(state, {
        domainSearchesTags: {$set: action.payload}
    }));
