import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, Toolbar} from "../components/Toolbar";
import {RootState, ThunkDispatch} from "../types";
import {saveLocale} from "@kopjra/uikit";
import {setLocale} from "react-redux-i18n";
import {routerTools, Routes} from "../utils/router";

function mapStateToProps({i18n}: RootState): StateProps {
    return {
        locale: i18n.locale,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onChangeLocale: (locale) => {saveLocale(locale); dispatch(setLocale(locale))},
        onGoHome: () => {routerTools.push(Routes.HOME)},
        onGoDashboard: (productType: string) => {
            routerTools.push(Routes.DASHBOARD(productType))
        },
        onGoAccount: (productType: string) => {routerTools.push(Routes.USER_ACCOUNT)},
        onGoSearches: (productType: string) => {routerTools.push(Routes.SEARCHES(productType))},
        onGoDomainInfo: (productType: string) => {routerTools.push(Routes.DOMAINS_INFO(productType))},
        onGoDomainSearch: (productType: string) => {routerTools.push(Routes.DOMAINS_SEARCHES(productType))},
        onGoTDProcesses: (productType: string) => {routerTools.push(Routes.WELLS(productType))}
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Toolbar);
