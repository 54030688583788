import * as React from "react";
import {Card, Col, Row, Table} from "react-bootstrap";
import {UGCStats} from "../../types/detection/statsAreaState";
import {Doughnut} from "react-chartjs-2";
import {ChartData} from "chart.js";
import {KCard, KLabel, KSpace} from "@kopjra/uikit";
import {I18n, Localize, Translate} from "react-redux-i18n";
import {numberToDottedNumber, secondsToHms} from "../../utils/commons";
import {useParams} from "react-router-dom";


export type SearchStatsUGCProps = {
    stats?: UGCStats;
    validationPercent?: number;
    onOpenResult: (productType: string, searchId: string, domainId: string, resultId: string) => void;
};

export const SearchStatsUGC: React.FC<SearchStatsUGCProps> = ({stats, validationPercent, onOpenResult}) => {
    // @ts-ignore
    const {productType, searchId} = useParams();

    const colorMap = new Map([["youtube", "#363D49"], ["dailymotion", "#6E7682"], ["vimeo", "#B8C1D2"]]);
    const sourcesLabels: string[] = [];
    const sourcesValues: number[] = [];
    const sourcesColours: string[] = [];
    stats?.domainsRankByInfringements.forEach( d => {
        const ugcName = d.name.split(".")[0];
        sourcesLabels.push(ugcName);
        sourcesValues.push(d.potentialInfringements);
        sourcesColours.push(colorMap.get(ugcName) || "#FFFFFF");
    });
    const sourcesData: ChartData = {
        labels: sourcesLabels,
        datasets: [{data: sourcesValues, backgroundColor: sourcesColours}]
    };
    const validationData: ChartData = {
        labels: [I18n.t("stats.validationPercentCompleted"), I18n.t("stats.validationPercentNotCompleted")],
        datasets: [{data: [validationPercent, (100-(validationPercent || 0))], backgroundColor: ["#2BD18F", "#DFE7F5"]}]
    };

    return (
        <>
            <Row>
                <Col md={6}>
                    <KCard>
                        <KSpace/>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.ugc.chartUGC"}/>}/></Col></Row>
                        <Row><Doughnut height={100} data={sourcesData} legend={{align: "center", position: "right"}} options={{cutoutPercentage: 70, layout: {padding: 30}, responsive: true}}/></Row>
                    </KCard>
                </Col>
                <Col md={6}>
                    <KCard>
                        <KSpace/>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.chartValidation"}/>}/></Col></Row>
                        <Row><Doughnut height={100} data={validationData} legend={{align: "center", position: "right"}} options={{cutoutPercentage: 70, layout: {padding: 30}, responsive: true}}/></Row>
                    </KCard>
                </Col>
            </Row>
            <KSpace/>
            <Row>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.ugc.topTenViews"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.ugc.titleLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.ugc.viewsLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.resultsRankByViews.map( r => { return (
                                <tr key={r.id}>
                                    <td onClick={() => onOpenResult(productType, searchId, r.domainId, r.id)}>{r.title}</td>
                                    <td className="text-end">{numberToDottedNumber(r.views || 0)}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.ugc.topTenDuration"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.ugc.titleLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.ugc.durationLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.resultsRankByDuration.map( r => { return (
                                <tr key={r.id}>
                                    <td onClick={() => onOpenResult(productType, searchId, r.domainId, r.id)}>{r.title}</td>
                                    <td className="text-end">{secondsToHms(r.duration || 0)}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
            <KSpace/>
            <Row>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.ugc.topTenRecent"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.ugc.titleLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.ugc.dateLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.resultsRankByDate.map( r => { return (
                                <tr key={r.id}>
                                    <td onClick={() => onOpenResult(productType, searchId, r.domainId, r.id)}>{r.title}</td>
                                    <td className="text-end"><Localize value={r.publishedAt || ""} dateFormat={"kptable.date.short"}/></td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className={"statsCard"}>
                        <Row><Col className="text-center"><KLabel text={<Translate value={"stats.ugc.topTenChannels"}/>}/></Col></Row>
                        <Table responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th><Translate value={"stats.ugc.channelLabel"}/></th>
                                <th className="text-end"><Translate value={"stats.ugc.uploadedLabel"}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {stats?.channelsRankByUploaded.map( ch => { return (
                                <tr key={ch.channel}>
                                    <td style={{cursor: "auto"}}><a target="_blank" rel="noopener noreferrer" href={`${ch.channelURL}`}>{ch.channel}</a></td>
                                    <td className="text-end">{numberToDottedNumber(ch.uploadedVideos || 0)}</td>
                                </tr>
                            )})}
                            <tr><td/><td/></tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
