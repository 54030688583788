import * as React from "react";
import {ReactChild, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FreeTextFilter,
    GetParams,
    KCard,
    KLabel,
    KSpace,
    KSpinner,
    KTableLoader,
    SortingDirection,
    Table,
    tooltipate,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {OSDSpinner} from "../OSDSpinner";
import {tableGetParams} from "../../utils/commons";
import {DomainInfo, DomainInfoState} from "../../types/analysis/domainsInfoState";


export interface StateProps {
    domainsInfo?: DomainInfo[];
    total: number;
}

export interface DispatchProps {
    onGetDomainsInfo: (query: GetParams) => Promise<void>;
    onOpenDomainInfo: (domainInfoId: string) => void;
    onDeleteDomainInfo: (domainInfoId: string) => void;
}

export interface InnerProps {
    reduced?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainsInfo: React.FC<Props> = ({
                                                 domainsInfo,
                                                 total,
                                                 onGetDomainsInfo,
                                                 onOpenDomainInfo,
                                                 onDeleteDomainInfo,
                                                 reduced = true
                                             }) => {

    const queryParams: GetParams = {
        top: 5,
        skip: 0,
    };
    if (reduced) {
        queryParams.sort = "created";
        queryParams.direction = SortingDirection.DOWN;
    }

    const domainsInfoRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetDomainsInfo(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get domains info error", e));
    };

    useEffect(() => {
        domainsInfoRetriever(reduced ? queryParams : tableGetParams("domainsInfo/list/full"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderDomainInfoState(datum: object): ReactChild {
        const domainInfo = datum as DomainInfo;
        if (domainInfo.state === DomainInfoState.REQUESTED) {
            return tooltipate(<KSpinner/>, <Translate value="domainInfo.table.stateTooltip"/>);
        } else if (domainInfo.state === DomainInfoState.FOUND) {
            return <div className="fal fa-check-circle validationCheck"/>
        } else {
            const cross = <div className="fal fa-times-circle validationCross"/>;
            return tooltipate(cross, <Translate value={"domainInfo.dashboard.errorTooltip"}/>)
        }
    }

    const rowAction = async (datum: DomainInfo) => {
        if (datum.state === DomainInfoState.FOUND) {
            onOpenDomainInfo(datum.id);
        }
    };

    const actions: ActionType<DomainInfo>[] = [
        {
            name: <><i className="fal fa-folder-open action-icon"/><Translate value="searches.table.actions.view"/></>,
            handler: rowAction,
            shouldRender: (datum: DomainInfo) => (datum.state === DomainInfoState.FOUND)
        },
        {
            name: <><i className="fal fa-trash"/><Translate value="searches.table.actions.delete"/></>,
            handler: async (datum: DomainInfo) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("domainInfo.table.actions.deleteMessage")
                };
                const deleteDomainInfo = await confirmBox(confirmBoxConf);
                if (deleteDomainInfo) {
                    await onDeleteDomainInfo(datum.id);
                }
            },
            shouldRender: (datum: DomainInfo) => datum.state !== DomainInfoState.REQUESTED,
            tooltip: <Translate value="domainInfo.table.actions.deleteTip"/>,
        }
    ];

    return (
        <KCard header={<Translate value={`searches.title${reduced ? "LastFive" : "Complete"}`}/>}>
            {domainsInfo ? (
                <>
                    {reduced && domainsInfo.length > 0 ? (
                        <>
                            <Row>
                                <Col className="text-center" md={12}>
                                    <span className="bigNumberGray">{total}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center" md={12}>
                                    <KLabel text={<Translate value="searches.totalSearches"/>}/>
                                </Col>
                            </Row>
                            <KSpace/>
                        </>
                    ) : (
                        <></>
                    )}
                    {!reduced || (reduced && domainsInfo.length > 0) ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <Table
                                    checkboxes={false}
                                    filterDefinition={[
                                        new FreeTextFilter("domainName", I18n.t("domainInfo.table.name")),
                                    ]}
                                    globalActions={[]}
                                    globalWaiter={<OSDSpinner size={200}/>}
                                    waiter={<OSDSpinner size={100} variant="dark"/>}
                                    id={`domainsInfo/list/${reduced ? "reduced" : "full"}`}
                                    total_count={total}
                                    loaderFunc={(q: GetParams) => domainsInfoRetriever(q, reduced ? queryParams : undefined)}
                                    loadInterval={10000}
                                    data={domainsInfo}
                                    hideColumnSelector={true}
                                    hideFilters={reduced === true}
                                    keyField={"id"}
                                    hidePager={reduced === true}
                                    rowAction={rowAction}
                                >
                                    <Column colid="domainName" classes="text-start" name="domainInfo.table.name"
                                            type={DataType.GENERIC} sort={!reduced ? "domainName" : undefined}
                                            colspan={4}/>
                                    <Column colid="created" classes="text-end" name="domainInfo.table.date"
                                            type={DataType.DATETIME} sort={!reduced ? "created" : undefined}
                                            colspan={2}/>
                                    <Column colid="state" classes="text-center" name="domainInfo.table.state"
                                            type={DataType.GENERIC} sort={!reduced ? "state" : undefined} colspan={2}
                                            render={renderDomainInfoState}/>
                                    <Column colid="actions" classes="text-start" name="" type={DataType.ACTIONS}
                                            actions={actions} colspan={2}/>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <KLabel text={<Translate value="domainInfo.table.noSearches" />} />
                    )}
                </>
            ) : (
                <KTableLoader/>
            )}
        </KCard>
    );
};
