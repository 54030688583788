import { CreditsState } from "./creditsState";

export enum SearchState {
    "INITIALIZING" = "INITIALIZING",
    "STARTED" = "STARTED",
    "STOPPED" = "STOPPED",
    "ERROR" = "ERROR",
    "REFRESHING" = "REFRESHING",
    "COMPLETED" = "COMPLETED",
    "COMPLETED_FORCED" = "COMPLETED_FORCED",
}

export interface NewSearch {
    query: string;
    language?: string;
    webType: string;
    categories?: string[];
    domains?: string[];
    languages?: string[];
    customUrls?: string[];
    name?: string;
    maxVisitedUrls?: number;
    browserAutomation?: boolean;
    torProxy?: boolean;
    credential?: Credential;
}

export interface Credential {
    loginUrl: string;
    username: string;
    password: string;
}

export interface Search {
    id: string;
    query: string;
    language?: string;
    languages?: string[];
    excludeFilters?: string[];
    userId: string;
    created: Date;
    refreshed?: Date;
    refreshIntervalDays?: number;
    state: SearchState;
    domainNumber: number;
    resultNumberValidated: number;
    lastResultUpdate?: Date;
    resultNumber?: number;
    completionPercent?: number;
    categories?: Array<string>;
    tags?: Array<string>;
    webType: string;
    // CrawlSearch fields
    name?: string;
    visitedUrls?: number;
    maxVisitedUrls?: number;
    browserAutomation?: boolean;
    torProxy?: boolean;
    credential?: Credential;
}

export interface SearchesState {
    list?: Search[];
    searchesTags?: string[];
    total: number;
    credits?: CreditsState;
    totalPotentialInfringements: number;
}
