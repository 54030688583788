import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {WellsState} from "../../types/takedown/wellsState";
import {
    addedWellTag,
    cancelWell, deletedWellTag, gotWells,
    gotWellsGlobalStats, gotWellsTags
} from "../../actions/takedown/wells";


const initialState: WellsState = {
    total: 0,
    wellsGlobalStats: {
        totalRemovedUrls: 0,
        totalUrls: 0
    }
};

export const wellsReducer = createReducer(initialState)
    .handleAction(gotWellsGlobalStats, (state, action) => update(state, {
        wellsGlobalStats: {$set: action.payload}
    }))
    .handleAction(gotWells, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(cancelWell, (state, action) => {
        if (state.list) {
            const wellId = action.payload;
            const newState = state.list.filter( well => well.id !== wellId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    })
    .handleAction(gotWellsTags, (state, action) => update(state, {
        wellsTags: {$set: action.payload}
    }))
    .handleAction(addedWellTag, (state, action) => {
        const index = (state.list || []).findIndex(w => w.id === action.payload.wellId);
        if (index > -1) {
            return update(state, {
                list: {
                    [index]: {
                        tags: {$push: [action.payload.tag]}
                    }
                }
            })
        } else {
            return state;
        }
    })
    .handleAction(deletedWellTag, (state, action) => {
        const index = (state.list || []).findIndex(s => s.id === action.payload.wellId);
        if (index > -1) {
            const tagIndex = ((state.list || [])[index].tags || []).indexOf(action.payload.tag);
            return update(state, {
                list: {
                    [index]: {
                        tags: {$splice: [[tagIndex, 1]]}
                    }
                }
            })
        } else {
            return state;
        }
    });
