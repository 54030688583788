import {connect} from "react-redux";
import {Analysis, DispatchProps, StateProps, InnerProps} from "../../components/analysis/Analysis";
import {RootState, ThunkDispatch} from "../../types";
import {routerTools, Routes} from "../../utils/router";
import {ProductType} from "../../types/extra";

function mapStateToProps(): StateProps{
    return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        openDomainInfo: () => {
            routerTools.push(Routes.DASHBOARD(ProductType.DOMAIN_INFO));
        },
        openDomainSearches: () => {
            routerTools.push(Routes.DASHBOARD(ProductType.DOMAIN_SEARCHES));
        },
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Analysis);
