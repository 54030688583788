import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {DomainInfoDetails} from "../../types/analysis/DomainInfoDetails";
import {gotCurrentDomainInfoDetails, resetDomainInfoDetails} from "../../actions/analysis/domainInfoDetails";

const initialState: DomainInfoDetails = {
};

export const domainInfoDetailsReducer = createReducer(initialState)
    .handleAction(gotCurrentDomainInfoDetails, (state, action) => update(state, {
        currentDomainInfoDetails: {$set: action.payload}
    }))
    .handleAction(resetDomainInfoDetails, (state) => update(state, {
        currentDomainInfoDetails: {$set: undefined}
    }));
