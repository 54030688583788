import * as React from "react";
import {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    DataType, Filter, FreeTextFilter,
    GetParams, GlobalActionType, KSpinner, KSpinnerSize,
    KTableLoader,
    Table
} from "@kopjra/uikit";
import {OSDSpinner} from "../OSDSpinner";
import {useParams} from "react-router-dom";
import {DomainAnalysis} from "../../types/analysis/domainSearchResultsState";
import {tableGetParams} from "../../utils/commons";
import {DomainAnalysisValidationState} from "../../types/detection/resultsAreaState";
import {DomainSearchType} from "../../types/extra";
import {I18n} from "react-redux-i18n";
import {DomainAnalysisGetParams} from "../../api/analysis/domainSearchResults";

export interface StateProps {
    domainSearchResults?: DomainAnalysis[];
    total: number;
    forceUpdate: boolean;
}

export interface DispatchProps {
    onGetDomainSearchResults: (domainSearchId: string, domainSearchType: string, query: DomainAnalysisGetParams) => Promise<void>;
    onValidateDomainsAnalysis: (domainSearchId: string, domains: DomainAnalysis[], updateAll?: string, queryParams?: GetParams) => Promise<void>;
}

export interface InnerProps {
    domainSearchType: string;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainSearchResults: React.FC<Props> = ({domainSearchResults, total, domainSearchType, onGetDomainSearchResults, onValidateDomainsAnalysis, forceUpdate}) => {
    // @ts-ignore
    const {domainSearchId} = useParams();
    const queryParams = tableGetParams("domainSearchResults/list");

    const domainSearchResultsRetriever = async (domainSearchId: string, query: GetParams) => {
        await onGetDomainSearchResults(domainSearchId, domainSearchType, query);
    };

    useEffect(() => {
        onGetDomainSearchResults(domainSearchId as string, domainSearchType, queryParams).catch((e) => console.warn("Get analysis domains results error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderValidationCheck(datum: DomainAnalysis): JSX.Element {
        let validationCheck;
        switch(datum.validation) {
            case DomainAnalysisValidationState.ACCEPTED:
                validationCheck = <div className="far fa-check validationCheck action-icon"/>;
                break;
            case DomainAnalysisValidationState.REJECTED:
                validationCheck = <div className="far fa-check validationNotSelected action-icon"/>;
                break;
            case DomainAnalysisValidationState.NOT_VALIDATED:
                validationCheck = <div className="far fa-check validationCheckAuto action-icon"/>;
                break;
            default: validationCheck = <div className="far fa-check validationNotSelected action-icon"/>;
                break;
        }
        return validationCheck;
    }

    function renderValidationCross(datum: DomainAnalysis): JSX.Element {
        let validationCross;
        switch(datum.validation) {
            case DomainAnalysisValidationState.ACCEPTED:
                validationCross = <div className="far fa-times validationNotSelected action-icon"/>;
                break;
            case DomainAnalysisValidationState.REJECTED:
                validationCross = <div className="far fa-times validationCross action-icon"/>;
                break;
            case DomainAnalysisValidationState.NOT_VALIDATED:
                validationCross = <div className="far fa-times validationCheckAuto action-icon"/>;
                break;
            default: validationCross = <div className="far fa-times validationNotSelected action-icon"/>;
                break;
        }
        return validationCross;
    }

    const filters: Filter[] = [
        new FreeTextFilter("domain", I18n.t("domainAnalysis.table.domain"))
    ];
    if (domainSearchType === DomainSearchType.TYPOSQUAT) {
        filters.push(new FreeTextFilter("typosquat", I18n.t("domainAnalysis.table.typosquat")));
    }

    const actions: ActionType<DomainAnalysis>[] = [
        {
            name: renderValidationCheck,
            handler: async (datum: DomainAnalysis) => {
                if (datum.validation !== DomainAnalysisValidationState.ACCEPTED) {
                    datum.validation = DomainAnalysisValidationState.ACCEPTED;
                    await onValidateDomainsAnalysis(domainSearchId as string, [datum]);

                }
            }
        },
        {
            name: renderValidationCross,
            handler: async (datum: DomainAnalysis) => {
                if (datum.validation !== DomainAnalysisValidationState.REJECTED) {
                    datum.validation = DomainAnalysisValidationState.REJECTED;
                    await onValidateDomainsAnalysis(domainSearchId as string, [datum]);
                }
            }
        }
    ];

    const globalActions: GlobalActionType[] = [
        {
            name: <div className="far fa-times fa-sm validationCross action-icon" />,
            handler: async (data: DomainAnalysis[], config) => {
                let validateAll;
                if (config.globalCheckedAll) { validateAll = DomainAnalysisValidationState.REJECTED; }
                data.forEach( r => r.validation = DomainAnalysisValidationState.REJECTED);
                await onValidateDomainsAnalysis(domainSearchId as string, data, validateAll, config.queryParams);
            },
            bulk: true
        },
        {
            name: <div className="far fa-check fa-sm validationCheck action-icon"/>,
            handler: async (data: DomainAnalysis[], config) => {
                let validateAll;
                if (config.globalCheckedAll) { validateAll = DomainAnalysisValidationState.ACCEPTED; }
                data.forEach( r => r.validation = DomainAnalysisValidationState.ACCEPTED);
                await onValidateDomainsAnalysis(domainSearchId as string, data, validateAll, config.queryParams);
            },
            bulk: true
        }
    ];

    function renderDomain(domain: DomainAnalysis) {
        const url = `https://${domain.domain}`;
        return <a href={url} target="_blank" rel="noopener noreferrer">{domain.domain}</a>;
    }

    function renderIp(domain: DomainAnalysis) {
        const url = `https://${domain.ip}`;
        return <a href={url} target="_blank" rel="noopener noreferrer">{domain.ip}</a>;
    }

    function renderOnline(domain: DomainAnalysis) {
        if (domain.online === undefined) {
            return <KSpinner size={KSpinnerSize.nm} theme={"dark"}/>;
        } else {
            return <i className={`fa-light fa-globe fa-lg ${domain.online ? "validationCheckAuto" : "validationNotSelected"}`}/>;
        }
    }

    const registrationDateColumn = domainSearchType === DomainSearchType.REGISTERED ? (
        <Column colid="registrationDate" classes="text-start" name="domainAnalysis.table.registrationDate"
                type={DataType.DATE} sort={"registrationDate"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const ipColumn = domainSearchType === DomainSearchType.FAVICON ? (
        <Column colid="ip" classes="text-start" name="domainAnalysis.table.ip" type={DataType.GENERIC} render={renderIp} sort={"ip"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC} sort={"ip"}/>;

    const typosquatColumn = domainSearchType === DomainSearchType.TYPOSQUAT ? (
        <Column colid="typosquat" classes="text-start" name="domainAnalysis.table.typosquat" type={DataType.GENERIC} sort={"typosquat"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC} sort={"typosquat"}/>;

    const adIdColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="adId" classes="text-start" name="domainAnalysis.table.adId"
                type={DataType.GENERIC} sort={"adId"}
                render={i => <a href={i.snapshotUrl} target="_blank"
                                rel="noopener noreferrer">{i.id}</a>}
        />
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const pageNameColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="pageName" classes="text-start" name="domainAnalysis.table.page" type={DataType.GENERIC} sort={"pageName"}
                render={i => <a href={i.adSearchPageUrl} target="_blank"
                                rel="noopener noreferrer">{i.pageName}</a>}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const titlesColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="titles" classes="text-start" name="domainAnalysis.table.title" type={DataType.GENERIC} sort={"titles"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const languagesColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="languages" classes="text-start" name="domainAnalysis.table.languages" type={DataType.GENERIC}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const creationTimeColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="creationTime" classes="text-start" name="domainAnalysis.table.creation" type={DataType.DATE} sort={"creationTime"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const deliveryStartTimeColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="deliveryStartTime" classes="text-start" name="domainAnalysis.table.deliveryStart" type={DataType.DATE} sort={"deliveryStartTime"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const deliveryEndTimeColumn = domainSearchType === DomainSearchType.ADS ? (
        <Column colid="deliveryEndTime" classes="text-start" name="domainAnalysis.table.deliveryEnd" type={DataType.DATE} sort={"deliveryEndTime"}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const onlineColumn = domainSearchType !== DomainSearchType.ADS ? (
        <Column colid="online" classes="text-start" name="online" type={DataType.GENERIC} actions={actions} render={renderOnline}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    const faviconColumn = domainSearchType !== DomainSearchType.ADS ? (
        <Column colid="favicon" classes="text-center" name="favicon"
                colspan={1} type={DataType.GENERIC}
                render={(d: DomainAnalysis) => <img alt="favicon" src={`https://www.google.com/s2/favicons?domain=${d.domain}`} width="20" height="20"/>}/>
    ) : <Column colid="none" colspan={0} type={DataType.GENERIC}/>;

    return (
        <>
        {domainSearchResults ? (
                <>
                    <Row>
                        <Col md={12} className="text-center">
                            <Table
                                filterDefinition={filters}
                                globalActions={globalActions}
                                checkboxes={true}
                                globalWaiter={<OSDSpinner size={200}/>}
                                waiter={<OSDSpinner size={100} variant="dark"/>}
                                id={`domainSearchResults/list`}
                                total_count={total}
                                loaderFunc={(q: GetParams) => domainSearchResultsRetriever(domainSearchId as string, q)}
                                data={domainSearchResults}
                                hideColumnSelector={true}
                                hideFilters={false}
                                keyField={"id"}
                                hidePager={false}
                            >
                                <Column colid="domain" classes="text-start" name="domainAnalysis.table.domain" type={DataType.GENERIC} sort={"domain"} render={renderDomain}/>
                                {registrationDateColumn}
                                {ipColumn}
                                {typosquatColumn}
                                {adIdColumn}
                                {pageNameColumn}
                                {titlesColumn}
                                {languagesColumn}
                                {creationTimeColumn}
                                {deliveryStartTimeColumn}
                                {deliveryEndTimeColumn}
                                {onlineColumn}
                                {faviconColumn}
                                <Column colid="createdAt" classes="text-start" name="domainAnalysis.table.added"
                                        type={DataType.DATE} sort={"createdAt"} colspan={2}/>
                                <Column classes="colBound text-end" colid="validation" name="domainAnalysis.table.validation" type={DataType.ACTIONS} actions={actions} colspan={2}/>
                            </Table>
                        </Col>
                    </Row>
                </>
            ) : (
                <KTableLoader/>
            )}
        </>
    );
};
