import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {setAlert} from "@kopjra/uikit";
import {
    addedDomainSearchTag,
    cancelDomainSearch, deletedDomainSearchTag,
    doDomainSearch,
    gotDomainSearches, gotDomainSearchesTags
} from "../../analysis/domainsSearches";
import {
    deleteDomainSearches, DomainSearchGetParams, downloadCsvReport,
    getDomainSearches,
    newDomainSearch,
    NewDomainSearchResponse, refreshDomainSearch
} from "../../../api/analysis/domainsSearches";
import {tableGetParams, transformToDomainSearchesParams} from "../../../utils/commons";
import {I18n} from "react-redux-i18n";
import {gotCreditsState} from "../../detection/searchCredits";
import {getCredits} from "../../../api/detection/searchCredits";
import {NewDomainSearch} from "../../../types/analysis/domainSearchesState";
import {addDomainSearchTag, deleteDomainSearchTag, getDomainSearchesTags} from "../../../api/analysis/domainsSearches";


export const doGetDomainSearches = (query: DomainSearchGetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotDomainSearches(await getDomainSearches(transformToDomainSearchesParams(query))));
};

export const doNewDomainSearch = (domainSearch: NewDomainSearch): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    dispatch(setAlert(I18n.t("alerts.infos.newDomainInfo"), "info"));
    const savedNewDomainSearch: NewDomainSearchResponse = await newDomainSearch(domainSearch);
    if (savedNewDomainSearch.domainSearch) {
        dispatch(doDomainSearch(savedNewDomainSearch.domainSearch));
        const creditsState = getState().searchCredits;
        if (!creditsState.infinite) {
            dispatch(gotCreditsState(await getCredits()));
        }
    } else {
        const msg = I18n.t("alerts.errors.newDomainInfo") + savedNewDomainSearch.statusCode;
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
};

export const doDeleteDomainSearches = (domainSearchIds: string[], deleteAll: boolean, queryParams?: DomainSearchGetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await deleteDomainSearches(domainSearchIds, deleteAll, transformToDomainSearchesParams(queryParams || {}));
    if (outcome) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteDomainInfo"), "info"));
        for (const id of domainSearchIds) {
            dispatch(cancelDomainSearch(id));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteDomainInfo"), "error"));
    }

}

export const doUpdateDomainSearch = (domainSearchId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await refreshDomainSearch(domainSearchId);
    if (outcome) {
        dispatch(setAlert(I18n.t("alerts.infos.refreshDomainInfo"), "info"));
        dispatch(gotDomainSearches(await getDomainSearches(transformToDomainSearchesParams(tableGetParams("domainSearches/list/full")))));
    } else {
        const msg = I18n.t("alerts.errors.refreshDomainInfo");
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
}

export const doAddDomainSearchTag = (domainSearchId: string, tag: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(addedDomainSearchTag(domainSearchId, tag));
    const ok = await addDomainSearchTag(domainSearchId, tag);
    if (ok) {
        dispatch(gotDomainSearchesTags(await getDomainSearchesTags()));
    }
}

export const doDeleteDomainSearchTag = (domainSearchId: string, tag: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(deletedDomainSearchTag(domainSearchId, tag));
    const ok = await deleteDomainSearchTag(domainSearchId, tag);
    if (ok) {
        dispatch(gotDomainSearchesTags(await getDomainSearchesTags()));
    }
}

export const doGetDomainsSearchesTags = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotDomainSearchesTags(await getDomainSearchesTags()));
}

export const downloadCsv = (domainSearchIds: string[], _reportType: string, _language: string, _all: boolean, _queryParams?: any): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    await downloadCsvReport(domainSearchIds[0]);
}
