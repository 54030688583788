import {Domain} from "../../types/detection/domainsState";
import {apiCall} from "../index";

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetDomainsResponse = GetListResponse<Domain>;

export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetDomainsParams extends GetParams {
    name?: string;
    importance?: number;
    channel?: string;
}

export function transformToDomain(obj: any): Domain {
    const domain: Domain = {
        id: obj.id,
        name: obj.name,
        potentialInfringements: obj.resultsNumber || 0,
        channel: obj.channel || "",
        importance: obj.importance || 0,
        validationStatus: obj.validationStatus || 0,
        nResultsValidated: obj.nResultsValidated
    };
    return domain;
}

export async function getDomains(searchId: string, query: GetDomainsParams): Promise<GetDomainsResponse> {
    const res = await apiCall({urlComponent: `/searches/${searchId}/domains`, query});
    const json = await res.json();
    const domains = await json.results.map((o: any) => transformToDomain(o));

    return {
        results: domains,
        total: json.total,
    };
}

export async function getDomain(searchId: string, domainId: string): Promise<Domain> {
    const res = await apiCall({urlComponent: `/searches/${searchId}/domains/${domainId}`});
    const json = await res.json();
    const domain = transformToDomain(json);
    return domain;
}
