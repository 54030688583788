import {createAction} from "typesafe-actions";
import {GetResultsResponse} from "../../api/detection/resultsArea";
import {Result} from "../../types/detection/resultsAreaState";
import {Domain} from "../../types/detection/domainsState";
import { Search } from "../../types/detection/searchState";

export const gotResults = createAction("results/GOT", (response: GetResultsResponse) => response)();
export const validateResults = createAction("results/VALIDATE", (results: Array<Result>) => results)();
export const gotCurrentDomain = createAction("domain/GOT", (domain: Domain) => domain)();
export const gotCurrentSearch = createAction("search/GOT", (search: Search) => search)();
export const resetResultsState = createAction("results/RESET")();
