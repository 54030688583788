import * as React from "react";
import { Translate } from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";


export interface StateProps {
}

export interface DispatchProps {
    onOpenDomains: (productType: string, wellId: string) => void;
}

export interface InnerProps {
    type: "results" | "emails";
    wellId?: string;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const TDBreadcrumbs: React.FC<Props> = (
    {
        type, wellId,
        onOpenDomains
    }) => {
    // @ts-ignore
    const { productType } = useParams();
    const separator = <div style={{ color: "#8C9AB1", paddingLeft: "3em", paddingRight: "3em", display: "inline-block" }}>{"/"}</div>;

    function createBreadcrumb(textId: string, active: boolean, onClickFunction: any): JSX.Element {
        const color = active ? "#5198F4" : "#8C9AB1";
        const cursor = active ? "text" : "pointer";
        return <div style={{ color: color, cursor: cursor, display: "inline-block" }} onClick={onClickFunction}>
            <div style={{ color: color }}><Translate value={textId} /></div>
        </div>
    }

    const domainsBreadcrumbNotActive = createBreadcrumb("breadcrumbs.domains", false, () => onOpenDomains(productType, wellId || ""));
    const resultsBreadcrumbActive = createBreadcrumb("breadcrumbs.results", true, () => { });
    const emailsBreadcrumbActive = createBreadcrumb("breadcrumbs.emails", true, () => { });

    const resultsPath = <>
        {domainsBreadcrumbNotActive}
        {separator}
        {resultsBreadcrumbActive}
    </>

    const emailsPath = <>
        {domainsBreadcrumbNotActive}
        {separator}
        {emailsBreadcrumbActive}
    </>

    const breadcrumbs = type === "results" ? resultsPath : type === "emails" ? emailsPath : <></>;

    return (
        <div style={{ textAlign: "center", paddingBottom: "3em", fontSize: "14px" }}>{breadcrumbs}</div>
    );
};
