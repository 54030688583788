import {connect} from "react-redux";
import {Home, DispatchProps, StateProps, InnerProps} from "../components/Home";
import {RootState, ThunkDispatch} from "../types";
import {routerTools, Routes} from "../utils/router";
import {doGetPaidCredits} from "../actions/thunks/detection/statsArea";

function mapStateToProps({searchCredits}: RootState): StateProps {
    return {
        credits: searchCredits
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCreditsState: () => dispatch(doGetPaidCredits()),
        openDashboard: (productType: string) => {
            routerTools.push(Routes.DASHBOARD(productType));
        },
        openAnalysis: () => {
            routerTools.push(Routes.ANALYSIS());
        }
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Home);
