import * as React from "react";
import { Col, Row } from "react-bootstrap";
import Overview from "../containers/detection/Overview";
import { KContainer } from "@kopjra/uikit";
import Searches from "../containers/detection/Searches";
import DNAOverview from "../containers/analysis/DNAOverview";
import DomainsInfo from "../containers/analysis/DomainsInfo";
import DomainsSearches from "../containers/analysis/DomainSearches";
import TDOverview from "../containers/takedown/TDOverview";
import Wells from "../containers/takedown/Wells";
import { ProductType } from "../types/extra";
import { useParams } from "react-router-dom";
import DomainSearchesOverview from "../containers/analysis/DomainSearchesOverview";


export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Dashboard: React.FC<Props> = () => {
    // @ts-ignore
    const { productType } = useParams();
    
    const overview = productType === ProductType.DOMAIN_INFO ? <DNAOverview /> :
        productType === ProductType.DOMAIN_SEARCHES ? <DomainSearchesOverview /> :
        productType === ProductType.TAKEDOWN ? <TDOverview /> :
            <Overview />;

    const table = productType === ProductType.DOMAIN_INFO ? <DomainsInfo /> :
        productType === ProductType.DOMAIN_SEARCHES ? <DomainsSearches /> :
        productType === ProductType.TAKEDOWN ? <Wells /> :
            <Searches />;

    return (
        <KContainer>
            <Row>
                <Col md={12}>
                    {overview}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {table}
                </Col>
            </Row>
        </KContainer>
    );
};
