import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { KCard, KSpace, KSpinner } from "@kopjra/uikit";
import { Translate } from "react-redux-i18n";
import { NewSearch } from "../../types/detection/searchState";
import { CreditsState } from "../../types/detection/creditsState";
import { KCredit } from "../KCredit";
import { useEffect } from "react";
import { SearchNew } from "./SearchNew";


export interface StateProps {
    credits: CreditsState;
}

export interface DispatchProps {
    onNewSearch: (newSearch: NewSearch) => Promise<void>;
    onGetCreditsState: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Overview: React.FC<Props> = ({ credits, onGetCreditsState, onNewSearch }) => {
    useEffect(() => {
        onGetCreditsState().catch((e) => console.warn("Get Credits Stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KCard header={<Translate value="credits.title" />}>
            <Row md={12}>
                <Col md={12} className="text-center">
                    {credits.infinite || credits.available > -1 ? (
                        <KCredit remaining={credits.available}
                            total={credits.total}
                            infinite={credits.infinite}
                            labelText={<Translate value="credits.genericSearches" />} />
                    ) : (
                        <KSpinner />
                    )}
                </Col>
            </Row>
            <KSpace spaces={2} />
            <Row md={12}>
                <Col className={"text-start"}><SearchNew onNewSearch={onNewSearch} /></Col>
            </Row>
        </KCard>
    );
};
