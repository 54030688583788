import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {GetParams} from "@kopjra/uikit";
import {
    gotDomainSearchResults, updateDomainOnlineStatus,
    validatedAnalysisDomains
} from "../../analysis/domainSearchResults";
import {transformToDomainSearchResultsParams} from "../../../utils/commons";
import {
    DomainAnalysisGetParams,
    getDomainSearchResults,
    onlineInfo,
    validateDomainsAnalysis
} from "../../../api/analysis/domainSearchResults";
import {DomainAnalysis} from "../../../types/analysis/domainSearchResultsState";
import {DomainSearchType} from "../../../types/extra";

export const doGetDomainSearchResults = (domainSearchId: string, domainSearchType: string, queryParams: DomainAnalysisGetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const domainsResponse = await getDomainSearchResults(domainSearchId, transformToDomainSearchResultsParams(queryParams));
    dispatch(gotDomainSearchResults(domainsResponse));

    if (domainSearchType !== DomainSearchType.ADS) {
        Promise.all(domainsResponse.results.map(async (domain): Promise<void> => {
            const info = await onlineInfo(domain.domain);
            dispatch(updateDomainOnlineStatus(domain.id, info.online));
        })).catch();
    }
};

export const doValidateDomainsAnalysis = (domainSearchId: string, domains: DomainAnalysis[], updateAll?: string, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    let validateStatusCode;
    if (queryParams) {
        validateStatusCode = await validateDomainsAnalysis(domainSearchId, domains, updateAll, transformToDomainSearchResultsParams(queryParams as GetParams));
    } else {
        validateStatusCode = await validateDomainsAnalysis(domainSearchId, domains, updateAll);
    }
    if(validateStatusCode < 400) {
        dispatch(validatedAnalysisDomains(domains));
    }
};
