import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KContainer} from "@kopjra/uikit";
import Wells from "../../containers/takedown/Wells";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const ManageWells: React.FC<Props> = () => {
    return (
        <KContainer>
            <Row>
                <Col md={12}><Wells reduced={false}/></Col>
            </Row>
        </KContainer>
    );
};
