import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {WellDomainResultsAreaState} from "../../types/takedown/wellDomainResultsAreaState";
import {
    gotCurrentWellDomain, gotNResultsWillChange,
    gotWellDomainResults, resetNResultsWillChange,
    resetWellDomainResultsState
} from "../../actions/takedown/wellDomainResultsArea";


const initialState: WellDomainResultsAreaState = {
    total: 0
};

export const wellDomainResultsAreaReducer = createReducer(initialState)
    .handleAction(gotWellDomainResults, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(gotCurrentWellDomain, (state, action) => update(state, {
        currentWellDomain: {$set: action.payload}
    }))
    .handleAction(gotNResultsWillChange, (state, action) => update(state, {
        curNResultsWillChange: {$set: action.payload}
        }))
    .handleAction(resetNResultsWillChange, (state) => update(state, {
        curNResultsWillChange: {$set: undefined},
    }))
    .handleAction(resetWellDomainResultsState, (state) => update(state, {
        currentWellDomain: {$set: undefined},
        list: {$set: undefined},
        total: {$set: 0}
    }));
