import {User} from "../types/main";
import {apiCall} from "./index";

function transformToUser(obj: any): User {
    const user: User = {
        externalId: obj.externalId,
        email: obj.email,
        scope: obj.scope,
        customSenderEmails: obj.customSenderEmails
    }
    return user;
}

export async function getCurrentUser(): Promise<User | undefined> {
    const res = await apiCall({urlComponent: `/users/current`});
    if (res.status === 200) {
        const json = await res.json();
        return transformToUser(json);
    }
    return undefined;
}
