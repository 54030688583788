import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KContainer} from "@kopjra/uikit";
import DomainsInfo from "../../containers/analysis/DomainsInfo";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const ManageDomainsInfo: React.FC<Props> = () => {
    return (
        <KContainer>
            <Row>
                <Col md={12}><DomainsInfo reduced={false}/></Col>
            </Row>
        </KContainer>
    );
};
