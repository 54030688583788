import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {DispatchProps, StateProps, InnerProps, WellDomainsArea} from "../../components/takedown/WellDomainsArea";
import {routerTools, Routes} from "../../utils/router";
import {
    doAddManualUrls, doGetCurrentFormResults,
    doGetCurrentWell,
    doGetWellDomains, doRequestDomainsIndexing,
    doSendNotice, doSetIndexingState
} from "../../actions/thunks/takedown/wellDomainsArea";
import {GetParams, unregister} from "@kopjra/uikit";
import {resetWellDomainResultsState} from "../../actions/takedown/wellDomainResultsArea";
import {resetEmailsState} from "../../actions/takedown/wellMails";
import {WellDomain} from "../../types/takedown/wellDomainsState";


function mapStateToProps({wellDomains, main}: RootState): StateProps {
    return {
        currentUser: main.currentUser,
        currentWell: wellDomains.currentWell,
        wellDomains: wellDomains.list,
        total: wellDomains.total,
        currentFormResults: wellDomains.currentFormResults
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentWell: (wellId: string) => dispatch(doGetCurrentWell(wellId)),
        onGetWellDomains: (wellId: string, queryParams) => dispatch(doGetWellDomains(wellId, queryParams)),
        onOpenWellDomain: (productType: string, wellId: string, domainName: string) => {
            dispatch(unregister("wellResults/list"));
            dispatch(resetWellDomainResultsState());
            routerTools.push(Routes.WELL_DOMAIN_RESULTS(productType, wellId, domainName));
        },
        onSendNotice: async (wellId: string, domainName: string) => {
            await dispatch(doSendNotice(wellId, domainName));
        },
        onOpenMails: (productType: string, wellId: string, domainName: string) => {
            dispatch(unregister("wellMails/list"));
            dispatch(resetEmailsState());
            routerTools.push(Routes.WELL_MAILS(productType, wellId, domainName));
        },
        onUpdateWell: (productType: string, wellId: string) => {
            routerTools.push(Routes.WELL_UPDATE(productType, wellId));
        },
        onAddManualUrls: async (wellId: string, urls: string[], autoScreen: boolean, query: GetParams) => {
            await dispatch(doAddManualUrls(wellId, urls, autoScreen));
            dispatch(doGetCurrentWell(wellId));
            return dispatch(doGetWellDomains(wellId, query));
        },
        onGetCurrentFormResults: (wellId: string, domainName: string) => dispatch(doGetCurrentFormResults(wellId, domainName)),
        onSetIndexingState: (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, stateDate?: string, requestId?: string) => dispatch(doSetIndexingState(wellId, urlsArray, searchEngine, indexingState, stateDate, requestId)),
        onRequestDomainsIndexing: (wellId: string, domains: WellDomain[], all: boolean, searchEngine: string, queryParams: GetParams) => dispatch(doRequestDomainsIndexing(wellId, domains, all, searchEngine, queryParams))

    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(WellDomainsArea);
