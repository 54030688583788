import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, TDOverview} from "../../components/takedown/TDOverview";
import {RootState, ThunkDispatch} from "../../types";
import {doGetWellsGlobalStats} from "../../actions/thunks/takedown/wells";
import {routerTools, Routes} from "../../utils/router";
import {resetWellStaticInfoState} from "../../actions/takedown/wellCreation";
import { ProductType } from "../../types/extra";


function mapStateToProps({wells}: RootState): StateProps {
    return {
        wellsGlobalStats: wells.wellsGlobalStats
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetWellsGlobalStats: () => dispatch(doGetWellsGlobalStats()),
        onCreateWell: () => {
            dispatch(resetWellStaticInfoState());
            routerTools.push(Routes.WELL_CREATE(ProductType.TAKEDOWN));
        }
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(TDOverview);
