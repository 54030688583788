import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { KButton, KCard, KContainer, KSpace } from "@kopjra/uikit";
import { Search } from "../../types/detection/searchState";
import { ClearStats, DeepStats, SearchStatistics, UGCStats } from "../../types/detection/statsAreaState";
import { SearchStatsDeep } from "./SearchStatsDeep";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { SearchDetailsDeep } from "./SearchDetailsDeep";
import { Translate } from "react-redux-i18n";
import { SearchType } from "../../types/extra";
import { SearchDetailsUGC } from "./SearchDetailsUGC";
import { SearchStatsUGC } from "./SearchStatsUGC";
import { SearchStatsClear } from "./SearchStatsClear";
import OSDBreadcrumbs from "../../containers/detection/OSDBreadcrumbs";
import { OSDSpinner } from "../OSDSpinner";
import { SearchDetailsClear } from "./SearchDetailsClear";

export interface StateProps {
    currentSearch?: Search;
    stats?: SearchStatistics;
}

export interface DispatchProps {
    onGetCurrentSearch: (searchId: string) => Promise<void>;
    onGetStats: (searchId: string) => Promise<void>;
    onOpenDomains: (productType: string, searchId: string) => void;
    onOpenDomain: (productType: string, searchId: string, domainId: string) => void;
    onOpenResult: (productType: string, searchId: string, domainId: string, resultId: string) => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const StatsArea: React.FC<Props> = (
    {
        currentSearch, stats, onGetCurrentSearch,
        onGetStats, onOpenDomains, onOpenDomain, onOpenResult
    }
) => {
    // @ts-ignore
    const { productType, searchId } = useParams();

    useEffect(() => {
        onGetCurrentSearch(searchId as string).catch((e) => console.warn("Get current search error", e));
        onGetStats(searchId as string).catch((e) => console.warn("Get search stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationPercent = Math.trunc(((currentSearch?.resultNumberValidated || 0) / (currentSearch?.resultNumber || 0)) * 100);

    const searchDetailsDeep = <SearchDetailsDeep currentSearch={currentSearch} type={"stats"} />;
    const searchDetailsUGC = <SearchDetailsUGC currentSearch={currentSearch} type={"stats"} />
    const searchDetailsClear = <SearchDetailsClear currentSearch={currentSearch} type={"stats"} />
    const searchStatsDeep = <SearchStatsDeep stats={stats as DeepStats} validationPercent={validationPercent} onOpenDomain={onOpenDomain} />;
    const searchStatsUGC = <SearchStatsUGC stats={stats as UGCStats} validationPercent={validationPercent} onOpenResult={onOpenResult} />;
    const searchStatsClear = <SearchStatsClear stats={stats as ClearStats} validationPercent={validationPercent} onOpenDomain={onOpenDomain} />;

    return (
        <KContainer>
            <OSDBreadcrumbs type={"overview"} searchId={searchId} />
            <Row>
                <Col md={12}>
                    <KCard header={<Translate value={"stats.searchOverview"} />}>
                        {currentSearch ? (
                            <>
                                {
                                    currentSearch.webType === SearchType.DEEP ? searchDetailsDeep :
                                        currentSearch.webType === SearchType.UGC ? searchDetailsUGC :
                                            currentSearch.webType === SearchType.CLEAR ? searchDetailsClear :
                                                currentSearch.webType === SearchType.CUSTOM ? searchDetailsClear : <></>
                                }
                                <KSpace spaces={2} />
                                <Row>
                                    <Col className={"text-center"}><KButton text={<><i className="fal fa-angle-double-right" />&nbsp;<Translate value={`domains.searchDomains`} /></>} onClick={() => onOpenDomains(productType, searchId)} /></Col>
                                </Row></>
                        ) : (
                            <OSDSpinner size={100} variant={"light"} />
                        )}
                    </KCard>
                </Col>
            </Row>
            <KSpace />
            <Row>
                <Col md={12}>
                    {currentSearch ? (
                        <>
                            {
                                currentSearch.webType === SearchType.DEEP ? searchStatsDeep :
                                    currentSearch.webType === SearchType.UGC ? searchStatsUGC :
                                        currentSearch.webType === SearchType.CLEAR ? searchStatsClear :
                                            currentSearch.webType === SearchType.CUSTOM ? searchStatsClear : <></>
                            }
                        </>
                    ) : (
                        <OSDSpinner size={100} variant={"light"} />
                    )}
                </Col>
            </Row>
        </KContainer>
    );
};
