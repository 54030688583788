import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Localize, Translate } from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { KLabel, KSpace } from "@kopjra/uikit";
import { SearchType } from "../../types/extra";
import { Search } from "../../types/detection/searchState";
import { KCredit } from "../KCredit";
import { OSDSpinner } from "../OSDSpinner";

export type SearchDetailsProps = {
    currentSearch?: Search;
};

export const SearchDetailsCrawl: React.FC<SearchDetailsProps> = ({ currentSearch }) => {

    function renderBrowserAutomation(datum: Search) {
        return datum.browserAutomation ? <Translate value={"searches.browserAutomationYes"}/> :
            <Translate value={"searches.browserAutomationNo"}/>;
    }

    function renderCredential(datum: Search){
        return datum.credential ? <Translate value={"generic.yes"}/> :
            <Translate value={"generic.no"}/>;
    }

    function renderTorProxy(datum: Search) {
        return datum.torProxy ? <Translate value={"generic.yes"}/> :
            <Translate value={"generic.no"}/>;
    }

    const searchDetailsCrawl = <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{currentSearch && `"${currentSearch.query}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={4}>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.webType}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.authenticated" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? renderCredential(currentSearch) : ""}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.browserAutomation" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? renderBrowserAutomation(currentSearch) : ""}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.torProxy" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? renderTorProxy(currentSearch) : ""}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.visitedUrls" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.visitedUrls}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.maxVisitedUrls" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.maxVisitedUrls}</span></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <KCredit remaining={currentSearch ? currentSearch.resultNumberValidated : 0}
                    total={currentSearch ? currentSearch.resultNumber : 0}
                    infinite={false}
                    labelText={<Translate value="domains.validated" />} />
            </Col>
            <Col className="text-center" md={4}>
            </Col>
        </Row>
    </>

    return (
        <>
            {
                currentSearch ? (
                    currentSearch.webType === SearchType.CRAWL ? searchDetailsCrawl : <></>
                ) : (
                    <OSDSpinner size={100} variant={"light"} />
                )
            }
        </>
    );
};
