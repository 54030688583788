import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {ResultsAreaState, Result} from "../../types/detection/resultsAreaState";
import {
    gotCurrentDomain,
    gotResults,
    validateResults,
    resetResultsState,
    gotCurrentSearch
} from "../../actions/detection/resultsArea";


const initialState: ResultsAreaState = {
    total: 0,
    forceUpdate: false,
};

export const resultsAreaReducer = createReducer(initialState)
    .handleAction(gotResults, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(validateResults, (state, action) => {
        const results: Array<Result> = action.payload;
        const validationValue = results[0].validation;
        const stateIds: any = {};
        if (state.list) {
            for (const result of results) {
                const id = state.list.findIndex( r => r.id === result.id);
                if (id !== -1) {
                    stateIds[id] = {validation: {$set: validationValue}};
                }
            }
            return update(state, {
                list: stateIds,
                forceUpdate: {$set: !state.forceUpdate}
            });
        } else {
            return state;
        }
    })
    .handleAction(gotCurrentDomain, (state, action) => update(state, {
        currentDomain: {$set: action.payload}
    }))
    .handleAction(gotCurrentSearch, (state, action) => update(state, {
        currentSearch: {$set: action.payload}
    }))
    .handleAction(resetResultsState, (state) => update(state, {
        currentDomain: {$set: undefined},
        list: {$set: undefined},
        total: {$set: 0},
        forceUpdate: {$set: false}
    }));
