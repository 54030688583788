import * as React from "react";
import {Col, ProgressBar, Row} from "react-bootstrap";
import {
    ActionType, alertBox,
    Column,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT, KFileUpload,
    KForm, KLabel, KModal,
    KModalSize,
    KModalVariant,
    KSpace,
    KTableLoader,
    Table,
    tooltipate,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {OSDSpinner} from "../OSDSpinner";
import {Domain, DomainValidationState} from "../../types/detection/domainsState";
import {useParams} from "react-router-dom";
import {ReactChild, useState} from "react";
import {Notebook, SearchType} from "../../types/extra";
import {ManualUrls} from "../takedown/ManualUrls";
import {parse} from "csv-parse/browser/esm/sync";


export interface DomainsProps {
    domains?: Domain[];
    total: number;
    searchType: string;
    onGetDomains: (searchId: string, query: GetParams) => Promise<void>;
    onOpenDomain: (productType: string, searchId: string, domainId: string) => void;
    onAddManualUrls: (searchId: string, urls: string[]) => Promise<void>;
    onUploadSearchNotebook: (searchId: string, notebook: Notebook) => Promise<void>;
}

export const Domains: React.FC<DomainsProps> = ({domains, total, searchType, onGetDomains, onOpenDomain, onAddManualUrls, onUploadSearchNotebook}) => {
    // @ts-ignore
    const {productType, searchId} = useParams();
    const [openedAdd, setOpenedAdd] = useState(false);
    const [openedNotebook, setOpenedNotebook] = React.useState(false);


    const domainsRetriever = async (searchId: string, query: GetParams) => {
        await onGetDomains(searchId, query);
    };

    function renderChannel(datum: object): ReactChild {
        const domain = datum as Domain;
        if (domain.channel === "torrent") {
            return <div>torrent</div>
        } else if (domain.channel === "linker") {
            return <div>cyberlocker</div>
        } else {
            return <div></div>
        }
    }

    function renderValidationStatus(datum: object): ReactChild {
        const domain = datum as Domain;
        const colorMap = new Map([
            [`${DomainValidationState.COMPLETED}`, "#2BD18F"],
            [`${DomainValidationState.IN_VALIDATION}`, "#FFD754"],
            [`${DomainValidationState.NOT_STARTED}`, "#DFE7F5"],
        ]);
        const tipMap = new Map([
            [`${DomainValidationState.COMPLETED}`, "domains.table.validationTipCompleted"],
            [`${DomainValidationState.IN_VALIDATION}`, "domains.table.validationTipProgress"],
            [`${DomainValidationState.NOT_STARTED}`, "domains.table.validationTipNotStarted"],
        ]);
        const statusIndicator = <i className="fa fa-circle" style={{color: colorMap.get(domain.validationStatus)}}/>;
        return tooltipate(statusIndicator, <Translate value={tipMap.get(domain.validationStatus) || ""}/>);
    }

    function renderImportance(datum: object): ReactChild {
        const domain = datum as Domain;
        const val = domain.importance > 0 ? domain.importance : 1;
        return <ProgressBar now={val} max={5} className={`kProgressImportance${val}`}/>
    }

    const rowAction = async (datum: Domain) => {
        onOpenDomain(productType, searchId, datum.id);
    };

    const importanceMap = [
        {label: "5", value: "5"},
        {label: "4", value: "4"},
        {label: "3", value: "3"},
        {label: "2", value: "2"},
        {label: "1", value: "1"},
    ];

    const channelMap = [
        {label: "Torrent", value: "torrent"},
        {label: "Cyberlocker", value: "linker"}
    ];
    const filters = searchType === SearchType.DEEP ? [
            new FreeTextFilter("name", I18n.t("domains.table.name")),
            new FixedSelectionFilter("importance", I18n.t("domains.table.importance"), importanceMap),
            new FixedSelectionFilter("channel", I18n.t("domains.table.channel"), channelMap)
        ] : [
            new FreeTextFilter("name", I18n.t("domains.table.name"))
        ];

    const importanceColumn = searchType !== SearchType.UGC ? (
        <Column colid="importance" classes="text-start" name="domains.table.importance" type={DataType.GENERIC} sort={"importance"} render={renderImportance} colspan={2}/>
    ) : (
        <Column colid="none" colspan={0} type={DataType.GENERIC}/>

    );

    const channelColumn = searchType === SearchType.DEEP ? (
        <Column colid="channel" classes="text-start" name="domains.table.channel" sort={"channel"} render={renderChannel} type={DataType.GENERIC} colspan={1}/>
    ) : (
        <Column colid="none" colspan={0} type={DataType.GENERIC}/>
    );

    const globalActions: GlobalActionType[] = [
        {
            name: <><i className="fal fa-notebook"/>&nbsp;<Translate value="results.table.notesCSV"/></>,
            handler: async () => {
                setOpenedNotebook(true);
            },
            bulk: false,
        },
        {
            name: <><i className="fal fa-plus"/>&nbsp;<Translate value="wells.domains.table.insertUrls"/></>,
            handler: async () => {
                await setOpenedAdd(true);
            },
            bulk: false,
        }
    ];

    const actions: ActionType<Domain>[] = [
        {
            name: <><i className="fal fa-folder-open action-icon"/>&nbsp;<Translate value="domains.table.actions.view"/></>,
            handler: rowAction,
        }
    ];

    return (
        <>
            {domains ? (
                <Row>
                    <Col md={12} className="text-center">
                        <Table
                            checkboxes={false}
                            filterDefinition={filters}
                            globalActions={globalActions}
                            globalWaiter={<OSDSpinner size={200}/>}
                            waiter={<OSDSpinner size={100} variant="dark"/>}
                            id={`domains/list`}
                            total_count={total}
                            loaderFunc={(q: GetParams) => domainsRetriever(searchId as string, q)}
                            data={domains}
                            hideColumnSelector={true}
                            hideFilters={false}
                            keyField={"id"}
                            hidePager={false}
                            rowAction={rowAction}
                        >
                            <Column colid="name" classes="text-start" name="domains.table.name" type={DataType.GENERIC} sort={"name"} colspan={3}/>
                            {importanceColumn}
                            <Column colid="potentialInfringements" classes="text-end" name="domains.table.resultNumber" type={DataType.GENERIC} sort={"potentialInfringements"} colspan={2}/>
                            {channelColumn}
                            <Column colid="validationStatus" classes="text-center" name="domains.table.validation" type={DataType.GENERIC} sort={"validationStatus"} render={renderValidationStatus} colspan={2}/>
                            <Column colid="actions" classes="text-start" name="" type={DataType.ACTIONS} actions={actions} colspan={2} />
                        </Table>
                    </Col>
                </Row>
            ) : (
                <KTableLoader/>
            )}
            <KModal size={KModalSize.lg} variant={KModalVariant.secondary} show={openedAdd}
                    onHide={() => setOpenedAdd(false)} header={I18n.t("wells.static.manualResults")} footer={<></>}>
                <KForm onSubmit={async (values) => {
                    const urlsArray = values?.urls ? (values.urls as string).trim().split("\n") : [];
                    onAddManualUrls(searchId, urlsArray).catch();
                    setOpenedAdd(false);
                }}>
                    <ManualUrls/>
                    <KSpace spaces={2}/>
                    <Row>
                        <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                             text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                 value={`wells.static.cancel`}/></>}
                                             onClick={() => setOpenedAdd(false)}/>
                        </Col>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                             text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate
                                                 value={`wells.static.confirm`}/></>}/>
                        </Col>
                    </Row>
                </KForm>
            </KModal>
            <KModal size={KModalSize.lg} variant={KModalVariant.secondary} show={openedNotebook}
                    onHide={() => setOpenedNotebook(false)} header={I18n.t("results.table.notes")} footer={<></>}>
                <KLabel text={<Translate value="results.table.notebookLabel"/>}/>
                <KSpace />
                <KFileUpload id={"csv-notes"} accept={"text/csv"} label={<Translate value={"results.table.notesCSV"}/>} showUploadedFilename={true}
                             onChange={async (file) => {
                                 try {
                                     const records: any[] = parse(await (file as File).text(), {
                                         columns: true,
                                         skipEmptyLines: true,
                                         skipRecordsWithError: true,
                                         skipRecordsWithEmptyValues: true,
                                         trim: true
                                     });
                                     const missingFields: boolean = records.some(r => !r.url || !r.notes || Object.keys(r).length > 2);
                                     if (missingFields) {
                                         await alertBox({message: `Missing fields in CSV file or there are additional columns not allowed (allowed only url and notes)`});
                                     } else if (records.length > 0) {
                                         onUploadSearchNotebook(searchId, records).catch(console.error);
                                     }
                                 } catch (err) {
                                     console.error("Error parsing CSV file", err);
                                     await alertBox({message: `Error parsing CSV file`});
                                 } finally {
                                     setOpenedNotebook(false);
                                 }
                             }}
                />
            </KModal>
        </>
    );
};
