import {connect} from "react-redux";
import {App, DispatchProps, StateProps, InnerProps} from "../components/App";
import {RootState, ThunkDispatch} from "../types";
import {doGetCurrentUser} from "../actions/thunks/main";

function mapStateToProps({main}: RootState): StateProps {
    return {
        currentUser: main.currentUser
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentUser: () => dispatch(doGetCurrentUser())
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(App);
