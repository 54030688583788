import {combineReducers, Reducer} from "redux";
import {kAlertReducer, kptableReducer} from "@kopjra/uikit";
import {i18nReducer, I18nState} from "react-redux-i18n";
import {mainReducer} from "./main";
import {searchCreditsReducer} from "./detection/searchCredits";
import {searchesReducer} from "./detection/searches";
import {resultsAreaReducer} from "./detection/resultsArea";
import {domainsAreaReducer} from "./detection/domainsArea";
import {statsAreaReducer} from "./detection/statsArea";
import {resultUGCReducer} from "./detection/resultUGC";
import {domainsInfoReducer} from "./analysis/domainsInfo";
import {domainsSearchesReducer} from "./analysis/domainsSearches";
import {domainInfoDetailsReducer} from "./analysis/domainInfoDetails";
import {domainAnalysisAreaReducer} from "./analysis/domainAnalysisArea";
import {domainSearchResultsReducer} from "./analysis/domainSearchResults";
import {wellsReducer} from "./takedown/wells";
import {wellDomainsAreaReducer} from "./takedown/wellDomainsArea";
import {wellDomainResultsAreaReducer} from "./takedown/wellDomainResultsArea";
import {wellCreationReducer} from "./takedown/wellCreation";
import {wellDomainMailsReducer} from "./takedown/wellMails";


export const rootReducer = combineReducers({
    i18n: i18nReducer as unknown as Reducer<I18nState>,
    alert: kAlertReducer,
    main: mainReducer,
    kptable: kptableReducer,
    searchCredits: searchCreditsReducer,
    searches: searchesReducer,
    statsArea: statsAreaReducer,
    domainsArea: domainsAreaReducer,
    resultsArea: resultsAreaReducer,
    resultUGC: resultUGCReducer,
    domainsInfo: domainsInfoReducer,
    domainsSearches: domainsSearchesReducer,
    domainInfoDetails: domainInfoDetailsReducer,
    domainSearchDetails: domainAnalysisAreaReducer,
    domainSearchResults: domainSearchResultsReducer,
    wells: wellsReducer,
    wellDomains: wellDomainsAreaReducer,
    wellDomainResults: wellDomainResultsAreaReducer,
    wellCreation: wellCreationReducer,
    wellMails: wellDomainMailsReducer
});
