import {connect} from "react-redux";
import {RootState} from "../../types";
import {routerTools, Routes} from "../../utils/router";
import {DispatchProps, InnerProps, OSDBreadcrumbs, StateProps} from "../../components/detection/OSDBreadcrumbs";

function mapStateToProps({main}: RootState): StateProps {
    return {};
}

function mapDispatchToProps(): DispatchProps {
    return {
        onOpenOverview: (productType: string, searchId: string) => {
            routerTools.push(Routes.STATS(productType, searchId));
        },
        onOpenDomains: (productType: string, searchId: string) => {
            routerTools.push(Routes.DOMAINS(productType, searchId));
        }
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(OSDBreadcrumbs);
