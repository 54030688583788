import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, DomainsInfo} from "../../components/analysis/DomainsInfo";
import {RootState, ThunkDispatch} from "../../types";
import {routerTools, Routes} from "../../utils/router";
import {doDeleteDomainInfo, doGetDomainsInfo} from "../../actions/thunks/analysis/domainsInfo";
import {resetDomainInfoDetails} from "../../actions/analysis/domainInfoDetails";


function mapStateToProps({domainsInfo}: RootState): StateProps {
    return {
        domainsInfo: domainsInfo.list,
        total: domainsInfo.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetDomainsInfo: (params) => dispatch(doGetDomainsInfo(params)),
        onOpenDomainInfo: (domainInfoId: string) => {
            dispatch(resetDomainInfoDetails());
            routerTools.push(Routes.DOMAIN_INFO(domainInfoId));
        },
        onDeleteDomainInfo: (domainInfoId: string) => dispatch(doDeleteDomainInfo(domainInfoId))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainsInfo);
