import * as React from "react";
import {ReactChild, useState} from "react";
import { I18n, Translate } from "react-redux-i18n";
import {
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCheck,
    KForm,
    KInput,
    KLabel,
    KModal,
    KModalSize,
    KModalVariant,
    KSpace
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {DomainSearchType} from "../../types/extra";
import {NewDomainSearch} from "../../types/analysis/domainSearchesState";


export interface Props {
    onNewDomainSearch: (newDomainSearch: NewDomainSearch) => Promise<void>;
}

export const DomainSearchNew: React.FC<Props> = ({ onNewDomainSearch }) => {
    const [opened, setOpened] = useState(false);
    const [domainSearchType, setDomainSearchType] = useState<string>(DomainSearchType.REGISTERED);

    const baseInputRegex = "[A-Za-z0-9\\-\\.\\ ]{3,}";
    const domainRegex = "^(([a-z0-9]+(-[a-z0-9]+)*\\.)+[a-z]{2,})$";


    const queryForm = <>
        <Row><Col>
            <KInput label={<Translate value="domainSearches.dashboard.query" />} required={true} id={"query"} placeholder={I18n.t("domainSearches.dashboard.placeholderQuery")} pattern={baseInputRegex} />
        </Col></Row>
    </>;

    const domainForm = <>
        <Row><Col>
            <KInput label={<Translate value="domainSearches.dashboard.domain" />} required={true} id={"query"} placeholder={I18n.t("domainSearches.dashboard.placeholderDomain")} pattern={domainRegex} />
        </Col></Row>
    </>;


    function getSearchForm(): ReactChild {
        switch (domainSearchType) {
            case DomainSearchType.REGISTERED:
            case DomainSearchType.ADS:
                return queryForm;
            case DomainSearchType.FAVICON:
            case DomainSearchType.TYPOSQUAT:
            case DomainSearchType.SUBDOMAINS:
                return domainForm;
            default:
                return <></>;
        }
    }
    return (
        <>
            <Row><Col className={"text-center"}>
                <KButton text={<><i className="fal fa-plus" />&nbsp;<Translate value="credits.newSearch" /></>}
                    onClick={() => {setOpened(true); setDomainSearchType(DomainSearchType.REGISTERED)}}
                />
            </Col></Row>
            <KModal variant={KModalVariant.secondary} size={KModalSize.lg} show={opened} onHide={() => setOpened(false)} header={I18n.t("credits.newSearch")} footer={<></>}>
                <KForm onSubmit={values => {
                            const newDomainSearch: NewDomainSearch = {
                                query: (values.query as string).toLowerCase(),
                                type: domainSearchType
                            };
                            setOpened(false);
                            onNewDomainSearch(newDomainSearch);
                        }}>
                    <Row>
                        <Col>
                            <KLabel text={<Translate value={"domainSearches.table.type"} />} />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <KCheck id={"registeredOption"} name={"domainSearchOption"} label={DomainSearchType.REGISTERED} type={"radio"} inline required defaultChecked={true} onChange={(e: any) => e.currentTarget.checked ? setDomainSearchType(DomainSearchType.REGISTERED) : {}} />
                            <KCheck id={"faviconOption"} name={"domainSearchOption"} label={DomainSearchType.FAVICON} type={"radio"} inline required onChange={(e: any) => e.currentTarget.checked ? setDomainSearchType(DomainSearchType.FAVICON) : {}} />
                            <KCheck id={"typosquatOption"} name={"domainSearchOption"} label={DomainSearchType.TYPOSQUAT} type={"radio"} inline required onChange={(e: any) => e.currentTarget.checked ? setDomainSearchType(DomainSearchType.TYPOSQUAT) : {}} />
                            <KCheck id={"subdomainsOption"} name={"domainSearchOption"} label={DomainSearchType.SUBDOMAINS} type={"radio"} inline required onChange={(e: any) => e.currentTarget.checked ? setDomainSearchType(DomainSearchType.SUBDOMAINS) : {}} />
                            <KCheck id={"adsOption"} name={"domainSearchOption"} label={DomainSearchType.ADS} type={"radio"} inline required onChange={(e: any) => e.currentTarget.checked ? setDomainSearchType(DomainSearchType.ADS) : {}} />
                        </Col>
                    </Row>
                    {getSearchForm()}
                    <KSpace spaces={2} />
                    <Row>
                        <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                            text={<><i className="fal fa-times" />&nbsp;<Translate value={`wells.static.cancel`} /></>}
                            onClick={() => setOpened(false)} />
                        </Col>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                            text={<><i className="fal fa-chevron-double-right" />&nbsp;<Translate value={`wells.static.confirm`} /></>} /></Col>
                    </Row>
                </KForm>
            </KModal>
        </>
    );
};
