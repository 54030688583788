import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, DomainInfoDetails} from "../../components/analysis/DomainInfoDetails";
import {RootState, ThunkDispatch} from "../../types";
import {doGetCurrentDomainInfoDetails} from "../../actions/thunks/analysis/domainInfoDetails";

function mapStateToProps({domainInfoDetails}: RootState): StateProps {
    return {
        currentDomainInfo: domainInfoDetails.currentDomainInfoDetails
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentDomainInfo: (domainInfoId: string) => dispatch(doGetCurrentDomainInfoDetails(domainInfoId))
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainInfoDetails);
