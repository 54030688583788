import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {WellBaseInfo} from "../../types/takedown/wellsState";
import {
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KForm,
    KInput,
    KSelect,
    KSELECT_SIZE,
    KSpace, KStepProgress
} from "@kopjra/uikit";
import {InfringementType} from "../../utils/commons";
import {OSDSpinner} from "../OSDSpinner";
import {User} from "../../types/main";

export type WellInfoProps = {
    currentUser?: User;
    wellId?: string
    baseInfo?: WellBaseInfo;
    onSubmit: (newInfo: WellBaseInfo) => void;
    onBack: () => void;
};

export const WellInfo: React.FC<WellInfoProps> = ({wellId, currentUser, baseInfo, onSubmit, onBack}) => {
    const states = [
        <Translate value={"wells.static.takedownInfo"}/>, <Translate value={"wells.static.rightsOwnerInfo"}/>,
        <Translate value={"wells.static.attachmentsInfo"}/>, <Translate value={"wells.static.templatePreview"}/>
    ];
    if (!wellId) {
        states.push(<Translate value={"wells.static.manualUrls"}/>);
    }
    const mainCardTitle = wellId
        ? <><Translate value={"wells.table.edit"} />&nbsp;<Translate value={"wells.static.takedownInfo"}/></>
        : <Translate value={"wells.static.takedownInfo"}/>;
    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={0} states={states}/></Col></Row>
            <KSpace spaces={4}/>
            <Row><Col lg={7} md={12}>
                <KCard header={mainCardTitle}>
                    {(baseInfo && wellId) || !wellId ? (
                        <KForm onSubmit={values => {
                            const newBaseInfo = {
                                id: wellId || "",
                                name: (values.asset as string) + " - ",
                                asset: values.asset as string,
                                assetUrl: values.assetUrl as string,
                                infringementType: values.infringementType as string,
                                customSenderEmail: values.customSenderEmail as string,
                            };
                            onSubmit(newBaseInfo);
                        }}>
                            <Col>
                                <Row>
                                    <Col><KInput label={<Translate value="wells.table.asset"/>} required={true}
                                                 id={"asset"} defaultValue={baseInfo?.asset}
                                                 placeholder={I18n.t("wells.static.defaultAsset")}/></Col>
                                </Row>
                                <Row>
                                    <Col><KInput label={<Translate value="wells.table.assetUrl"/>} id={"assetUrl"}
                                                 defaultValue={baseInfo?.assetUrl}
                                                 placeholder={I18n.t("wells.static.defaultUrl")}/></Col>
                                </Row>
                                <Row>
                                    <Col><KSelect label={<Translate value="wells.table.infringementType"/>}
                                                  required={true} id={"infringementType"} size={KSELECT_SIZE.lg}
                                                  defaultValue={baseInfo?.infringementType}
                                                  placeholder={<Translate value="wells.static.select"/>}
                                                  options={Object.values(InfringementType).map(t => {
                                                      const l = `${t.replaceAll("_", "").toLowerCase()}Type`;
                                                      return {value: t, label: I18n.t(`wells.static.${l}`)};
                                                  })}/></Col>
                                </Row>
                                {currentUser?.customSenderEmails && currentUser.customSenderEmails.length > 0 ? (
                                    <Row>
                                        <Col><KSelect label={<Translate value="wells.table.customSenderEmail" />}
                                                      options={currentUser.customSenderEmails.map((e: string) => {return { value: e, label: e }})}
                                                      id={"customSenderEmail"} placeholder={I18n.t("wells.static.defaultCustomSenderEmail")} />
                                        </Col>
                                    </Row>
                                ) : (
                                    <></>
                                )}
                            </Col>
                            <KSpace spaces={2}/>
                            <Row>
                                <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                                     text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                         value={`wells.static.cancel`}/></>}
                                                     onClick={() => {onBack()}}/>
                                </Col>
                                <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                                     text={<><i className="fal fa-chevron-double-right"/>&nbsp;
                                                         <Translate value={`wells.static.confirm`}/></>}/></Col>
                            </Row>
                        </KForm>
                    ) : (
                        <OSDSpinner size={200}/>
                    )}
                </KCard>
            </Col></Row>
        </>
    );
}
