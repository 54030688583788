import {apiCall, ApiCallParams, HttpMethod} from "../index";
import {DomainSearch, NewDomainSearch} from "../../types/analysis/domainSearchesState";
import {GetParams} from "@kopjra/uikit";
import {triggerCSVDownload} from "../../utils/commons";


export interface DomainSearchGetParams extends GetParams {
    query?: string;
    tags?: string[];
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetDomainsSearchesResponse = GetListResponse<DomainSearch>;

export type NewDomainSearchResponse = {
    domainSearch?: DomainSearch;
    statusCode: number;
};

function transformToDomainSearch(obj: any): DomainSearch {
    const domainSearch: DomainSearch = {
        id: obj.id,
        createdAt: new Date(obj.createdAt),
        query: obj.query,
        type: obj.type,
        status: obj.status,
        nDomains: obj.nDomains,
        nDomainsValidated: obj.nDomainsValidated,
        validationStatus: obj.validationStatus,
        tags: obj.tags,
        refreshed: obj.refreshed ? new Date(obj.refreshed) : undefined,
    };
    return domainSearch;
}

export async function getDomainSearches(query: DomainSearchGetParams): Promise<GetDomainsSearchesResponse> {
    const res = await apiCall({urlComponent: "/domainSearches", query});
    const json = await res.json();
    const domainsInfo = await json.results.map((o: any) => transformToDomainSearch(o));
    return {
        results: domainsInfo,
        total: json.total,
    };
}

export async function newDomainSearch(newDomainSearch: NewDomainSearch): Promise<NewDomainSearchResponse> {
    const apiCallParams: ApiCallParams = {urlComponent: "/domainSearches", method: HttpMethod.POST, body: newDomainSearch};
    try {
        const res = await apiCall(apiCallParams);
        if (!res.ok) {
            return {domainSearch: undefined, statusCode: res.status};
        }
        const json = await res.json();
        return {domainSearch: transformToDomainSearch(json), statusCode: res.status};
    } catch (err) {
        return {domainSearch: undefined, statusCode: -1};
    }
}

export async function getDomainSearch(id: string): Promise<DomainSearch> {
    const res = await apiCall({urlComponent: `/domainSearches/${id}`});
    const json = await res.json();
    const domainSearch = transformToDomainSearch(json);
    return domainSearch;
}

export async function deleteDomainSearches(domainSearchIds: string[], deleteAll: boolean, queryParams: DomainSearchGetParams): Promise<boolean> {
    const deleteMessage = {ids: domainSearchIds, deleteAll: deleteAll, queryParameters: {query: queryParams?.query, tags: queryParams?.tags}};
    const res = await apiCall({urlComponent: `/domainSearches`, method: HttpMethod.DELETE, body: deleteMessage});
    const outcome = res.status < 400;
    return outcome;
}

export async function refreshDomainSearch(id: string): Promise<boolean> {
    const res = await apiCall({urlComponent: `/domainSearches/${id}/refresh`, method: HttpMethod.POST});
    return res.status < 400;
}

export async function getDomainSearchesTags(): Promise<string[]> {
    const res = await apiCall({urlComponent: `/domainSearches/tags`});
    if (res.status === 200) {
        return await res.json();
    }
    return [];
}

export async function addDomainSearchTag(domainSearchId: string, tag: string): Promise<boolean> {
    const body = {tags: [tag]};
    const res = await apiCall({urlComponent: `/domainSearches/${domainSearchId}/tags`, method: HttpMethod.PATCH, body});
    return res.ok;
}

export async function deleteDomainSearchTag(domainSearchId: string, tag: string): Promise<boolean> {
    const body = {tags: [tag]};
    const res = await apiCall({urlComponent: `/domainSearches/${domainSearchId}/tags`, method: HttpMethod.DELETE, body});
    return res.ok;
}

export async function downloadCsvReport(domainSearchId: string): Promise<boolean> {
    const res = await apiCall({urlComponent: `/domainSearches/${domainSearchId}/csv`, method: HttpMethod.POST});
    triggerCSVDownload(await res.text(), `domainSearch_${domainSearchId}`);
    return res.ok;
}
