import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {StatsAreaState} from "../../types/detection/statsAreaState";
import {gotCurrentSearch, resetStatsState} from "../../actions/detection/statsArea";
import {gotSearchStats} from "../../actions/detection/statsArea";


const initialState: StatsAreaState = {

};

export const statsAreaReducer = createReducer(initialState)
    .handleAction(gotCurrentSearch, (state, action) => update(state, {
        currentSearch: {$set: action.payload}
    }))
    .handleAction(gotSearchStats, (state, action) => update(state, {
        stats: {$set: action.payload}
    }))
    .handleAction(resetStatsState, (state) => update(state, {
        currentSearch: {$set: undefined},
        stats: {$set: undefined}
    }));
