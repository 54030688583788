import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {GetParams, KCard, KContainer, KSpace} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {tableGetParams} from "../../utils/commons";
import {WellDomainResult} from "../../types/takedown/wellDomainResultsAreaState";
import {GetWellDomainResultsParams} from "../../api/takedown/wellDomainResultsArea";
import {WellFlatResults} from "./WellFlatResults";
import {WellDetails} from "./WellDetails";
import {Well} from "../../types/takedown/wellsState";
import {Notebook} from "../../types/extra";


export interface StateProps {
    currentWell?: Well;
    wellFlatResults?: WellDomainResult[];
    total: number;
    curNResultsWillChange?: number;
}

export interface DispatchProps {
    onGetCurrentWell: (wellId: string) => Promise<void>;
    onUpdateWell: (productType: string, wellId: string) => void;
    onGetWellFlatResults: (wellId: string, query: GetParams) => Promise<void>;
    onGetNResultsWillChange: (wellId: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => Promise<void>;
    onUpdateTakedownState: (wellId: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => Promise<void>;
    resetNResultsWillChange: () => void;
    onRequestIndexing: (wellId: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: GetParams) => Promise<void>;
    onDeleteWellUrls: (wellId: string, urls: string[], all: boolean, queryParams: GetWellDomainResultsParams) => Promise<void>;
    onCreateScreenshotArchiveLink: (wellId: string, domainName?: string) => Promise<string>;
    onRequestScreenshot: (wellId: string, data: WellDomainResult[], all: boolean, queryParams?: GetParams) => Promise<void>;
    getScreenDownloadUrl: (wellId: string, url: string) => Promise<void>;
    onAddManualUrls: (wellId: string, urls: string[], autoScreen: boolean, query: GetParams) => Promise<void>;
    onSetIndexingState: (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, query: GetParams, stateDate?: string, requestId?: string) => Promise<void>;
    onUpdateNotes: (wellId: string, url: string, notes?: string) => Promise<void>;
    onUploadWellNotebook: (wellId: string, notebook: Notebook) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const WellFlatResultsArea: React.FC<Props> = (
    {
        currentWell,
        wellFlatResults,
        total,
        curNResultsWillChange,
        onGetCurrentWell,
        onUpdateWell,
        onGetWellFlatResults,
        onGetNResultsWillChange,
        resetNResultsWillChange,
        onUpdateTakedownState,
        onRequestIndexing,
        onDeleteWellUrls,
        onCreateScreenshotArchiveLink,
        onRequestScreenshot,
        getScreenDownloadUrl,
        onAddManualUrls,
        onSetIndexingState,
        onUpdateNotes,
        onUploadWellNotebook
    }
) => {
    // @ts-ignore
    const {wellId} = useParams();
    const queryParams = tableGetParams("wellResults/list");

    useEffect(() => {
        onGetCurrentWell(wellId as string).catch((e) => console.warn("Get current well error", e));
        onGetWellFlatResults(wellId as string, queryParams).catch((e) => console.warn("Get well flat results error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer className="bigger">
            <KCard header={<Translate value={"wells.results.flatTitle"}/>}>
                <Row>
                    <Col md={12}>
                        <WellDetails currentWell={currentWell} type={"domains"} onUpdateWell={onUpdateWell}/>
                    </Col>
                </Row>
                <KSpace/>
                <Row>
                    <Col md={12}>
                        <WellFlatResults wellFlatResults={wellFlatResults}
                                         total={total} curNResultsWillChange={curNResultsWillChange}
                                         onGetWellFlatResults={onGetWellFlatResults}
                                         onGetNResultsWillChange={onGetNResultsWillChange}
                                         resetNResultsWillChange={resetNResultsWillChange}
                                         onUpdateTakedownState={onUpdateTakedownState}
                                         onRequestIndexing={onRequestIndexing}
                                         onDeleteWellUrls={onDeleteWellUrls}
                                         onCreateScreenshotArchiveLink={onCreateScreenshotArchiveLink}
                                         onRequestScreenshot={onRequestScreenshot}
                                         getScreenDownloadUrl={getScreenDownloadUrl}
                                         onAddManualUrls={onAddManualUrls}
                                         onSetIndexingState={onSetIndexingState}
                                         onUpdateNotes={onUpdateNotes}
                                         onUploadWellNotebook={onUploadWellNotebook}
                        />
                    </Col>
                </Row>
            </KCard>
        </KContainer>
    );
};
