import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {WellCreationState} from "../../types/takedown/wellCreationState";
import {
    doUpdateWellInfo,
    gotAvailableSearches,
    gotCompiledNoticeTemplate,
    gotCurrentWellStaticInfo,
    resetWellStaticInfoState
} from "../../actions/takedown/wellCreation";


const initialState: WellCreationState = {
    total: 0
};

export const wellCreationReducer = createReducer(initialState)
    .handleAction(gotCurrentWellStaticInfo, (state, action) => update(state, {
        currentWellStaticInfo: {$set: action.payload}
    }))
    .handleAction(doUpdateWellInfo, (state, action) => update(state, {
        currentWellStaticInfo: {$set: action.payload}
    }))
    .handleAction(gotCompiledNoticeTemplate, (state, action) => update(state, {
        noticeTemplateCompiled: {$set: action.payload}
    }))
    .handleAction(resetWellStaticInfoState, (state) => update(state, {
        currentWellStaticInfo: {$set: undefined},
        noticeTemplateCompiled: {$set: undefined},
    }))
    .handleAction(gotAvailableSearches, (state, action) => update(state, {
        availableSearches: {$set: action.payload.results},
        total: {$set: action.payload.total}
    }));
