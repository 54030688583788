import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, ResultUGC} from "../../components/detection/ResultUGC";
import {RootState, ThunkDispatch} from "../../types";
import {doGetCurrentResult} from "../../actions/thunks/detection/resultUGC";

function mapStateToProps({resultUGC}: RootState): StateProps {
    return {
        currentResult: resultUGC.currentResult
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentResult: (searchId: string, domainId: string, resultId: string) => dispatch(doGetCurrentResult(searchId, domainId, resultId))
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(ResultUGC);
