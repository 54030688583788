import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {
    addedSearchTag,
    cancelSearch, deletedSearchTag,
    doSearch,
    gotSearches,
    gotSearchesTags,
    gotTotalPotentialInfringements,
    updateState
} from "../../detection/searches";
import {
    getSearches,
    newSearch,
    getTotalPotentialInfringements,
    deleteSearches,
    getSearchesTags, deleteSearchTag, addSearchTag, newCustomSearch, updateSearch, stopOrResumeCrawlSearch
} from "../../../api/detection/searches";
import {transformToSearchParams} from "../../../utils/commons";
import {NewSearch, SearchState} from "../../../types/detection/searchState";
import {gotCreditsState} from "../../detection/searchCredits";
import {GetParams, setAlert, } from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";
import {getCredits} from "../../../api/detection/searchCredits";
import { triggerReportGeneration } from "../../../api/detection/searches";
import {SearchType} from "../../../types/extra";

export const doGetSearches = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotSearches(await getSearches(transformToSearchParams(query))));
};

export const doNewSearch = (search: NewSearch): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    dispatch(setAlert(I18n.t("alerts.infos.newSearchCreation"), "info"));
    const savedNewSearch = search.webType === SearchType.CUSTOM ? await newCustomSearch(search) : await newSearch(search);
    if (savedNewSearch.search) {
        dispatch(doSearch(savedNewSearch.search));
        const creditsState = getState().searchCredits;
        if (!creditsState.infinite) {
            dispatch(gotCreditsState(await getCredits()));
        }
    } else {
        const msg = I18n.t("alerts.errors.newSearch") + savedNewSearch.statusCode;
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
};

export const doGetTotalPotentialInfringements = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotTotalPotentialInfringements(await getTotalPotentialInfringements()));
};

export const doDeleteSearches = (searchIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await deleteSearches(searchIds, deleteAll, transformToSearchParams(queryParams || {}));
    if (outcome) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteSearches"), "info"));
        for (const id of searchIds) {
            dispatch(cancelSearch(id));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteSearches"), "error"));
    }
};

export const doReportGeneration = (searchIds: string[], reportType: string, language: string, all: boolean, queryParams?: any): ThunkResult<Promise<void>> => async () => {
    await triggerReportGeneration(searchIds, reportType, language, all, transformToSearchParams(queryParams || {}));
};

export const doGetSearchesTags = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotSearchesTags(await getSearchesTags()));
}

export const doAddSearchTag = (searchId: string, tag: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(addedSearchTag(searchId, tag));
    const ok = await addSearchTag(searchId, tag);
    if (ok) {
        dispatch(gotSearchesTags(await getSearchesTags()));
    }
}

export const doDeleteSearchTag = (searchId: string, tag: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(deletedSearchTag(searchId, tag));
    const ok = await deleteSearchTag(searchId, tag);
    if (ok) {
        dispatch(gotSearchesTags(await getSearchesTags()));
    }
}

export const doUpdateSearch = (searchId: string, params: GetParams, refreshIntervalDays?: number): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const done: boolean = await updateSearch(searchId,refreshIntervalDays);
    if (done) {
        const msg: string = I18n.t(`alerts.infos.${refreshIntervalDays !== undefined ? "searchRefreshPlan" : "searchRefreshNow"}`);
        dispatch(setAlert(msg, "info"));
        dispatch(gotSearches(await getSearches(transformToSearchParams(params))));
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.searchRefresh"), "error"));
    }
}

export const doStopOrResumeCrawlSearch = (searchId: string, state: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const newState = state === SearchState.STARTED ? SearchState.STOPPED : SearchState.INITIALIZING;
    dispatch(updateState(searchId, newState));
    const outcome = await stopOrResumeCrawlSearch(searchId, state);
    if (outcome) {
        if (state === SearchState.STARTED) {
            dispatch(setAlert(I18n.t("alerts.infos.stopCrawlSearch"), "info"));
        } else if (state === SearchState.STOPPED) {
            dispatch(setAlert(I18n.t("alerts.infos.restartCrawlSearch"), "info"));
        }
    } else {
        dispatch(updateState(searchId, state));
        if (state === SearchState.STARTED) {
            dispatch(setAlert(I18n.t("alerts.errors.stopCrawlSearch"), "error"));
        } else if (state === SearchState.STOPPED) {
            dispatch(setAlert(I18n.t("alerts.errors.restartCrawlSearch"), "error"));
        }
    }
};
