import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, Overview} from "../../components/detection/Overview";
import {RootState, ThunkDispatch} from "../../types";
import {NewSearch} from "../../types/detection/searchState";
import {doNewSearch} from "../../actions/thunks/detection/searches";
import {doGetPaidCredits} from "../../actions/thunks/detection/statsArea";

function mapStateToProps({searchCredits}: RootState): StateProps {
    return {
        credits: searchCredits
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onNewSearch: (search: NewSearch) => dispatch(doNewSearch(search)),
        onGetCreditsState: () => dispatch(doGetPaidCredits())
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Overview);
