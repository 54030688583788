import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Localize, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {KLabel} from "@kopjra/uikit";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {NoticeType} from "../../utils/commons";

export type WellDomainDetailsProps = {
    currentWellDomain?: WellDomain;
};

export const WellDomainDetails: React.FC<WellDomainDetailsProps> = ({currentWellDomain}) => {

    return (
        <Row>
            <Col>
                <Row>
                    <Col md={3}><KLabel text={<Translate value="wells.domains.table.name"/>}/></Col>
                    <Col><span style={{fontSize: 12}}>{currentWellDomain && currentWellDomain.name}</span></Col>
                </Row>
                <Row>
                    <Col md={3}><KLabel text={<Translate value="wells.domains.table.lastNotice"/>}/></Col>
                    <Col><span style={{fontSize: 12}}>{currentWellDomain && currentWellDomain.lastNotice ? (<Localize value={currentWellDomain.lastNotice} dateFormat="kptable.date.short"/>) : (<span/>)}</span></Col>
                </Row>
                <Row>
                    <Col md={3}><KLabel text={<Translate value="wells.domains.table.nUrls"/>}/></Col>
                    <Col><span style={{fontSize: 12}}>{currentWellDomain && currentWellDomain.nUrls}</span></Col>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="wells.domains.table.nUrlsRemoved"/>}/></Col>
                    <Col><span style={{fontSize: 12}}>{currentWellDomain && currentWellDomain.nUrlsRemoved}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="wells.domains.table.noticeType"/>}/></Col>
                    <Col><span style={{fontSize: 12}}>{
                        currentWellDomain && (currentWellDomain.noticeForm ? NoticeType.FORM : NoticeType.MAIL)
                    }</span></Col>
                </Row>
            </Col>
        </Row>
    );
};
