import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {gotCurrentSearch, gotSearchStats} from "../../detection/statsArea";
import {getSearch} from "../../../api/detection/searches";
import {getSearchStats} from "../../../api/detection/statsArea";
import {getCredits} from "../../../api/detection/searchCredits";
import {gotCreditsState} from "../../detection/searchCredits";


export const doGetCurrentSearch = (searchId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCurrentSearch(await getSearch(searchId)));
};

export const doGetSearchStats = (searchId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotSearchStats(await getSearchStats(searchId)));
};

export const doGetPaidCredits = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCreditsState(await getCredits()));
};


