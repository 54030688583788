import {createAction} from "typesafe-actions";
import {WellsGlobalStats} from "../../types/takedown/wellsState";
import {GetWellsResponse} from "../../api/takedown/wells";


export const gotWellsGlobalStats = createAction("wellsGlobalStats/GOT", (wellsGlobalStats: WellsGlobalStats) => wellsGlobalStats)();
export const gotWells = createAction("wells/GOT", (response: GetWellsResponse) => response)();
export const cancelWell = createAction("wells/DELETE", (wellId: string) => wellId)();
export const gotWellsTags = createAction("wells/GOT_TAGS", (wellsTags: string[]) => wellsTags)();
export const addedWellTag = createAction("wells/ADD_WELL_TAG", (wellId: string, tag: string) => ({wellId, tag}))();
export const deletedWellTag = createAction("wells/DELETE_WELL_TAG", (wellId: string, tag: string) => ({wellId, tag}))();
