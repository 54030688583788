import {NewSearch, Search, SearchState} from "../../types/detection/searchState";
import {apiCall, ApiCallParams, HttpMethod} from "../index";
import {Notebook, SearchType} from "../../types/extra";

export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetAvailableSearchParams extends GetParams {
    query?: string | string[];
}

export interface GetSearchParams extends GetParams {
    query?: string;
    webType?: string;
    tags?: string[];
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetSearchesResponse = GetListResponse<Search>;

export type NewSearchResponse = {
    search?: Search;
    statusCode: number;
};

function transformToSearch(obj: any): Search {
    const search: Search = {
        id: obj.id,
        query: obj.query,
        language: obj.language,
        languages: obj.languages,
        excludeFilters: obj.excludeFilters,
        userId: obj.userId,
        state: obj.state,
        domainNumber: obj.domainNumber,
        resultNumberValidated: obj.resultNumberValidated,
        created: obj.created,
        refreshed: obj.refreshed ? obj.refreshed : undefined,
        refreshIntervalDays: obj.refreshIntervalDays,
        resultNumber: obj.resultNumber,
        categories: obj.categories,
        tags: obj.tags,
        webType: obj.webType,
        // CrawlSearch fields
        name: obj.name,
        visitedUrls: obj.visitedUrls,
        maxVisitedUrls: obj.maxVisitedUrls,
        browserAutomation: obj.browserAutomation,
        torProxy: obj.torProxy,
        credential: obj.credential
    };
    const lastResultUpdate = obj.lastResultUpdate;
    if (lastResultUpdate) {
        search.lastResultUpdate = lastResultUpdate;
    }
    return search;
}

export async function getSearches(query: GetSearchParams): Promise<GetSearchesResponse> {
    const res = await apiCall({urlComponent: "/searches", query});
    const json = await res.json();
    const searches = await json.results.map((o: any) => transformToSearch(o));
    for (const search of searches) {
        let percent = 0;
        if (search.state === SearchState.STARTED) {
            percent = await getCompletionPercentage(search.id);
            search.completionPercent = percent;
        } else {
            search.completionPercent = 100;
        }
    }

    return {
        results: searches,
        total: json.total,
    };
}

export async function newSearch(newSearch: NewSearch): Promise<NewSearchResponse> {
    const body = newSearch.webType === SearchType.DEEP ? {deep: newSearch} :
        newSearch.webType === SearchType.UGC ? {ugc: newSearch} :
            newSearch.webType === SearchType.CLEAR ? {clear: newSearch} :
                newSearch.webType === SearchType.CRAWL ? {crawl: newSearch} : "";
    const apiCallParams: ApiCallParams = {urlComponent: "/searches", method: HttpMethod.POST, body: body};
    try {
        const res = await apiCall(apiCallParams);
        if (!res.ok) {
            return {search: undefined, statusCode: res.status};
        }
        const json = await res.json();
        return {search: transformToSearch(json), statusCode: res.status};
    } catch (err) {
        return {search: undefined, statusCode: -1};
    }
}

export async function updateSearch(id: string, refreshIntervalDays?: number): Promise<boolean> {
    const body: any = {refreshIntervalDays: refreshIntervalDays};
    const res = await apiCall({
        method: HttpMethod.POST, urlComponent: `/searches/${id}/refresh`, body: body
    });
    return res.ok;
}

export async function getSearch(id: string): Promise<Search> {
    const res = await apiCall({urlComponent: `/searches/${id}`});
    const json = await res.json();
    const search = transformToSearch(json);
    return search;
}

export async function deleteSearches(searchIds: string[], deleteAll: boolean, queryParams?: GetSearchParams): Promise<boolean> {
    const deleteMessage = {
        ids: searchIds,
        deleteAll: deleteAll,
        queryParameters: {query: queryParams?.query, webType: queryParams?.webType, tags: queryParams?.tags}
    };
    const res = await apiCall({urlComponent: `/searches`, method: HttpMethod.DELETE, body: deleteMessage});
    const outcome = res.status < 400;
    return outcome;
}

export async function getCompletionPercentage(searchId: string): Promise<number> {
    const res = await apiCall({urlComponent: `/searches/${searchId}/completion`});
    const percent = await res.json();
    return percent;
}

export async function getTotalPotentialInfringements(webType?: string): Promise<number> {
    const query = webType ? {webType: webType} : undefined;
    const res = await apiCall({urlComponent: `/searches:totalPotentialInfringements`, query});
    const tot = await res.json();
    return tot;
}

export async function getAvailableSearches(query: GetAvailableSearchParams): Promise<GetSearchesResponse> {
    const res = await apiCall({urlComponent: "/searches/availableForWell", query});
    const json = await res.json();
    const searches = await json.results.map((o: any) => transformToSearch(o));
    return {
        results: searches,
        total: json.total,
    };
}

export async function newCustomSearch(newSearch: NewSearch): Promise<NewSearchResponse> {
    const body = {query: newSearch.query, urls: newSearch.customUrls};
    const res = await apiCall({urlComponent: "/searches/customSearch", method: HttpMethod.POST, body: body});
    if (res.status < 400) {
        const json = await res.json();
        return {search: transformToSearch(json), statusCode: res.status};
    }
    return {search: undefined, statusCode: -1};
}

export async function doManualSearch(wellId: string, urls: string[], autoScreen: boolean = false): Promise<number> {
    let n = 0;
    const body = {wellId, urls, autoScreen};
    const res = await apiCall({urlComponent: "/searches/manualSearch", method: HttpMethod.POST, body: body});
    if (res.status < 400) {
        const json = await res.json();
        n = json.nInsertUrls;
    }
    return n;
}

export async function addSearchManualUrls(searchId: string, urls: string[]): Promise<number> {
    let n = 0;
    const res = await apiCall({
        urlComponent: `/searches/${searchId}/addResults`,
        method: HttpMethod.POST,
        body: {urls}
    });
    if (res.status < 400) {
        const json = await res.json();
        n = json.nInsertUrls;
    }
    return n;
}

export async function triggerReportGeneration(searchIds: string[], reportType: string, language: string, all: boolean, queryParams?: any): Promise<boolean> {
    const reportParams = {
        ids: searchIds,
        reportType: reportType,
        language: language.toUpperCase(),
        all: all,
        filters: {query: queryParams?.query, webType: queryParams?.webType, tag: queryParams?.tag}
    }
    const res = await apiCall({urlComponent: `/searches/report`, method: HttpMethod.POST, body: reportParams});
    return res.status < 400;
}

export async function getSearchesTags(): Promise<string[]> {
    const res = await apiCall({urlComponent: `/searches/tags`});
    if (res.status === 200) {
        return await res.json();
    }
    return [];
}

export async function addSearchTag(searchId: string, tag: string): Promise<boolean> {
    const body = {tags: [tag]};
    const res = await apiCall({urlComponent: `/searches/${searchId}/tags`, method: HttpMethod.PATCH, body});
    return res.ok;
}

export async function deleteSearchTag(searchId: string, tag: string): Promise<boolean> {
    const body = {tags: [tag]};
    const res = await apiCall({urlComponent: `/searches/${searchId}/tags`, method: HttpMethod.DELETE, body});
    return res.ok;
}

export async function stopOrResumeCrawlSearch(searchId: string, status: string): Promise<boolean> {
    const action = status === SearchState.STARTED ? "stop" :
        status === SearchState.STOPPED ? "resume" : "unknown";
    const stopOrResumeMessage = {action: action};
    const res = await apiCall({urlComponent: `/searches/${searchId}/playPause`, method: HttpMethod.PATCH, body: stopOrResumeMessage});
    return res.status < 400;
}

export async function updateNotes(searchId: string, domainId: string, resultId: string, notes?: string): Promise<boolean> {
    const body = {notes};
    const res = await apiCall({urlComponent: `/searches/${searchId}/domains/${domainId}/results/${resultId}/notes`, method: HttpMethod.PATCH, body});
    return res.ok;
}

export const uploadSearchNotebook = async (searchId: string, notebook: Notebook): Promise<boolean> => {
    const res = await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/searches/${searchId}/results/notebook`,
        body: {notebook}
    });
    return res.status < 400;
}
