import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {DomainAnalysisArea} from "../../types/analysis/DomainAnalysisArea";
import {gotCurrentDomainSearch, resetDomainSearch} from "../../actions/analysis/domainSearchDetails";

const initialState: DomainAnalysisArea = {
};

export const domainAnalysisAreaReducer = createReducer(initialState)
    .handleAction(gotCurrentDomainSearch, (state, action) => update(state, {
        currentDomainSearch: {$set: action.payload}
    }))
    .handleAction(resetDomainSearch, (state) => update(state, {
        currentDomainSearch: {$set: undefined}
    }));
