import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {
    DispatchProps,
    InnerProps,
    StateProps,
    WellFlatResultsArea
} from "../../components/takedown/WellFlatResultsArea";
import {
    doCreateScreenshotArchiveLink,
    doDeleteWellDomainResults,
    doGetNResultsWillChange,
    doGetWellDomainResults, doRequestIndexing, doRequestScreenshots, doRequestScreenshotUrl,
    doUpdateTakedownState, doUpdateWellResultNotes, doUploadWellNotebook
} from "../../actions/thunks/takedown/wellDomainResultsArea";
import {WellDomainResult} from "../../types/takedown/wellDomainResultsAreaState";
import {GetParams} from "@kopjra/uikit";
import {resetNResultsWillChange} from "../../actions/takedown/wellDomainResultsArea";
import {GetWellDomainResultsParams} from "../../api/takedown/wellDomainResultsArea";
import {
    doAddManualUrls,
    doGetCurrentWell,
    doSetIndexingState,
} from "../../actions/thunks/takedown/wellDomainsArea";
import {routerTools, Routes} from "../../utils/router";
import {Notebook} from "../../types/extra";


function mapStateToProps({wellDomains, wellDomainResults}: RootState): StateProps {
    return {
        currentWell: wellDomains.currentWell,
        wellFlatResults: wellDomainResults.list,
        total: wellDomainResults.total,
        curNResultsWillChange: wellDomainResults.curNResultsWillChange
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentWell: (wellId: string) => dispatch(doGetCurrentWell(wellId)),
        onUpdateWell: (productType: string, wellId: string) => {
            routerTools.push(Routes.WELL_UPDATE(productType, wellId));
        },
        onGetWellFlatResults: (wellId: string, query) => dispatch(doGetWellDomainResults(wellId, query)),
        onGetNResultsWillChange: (wellId: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => dispatch(doGetNResultsWillChange(wellId, data, newState, all, query)),
        resetNResultsWillChange: () => dispatch(resetNResultsWillChange()),
        onUpdateTakedownState: async (wellId: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => {
            await dispatch(doUpdateTakedownState(wellId, data, newState, all, query));
            dispatch(doGetCurrentWell(wellId));
            return dispatch(doGetWellDomainResults(wellId, query));
        },
        onRequestIndexing: (wellId: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: GetParams) => dispatch(doRequestIndexing(wellId, data, all, searchEngine, queryParams)),
        onDeleteWellUrls: async (wellId: string, urls: string[], all: boolean, queryParams: GetWellDomainResultsParams) => {
            await dispatch(doDeleteWellDomainResults(wellId, urls, all, queryParams));
            dispatch(doGetCurrentWell(wellId));
            return dispatch(doGetWellDomainResults(wellId, queryParams));
        },
        onCreateScreenshotArchiveLink: (wellId: string, domainName?: string) => dispatch(doCreateScreenshotArchiveLink(wellId, domainName)),
        onRequestScreenshot: (wellId: string, data: WellDomainResult[], all: boolean, queryParams?: GetParams) => dispatch(doRequestScreenshots(wellId, data, all, queryParams)),
        getScreenDownloadUrl: (wellId: string, url: string) => dispatch(doRequestScreenshotUrl(wellId, url)),
        onAddManualUrls: async (wellId: string, urls: string[], autoScreen: boolean, query: GetParams) => {
            await dispatch(doAddManualUrls(wellId, urls, autoScreen));
            dispatch(doGetCurrentWell(wellId));
            return dispatch(doGetWellDomainResults(wellId, query));
        },
        onSetIndexingState: async (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, query: GetParams, stateDate?: string, requestId?: string) => {
            await dispatch(doSetIndexingState(wellId, urlsArray, searchEngine, indexingState, stateDate, requestId));
            dispatch(doGetCurrentWell(wellId));
            return dispatch(doGetWellDomainResults(wellId, query));
        },
        onUpdateNotes: async (wellId: string, url: string, notes?: string) => dispatch(doUpdateWellResultNotes(wellId, url, notes)),
        onUploadWellNotebook: async (wellId: string, notebook: Notebook) => dispatch(doUploadWellNotebook(wellId, notebook)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(WellFlatResultsArea);
