import * as React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { Localize, Translate } from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import { KLabel, KSpace } from "@kopjra/uikit";
import { Domain } from "../../types/detection/domainsState";
import { ReactChild } from "react";
import { SearchType } from "../../types/extra";
import { Search } from "../../types/detection/searchState";
import { KCredit } from "../KCredit";
import { OSDSpinner } from "../OSDSpinner";

export type DomainDetailsProps = {
    currentDomain?: Domain,
    currentSearch?: Search;
};

export const DomainDetails: React.FC<DomainDetailsProps> = ({ currentDomain, currentSearch }) => {

    function renderChannel(domain: string): ReactChild {
        if (domain === "torrent") {
            return <div>torrent</div>
        } else if (domain === "linker") {
            return <div>cyberlocker</div>
        } else {
            return <div></div>
        }
    }

    function renderImportance(val: number): ReactChild {
        val = val > 0 ? val : 1;
        return <div style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}><ProgressBar now={val} max={5} className={`kProgressImportance${val}`} /></div>
    }

    const domainDetailsDeep = <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{currentSearch && `"${currentSearch.query}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={4}>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.updated" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch?.refreshed ? (<Localize value={currentSearch.refreshed} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.webType}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="domains.table.name" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentDomain && currentDomain.name}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="domains.table.importance" />} /></Col>
                    <Col style={{ position: "relative" }}>{renderImportance(currentDomain?.importance || 0)}</Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="domains.table.channel" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{renderChannel(currentDomain?.channel || "")}</span></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <KCredit remaining={currentDomain ? currentDomain.nResultsValidated : 0}
                    total={currentDomain ? currentDomain.potentialInfringements : 0}
                    infinite={false}
                    labelText={<Translate value="domains.validated" />} />
            </Col>
            <Col className="text-center" md={4}>
            </Col>
        </Row>
    </>

    const domainDetailsUGC = <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{currentSearch && `"${currentSearch.query}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={4}>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.updated" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch?.refreshed ? (<Localize value={currentSearch.refreshed} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{"VIDEOS"}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="domains.table.name" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentDomain && currentDomain.name}</span></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <KCredit remaining={currentDomain ? currentDomain.nResultsValidated : 0}
                    total={currentDomain ? currentDomain.potentialInfringements : 0}
                    infinite={false}
                    labelText={<Translate value="domains.validated" />} />
            </Col>
            <Col className="text-center" md={4}>
            </Col>
        </Row>
    </>

    const domainDetailsClear = <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{currentSearch && `"${currentSearch.query}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={4}>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.created" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch ? (<Localize value={currentSearch.created} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.updated" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch?.refreshed ? (<Localize value={currentSearch.refreshed} dateFormat="kptable.date.long" />) : (<span />)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="searches.table.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentSearch && currentSearch.webType}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="domains.table.name" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{currentDomain && currentDomain.name}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="domains.table.importance" />} /></Col>
                    <Col style={{ position: "relative" }}>{renderImportance(currentDomain?.importance || 0)}</Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <KCredit remaining={currentDomain ? currentDomain.nResultsValidated : 0}
                    total={currentDomain ? currentDomain.potentialInfringements : 0}
                    infinite={false}
                    labelText={<Translate value="domains.validated" />} />
            </Col>
            <Col className="text-center" md={4}>
            </Col>
        </Row>
    </>

    return (
        <>
            {
                currentSearch && currentDomain ? (
                    currentSearch.webType === SearchType.DEEP ? domainDetailsDeep :
                        currentSearch.webType === SearchType.UGC ? domainDetailsUGC :
                            currentSearch.webType === SearchType.CLEAR ? domainDetailsClear : <></>
                ) : (
                    <OSDSpinner size={100} variant={"light"} />
                )
            }
        </>
    );
};
