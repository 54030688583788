import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, DomainsSearchesOverview} from "../../components/analysis/DomainsSearchesOverview";
import {RootState, ThunkDispatch} from "../../types";
import {doGetPaidCredits} from "../../actions/thunks/detection/statsArea";
import {doNewDomainSearch} from "../../actions/thunks/analysis/domainsSearches";
import {NewDomainSearch} from "../../types/analysis/domainSearchesState";


function mapStateToProps({searchCredits}: RootState): StateProps {
    return {
        credits: searchCredits
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onDomainSearch: (newDomainSearch: NewDomainSearch) => dispatch(doNewDomainSearch(newDomainSearch)),
        onGetCreditsState: () => dispatch(doGetPaidCredits())
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainsSearchesOverview);
