import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {
    KButton, KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KSpace, KStepProgress
} from "@kopjra/uikit";
import {OSDSpinner} from "../OSDSpinner";

export type TemplatePreviewProps = {
    wellId?: string;
    noticeTemplateCompiled?: string
    onSubmit: () => void;
    onBack: () => void;
};

export const TemplatePreview: React.FC<TemplatePreviewProps> = ({wellId, noticeTemplateCompiled, onSubmit, onBack}) => {
    const states = [
        <Translate value={"wells.static.takedownInfo"}/>, <Translate value={"wells.static.rightsOwnerInfo"}/>,
        <Translate value={"wells.static.attachmentsInfo"}/>, <Translate value={"wells.static.templatePreview"}/>
    ];

    if (!wellId) {
        states.push(<Translate value={"wells.static.manualUrls"}/>);
    }

    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={3} states={states}/></Col></Row>
            <KSpace spaces={4}/>
            {noticeTemplateCompiled ? (
                <>
                    <Row><Col lg={7} md={12}>
                    <KCard header={<Translate value={"wells.static.templatePreview"}/>}>
                        <Col md={12} className="noticePreview" id={"templateArea"}><div dangerouslySetInnerHTML={{__html: noticeTemplateCompiled}}/></Col>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                                 text={<><i className="fal fa-times"/>&nbsp;<Translate value={`wells.static.back`}/></>}
                                                 onClick={() => {onBack()}}/>
                            </Col>
                            <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                                 onClick={async () => onSubmit()}
                                                 text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate value={`wells.static.confirm`}/></>}/></Col>

                        </Row>
                    </KCard>
                    </Col></Row>
                </>
            ):(
                <OSDSpinner size={200}/>
            )}
        </>
    );
}
