import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {DomainsState} from "../../types/detection/domainsState";
import {gotCurrentSearch, gotDomains, resetDomainsState} from "../../actions/detection/domainsArea";


const initialState: DomainsState = {
    total: 0
};

export const domainsAreaReducer = createReducer(initialState)
    .handleAction(gotCurrentSearch, (state, action) => update(state, {
        currentSearch: {$set: action.payload}
    }))
    .handleAction(gotDomains, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(resetDomainsState, (state) => update(state, {
        currentSearch: {$set: undefined},
        list: {$set: undefined},
        total: {$set: 0}
    }));
