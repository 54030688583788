import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KButton, KCard, KLabel, KSpace} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useEffect} from "react";
import {WellsGlobalStats} from "../../types/takedown/wellsState";


export interface StateProps {
    wellsGlobalStats: WellsGlobalStats;
}

export interface DispatchProps {
    onGetWellsGlobalStats: () => Promise<void>;
    onCreateWell: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const TDOverview: React.FC<Props> = ({wellsGlobalStats, onGetWellsGlobalStats, onCreateWell}) => {
    useEffect(() => {
        onGetWellsGlobalStats().catch((e) => console.warn("Get Well Global Stats  Stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const globalPercent = wellsGlobalStats.totalUrls > 0 ? Math.round((wellsGlobalStats.totalRemovedUrls / wellsGlobalStats.totalUrls) * 100) : 0;

    const wellStats = <>
        <Col md={6} className="text-center">
            <span className="bigNumberGray">{wellsGlobalStats.totalUrls}</span>
            <br/>
            <KLabel text={<Translate value={`wells.overview.totalUrls`}/>}/>
        </Col>
        <Col md={6} className="text-center">
            <span className="bigNumberColored">{`${globalPercent}%`}</span>
            <br/>
            <KLabel text={<Translate value={`wells.overview.globalTakedownPercent`}/>}/>
        </Col>
    </>

    return (
        <KCard header={<Translate value="credits.tdTitle" />}>
            <Row>
                {wellStats}
            </Row>
            <KSpace spaces={2}/>
            <Row>
                <Col>
                    <KButton text={<><i className="fal fa-plus"/>&nbsp;<Translate value={`wells.overview.createWell`}/></>} onClick={() => {onCreateWell()}}/>
                </Col>
            </Row>
        </KCard>
    );
};
