import * as React from "react";
import {ReactChild, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType, FixedSelectionFilter,
    FreeTextFilter,
    GetParams, GlobalActionType,
    KCard,
    KLabel, KSelect, KSELECT_SIZE,
    KSpace, KTableLoader,
    SortingDirection,
    Table,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {OSDSpinner} from "../OSDSpinner";
import {tableGetParams} from "../../utils/commons";
import {Well} from "../../types/takedown/wellsState";


export interface StateProps {
    wells?: Well[];
    total: number;
    wellsTags?: string[];
    locale: string;
}

export interface DispatchProps {
    onGetWells: (query: GetParams) => Promise<void>;
    onOpenWell: (wellId: string) => void;
    onOpenFlatResults: (wellId: string) => void;
    onUpdateWell: (wellId: string) => void;
    onDeleteWells: (wellIds: string[], deleteAll: boolean, queryParams?: GetParams) => void;
    onTDReportGeneration: (wellIds: string[], reportType: string, language: string, all: boolean, filters?: any) => Promise<void>;
    onGetWellsTags: () => Promise<void>;
    onAddWellTag: (wellId: string, tag: string) => Promise<void>;
    onDeleteWellTag: (wellId: string, tag: string) => Promise<void>;
    onArchiveWell: (wellId: string, archive: boolean) => Promise<void>;
}

export interface InnerProps {
    reduced?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Wells: React.FC<Props> = ({
                                           wells,
                                           total,
                                           wellsTags,
                                           locale,
                                           onGetWells,
                                           onOpenWell,
                                           onOpenFlatResults,
                                           onDeleteWells,
                                           onTDReportGeneration,
                                           onGetWellsTags,
                                           onAddWellTag,
                                           onDeleteWellTag,
                                           onArchiveWell,
                                           reduced = true
                                       }) => {

    const queryParams: GetParams = {
        top: 5,
        skip: 0,
    };
    if (reduced) {
        queryParams.sort = "created";
        queryParams.direction = SortingDirection.DOWN;
    }

    const wellsRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetWells(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get wells error", e));
    };

    useEffect(() => {
        onGetWellsTags().catch(() => console.warn("Get wells tags error"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        wellsRetriever(reduced ? queryParams : tableGetParams("wells/list/full"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rowAction = async (datum: Well) => {
        onOpenWell(datum.id);
    }

    function renderTags(datum: object): ReactChild {
        const well = datum as Well;
        return <div className={"table-select"} onClick={(e) => e.stopPropagation()}>
            <KSelect id={`${well.id}`} creatable multi searchable
                     options={wellsTags!.map(t => {
                         return {label: t, value: t};
                     })}
                     value={well.tags}
                     onChange={async (_val, updated) => {
                         if (updated && updated.length > 0) {
                             if (updated[0].operation === "added") {
                                 await onAddWellTag(well.id, updated[0].value);
                             } else {
                                 await onDeleteWellTag(well.id, updated[0].value);
                             }
                         }
                     }}
                     size={KSELECT_SIZE.nm}
            /></div>;
    }

    const actions: ActionType<Well>[] = [
        {
            name: <><i className="fa-light fa-folders action-icon"/>&nbsp;<Translate
                value="wells.table.flatResults"/></>,
            handler: async (datum: Well) => {
                await onOpenFlatResults(datum.id)
            },
            collapsable: false
        },
        {
            name: <><i className="fal fa-file-pdf action-icon">&nbsp;</i>PDF</>,
            handler: async (datum: Well) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.generateReportPDF")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    await onTDReportGeneration([datum.id], "pdf", locale, false);
                }
            },
            collapsable: true
        },
        {
            name: <><i className="fal fa-file-csv action-icon">&nbsp;</i>CSV</>,
            handler: async (datum: Well) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.generateReportCSV")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    await onTDReportGeneration([datum.id], "csv", locale, false);
                }
            },
            collapsable: true
        },
        {
            name: <><i className="fa-light fa-folder-arrow-down action-icon"/>&nbsp;<Translate
                value="wells.table.archive"/></>,
            handler: async (datum: Well) => {
                await onArchiveWell(datum.id, true);
            },
            collapsable: true,
            shouldRender: (datum: Well) => !datum.archived
        },
        {
            name: <><i className="fa-light fa-folder-arrow-up action-icon"/>&nbsp;<Translate
                value="wells.table.unarchive"/></>,
            handler: async (datum: Well) => {
                await onArchiveWell(datum.id, false);
            },
            collapsable: true,
            shouldRender: (datum: Well) => !!datum.archived
        },
    ];

    const globalActions: GlobalActionType[] = [
        {
            name: <><i className="fal fa-trash action-icon"/><Translate value="searches.table.actions.delete"/></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("wells.table.deleteMessage")
                };
                const deleteWells = await confirmBox(confirmBoxConf);
                if (deleteWells) {
                    const wellIds = (data as Well[]).map(w => w.id);
                    await onDeleteWells(wellIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true,
        },
        {
            name: <>
                <div className="fa-thin fa-file-zipper action-icon"/>
                CSV</>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.globalReportCSV")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    const wellIds = (data as Well[]).map(w => w.id);
                    await onTDReportGeneration(wellIds, "csv", locale, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <>
                <div className="fa-thin fa-file-zipper action-icon"/>
                PDF</>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("searches.table.actions.globalReportPDF")
                };
                const generate = await confirmBox(confirmBoxConf);
                if (generate) {
                    const wellIds = (data as Well[]).map(w => w.id);
                    await onTDReportGeneration(wellIds, "pdf", locale, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        }
    ];

    return (
        <KCard header={<Translate value={`wells.overview.title${reduced ? "LastFive" : "Complete"}`}/>}>
            {wells && wellsTags ? (
                <>
                    {reduced && wells.length > 0 ? (
                        <>
                            <Row>
                                <Col className="text-center" md={12}>
                                    <span className="bigNumberGray">{total}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center" md={12}>
                                    <KLabel text={<Translate value="wells.overview.totalWells"/>}/>
                                </Col>
                            </Row>
                            <KSpace/>
                        </>
                    ) : (
                        <></>
                    )}
                    {!reduced || (reduced && wells.length > 0) ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <Table
                                    checkboxes={!reduced}
                                    filterDefinition={[
                                        new FreeTextFilter("name", I18n.t("wells.table.name")),
                                        new FixedSelectionFilter("tags", I18n.t("searches.table.filters.tag"), wellsTags.map(wt => {
                                            return {label: wt, value: wt};
                                        }), true),
                                        new FixedSelectionFilter("archived", I18n.t("wells.table.archived"), [
                                            {label: I18n.t("generic.yes"), value: "true"},
                                            {label: I18n.t("generic.no"), value: "false"}
                                        ])
                                    ]}
                                    globalActions={reduced ? [] : globalActions}
                                    globalWaiter={<OSDSpinner size={200}/>}
                                    waiter={<OSDSpinner size={100} variant="dark"/>}
                                    id={`wells/list/${reduced ? "reduced" : "full"}`}
                                    total_count={total}
                                    loaderFunc={(q: GetParams) => wellsRetriever(q, reduced ? queryParams : undefined)}
                                    data={wells}
                                    hideColumnSelector={true}
                                    hideFilters={reduced}
                                    keyField={"id"}
                                    hidePager={reduced}
                                    rowAction={rowAction}
                                >
                                    <Column colid="name" classes="text-start" name="wells.table.name"
                                            type={DataType.GENERIC} sort={!reduced ? "name" : undefined} colspan={3}
                                            render={d => (d as Well).baseInfo.name}/>
                                    <Column colid="infringementType" classes="text-center"
                                            name="wells.table.infringementType" type={DataType.GENERIC}
                                            sort={!reduced ? "infringementType" : undefined} colspan={2}
                                            render={d => (d as Well).baseInfo.infringementType}/>
                                    <Column colid="nUrls" classes="text-center" name="wells.table.nUrls"
                                            type={DataType.GENERIC} sort={!reduced ? "nUrls" : undefined} colspan={2}/>
                                    <Column colid="created" classes="text-center" name="wells.table.created"
                                            type={DataType.DATETIME} sort={!reduced ? "created" : undefined}
                                            colspan={2}/>
                                    <Column colid="tags" classes="text-center" name="searches.table.tags"
                                            render={renderTags} type={DataType.GENERIC} visible={!reduced}/>
                                    <Column colid="actions" classes="text-center" name="" type={DataType.ACTIONS}
                                            actions={actions} collapsed={true} colspan={4}/>
                                </Table>
                            </Col>
                        </Row>
                    ) : (
                        <KLabel text={<Translate value="wells.overview.noSearches"/>}/>
                    )}
                </>
            ) : (
                <KTableLoader/>
            )}
        </KCard>
    );
};
