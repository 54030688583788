import * as React from "react";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {KCard, KContainer, KLabel, KSpace, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {DomainSearch} from "../../types/analysis/domainSearchesState";
import {Col, Row} from "react-bootstrap";
import {Localize, Translate} from "react-redux-i18n";
import {KCredit} from "../KCredit";
import DomainSearchResults from "../../containers/analysis/DomainSearchResults";


export interface StateProps {
    currentDomainSearch?: DomainSearch;
}

export interface DispatchProps {
    onGetCurrentDomainSearch: (domainSearchId: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DomainAnalysisArea: React.FC<Props> = ({currentDomainSearch, onGetCurrentDomainSearch}) => {
    // @ts-ignore
    const {domainSearchId} = useParams();

    useEffect(() => {
        onGetCurrentDomainSearch(domainSearchId as string).catch((e) => console.warn("Get domain search error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer className="bigger">
            <KCard header={<Translate value={"domainAnalysis.card.title"}/>}>
                {currentDomainSearch ? (
                    <>
                        <Row>
                            <Col className="text-center">
                        <span style={{
                            fontSize: 22,
                            fontWeight: "bold"
                        }}>{currentDomainSearch && `"${currentDomainSearch.query}"`}</span>
                            </Col>
                        </Row>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="domainSearches.table.created"/>}/></Col>
                                    <Col><span style={{fontSize: 12}}>{currentDomainSearch ? (
                                        <Localize value={currentDomainSearch.createdAt}
                                                  dateFormat="kptable.date.long"/>) : (<span/>)}</span></Col>
                                </Row>
                                <Row>
                                    <Col md={4}><KLabel text={<Translate value="domainSearches.table.updated"/>}/></Col>
                                    <Col><span style={{fontSize: 12}}>{currentDomainSearch?.refreshed ? (
                                        <Localize value={currentDomainSearch.refreshed}
                                                  dateFormat="kptable.date.long"/>) : (<span/>)}</span></Col>
                                </Row>
                                <Row>
                                    <Col md={3}><KLabel text={<Translate value="domainSearches.table.type"/>}/></Col>
                                    <Col><span
                                        style={{fontSize: 12}}>{currentDomainSearch && currentDomainSearch.type}</span></Col>
                                </Row>
                            </Col>
                            <Col className="text-center" md={4}>
                                <Row>
                                    <Col><span
                                        className="bigNumberGray">{currentDomainSearch && currentDomainSearch.nDomains}</span></Col>
                                </Row>
                                <Row>
                                    <Col><KLabel text={<Translate value="domainAnalysis.card.total"/>}/></Col>
                                </Row>
                            </Col>
                            <Col className="text-center" md={4}>
                                <KCredit remaining={currentDomainSearch ? currentDomainSearch.nDomainsValidated : 0}
                                         total={currentDomainSearch ? currentDomainSearch.nDomains : 0}
                                         infinite={false}
                                         labelText={<Translate value="domainAnalysis.card.validated"/>}/>
                            </Col>
                        </Row>
                        <KSpace spaces={2}/>
                        <DomainSearchResults domainSearchType={currentDomainSearch.type} />
                    </>
                ) : (
                    <KSpinner size={KSpinnerSize.nm}/>
                )}
            </KCard>
        </KContainer>
    );
};
