import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {routerTools, Routes} from "../../utils/router";
import {StateProps, DispatchProps, InnerProps, Wells} from "../../components/takedown/Wells";
import {
    doAddWellTag, doArchiveWell,
    doDeleteWells,
    doDeleteWellTag,
    doGetWells,
    doGetWellsTags,
    doTriggerTDReport
} from "../../actions/thunks/takedown/wells";
import {resetWellStaticInfoState} from "../../actions/takedown/wellCreation";
import {unregister} from "@kopjra/uikit";
import {resetWellsState} from "../../actions/takedown/wellDomainsArea";
import {ProductType} from "../../types/extra";
import {GetParams} from "../../api/detection/searches";


function mapStateToProps({wells, i18n}: RootState): StateProps {
    return {
        wells: wells.list,
        total: wells.total,
        wellsTags: wells.wellsTags,
        locale: i18n.locale
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetWells: (query) => dispatch(doGetWells(query)),
        onOpenWell: (wellId: string) => {
            dispatch(unregister("wellDomains/list"));
            dispatch(resetWellsState());
            routerTools.push(Routes.WELL_DOMAINS(ProductType.TAKEDOWN, wellId));
        },
        onOpenFlatResults: (wellId: string) => {
            dispatch(unregister("wellResults/list"));
            dispatch(resetWellsState());
            routerTools.push(Routes.WELL_FLAT_RESULTS(ProductType.TAKEDOWN, wellId));
        },
        onUpdateWell: (wellId: string) => {
            dispatch(resetWellStaticInfoState());
            routerTools.push(Routes.WELL_UPDATE(ProductType.TAKEDOWN, wellId));
        },
        onDeleteWells: (wellIds: string[], deleteAll: boolean, queryParams?: GetParams) => dispatch(doDeleteWells(wellIds, deleteAll, queryParams)),
        onTDReportGeneration: (wellIds: string[], reportType: string, language: string, all: boolean, filters?: any) => dispatch(doTriggerTDReport(wellIds, reportType, language, all, filters)),
        onAddWellTag: (wellId: string, tag: string) => dispatch(doAddWellTag(wellId, tag)),
        onDeleteWellTag: (wellId: string, tag: string) => dispatch(doDeleteWellTag(wellId, tag)),
        onGetWellsTags: () => dispatch(doGetWellsTags()),
        onArchiveWell: (wellId: string, archived: boolean) => dispatch(doArchiveWell(wellId, archived)),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Wells);
