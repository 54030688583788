import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import {HookedBrowserRouter, routerTools} from "./utils/router";
import {applyMiddleware, createStore} from "redux";
import {rootReducer} from "./reducers";
import thunk, {ThunkMiddleware} from "redux-thunk";
import {RootState} from "./types";
import {RootAction} from "./actions";
import logger from "redux-logger";
import {defaultLocale, translations} from "./locale";
import {Provider as ReduxProvider} from 'react-redux';
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from "./utils/constants";
import {loadTranslations, setLocale, syncTranslationWithStore} from "react-redux-i18n";
import {Auth0Provider} from "@auth0/auth0-react";
import {AppState} from "@auth0/auth0-react/dist/auth0-provider";
import {KAlertProvider} from "@kopjra/uikit";
import App from "./containers/App";

import './index.scss';

// Creating the REDUX Store
export const store = createStore(rootReducer, applyMiddleware(thunk as ThunkMiddleware<RootState, RootAction>, logger as any));

// Configuring Locale Management
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(defaultLocale));

// Customizing Auth0 Callback handling using the custom router
const onRedirectCallback = (appState?: AppState) => {
    // Use the router's history module to replace the url
    routerTools.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
      <ReduxProvider store={store}>
          <Auth0Provider
              onRedirectCallback={onRedirectCallback}
              domain={AUTH0_DOMAIN}
              clientId={AUTH0_CLIENT_ID}
              redirectUri={window.location.origin}
              audience={AUTH0_AUDIENCE}
          >
              <HookedBrowserRouter>
                  <KAlertProvider timeout={4000}>
                      <App/>
                  </KAlertProvider>
              </HookedBrowserRouter>
          </Auth0Provider>
      </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
