import {BrowserRouter, Route, RouteChildrenProps, RouteProps, useLocation} from "react-router-dom";
import React, {createContext, FC, useContext} from "react";
import {History} from "history";
import {JustChildren} from "../types";
import {withAuthenticationRequired} from "@auth0/auth0-react";

const RouterContext = createContext<RouteChildrenProps>({} as RouteChildrenProps);

export let routerTools: History;

export const ProtectedRoute = ({component, ...args}: RouteProps) => (
    <Route component={withAuthenticationRequired(component as React.ComponentType)} {...args} />
);

export const HookedBrowserRouter: FC<JustChildren> = ({children}: JustChildren) => (
    <BrowserRouter>
        <Route>
            {(routeProps: RouteChildrenProps) => {
                routerTools = routeProps.history;
                return <RouterContext.Provider value={routeProps}>
                    {children}
                </RouterContext.Provider>;
            }}
        </Route>
    </BrowserRouter>
);

/**
 * This hook can be used in any component inside the HookedBrowserRouter
 * to retrieve route properties like: location
 */
export function useRouter() {
    return useContext(RouterContext);
}

/**
 * This hook can be used in any component inside the HookedBrowserRouter
 * to get the query parameters' Map
 */
export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export const Routes = {
    ALL: "*",
    HOME: "/",
    USER_ACCOUNT: "/user/account",
    DASHBOARD(productType?: string): string {
        return productType ? `/${productType}/dashboard` : "/:productType/dashboard";
    },
    SEARCHES(productType?: string): string {
        return productType ? `/${productType}/searches` : "/:productType/searches";
    },
    STATS(productType?: string, searchId?: string): string {
        return productType ? `/${productType}/searches/${searchId}/stats` : "/:productType/searches/:searchId/stats";
    },
    DOMAINS(productType?: string, searchId?: string): string {
        return productType ? `/${productType}/searches/${searchId}/domains` : "/:productType/searches/:searchId/domains";
    },
    RESULTS(productType?: string, searchId?: string, domainId?: string): string {
        return productType ? `/${productType}/searches/${searchId}/domains/${domainId}/results` : "/:productType/searches/:searchId/domains/:domainId/results";
    },
    CRAWL_RESULTS(productType?: string, searchId?: string): string {
        return productType ? `/products/${productType}/searches/${searchId}/results` : "/products/:productType/searches/:searchId/results";
    },
    RESULT(productType?: string, searchId?: string, domainId?: string, resultId?: string): string {
        return productType ? `/${productType}/searches/${searchId}/domains/${domainId}/results/${resultId}` : "/:productType/searches/:searchId/domains/:domainId/results/:resultId";
    },
    ANALYSIS(): string {
        return "/analysis";
    },
    DOMAINS_INFO(productType?: string): string {
        return productType ? `/${productType}` : "/domainInfo";
    },
    DOMAIN_INFO(domainInfoId?: string): string {
        return domainInfoId ? `/domainInfo/${domainInfoId}` : `/domainInfo/:domainInfoId`;
    },
    DOMAINS_SEARCHES(productType?: string): string {
        return productType ? `/${productType}` : "/domainSearches";
    },
    DOMAIN_SEARCH(domainSearchId?: string): string {
        return domainSearchId ? `/domainSearches/${domainSearchId}` : `/domainSearches/:domainSearchId`;
    },
    WELLS(productType?: string): string {
        return productType ? "/takedown/wells" : "/:productId/wells";
    },
    WELL_DOMAINS(productType?: string, wellId?: string): string {
        return productType ? `/${productType}/wells/${wellId}/domains` : "/:productType/wells/:wellId/domains";
    },
    WELL_DOMAIN_RESULTS(productType?: string, wellId?: string, domainName?: string): string {
        return productType ? `/${productType}/wells/${wellId}/domains/${domainName}/results` : "/:productType/wells/:wellId/domains/:domainName/results";
    },
    WELL_FLAT_RESULTS(productType?: string, wellId?: string): string {
        return productType ? `/${productType}/wells/${wellId}/results` : "/:productType/wells/:wellId/results";
    },
    WELL_UPDATE(productType?: string, wellId?: string): string {
        return productType ? `/${productType}/wells/${wellId}/update` : "/:productType/wells/:wellId/update";
    },
    WELL_CREATE(productType?: string): string {
        return productType ? `/${productType}/wells/create` : "/:productType/wells/create";
    },
    WELL_MAILS(productType?: string, wellId?: string, domainName?: string): string {
        return productType ? `/${productType}/wells/${wellId}/domains/${domainName}/mails` : "/:productType/wells/:wellId/domains/:domainName/mails";
    },
}
