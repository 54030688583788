import {apiCall, ApiCallParams, HttpMethod} from "../index";
import {DomainInfo, NewDomainInfoSearch} from "../../types/analysis/domainsInfoState";

export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetDomainsInfoParams extends GetParams {
    domainName?: string;
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetDomainsInfoResponse = GetListResponse<DomainInfo>;

export type NewDomainInfoResponse = {
    domainInfo?: DomainInfo;
    statusCode: number;
};

function transformToDomainInfo(obj: any): DomainInfo {
    const domainInfo: DomainInfo = {
        id: obj.id,
        created: new Date(obj.created),
        userId: obj.userId,
        state: obj.state,
        domainName: obj.domainName,
        domainIP: obj.domainIP,
        registrar: obj.registrar,
        registrant: obj.registrant,
        hoster: obj.hoster,
        registry: obj.registry,
        indexing: obj.indexing,
        importance: obj.importance,
        ads: obj.ads
    };
    return domainInfo;
}

export async function getDomainsInfo(query: GetDomainsInfoParams): Promise<GetDomainsInfoResponse> {
    const res = await apiCall({urlComponent: "/domainsInfo", query});
    const json = await res.json();
    const domainsInfo = await json.results.map((o: any) => transformToDomainInfo(o));
    return {
        results: domainsInfo,
        total: json.total,
    };
}

export async function newDomainInfo(newDomainInfo: NewDomainInfoSearch): Promise<NewDomainInfoResponse> {
    const apiCallParams: ApiCallParams = {urlComponent: "/domainsInfo", method: HttpMethod.POST, body: newDomainInfo};
    try {
        const res = await apiCall(apiCallParams);
        if (!res.ok) {
            return {domainInfo: undefined, statusCode: res.status};
        }
        const json = await res.json();
        return {domainInfo: transformToDomainInfo(json), statusCode: res.status};
    } catch (err) {
        return {domainInfo: undefined, statusCode: -1};
    }
}

export async function getDomainInfo(id: string): Promise<DomainInfo> {
    const res = await apiCall({urlComponent: `/domainsInfo/${id}`});
    const json = await res.json();
    const domainInfo = transformToDomainInfo(json);
    return domainInfo;
}

export async function deleteDomainInfo(id: string): Promise<boolean> {
    const res = await apiCall({urlComponent: `/domainsInfo/${id}`, method: HttpMethod.DELETE});
    const outcome = res.status < 400;
    return outcome;
}
