import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KInput} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";

export type ManualUrlsProps = {
    label?: string | JSX.Element;
    required?: boolean;
}

export const ManualUrls: React.FC<ManualUrlsProps> = ({label, required = false}) => {
    return (
        <>
            <Row><Col>
                <KInput as="textarea" id={"urls"} name={"urls"} placeholder={I18n.t("wells.static.manualPlaceholder")} addedTextAreaHeight={200}
                        label={label} required={required} errorMessage={<Translate value={"wells.static.textareaError"}/>} validate={textAreaValue => {
                    const urlsArray = textAreaValue.trim().toLowerCase().split("\n");
                    const pattern = /(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
                    const validUrls = urlsArray.filter(u => pattern.test(u));
                    return urlsArray.length === validUrls.length;
                }}
                />
            </Col></Row>
        </>
    );
}
