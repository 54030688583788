import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, WellMails} from "../../components/takedown/WellMails";
import {RootState, ThunkDispatch} from "../../types";
import {
    doGetCurrentMailContent,
    doGetCurrentMailResponses,
    doGetWellDomainMails, doSetEmailOpened
} from "../../actions/thunks/takedown/wellMails";
import {doGetCurrentWellDomain} from "../../actions/thunks/takedown/wellDomainResultsArea";
import {resetCurrentMailResponses} from "../../actions/takedown/wellMails";


function mapStateToProps({wellMails}: RootState): StateProps {
    return {
        currentWellDomain: wellMails.currentWellDomain,
        wellDomainMails: wellMails.wellDomainMails,
        currentMailContent: wellMails.currentMailContent,
        currentMailResponses: wellMails.currentMailResponses
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentWellDomain: (wellId: string, domainName: string) => dispatch(doGetCurrentWellDomain(wellId, domainName)),
        onGetWellDomainMails: (wellId: string, domainName: string) => dispatch(doGetWellDomainMails(wellId, domainName)),
        onGetCurrentMailContent: (mailId: string) => dispatch(doGetCurrentMailContent(mailId)),
        onGetCurrentMailResponses: (wellId: string, domainName: string, conversationId: string) => {
            dispatch(resetCurrentMailResponses());
            return dispatch(doGetCurrentMailResponses(wellId, domainName, conversationId))
        },
        onSetEmailOpened: (emailId: string) => dispatch(doSetEmailOpened(emailId))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(WellMails);
