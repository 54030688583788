const it = {
    kptable: {
        filters: "Filtri",
        apply: "Applica",
        show_columns: "Mostra colonne",
        pager: {
            first: "Prima",
            last: "Ultima",
        },
        actions: "Azioni",
        date: {
            short: "DD/MM/YYYY",
            long: "DD/MM/YYYY hh:mm a",
        },
        confirm: {
            title: "Conferma",
            yes: "Si",
            no: "No",
        },
        show: "Mostra",
        globalSelectionWarn1: "Tutti i risultati in questa pagina verranno selezionati. ",
        globalSelectionWarn2: "Tutti i risultati sono stati selezionati. ",
        globalSelectionWarnAll: "Seleziona tutti i risultati della ricerca",
        globalSelectionWarnNotAll: "Cancella selezione",
        globalSelectionFilterWarn1: "(Verranno considerati tutti i filtri attivi)",
        globalSelectionFilterWarn2: "(Tutti i filtri attivi sono considerati)",
    },
    locale: {
        en: "EN",
        it: "IT",
    },
    user: {
        profile: "Profilo utente",
        account: "Account",
        logout: "Disconnetti",
        login: "Accedi",
        support: "Supporto",
        name: "Nome",
        email: "Email",
        verified: "Email verificata"
    },
    generic: {
        yes: "Sì",
        no: "No",
        save: "Salva"
    },
    error: {
        page: "Errore",
        forbidden: "Accesso negato",
        notFound: "Pagina non trovata",
        notAuthenticated: "Utente non autenticato",
    },
    alerts: {
        infos: {
            newSearch: "Ricerca avviata",
            newSearchCreation: "Creazione della ricerca...",
            deleteSearches: "Ricerche eliminata",
            stopCrawlSearch: "Ricerca stoppata",
            restartCrawlSearch: "Ricerca riavviata",
            newReport: "Generazione report in corso... Verrà inviato alla tua mail appena disponibile",
            searchConversion: "Conversione a ricerca avanzata in corso... Verrai reindirizzato alla dashboard",
            newDomainInfo: "Ricerca dominio in corso...",
            deleteDomainInfo: "Dominio eliminato",
            updateWell: "Informazioni modificate",
            deleteWell: "Rimozione eliminata",
            notice: "Diffida presa in carico",
            addManualUrls: "Risultati aggiunti",
            wellResultsDeleted: "Url eliminati correttamente",
            searchRefreshPlan: "Aggiornamento ricerca pianificato",
            searchRefreshNow: "Aggiornamento ricerca in corso"
        },
        errors: {
            newSearch: "Errore creazione ricerca",
            noCategories: "Seleziona almeno una categoria",
            deleteSearches: "Errore eliminazione ricerche",
            stopCrawlSearch: "Errore stop ricerca",
            restartCrawlSearch: "Errore riavvio ricerca",
            notOnionUrl: "Errore url non valido con proxy tor",
            newReport: "Errore generazione report",
            searchConversion: "Errore conversione ricerca",
            newDomainInfo: "Errore ricerca dominio",
            deleteDomainInfo: "Errore eliminazione dominio",
            deleteWell: "Errore eliminazione processo",
            notice: "Errore invio diffida",
            newWellCreation: "Aggiungere almeno una ricerca di detection o un url",
            newWellError: "Errore nella creazione della rimozione, riprova",
            updateWell: "Errore nella modifica della rimozione, riprova",
            addManualUrls: "Errore inserimento risultati",
            addManualUrlsAtLeast: "Inserisci almeno un risultato",
            changeTDState: "Errore durante il cambio di stato di rimozione dell'url, riprova",
            wellResultsDeleted: "Errore durante l'eliminazione degli url, riprova",
            searchRefresh: "Errore nell'aggiornamento della ricerca, riprova",
            screenshotArchive: "Errore nella creazione dell'archivio contenente screenshot",
        }
    },
    breadcrumbs: {
        overview: "PANORAMICA RICERCA",
        domains: "DOMINI",
        results: "RISULTATI",
        emails: "EMAIL"
    },
    menu: {
        dashboard: "Dashboard",
        searches: "Gestione ricerche",
        domainSearches: "Gestione ricerche",
        domains: "Gestione domini",
        tdprocesses: "Rimozioni"
    },
    credits: {
        title: "Nuova ricerca",
        tdTitle: "Nuova rimozione",
        baseSearches: "Ricerche base disponibili",
        genericSearches: "Ricerche disponibili",
        newSearch: "Nuova Ricerca",
        placeholderSearch: "Inserisci il titolo del contenuto da cercare",
        typeBaseSearch: "Base",
        typeAdvancedSearch: "Avanzata",
        addCredits: "Aggiungi Crediti",
        inputErrorMessage: "Inserisci almeno una query di 3 caratteri",
        inputErrorMessageDomain: "Inserisci un dominio valido",
        checksErrorMessageDeep: "Seleziona almeno una categoria",
        checksErrorMessageUGC: "Seleziona almeno un sito",
        checksErrorMessageClear: "Seleziona almeno una lingua",
        clearLabel: "Inserisci fino a 10 domini in cui cercare il contenuto",
        clearError: "Inserire correttamente la lista di domini",
        clearHelp: "Usa la virgola per inserire più domini"
    },
    categories: {
        cinemaLabel: "Cinema",
        publishingLabel: "Editoria",
        musicLabel: "Musica",
        radioLabel: "Radio",
        softwareLabel: "Software",
        sportLabel: "Sport",
        tvLabel: "TV",
        videogamesLabel: "Videogiochi",
        elearningLabel: "Elearning",
        pornLabel: "Porno",
        codeLabel: "Codice"
    },
    searches: {
        status: {
            started: "Ricerca avviata"
        },
        browserAutomationYes: "Browser",
        browserAutomationNo: "HTTP",
        titleComplete: "Ricerche",
        titleLastFive: "Ultime ricerche",
        totalSearches: "Ricerche totali",
        totalPotentialInfringements: "Risultati totali",
        table: {
            noSearches: "Non ci sono ricerche attive. Effettuane una per visualizzare i risultati",
            created: "Creazione",
            updated: "Aggiornamento",
            visitedUrls: "Urls visitati",
            totalUrls: "Numero url",
            maxVisitedUrls: "Urls visitabili",
            authenticated: "Autenticato",
            browserAutomation: "Metodo",
            torProxy: "Tor Proxy",
            refreshIntervalDays: "Pianificazione (giorni)",
            query: "Ricerca",
            language: "Lingua",
            languages: "Lingue",
            type: "Tipo",
            resultNumber: "Risultati",
            state: "Stato",
            stateFree: "Base",
            statePaid: "Avanzata",
            categories: "Categorie",
            sources: "Siti UGC",
            validation: "Risultati validati",
            actions: {
                stopSearch: "Vuoi davvero interrompere la ricerca? Potrai riprenderla in seguito.",
                restartSearch: "Vuoi davvero riavviare la ricerca?",
                view: "Apri",
                viewtip: "Domini della ricerca",
                delete: "Elimina",
                deleteTip: "Elimina ricerca",
                deleteMessage: "Vuoi davvero eliminare tutte le ricerche selezionate?",
                add: "Aggiungi",
                details: "Dettagli risultato",
                generateReportCSV: "Vuoi generare il report in formato CSV? Ti verrà inviato via email non appena disponibile",
                generateReportPDF: "Vuoi generare il report in formato PDF? Ti verrà inviato via email non appena disponibile",
                globalReportPDF: "Vuoi generare un archivio contenente i report PDF degli elementi selezionati? Ti verrà inviato via email non appena disponibile",
                globalReportCSV: "Vuoi generare un unico file in formato CSV contenente tutti i dati degli elementi selezionati? Ti verrà inviato via email non appena disponibile",
                pdf: "PDF",
                csv: "CSV",
                update: "Aggiorna",
                planning: "Pianifica",
                planningMessage: "Scegli la frequenza in giorni del monitoraggio, scegli 0 per disabilitare il monitoraggio (min: 0, max: 30)",
                cancel: "Annulla",
                errorInterval: "Inserire un numero tra 0 e 30",
            },
            filters: {
                query: "Ricerca",
                type: "Tipo",
                tags: "Tag"
            }
        },
        new: {
            query: "Termini di ricerca",
            name: "Nome ricerca",
            maxVisitedUrls: "Numero url da visitare",
            browserAutomation: "Vuoi utilizzare il browser?",
            torProxy: "Vuoi navigare su rete TOR?",
            login: "Vuoi effettuare il login automatico?",
            loginUrl: "Url contenente il form di login",
            username: "Nome utente",
            password: "Password",
            categories: "Tipi di contenuto",
            sites: "Siti UGC",
            languages: "Lingue",
            domains: "Domini",
            url: "Url",
            placeholders: {
                query: "Inserire query di ricerca",
                name: "Inserire nome di ricerca",
                maxVisitedUrls: "Inserire numero massimo di url",
                loginUrl: "Inserire url di login",
                username: "Inserire username per l'autenticazione",
                password: "Inserire password per l'autenticazione",
                categories: "Seleziona uno o più tipi di contenuto",
                sites: "Seleziona uno o più siti UGC",
                languages: "Seleziona una o più lingue",
                domains: "Inserire un dominio per riga, massimo 10 (es. multiplayer.it)",
                url: "Inserire un url (es. https://www.google.it)",
            }
        },
        clear: {
            languages: {
                it: "Italia",
                es: "Spagna",
                fr: "Francia",
                de: "Germania",
                uk: "Regno Unito",
                us: "Stati Uniti"
            }
        },
    },
    results: {
        title: "Validazione risultati dominio",
        titleCrawl: "Validazione risultati",
        date: "Data",
        violations: "Risultati",
        totalDomains: "Domini totali",
        convert: "Converti in avanzata",
        table: {
            preview: "Anteprima",
            title: "Titolo",
            views: "Visualizzazioni",
            duration: "Durata",
            url: "Url",
            violation: "Validazione",
            publishedAt: "Data caricamento",
            created: "Creazione",
            visited: "Visitato",
            notes: "Note",
            notesLabel: "Note",
            notesCSV: "Note CSV",
            notebookLabel: "Note CSV (solo 2 colonne: url, notes)",
            filters: {
                url: "Url",
                title: "Titolo",
                infringement: "Validazione",
                infringementYes: "Accettato",
                infringementNo: "Rifiutato",
                infringementNotValidated: "Non validato",
                visited: "Visitato",
                visitedYes: "Si",
                visitedNo: "No",
                htmlQuery: "HTML",
                strictHtmlQuery: "Rigida",
                strictYes: "Si",
                strictNo: "No",
                notes: "Note",

            }
        },
    },
    resultUGC: {
        cardTitle: "Dettagli risultato",
        title: "Titolo",
        channel: "Canale",
        channelFollowers: "Iscritti canale",
        description: "Descrizione",
        descriptionShow: "Mostra",
        url: "URL",
        views: "Visualizzazioni",
        duration: "Durata",
        publishedAt: "Data pubblicazione"
    },
    domains: {
        title: "Domini della ricerca",
        date: "Data",
        validated: "Risultati validati",
        violations: "Risultati",
        totalDomains: "Domini totali",
        table: {
            faviconSimilarSites: "Siti favicon simile",
            name: "Dominio",
            importance: "Importanza",
            potentialInfringements: "Risultati",
            resultNumber: "Risultati",
            channel: "Canale",
            validation: "Stato validazione",
            validationTipCompleted: "Completata",
            validationTipProgress: "In validazione",
            validationTipNotStarted: "Non iniziata",
            actions: {
                view: "Risultati",
                viewtip: "Risultati del dominio",
                delete: "Elimina",
                deleteTip: "Elimina dominio",
                deleteMessage: "Sei davvero sicuro di eliminare questo dominio con tutti i suoi risultati?"
            },
        },
        searchDomains: "Esplora e valida"
    },
    searchConversion: {
        header: "Conversione ricerca",
        rememberCategory: "Scegli le categorie su cui effettuare la ricerca avanzata per proseguire",
        message: "Convertire la ricerca in Avanzata? Verra' usato un credito per ricerche avanzate",
        yes: "Si",
        no: "No",
        remainingCredits: "Sono disponibili %{count} crediti per ricerche avanzate, vuoi usarne uno per convertire questa ricerca?",
        infinite: "infiniti",
        nocredits: "I crediti per ricerche avanzate sono terminati, vuoi acquistarne altri sullo store di Kopjra?",
        nocreditsRedirect: " (Verrai reindirizzato alla dashboard)"
    },
    reportGeneration: {
        title: "Esporta relazione",
        reportType: "Tipo relazione",
        reportTypeMetrics: "Solo metriche",
        reportTypeFull: "Metriche + url",
        reportTypeCSV: "CSV",
        reportLanguage: "Lingua",
        sendTo: "Invia relazione a (mail dell'account già inclusa)",
        mailPlaceholder: "Es.: vittoria@kopjra.com, lucio@legaltechforum.com...",
        mailTip: "Usa la virgola per inserire più indirizzi email",
        mailError: "Inserisci almeno un indirizzo email valido",
        confirm: {
            send: "Invia",
            cancel: "Annulla"
        },
        confirmMessage1: "Relazione esportata ed inviata con successo.",
        confirmMessage2: "Riceverai a breve una mail con",
        confirmMessage3: "il documento in allegato."
    },
    stats: {
        searchOverview: "Panoramica ricerca",
        validationPercentCompleted: "Risultati validati (%)",
        validationPercentNotCompleted: "Risultati non validati (%)",
        chartValidation: "Stato validazione",
        deep: {
            chartChannels: "Distribuzione dei domini per canale",
            topTenCyberlockersImportance: "Top cyberlocker per importanza",
            topTenCyberlockersInfringements: "Top cyberlocker per numero di risultati",
            topTenTorrentsImportance: "Top torrent per importanza",
            topTenTorrentsInfringements: "Top torrent per numero di risultati",
            domainLabel: "Dominio",
            importanceLabel: "Importanza",
            infringementsLabel: "Risultati"
        },
        ugc: {
            chartUGC: "Distribuzione risultati siti UGC",
            topTenViews: "Top risultati per visualizzazioni",
            topTenDuration: "Top risultati per durata",
            topTenRecent: "Top risultati per data caricamento",
            topTenChannels: "Top canali per video caricati",
            titleLabel: "Titolo",
            viewsLabel: "Views",
            durationLabel: "Durata",
            dateLabel: "Data",
            channelLabel: "Canale",
            uploadedLabel: "Caricati"
        },
        clear: {
            chartSites: "Distribuzione risultati siti web",
            topTenDomains: "Top domini per numero di risultati",
            topTenImportance: "Top domini per importanza",
            domainLabel: "Dominio",
            importanceLabel: "Importanza",
            resultsLabel: "Risultati"
        }
    },
    home: {
        mainDescription1: "Benvenuti in Web Intelligence",
        mainDescription2: "Accedi alle applicazioni o acquista crediti per utilizzarle",
        signin: "Accedi",
        detection: "Attività di OSINT (Open Source Intelligence) complesse come l’individuazione delle violazioni su clear, deep e dark web.",
        analysis: "Effettua analisi di vario tipo utilizzando i nomi di dominio.",
        dna: "Estrazione delle informazioni rilevanti di un dominio come ad esempio i domain owner, gli hosting provider e le CDN (Content Delivery Network).",
        domainSearches: "Effettua ricerche di vario tipo partendo da un nome di dominio o una keyword (domini registrati, typosquatting, favicon, sottodomini, pubblicità).",
        takedown: "Rimozione delle violazioni con invio massivo di N&TD (Notice & Takedown) e richieste di delisting ai soggetti coinvolti.",
        contactus: "Hai bisogno di una soluzione su misura? Contattaci",
        contactSubject: "Soluzione su misura: [SOLUZIONE]",
        contactBody: "Salve,\nSaremmo interessati a una soluzione su misura di [SOLUZIONE],\n",
    },
    analysis: {
        mainDescription1: "Scopri le informazioni rilevanti correlate ad un nome di dominio.",
    },
    domainSearches: {
        card: {
            title: "Nuova ricerca su dominio",
            titleLast: "Ultime ricerche su domini",
            titleComplete: "Ricerche su domini",
        },
        dashboard: {
            query: "Termini di ricerca",
            domain: "Dominio",
            placeholderQuery: "Inserisci termini per almeno 3 caratteri",
            placeholderDomain: "Inserisci un dominio (es. google.com)",
            totalSearches: "Ricerche su domini totali",
        },
        table: {
            query: "Dominio",
            type: "Tipo",
            nDomains: "N. domini",
            validation: "Validazione",
            created: "Creazione",
            updated: "Aggiornamento",
            tags: "Tag",
            actions: {
                view: "Apri",
                update: "Aggiorna",
                delete: "Elimina",
                deleteMessage: "Vuoi veramente eliminare queste ricerche su domini?",
                downloadReportCSV: "Vuoi scaricare il report in formato CSV?",
            },
            noSearches: "Non ci sono ricerche su domini attive. Effettuane una per visualizzare i risultati",
        },
    },
    domainAnalysis: {
        card: {
            title: "Panoramica Ricerca",
            total: "Domini totali",
            validated: "Domini validati"
        },
        table: {
            domain: "Dominio",
            typosquat: "Typosquat",
            ip: "IP",
            registration: "Registrazione",
            adId: "ID Ad",
            page: "Pagina",
            title: "Titolo",
            description: "Descrizione",
            languages: "Lingue",
            creation: "Creazione Ad",
            deliveryStart: "Inizio distribuzione",
            deliveryEnd: "Fine distribuzione",
            added: "Aggiunta",
            validation: "Validazione",
        }
    },
    domainInfo: {
        dashboard: {
            remainingCredits: "Crediti Web Intelligence",
            placeholderSearchbar: "Inserisci un dominio (Es. google.it)",
            inputErrorSearchbar: "Inserisci un domino corretto",
            titleLastFive: "Ultimi domini",
            titleComplete: "Domini",
            totalDomains: "Domini totali",
            errorTooltip: "Dominio non esistente, non più attivo o non trovato"
        },
        table: {
            name: "Dominio",
            date: "Data",
            state: "Stato",
            stateTooltip: "Ricerca dominio in corso (Potrebbero volerci fino a 15 minuti)",
            actions: {
                viewTip: "Apri dominio",
                deleteTip: "Elimina dominio",
                deleteMessage: "Vuoi davvero eliminare questo dominio?"
            },
            noSearches: "Non ci sono ricerche di domini attive. Effettuane una per visualizzare le informazioni",
        },
        details: {
            main: {
                title: "Generali",
                domain: "Dominio",
                ip: "IP",
                date: "Data",
                indexing: "Indicizzazione Google",
                importance: "Importanza"
            },
            registrar: {
                title: "Registrar",
                name: "Nome",
                ianaID: "IANA ID",
                url: "URL",
                whoisServerUrl: "URL Server Whois",
                whoisEmail: "Email Whois"
            },
            registrant: {
                title: "Registrant",
                name: "Nome",
                organization: "Organizzazione",
                address: "Indirizzo",
                url: "URL",
                emails: "Email"
            },
            hoster: {
                title: "Hoster",
                name: "Nome",
                organization: "Organizzazione",
                address: "Indirizzo",
                asn: "ASN",
                rirName: "Nome RIR",
                rirCountryCode: "Codice paese RIR",
                rirWhoisServer: "Server Whois RIR",
                emails: "Email"
            },
            registry: {
                title: "Registry",
                name: "Nome TLD",
                organization: "Organizzazione",
                organizationAddress: "Indirizzo Organizzazione",
                whoisServer: "Server Whois",
                administrativeEmail: "Email Amministrativa",
                technicalEmail: "Email Tecnica",
                registrationServicesURL: "URL Registrazione Servizi"
            },
            indexing: {
                title: "Indexing",
                google: "Google",
                bing: "Bing",
                yahoo: "Yahoo",
                yandex: "Yandex"
            },
            advertising: {
                title: "Advertising",
                adServers: "Ad Servers",
                adultServers: "Adult Ad Servers",
                trackerServers: "Tracker Servers"
            }
        }
    },
    wells: {
        overview: {
            totalUrls: "Risultati Totali",
            globalTakedownPercent: "Risultati Rimossi",
            createWell: "Nuova rimozione",
            titleComplete: "Rimozione",
            titleLastFive: "Ultime rimozioni",
            totalWells: "Rimozioni totali",
            noSearches: "Non ci sono Rimozioni attive. Crea una Rimozione per poter effettuare delle richieste di rimozione",
        },
        attachments: {
            attachmentType: "Tipo allegato",
            registrationNumber: "Numero di registrazione",
            registrationEntity: "Ente di registrazione",
            registrationZone: "Zona di registrazione",
            registrationClass: "Classe di registrazione",
            registrationDate: "Data di registrazione",
            expirationDate: "Data scadenza",
            url: "Url allegato",
            selectAttachmentType: "Seleziona il tipo di allegato",
            trademarkType: "Registrazione di marchio",
            patentType: "Registrazione di brevetto",
            designType: "Registrazione di modello di utilità",
            mediaType: "Contenuto multimediale",
            insertRegistrationNumber: "Inserire numero di registrazione",
            insertRegistrationEntity: "Inserire ente di registrazione",
            insertRegistrationZone: "Inserire zona di registrazione",
            insertRegistrationClass: "Inserire classe di registrazione",
            insertRegistrationDate: "Inserire data di registrazione",
            insertExpirationDate: "Inserire data scadenza",
            insertUrl: "Inserire l'url",
            document: "Documento",
            loadDocument: "Carica documento",
            add: "Aggiungi allegato"
        },
        static: {
            createdWell: "Rimozione creata correttamente",
            bindingTitle: "Selezione ricerche",
            bindingSearches: "Ricerche di detection",
            manualResults: "Risultati manuali",
            manualPlaceholder: "Inserisci un url in ogni riga\nes:\nhttps://www.google.it\nhttp://mega.io",
            updatedWell: "Informazioni della rimozione modificate correttamente",
            updateWell: "MODIFICA",
            manualUrls: "Aggiunta risultati",
            results: "Risultati",
            resultsNoticed: "Risultati diffidati",
            mailsToRead: "Totale risposte diffide non lette",
            takedownInfo: "Info rimozione",
            delegateMessage: "Sei un delegato per la rimozione?",
            rightsOwnerInfo: "Titolare dei diritti",
            attachmentsInfo: "Allegati email",
            attachmentType: "Tipo dell'allegato",
            templatePreview: "Anteprima diffida",
            defaultName: "Inserire nome della rimozione",
            defaultAsset: "Inserire il contenuto",
            defaultUrl: "Inserisci l'url",
            defaultCustomSenderEmail: "Inserisci un indirizzo email utilizzato come mittente delle diffide",
            defaultInfringementType: "Seleziona il tipo di illecito",
            copyrightType: "Violazione di copyright",
            trademarkType: "Violazione di marchio",
            patentType: "Violazione di brevetto",
            designpatentType: "Violazione di modello di utilità",
            counterfeitingType: "Contraffazione",
            defaultOwnerName: "Inserire nome e cognome/organizzazione",
            defaultOwnerAddress: "Inserire l'indirizzo",
            defaultOwnerPhone: "Inserire il numero di telefono",
            defaultOwnerWebsite: "Inserire sito web",
            select: "Seleziona...",
            confirm: "Procedi",
            cancel: "Annulla",
            back: "Indietro",
            textareaError: "Inserire un url per riga es: http://www.google.it",
            addManualUrls: "Inserisci",
            insertPdf: "Carica un file pdf",
            noSearches: "Non ci sono ricerche di Detection attive con risultati validati. Valida i risultati delle tue ricerche per poterle aggiungere ad una Rimozione",
            notnoticedState: "Non diffidato",
            noticedState: "Diffidato",
            removedState: "Rimosso",
            removedredirectState: "Rimosso con redirect",
            notremovedState: "Non rimosso",
            exceededretriesState: "Tentativi massimi raggiunti",
            unnoticeableState: "Non diffidabile",
            loadPDF: "Carica PDF",
            downloadPOA: "Scarica template DOCX",
            deletableUrlsMessage: "Solo gli url non appartenenti ad una ricerca di detection possono essere eliminati. Stai eliminando %{nUrls} url, continuare?",
            notDeletableUrlsMessage: "Attenzione! Nessuno degli url selezionati può essere eliminato in quanto appartengono a ricerche di detection",
            indexedState: "Indicizzato",
            notindexedState: "Non indicizzato",
            delistedState: "Delistato",
            notdelistedState: "Non delistato",
            delistingwaitingState: "In attesa",
            notrequestedState: "Non richiesto",
            notes: "Note",
        },
        table: {
            name: "Nome",
            infringementType: "Tipo",
            nUrls: "Numero URL",
            created: "Data",
            ownerName: "Nome e cognome/organizzazione",
            ownerAddress: "Indirizzo",
            ownerPhone: "Telefono",
            ownerWebsite: "Sito web",
            asset: "Contenuto",
            assetUrl: "Url contenuto",
            customSenderEmail: "Mittente diffida personalizzato",
            edit: "Modifica",
            deleteMessage: "Sei davvero sicuro di voler eliminare questa rimozione? Le ricerche effettuate in detection rimarranno salvate, ma perderai gli url aggiunti manualmente con tutte le informazioni allegate",
            flatResults: "Risultati",
            archive: "Archivia",
            unarchive: "Ripristina",
            archived: "Archiviato",
            notArchived: "Non archiviato",
        },
        domains: {
            onlyForm: "Questo dominio può essere diffidato solo tramite form di diffida.\nClicca sul pulsante per aprire il form in un'altra pagina",
            openForm: "Apri form",
            copyLinks: "Dopo aver aperto il form di diffida copia tutti i risultati per inserirli nel form",
            openCopyLinks: "Copia risultati",
            title: "Domini",
            table: {
                name: "Nome",
                title: "Titolo",
                created: "Data creazione",
                nUrls: "URL",
                nUrlsRemoved: "URL rimossi",
                nMailsToRead: "Risposte non lette",
                lastNotice: "Ultima diffida",
                noticeType: "Tipo di diffida",
                view: "Apri",
                viewtip: "Visualizza dominio",
                notice: "Diffida",
                noticetip: "Invia diffida",
                formConfirm: "Questo dominio può essere diffidato solo via form. Vuoi aprire il relativo form in un'altra pagina?",
                noticeConfirm: "Stai inviando una diffida al dominio selezionato, questa includerà tutti gli url del dominio che non hanno raggiunto il massimo numero di diffide inviabili. Continuare?",
                indexingConfirm: "Su quale motore di ricerca vuoi richiedere lo stato di indicizzazione degli elementi selezionati?",
                screenshotConfirm: "Vuoi generare lo screenshot degli url selezionati?",
                screenshotArchiveConfirm: "Vuoi scaricare l'archivio contenente tutti gli screenshot generati?",
                screenshotArchive: "Zip screenshots",
                inbox: "Diffide",
                mailtip: "Email relative al dominio",
                insertUrls: "Aggiungi risultati",
                changeIndexingTitle: "Modifica stato di indicizzazione",
                changeIndexingMessage: "Inserisci un elenco di url appartenenti alla Rimozione dei quali vuoi cambiare lo stato di indicizzazione",
                searchEngine: "Motore di ricerca",
                indexingState: "Stato di indicizzazione",
                stateDate: "Data",
                requestId: "ID richiesta",
                updateIndexing: "Modifica stato indicizzazione",
                requestIndexing: "Richiesta stato indicizzazione"
            },
        },
        results: {
            title: "URL del dominio",
            flatTitle: "URl della rimozione",
            table: {
                url: "URL",
                takedownState: "Stato di rimozione",
                noticeAttempts: "Tentativi di diffida",
                lastNotice: "Ultima diffida",
                changeStateTitle: "Modifica stato di rimozione",
                changeStateConfirm1: "Stai cambiando lo stato di diffida di tutti i risultati selezionati allo stesso stato.",
                changeStateConfirm2: "Nota bene che solo i risultati che possono cambiare nello stato selezionato saranno aggiornati",
                changeStateConfirm3: "Numero di urls che verranno modificati:",
                created: "Data inserimento"
            }
        },
        mails: {
            title: "Diffide inviate",
            subject: "Oggetto",
            to: "Destinatario",
            noticedUrls: "Risultati diffidati",
            dateSent: "Data invio",
            lastReceivedDate: "Data ultima risposta",
            received: "Risposte email",
            from: "Da",
            dest: "A",
            date: "Data",
            you: "Web Intelligence"
        }
    },
};

export default it;
