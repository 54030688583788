import {ThunkResult} from "../../../types";
import {Dispatch} from "redux";
import {RootAction} from "../../index";
import {gotCreditsState} from "../../detection/searchCredits";
import {GetParams, setAlert} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";
import {cancelDomainInfo, doDomainInfoSearch, gotDomainsInfo} from "../../analysis/domainsInfo";
import {NewDomainInfoSearch} from "../../../types/analysis/domainsInfoState";
import {deleteDomainInfo, newDomainInfo, getDomainsInfo} from "../../../api/analysis/domainInfo";
import {transformToDomainInfoParams} from "../../../utils/commons";
import {getCredits} from "../../../api/detection/searchCredits";


export const doGetDomainsInfo = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotDomainsInfo(await getDomainsInfo(transformToDomainInfoParams(query))));
};

export const doNewDomainInfoSearch = (newDomain: NewDomainInfoSearch): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    dispatch(setAlert(I18n.t("alerts.infos.newDomainInfo"), "info"));
    const savedNewDomainInfo = await newDomainInfo(newDomain);
    if (savedNewDomainInfo.domainInfo) {
        dispatch(doDomainInfoSearch(savedNewDomainInfo.domainInfo));
        const creditsState = getState().searchCredits;
        if (!creditsState.infinite) {
            dispatch(gotCreditsState(await getCredits()));
        }
    } else {
        const msg = I18n.t("alerts.errors.newDomainInfo") + savedNewDomainInfo.statusCode;
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
};

export const doDeleteDomainInfo = (domainInfoId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const outcome = await deleteDomainInfo(domainInfoId);
    if (outcome) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteDomainInfo"), "info"));
        dispatch(cancelDomainInfo(domainInfoId));
    } else {
        const msg = I18n.t("alerts.errors.deleteDomainInfo");
        console.log(msg);
        dispatch(setAlert(msg, "error"));
    }
};
