import {WellDomainMail} from "../../types/takedown/wellMailsState";
import {apiCall, HttpMethod} from "../index";

function transformToWellDomainMail(obj: any): WellDomainMail {
    const wellDomainMail: WellDomainMail = {
        id: obj.id,
        conversationId: obj.conversationId,
        from: obj.from,
        to: obj.to,
        subject: obj.subject,
        sentByUser: obj.sentByUser,
        readByUser: obj.readByUser,
        noticedUrls: obj.noticedUrls,
        created: new Date(obj.created),
        nEmailsReceived: obj.nEmailsReceived,
        lastReceivedDate: obj.lastReceivedDate ? new Date(obj.lastReceivedDate) : undefined
    };
    return wellDomainMail;
}

export async function getWellDomainMails(wellId: string, domainName: string): Promise<Array<WellDomainMail>> {
    const query = {emailType: "SENT"};
    const res = await apiCall({urlComponent: `/wells/${wellId}/domains/${domainName}/mails`, query});
    const json = await res.json();
    const mails = await json.results.map((o: any) => transformToWellDomainMail(o));
    return mails;
}

export async function getMailResponses(wellId: string, domainName: string, conversationId: string): Promise<Array<WellDomainMail>> {
    const query = {conversationId};
    const res = await apiCall({urlComponent: `/wells/${wellId}/domains/${domainName}/mails`, query});
    const json = await res.json();
    const mails = await json.results.map((o: any) => transformToWellDomainMail(o));
    return mails;
}

export async function getMailContent(mailId: string): Promise<string> {
    const res = await apiCall({urlComponent: `/mails/${mailId}`});
    const content = await res.text();
    return content;
}

export async function setMailOpened(emailId: string): Promise<void> {
    await apiCall({urlComponent: `/mails/${emailId}/setOpened`, method: HttpMethod.PATCH});
}
