import {apiCall, HttpMethod} from "../index";
import {WellUpdate, Well, WellsGlobalStats, NewWell, WellAttachment} from "../../types/takedown/wellsState";
import {doManualSearch} from "../detection/searches";


export interface GetParams {
    top?: number;
    skip?: number;
    sort?: string;
}

export interface GetWellsParams extends GetParams {
    name?: string;
    tags?: string;
    archived?: boolean;
}

export interface GetListResponse<T> {
    results: T[];
    total: number;
}

export type GetWellsResponse = GetListResponse<Well>;

export async function getWellsGlobalStats(): Promise<WellsGlobalStats> {
    const res = await apiCall({urlComponent: `/wells/globalStats`});
    const stats = await res.json();
    return stats;
}

export async function getWells(query: GetWellsParams): Promise<GetWellsResponse> {
    const res = await apiCall({urlComponent: "/wells", query});
    const json = await res.json();
    const wells = await json.results.map((o: any) => transformToWell(o));
    return {
        results: wells,
        total: json.total,
    };
}

export async function getCompiledNoticeTemplate(well: WellUpdate): Promise<string> {
    const query = {
        asset: well.baseInfo.asset,
        assetUrl: well.baseInfo.assetUrl,
        infringementType: well.baseInfo.infringementType,
        rightsOwnerName: well.rightsOwnerInfo.name,
        rightsOwnerAddress: well.rightsOwnerInfo.address,
        rightsOwnerPhone: well.rightsOwnerInfo.phone,
        rightsOwnerWebsite: well.rightsOwnerInfo.website,
        delegateName: well.delegateInfo?.name,
        delegateAddress: well.delegateInfo?.address,
        delegatePhone: well.delegateInfo?.phone,
        delegateWebsite: well.delegateInfo?.website
    };
    const res = await apiCall({urlComponent: `/wells/noticeTemplate`, query});
    const template = res.text();
    return template;
}

export async function updateWellStaticInfo(well: WellUpdate): Promise<Well | undefined> {
    const updatedWell = {
        name: well.baseInfo.name,
        asset: well.baseInfo.asset,
        assetUrl: well.baseInfo.assetUrl,
        infringementType: well.baseInfo.infringementType,
        customSenderEmail: well.baseInfo.customSenderEmail,
        rightsOwnerName: well.rightsOwnerInfo.name,
        rightsOwnerAddress: well.rightsOwnerInfo.address,
        rightsOwnerPhone: well.rightsOwnerInfo.phone,
        rightsOwnerWebsite: well.rightsOwnerInfo.website,
        delegateName: well.delegateInfo?.name,
        delegateAddress: well.delegateInfo?.address,
        delegatePhone: well.delegateInfo?.phone,
        delegateWebsite: well.delegateInfo?.website
    };
    const res = await apiCall({urlComponent: `/wells/${well.id}`, method: HttpMethod.PATCH, body: updatedWell});
    if (res.status < 400) {
        const json = await res.json();
        const updatedWell = transformToWell(json);
        if (well.attachments.length > 0) {
            well.attachments.forEach(a => saveAttachment(well.id, a));
        }
        if (well.deletedAttachmentsIds.length > 0) {
            well.deletedAttachmentsIds.forEach(id => deleteAttachment(well.id, id));
        }
        if (well.delegateInfo?.powerOfAttorney) {
            await saveAttachment(well.id, well.delegateInfo.powerOfAttorney);
        }
        return  updatedWell;
    }
    return undefined;
}

export async function deleteAttachment(wellId: string, attachmentId: string): Promise<boolean> {
    const res = await apiCall({urlComponent: `/wells/${wellId}/attachments/${attachmentId}`, method: HttpMethod.DELETE});
    const outcome = res.status < 400;
    return outcome;
}

export async function deleteWells(wellIds: string[], deleteAll: boolean, queryParams?: GetWellsParams): Promise<boolean> {
    const deleteMessage = {ids: wellIds, deleteAll: deleteAll, queryParameters: {query: queryParams?.name}};
    const res = await apiCall({urlComponent: `/wells`, method: HttpMethod.DELETE, body: deleteMessage});
    const outcome = res.status < 400;
    return outcome;
}

export async function createWell(newWell: NewWell, urls: string[]): Promise<Well | undefined> {
    const reshapedNewWell = {
        name: newWell.baseInfo.name,
        asset: newWell.baseInfo.asset,
        assetUrl: newWell.baseInfo.assetUrl,
        infringementType: newWell.baseInfo.infringementType,
        customSenderEmail: newWell.baseInfo.customSenderEmail,
        rightsOwnerName: newWell.rightsOwnerInfo.name,
        rightsOwnerAddress: newWell.rightsOwnerInfo.address,
        rightsOwnerPhone: newWell.rightsOwnerInfo.phone,
        rightsOwnerWebsite: newWell.rightsOwnerInfo.website,
        delegateName: newWell.delegateInfo?.name,
        delegateAddress: newWell.delegateInfo?.address,
        delegatePhone: newWell.delegateInfo?.phone,
        delegateWebsite: newWell.delegateInfo?.website,
        searchIds: newWell.searchIds
    };
    const res = await apiCall({urlComponent: `/wells`, method: HttpMethod.POST, body: reshapedNewWell});
    if (res.status < 400) {
        const json = await res.json();
        const savedWell = transformToWell(json);
        if (urls.length > 0) {
            await doManualSearch(savedWell.id, urls);
        }
        if (newWell.attachments.length > 0) {
            newWell.attachments.forEach(a => saveAttachment(savedWell.id, a));
        }
        if (newWell.delegateInfo?.powerOfAttorney) {
            await saveAttachment(savedWell.id, newWell.delegateInfo.powerOfAttorney);
        }
        return savedWell;
    }
    return undefined;
}

export async function saveAttachment(wellId: string, attachment: WellAttachment): Promise<boolean> {
    const form = new FormData();
    form.append("id", attachment.id || "");
    form.append("type", attachment.type);
    form.append("registrationNumber", attachment.registrationNumber || "");
    form.append("registrationEntity", attachment.registrationEntity || "");
    form.append("registrationZone", attachment.registrationZone || "");
    form.append("registrationClass", attachment.registrationClass || "");
    form.append("registrationDate", attachment.registrationDate ? attachment.registrationDate.toISOString() : "");
    form.append("expirationDate", attachment.expirationDate ? attachment.expirationDate.toISOString() : "");
    form.append("url", attachment.url || "");
    if (typeof attachment.document === "object") {
        form.append("file", attachment.document);
    }

    const res = await apiCall({
        urlComponent: `/wells/${wellId}/attachments`,
        method: HttpMethod.POST,
        contentType: "multipart/form-data",
        body: form
    });
    let success = false;
    if (res.status < 400) {
        success = true;
    }
    return success;
}

export async function sendNotice(wellId: string, domainName: string): Promise<boolean> {
    const res = await apiCall({
        urlComponent: `/wells/${wellId}/notices`,
        method: HttpMethod.POST,
        body: {domain: domainName}
    });
    let success = false;
    if (res.status < 400) {
        success = true;
    }
    return success;
}

export async function getPOADownloadUrl(): Promise<string> {
    let url = "";
    const res = await apiCall({
        urlComponent: `/wells/getPOAUrl`,
    });
    if (res.status === 200) {
        const parsedRes = await res.json();
        url = parsedRes.url;
    }
    return url;
}

export async function triggerTDReport(wellIds: string[], reportType: string, language: string, all: boolean, filters: any): Promise<void> {
    const reportParams = {
        ids: wellIds,
        reportType: reportType,
        language: language.toUpperCase(),
        all: all,
        filters: {name: filters?.name, tag: filters?.tag}
    }
    await apiCall({urlComponent: `/wells/report`, method: HttpMethod.POST, body: reportParams});
}

export async function getWellsTags(): Promise<string[]> {
    const res = await apiCall({urlComponent: `/wells/tags`});
    if (res.status === 200) {
        return await res.json();
    }
    return [];
}

export async function addWellTag(wellId: string, tag: string): Promise<boolean> {
    const body = {tags: [tag]};
    const res = await apiCall({urlComponent: `/wells/${wellId}/tags`, method: HttpMethod.PATCH, body});
    return res.ok;
}

export async function deleteWellTag(wellId: string, tag: string): Promise<boolean> {
    const body = {tags: [tag]};
    const res = await apiCall({urlComponent: `/wells/${wellId}/tags`, method: HttpMethod.DELETE, body});
    return res.ok;
}

export async function archiveWell(wellId: string, archived: boolean): Promise<Well> {
    const res = await apiCall({urlComponent: `/wells/${wellId}/archive`, method: HttpMethod.PATCH, body: {archived}});
    const json = await res.json();
    return transformToWell(json);
}

export function transformToWell(obj: any): Well {
    const well: Well = {
        id: obj.id,
        baseInfo: {
            name: obj.name,
            asset: obj.asset,
            assetUrl: obj.assetUrl,
            infringementType: obj.infringementType
        },
        rightsOwnerInfo: {
            name: obj.rightsOwnerName,
            address: obj.rightsOwnerAddress,
            phone: obj.rightsOwnerPhone,
            website: obj.rightsOwnerWebsite
        },
        delegateInfo: {
            name: obj.delegateName,
            address: obj.delegateAddress,
            phone: obj.delegatePhone,
            website: obj.delegateWebsite,
            powerOfAttorney: obj.powerOfAttorney
        },
        attachments: obj.attachments,
        created: new Date(obj.created),
        nUrls: obj.nUrls,
        nUrlsNoticed: obj.nUrlsNoticed,
        nUrlsRemoved: obj.nUrlsRemoved,
        nWellMailsToRead: obj.nWellMailsToRead,
        tags: obj.tags || [],
        archived: obj.archived
    };

    if (well.attachments) {
        well.attachments = (well.attachments as WellAttachment[]).map(a => {
            if (a.registrationDate) { a.registrationDate = new Date(a.registrationDate); }
            if (a.expirationDate) { a.expirationDate = new Date(a.expirationDate); }
            return a;
        });
    }

    if (well.delegateInfo.powerOfAttorney?.registrationDate) {
        well.delegateInfo.powerOfAttorney.registrationDate = new Date(well.delegateInfo.powerOfAttorney.registrationDate)
    }
    return well;
}
