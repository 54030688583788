import * as React from "react";
import {ReactChild} from "react";
import {OSDSpinner} from "../OSDSpinner";
import {I18n, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {Result, ValidationState} from "../../types/detection/resultsAreaState";
import {
    ActionType,
    Column,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KTableLoader,
    Table
} from "@kopjra/uikit";
import {useParams} from "react-router-dom";
import {SearchType} from "../../types/extra";
import {numberToDottedNumber, secondsToHms, tableGetParams} from "../../utils/commons";
import {ResultNote} from "../ResultNote";


export interface ResultsProps {
    results?: Result[];
    total: number;
    searchType: string;
    forceUpdate: boolean;
    onGetResults: (searchId: string, domainId: string, query: GetParams) => Promise<void>;
    onValidateResults: (searchId: string, results: Array<Result>, domainId?: string, updateAll?: string, queryParams?: GetParams) => Promise<void>;
    onOpenResult: (productType: string, searchId: string, domainId: string, resultId: string) => void;
    onUpdateNotes: (searchId: string, domainId: string, resultId: string, query: GetParams, notes?: string) => Promise<void>;
}

export const Results: React.FC<ResultsProps> = (
    {
        results,
        total,
        searchType,
        forceUpdate,
        onGetResults,
        onValidateResults,
        onOpenResult,
        onUpdateNotes
    }
) => {
    // @ts-ignore
    const {productType, searchId, domainId} = useParams();

    const [openedNotes, setOpenedNotes] = React.useState(false);
    const [curResult, setCurResult] = React.useState<Result | undefined>(undefined);
    const resultsRetriever = async (domainId: string, query: GetParams) => {
        await onGetResults(searchId, domainId, query);
    };

    function renderValidationCheck(datum: Result): JSX.Element {
        let validationCheck;
        switch (datum.validation) {
            case ValidationState.VIOLATION:
                validationCheck = <div className="far fa-check validationCheck action-icon"/>;
                break;
            case ValidationState.NO_VIOLATION:
                validationCheck = <div className="far fa-check validationNotSelected action-icon"/>;
                break;
            case ValidationState.VIOLATION_AUTO:
                validationCheck = <div className="far fa-check validationCheckAuto action-icon"/>;
                break;
            default:
                validationCheck = <div className="far fa-check validationNotSelected action-icon"/>;
                break;
        }
        return validationCheck;
    }

    function renderValidationCross(datum: Result): JSX.Element {
        let validationCross;
        switch (datum.validation) {
            case ValidationState.VIOLATION:
                validationCross = <div className="far fa-times validationNotSelected action-icon"/>;
                break;
            case ValidationState.NO_VIOLATION:
                validationCross = <div className="far fa-times validationCross action-icon"/>;
                break;
            case ValidationState.VIOLATION_AUTO:
                validationCross = <div className="far fa-times validationCheckAuto action-icon"/>;
                break;
            default:
                validationCross = <div className="far fa-times validationNotSelected action-icon"/>;
                break;
        }
        return validationCross;
    }

    function renderNotes(datum: Result): ReactChild {
        const color = datum.notes ? "#5198f4" : "#8c9ab1";
        return <>
            <div onClick={() => {
                setCurResult(datum);
                setOpenedNotes(true);
            }}>
                <i className={`fa-light fa-note-sticky fa-2x`} style={{cursor: "pointer", color}}/>
            </div>
        </>;
    }

    const rowAction = async (datum: Result) => {
        onOpenResult(productType, searchId, domainId, datum.id);
    };

    const actions: ActionType<Result>[] = [
        {
            name: renderValidationCheck,
            handler: async (datum: Result) => {
                if (datum.validation !== ValidationState.VIOLATION) {
                    datum.validation = ValidationState.VIOLATION;
                    await onValidateResults(searchId as string, [datum], domainId as string);

                }
            }
        },
        {
            name: renderValidationCross,
            handler: async (datum: Result) => {
                if (datum.validation !== ValidationState.NO_VIOLATION) {
                    datum.validation = ValidationState.NO_VIOLATION;
                    await onValidateResults(searchId as string, [datum], domainId as string);
                }
            }
        }
    ];

    if (searchType === SearchType.UGC) {
        actions.unshift({
            name: <><i className="fal fa-eye action-icon"/>&nbsp;<Translate value="results.table.preview"/></>,
            handler: rowAction,
        })
    }

    const globalActions: GlobalActionType[] = [
        {
            name: <div className="far fa-times fa-sm validationCross action-icon"/>,
            handler: async (data: object[], config) => {
                let validateAll;
                if (config.globalCheckedAll) {
                    validateAll = ValidationState.NO_VIOLATION;
                }
                (data as Result[]).forEach(r => r.validation = ValidationState.NO_VIOLATION);
                await onValidateResults(searchId as string, data as Result[], domainId as string, validateAll, config.queryParams);
            },
            bulk: true
        },
        {
            name: <div className="far fa-check fa-sm validationCheck action-icon"/>,
            handler: async (data: object[], config) => {
                let validateAll;
                if (config.globalCheckedAll) {
                    validateAll = ValidationState.VIOLATION;
                }
                (data as Result[]).forEach(r => r.validation = ValidationState.VIOLATION);
                await onValidateResults(searchId as string, data as Result[], domainId as string, validateAll, config.queryParams);
            },
            bulk: true
        }
    ];

    const infringementMap = [
        {label: I18n.t("results.table.filters.infringementYes"), value: ValidationState.VIOLATION},
        {label: I18n.t("results.table.filters.infringementNo"), value: ValidationState.NO_VIOLATION},
        {label: I18n.t("results.table.filters.infringementNotValidated"), value: ValidationState.VIOLATION_AUTO},
    ];

    const visitedMap = [
        {label: I18n.t("results.table.filters.visitedYes"), value: "true"},
        {label: I18n.t("results.table.filters.visitedNo"), value: "false"}
    ];

    const strictMap = [
        {label: I18n.t("results.table.filters.strictYes"), value: "true"},
        {label: I18n.t("results.table.filters.strictNo"), value: "false"}
    ];

    const filters = searchType === SearchType.DEEP ? [
        new FreeTextFilter("url", I18n.t("results.table.filters.url")),
        new FixedSelectionFilter("validation", I18n.t("results.table.filters.infringement"), infringementMap),
        new FreeTextFilter("notes", I18n.t("results.table.filters.notes")),
    ] : searchType === SearchType.CRAWL ? [
        new FreeTextFilter("url", I18n.t("results.table.filters.url")),
        new FixedSelectionFilter("visited", I18n.t("results.table.filters.visited"), visitedMap),
        new FreeTextFilter("htmlQuery", I18n.t("results.table.filters.htmlQuery")),
        new FixedSelectionFilter("strictHtmlQuery", I18n.t("results.table.filters.strictHtmlQuery"), strictMap),
        new FixedSelectionFilter("validation", I18n.t("results.table.filters.infringement"), infringementMap),
        new FreeTextFilter("notes", I18n.t("results.table.filters.notes")),
    ] : [
        new FreeTextFilter("title", I18n.t("results.table.filters.title")),
        new FreeTextFilter("url", I18n.t("results.table.filters.url")),
        new FixedSelectionFilter("validation", I18n.t("results.table.filters.infringement"), infringementMap),
        new FreeTextFilter("notes", I18n.t("results.table.filters.notes")),
    ];

    const titleColumn = searchType === SearchType.DEEP ? (
        <Column classes="colBound text-start break-all" colid="url" name="results.table.url" type={DataType.GENERIC}
                render={(datum: Object) => <a target="_blank" rel="noopener noreferrer"
                                              href={`${(datum as Result).url}`}>{(datum as Result).url}</a>}
                sort={"url"} colspan={6}
        />
    ) : (
        <Column classes="colBound text-start break-all" colid="title" name="results.table.title" type={DataType.GENERIC}
                sort={"title"} colspan={4}
                render={(datum: Result) => <div style={{wordBreak: "break-all"}}>
                    <div>{datum.title}</div>
                    <a target="_blank" rel="noopener noreferrer" href={`${datum.url}`}>{datum.url}</a></div>}/>
    );

    function renderVisited(datum: object): ReactChild {
        return (datum as Result).visited ? <Translate value={"generic.yes"}/> :
            <Translate value={"generic.no"}/>;
    }

    const visitedColumn = searchType === SearchType.CRAWL ? (
        <Column classes="colBound text-start" colid="visited" name="results.table.visited"
                type={DataType.GENERIC} sort={"visited"} colspan={1}
                render={renderVisited}
        />
    ) : (
        <Column colid="none" colspan={0} type={DataType.GENERIC}/>
    );

    const viewsColumn = searchType === SearchType.UGC ? (
        <Column classes="colBound text-end" colid="views" name="results.table.views"
                type={DataType.GENERIC} sort={"views"} colspan={2}
                render={(datum: object) => numberToDottedNumber((datum as Result).views || 0)}
        />
    ) : (
        <Column colid="none" colspan={0} type={DataType.GENERIC}/>
    );

    const durationColumn = searchType === SearchType.UGC ? (
        <Column classes="colBound text-start" colid="duration" name="results.table.duration"
                type={DataType.GENERIC} sort={"duration"} colspan={1}
                render={(datum: object) => secondsToHms((datum as Result).duration || -1)}
        />
    ) : (
        <Column colid="none" colspan={0} type={DataType.GENERIC}/>
    );

    const dateUploadColumn = searchType === SearchType.UGC ? (
        <Column classes="colBound text-end" colid="publishedAt" name="results.table.publishedAt"
                type={DataType.DATE} sort={"publishedAt"} colspan={2}
        />
    ) : (
        <Column colid="none" colspan={0} type={DataType.GENERIC}/>
    );

    return (
        <>
            {results ? (
                <Table
                    checkboxes={true}
                    filterDefinition={filters}
                    globalActions={globalActions}
                    globalWaiter={<OSDSpinner size={200}/>}
                    waiter={<OSDSpinner size={100} variant="dark"/>}
                    id={`results/list`}
                    total_count={total}
                    loaderFunc={(q: GetParams) => resultsRetriever(domainId as string, q)}
                    data={results}
                    hideColumnSelector={true}
                    hideFilters={false}
                    keyField={"id"}
                    hidePager={false}
                >
                    {titleColumn}
                    {visitedColumn}
                    {viewsColumn}
                    {durationColumn}
                    {dateUploadColumn}
                    <Column classes="colBound text-center" colid="notes" name="wells.static.notes"
                            type={DataType.GENERIC} sort={"notes"} colspan={1} render={renderNotes}/>
                    <Column classes="colBound text-end" colid="createdAt" name="results.table.created"
                            type={DataType.DATETIME} sort={"createdAt"} colspan={2}/>
                    <Column classes="colBound text-end" colid="validation" name="results.table.violation"
                            type={DataType.ACTIONS} actions={actions} colspan={3}/>
                </Table>
            ) : (
                <KTableLoader/>
            )}
            <ResultNote notes={curResult?.notes}
                        onUpdate={(notes) => onUpdateNotes(searchId, domainId, curResult?.id || "", tableGetParams("results/list"), notes)}
                        openedNotes={openedNotes}
                        setOpenedNotes={setOpenedNotes}/>
        </>
    );
};
