import * as React from "react";
import {useState} from "react";
import {I18n, Translate} from "react-redux-i18n";
import {
    alertBox,
    AlertBoxConf,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCheck,
    KForm,
    KInput,
    KLabel,
    KModal,
    KModalSize,
    KModalVariant,
    KSelect,
    KSpace
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {NewSearch} from "../../types/detection/searchState";
import {ClearLanguages, SearchType, UGCSources} from "../../types/extra";
import {ManualUrls} from "../takedown/ManualUrls";


export interface Props {
    onNewSearch: (newSearch: NewSearch) => Promise<void>;
}

export const SearchNew: React.FC<Props> = ({ onNewSearch }) => {
    const [opened, setOpened] = useState(false);
    const [authenticated, setLoginInputs] = useState<boolean>(false);
    const [browserAutomation, setBrowserAutomation] = useState<boolean>(false);
    const [torProxy, setTorProxy] = useState<boolean>(false);
    const [searchType, setSearchType] = useState<string>(SearchType.DEEP);

    // const baseInputRegex = "[A-Za-z0-9]{3,}";
    const baseInputRegex = ".{2,}";
    const urlInputRegex = "^(http(s)?:\\/\\/)(www\\.)?[-a-zA-Z\\d@:%._+~#=]{2,256}\\.[a-z]{2,20}\\b([-a-zA-Z\\d@:%_+.~#?&/=]*)$";
    const numberInputRegex = "^([2-9]|[1-9]\\d|[1-9]\\d\\d|[1-4]\\d\\d\\d|[1-5]000)$";
    const deepFiltersArray = ["cinema", "publishing", "music", "software", "tv", "videogames", "elearning", "porn", "code"];
    const deepFilters = deepFiltersArray.map(filter => {
        return {value: filter, label: <Translate value={`categories.${filter}Label`}/>}
    });
    const clearLabels = Object.keys(ClearLanguages);
    const clearValues = Object.values(ClearLanguages);
    const clearFilters = clearLabels.map((key, index) => {
        return {label: I18n.t(`searches.clear.languages.${key}`), value: clearValues[index]}
    });
    const ugcFiltersArray = [UGCSources.YOUTUBE, UGCSources.DAILYMOTION, UGCSources.VIMEO];
    const ugcFilters = ugcFiltersArray.map(filter => {
        return {value: filter, label: filter.toLowerCase()}
    });

    const loginInputs = <>
        <Row>
            <Col>
                <KInput label={<Translate value="searches.new.loginUrl"/>} required={true} id={"loginUrl"}
                        placeholder={I18n.t("searches.new.placeholders.loginUrl")}
                        pattern={urlInputRegex}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <KInput label={<Translate value="searches.new.username"/>} required={true} id={"username"}
                        placeholder={I18n.t("searches.new.placeholders.username")}/>
            </Col>
        </Row>
        <Row>
            <Col>
                <KInput type={"password"} label={<Translate value="searches.new.password"/>} required={true}
                        id={"password"}
                        placeholder={I18n.t("searches.new.placeholders.password")}/>
            </Col>
        </Row>
    </>;

    const loginForm = authenticated ? loginInputs : <></>;
    const loginChecks = <>
        <Row>
            <Col>
                <KLabel text={<Translate value="searches.new.login"/>}/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <KCheck id={"noAuthOption"} name={"authOption"} label={<Translate value="generic.no"/>} type={"radio"}
                        inline required
                        defaultChecked={true}
                        onChange={(e: any) => e.currentTarget.checked ? setLoginInputs(false) : {}}/>
                <KCheck id={"yesAuthOption"} name={"authOption"} label={<Translate value="generic.yes"/>} type={"radio"}
                        inline required
                        onChange={(e: any) => e.currentTarget.checked ? setLoginInputs(true) : {}}/>
            </Col>
        </Row>
        {loginForm}
    </>;
    const torProxyChecks = <>
        <Row>
            <Col>
                <KLabel text={<Translate value="searches.new.torProxy"/>}/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <KCheck id={"noTorProxyOption"} name={"torProxyOption"} label={<Translate value="generic.no"/>}
                        type={"radio"}
                        inline required
                        defaultChecked={true}
                        onChange={(e: any) => e.currentTarget.checked ? setTorProxy(false) : {}}/>
                <KCheck id={"yesTorProxyOption"} name={"torProxyOption"} label={<Translate value="generic.yes"/>}
                        type={"radio"}
                        inline required
                        onChange={(e: any) => e.currentTarget.checked ? setTorProxy(true) : {}}/>
            </Col>
        </Row>
    </>;

    const viewLogin = browserAutomation ? loginChecks : <></>;
    const viewTorProxy = browserAutomation ? torProxyChecks : <></>;

    const crawlForm = <>
        <Row><Col>
            <KInput label={<Translate value="searches.new.url"/>} required={true} id={"query"}
                    placeholder={I18n.t("searches.new.placeholders.url")} pattern={urlInputRegex}/>
        </Col></Row>
        <Row><Col>
            <KInput label={<Translate value="searches.new.name"/>} required={true} id={"name"}
                    placeholder={I18n.t("searches.new.placeholders.name")} pattern={baseInputRegex}/>
        </Col></Row>
        <Row><Col>
            <KInput label={<Translate value="searches.new.maxVisitedUrls"/>} required={true} id={"maxVisitedUrls"}
                    placeholder={I18n.t("searches.new.placeholders.maxVisitedUrls")} pattern={numberInputRegex}/>
        </Col></Row>
        <Row><Col>
            <KLabel text={<Translate value="searches.new.browserAutomation"/>}/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <KCheck id={"noBrowserOption"} name={"browserAutomation"} label={<Translate value="generic.no"/>}
                    type={"radio"}
                    inline required
                    defaultChecked={true}
                    onChange={(e: any) => {
                        if (e.currentTarget.checked) {
                            setBrowserAutomation(false);
                            setTorProxy(false);
                            setLoginInputs(false);
                        }
                    }}/>
            <KCheck id={"yesBrowserOption"} name={"browserAutomation"} label={<Translate value="generic.yes"/>}
                    type={"radio"}
                    inline required
                    onChange={(e: any) => e.currentTarget.checked ? setBrowserAutomation(true) : {}}/>
        </Col></Row>
        {viewTorProxy}
        {viewLogin}
    </>

    const deepForm = <>
        <Row><Col>
            <KInput label={<Translate value="searches.new.query"/>} required={true} id={"query"}
                    placeholder={I18n.t("searches.new.placeholders.query")} pattern={baseInputRegex}/>
        </Col></Row>
        <Row><Col>
            <KSelect label={<Translate value="searches.new.categories"/>} required={true} options={deepFilters}
                     multi={true} id={"categories"} placeholder={I18n.t("searches.new.placeholders.categories")}/>
        </Col></Row>
        <Row><Col>
            <KInput label={I18n.t("searches.new.domains")} as="textarea" id={"domains"} name={"domains"}
                    placeholder={I18n.t("searches.new.placeholders.domains")} addedTextAreaHeight={50}
                    validate={textAreaValue => {
                        const domainsArray = textAreaValue.trim().toLowerCase().split("\n");
                        // eslint-disable-next-line
                        const pattern = /^(([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,})$/;
                        const validDomains = domainsArray.filter(d => pattern.test(d));
                        return domainsArray.length === validDomains.length;
                    }}
            />
        </Col></Row>
    </>;
    const clearForm = <>
        <Row><Col>
            <KInput label={<Translate value="searches.new.query"/>} required={true} id={"query"}
                    placeholder={I18n.t("searches.new.placeholders.query")} pattern={baseInputRegex}/>
        </Col></Row>
        <Row><Col>
            <KSelect label={<Translate value="searches.new.languages"/>} required={true} options={clearFilters}
                     multi={true} id={"languages"} placeholder={I18n.t("searches.new.placeholders.languages")}/>
        </Col></Row>
        <Row><Col>
            <KInput label={I18n.t("searches.new.domains")} as="textarea" id={"domains"} name={"domains"}
                    placeholder={I18n.t("searches.new.placeholders.domains")} addedTextAreaHeight={50}
                    validate={textAreaValue => {
                        const domainsArray = textAreaValue.trim().toLowerCase().split("\n");
                        // eslint-disable-next-line
                        const pattern = /^(([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,})$/;
                        const validDomains = domainsArray.filter(d => pattern.test(d));
                        return domainsArray.length === validDomains.length;
                    }}
            />
        </Col></Row>
    </>;
    const ugcForm = <>
        <Row><Col>
            <KInput label={<Translate value="searches.new.query"/>} required={true} id={"query"}
                    placeholder={I18n.t("searches.new.placeholders.query")} pattern={baseInputRegex}/>
        </Col></Row>
        <Row><Col>
            <KSelect label={<Translate value="searches.new.sites"/>} required={true} options={ugcFilters} multi={true}
                     id={"sites"} placeholder={I18n.t("searches.new.placeholders.sites")}/>
        </Col></Row>
    </>;
    const customForm = <>
        <Row><Col>
            <KInput label={<Translate value="searches.new.query"/>} required={true} id={"query"}
                    placeholder={I18n.t("searches.new.placeholders.query")} pattern={baseInputRegex}/>
        </Col></Row>
        <ManualUrls label={"urls"} required={true}/>
    </>;
    const searchForm = searchType === SearchType.DEEP ? deepForm :
        searchType === SearchType.CLEAR ? clearForm :
            searchType === SearchType.CRAWL ? crawlForm :
                searchType === SearchType.UGC ? ugcForm :
                    searchType === SearchType.CUSTOM ? customForm : <></>;
    return (
        <>
            <Row><Col className={"text-center"}>
                <KButton text={<><i className="fal fa-plus"/>&nbsp;<Translate value="credits.newSearch"/></>}
                         onClick={() => {
                             setOpened(true);
                             setSearchType(SearchType.DEEP)
                         }}
                />
            </Col></Row>
            <KModal variant={KModalVariant.secondary} size={KModalSize.lg} show={opened} onHide={() => setOpened(false)}
                    header={I18n.t("credits.newSearch")} footer={<></>}>
                <KForm onSubmit={values => {
                    let everythingGood = true;

                    const newSearch: NewSearch = {
                        query: values.query as string,
                        webType: searchType,
                        domains: values.domains ? (values.domains as string).trim().split("\n") : values.sites ? JSON.parse(values.sites as string) : undefined,
                        categories: values.categories ? JSON.parse(values.categories as string) : undefined,
                        languages: values.languages ? JSON.parse(values.languages as string) : undefined,
                        customUrls: values.urls ? (values.urls as string).trim().split("\n") : undefined,
                        name: values.name ? values.name as string : undefined,
                        maxVisitedUrls: values.maxVisitedUrls ? values.maxVisitedUrls as number : undefined,
                        browserAutomation: searchType === SearchType.CRAWL ? browserAutomation : undefined,
                        torProxy: searchType === SearchType.CRAWL ? torProxy : undefined,
                        credential: searchType === SearchType.CRAWL  && authenticated ? {loginUrl: values.loginUrl as string, username: values.username as string, password: values.password as string} : undefined
                    };
                    if (torProxy) {
                        const onionUrlInputRegex = "^(http(s)?:\\/\\/)[a-z2-7]{56}\\.onion(\\/[a-zA-Z0-9\\/\\-_.]*)?$";

                        if (!newSearch.query.match(onionUrlInputRegex)) {
                            everythingGood = false;
                        }

                        if (newSearch.credential) {
                            if (!newSearch.credential.loginUrl.match(onionUrlInputRegex)) {
                                everythingGood = false;
                            }
                        }
                    }

                    if (everythingGood) {
                        setOpened(false);
                        onNewSearch(newSearch);
                    } else {
                        const commonBoxConf: AlertBoxConf = {
                            message: I18n.t("alerts.errors.notOnionUrl")
                        };
                        alertBox(commonBoxConf).then();
                    }
                }}>
                    <Row>
                        <Col>
                            <KLabel text={<Translate value={"searches.table.type"}/>}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <KCheck id={"deepOption"} name={"searchOption"} label={"Deep"} type={"radio"} inline
                                    required defaultChecked={true}
                                    onChange={(e: any) => e.currentTarget.checked ? setSearchType(SearchType.DEEP) : {}}/>
                            <KCheck id={"clearOption"} name={"searchOption"} label={"Clear"} type={"radio"} inline
                                    required
                                    onChange={(e: any) => e.currentTarget.checked ? setSearchType(SearchType.CLEAR) : {}}/>
                            <KCheck id={"ugcOption"} name={"searchOption"} label={"Videos"} type={"radio"} inline
                                    required
                                    onChange={(e: any) => e.currentTarget.checked ? setSearchType(SearchType.UGC) : {}}/>
                            <KCheck id={"customOption"} name={"searchOption"} label={"Custom"} type={"radio"} inline
                                    required
                                    onChange={(e: any) => e.currentTarget.checked ? setSearchType(SearchType.CUSTOM) : {}}/>
                            <KCheck id={"crawlOption"} name={"searchOption"} label={"Crawl"} type={"radio"} inline
                                    required
                                    onChange={(e: any) => e.currentTarget.checked ? setSearchType(SearchType.CRAWL) : {}}/>
                        </Col>
                    </Row>
                    {searchForm}
                    <KSpace spaces={2}/>
                    <Row>
                        <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                             text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                 value={`wells.static.cancel`}/></>}
                                             onClick={() => setOpened(false)}/>
                        </Col>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                             text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate
                                                 value={`wells.static.confirm`}/></>}/></Col>
                    </Row>
                </KForm>
            </KModal>
        </>
    );
};
