import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, DomainAnalysisArea} from "../../components/analysis/DomainAnalysisArea";
import {RootState, ThunkDispatch} from "../../types";
import {doGetCurrentDomainSearch} from "../../actions/thunks/analysis/domainSearchDetails";

function mapStateToProps({domainSearchDetails}: RootState): StateProps {
    return {
        currentDomainSearch: domainSearchDetails.currentDomainSearch,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentDomainSearch: (domainSearchId: string) => dispatch(doGetCurrentDomainSearch(domainSearchId))
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DomainAnalysisArea);
