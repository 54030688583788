import {createAction} from "typesafe-actions";
import {GetDomainsSearchesResponse} from "../../api/analysis/domainsSearches";
import {DomainSearch} from "../../types/analysis/domainSearchesState";

export const gotDomainSearches = createAction("domainsSearches/GOT", (response: GetDomainsSearchesResponse) => response)();
export const doDomainSearch = createAction("domainSearch/NEW", (domainSearch: DomainSearch) => domainSearch)();
export const cancelDomainSearch = createAction("domainSearch/DELETE", (domainSearchId: string) => domainSearchId)();
export const resetDomainsSearchesState = createAction("domainsSearches/RESET")();
export const addedDomainSearchTag = createAction("domainSearch/ADD_TAG", (domainSearchId: string, tag: string) => ({domainSearchId, tag}))();
export const deletedDomainSearchTag = createAction("domainSearch/DELETE_TAG", (domainSearchId: string, tag: string) => ({domainSearchId, tag}))();
export const gotDomainSearchesTags = createAction("domainSearches/GET_TAGS", (tags: string[]) => tags)();
