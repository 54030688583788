import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { GetParams, KCard, KContainer, KSpace } from "@kopjra/uikit";
import { Translate } from "react-redux-i18n";
import { Result } from "../../types/detection/resultsAreaState";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Results } from "./Results";
import { tableGetParams } from "../../utils/commons";
import { Search } from "../../types/detection/searchState";
import { OSDSpinner } from "../OSDSpinner";
import {SearchDetailsCrawl} from "./SearchDetailsCrawl";


export interface StateProps {
    currentSearch?: Search;
    results?: Result[];
    total: number;
    forceUpdate: boolean;
}

export interface DispatchProps {
    onGetResults: (searchId: string, domainId: string, query: GetParams) => Promise<void>;
    onValidateResults: (searchId: string, results: Array<Result>, domainId?: string, updateAll?: string, queryParams?: GetParams) => Promise<void>;
    onOpenResult: (productType: string, searchId: string, domainId: string, resultId: string) => void;
    onGetCurrentSearch: (searchId: string) => Promise<void>;
    onUpdateNotes: (searchId: string, domainId: string, resultId: string, query: GetParams, notes?: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const CrawlArea: React.FC<Props> = (
    {
        currentSearch,
        results,
        total,
        forceUpdate,
        onGetResults,
        onValidateResults,
        onGetCurrentSearch,
        onOpenResult,
        onUpdateNotes
    }
) => {
    // @ts-ignore
    const { searchId, domainId } = useParams();
    const queryParams = tableGetParams("results/list");

    useEffect(() => {
        onGetCurrentSearch(searchId as string).catch((e) => console.warn("Get current search error", e));
        onGetResults(searchId as string, domainId as string, queryParams).catch((e) => console.warn("Get results error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer>
            <KCard header={<Translate value={"results.titleCrawl"} />}>
                <Row>
                    <Col md={12}>
                        {currentSearch ? (
                            <SearchDetailsCrawl currentSearch={currentSearch}/>
                        ) : (
                            <OSDSpinner size={100} variant={"light"} />
                        )}
                    </Col>
                </Row>
                <KSpace />
                <Row>
                    <Col md={12}>
                        {currentSearch ? (
                            <Results
                                results={results} total={total} searchType={currentSearch.webType} forceUpdate={forceUpdate}
                                onGetResults={onGetResults} onValidateResults={onValidateResults}
                                onOpenResult={onOpenResult} onUpdateNotes={onUpdateNotes}
                            />
                        ) : (
                            <OSDSpinner size={100} variant={"light"} />
                        )}
                    </Col>
                </Row>
            </KCard>
        </KContainer>
    );
};
