import * as React from "react";
import {useEffect} from "react";
import {I18n, Translate} from "react-redux-i18n";
import {KButton, KBUTTON_TYPE, KForm, KInput, KModal, KModalSize, KModalVariant, KSpace} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";


export interface ResultNoteProps {
    notes: string | undefined;
    openedNotes: boolean;
    setOpenedNotes: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdate: (notes?: string) => Promise<void>;
}

export const ResultNote: React.FC<ResultNoteProps> = (
    {
        notes,
        openedNotes,
        setOpenedNotes,
        onUpdate
    }
) => {
    const [curNotes, setCurNotes] = React.useState<string | undefined>(notes);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    useEffect(() => {
        if (openedNotes) {
            setCurNotes(notes);
        }
    }, [openedNotes, notes, setCurNotes]);

    return (
        <KModal size={KModalSize.lg} variant={KModalVariant.secondary} show={openedNotes}
                onHide={() => setOpenedNotes(false)} header={I18n.t("results.table.notes")} footer={<></>}>
            <KForm onSubmit={async values => {
                setSubmitting(true);
                try {
                    await onUpdate(curNotes);
                } finally {
                    setOpenedNotes(false);
                    setSubmitting(false);
                }
            }}>
                <KInput as="textarea" id={"notes"} name={"notes"}
                        label={I18n.t("results.table.notesLabel") + ` [${curNotes?.length || 0}/100]`}
                        validate={(value) => value.length <= 100}
                        value={curNotes || ""}
                        onChange={(e) => {
                            setCurNotes(e.currentTarget.value);
                        }}
                />
                <KSpace spaces={2}/>
                <Row>
                    <Col md={3}><KButton disabled={submitting} type={KBUTTON_TYPE.submit} fill={true}
                                         text={<><i className="fal fa-chevron-double-right"/>&nbsp;<Translate
                                             value={`generic.save`}/></>}/>
                    </Col>
                </Row>
            </KForm>
        </KModal>
    );
};
