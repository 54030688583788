import {connect} from "react-redux";
import {RootState, ThunkDispatch} from "../../types";
import {DispatchProps, StateProps, InnerProps, WellCreation} from "../../components/takedown/WellCreation";
import {
    doCreateNewWell,
    doGetAvailableSearches,
    doGetCompiledNoticeTemplate,
    doGetCurrentWellStaticInfo,
    doUpdateWellStaticInfo,
    doDownloadPOA
} from "../../actions/thunks/takedown/wellCreation";
import {NewWell, WellUpdate} from "../../types/takedown/wellsState";
import {GetParams} from "../../api/detection/searches";
import {doGetCurrentUser} from "../../actions/thunks/main";


function mapStateToProps({main, wellCreation}: RootState): StateProps {
    return {
        currentUser: main.currentUser,
        currentWellStaticInfo: wellCreation.currentWellStaticInfo,
        noticeTemplateCompiled: wellCreation.noticeTemplateCompiled,
        availableSearches: wellCreation.availableSearches,
        total: wellCreation.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentUser: () => dispatch(doGetCurrentUser()),
        onGetCurrentWellStaticInfo: (wellId: string) => dispatch(doGetCurrentWellStaticInfo(wellId)),
        onGetCompiledNoticeTemplate: (wellInfo: WellUpdate) => dispatch(doGetCompiledNoticeTemplate(wellInfo)),
        onUpdateWellStaticInfo: (wellInfo: WellUpdate) => dispatch(doUpdateWellStaticInfo(wellInfo)),
        onGetAvailableSearches: (query: GetParams) => dispatch(doGetAvailableSearches(query)),
        onCreateWell: (newWell: NewWell, urls: string[]) => dispatch(doCreateNewWell(newWell, urls)),
        onDownloadPOA: () => dispatch(doDownloadPOA())
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(WellCreation);
