import * as React from "react";
import {ReactChild, useState} from "react";
import {I18n, Translate} from "react-redux-i18n";
import "react-circular-progressbar/dist/styles.css";
import {
    alertBox,
    Column,
    confirmBox,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KForm,
    KLabel,
    KModal,
    KModalSize,
    KModalVariant,
    KSelect,
    KSELECT_SIZE,
    KSpace,
    KSpinner,
    KTableLoader,
    Table,
    tooltipate
} from "@kopjra/uikit";
import {useParams} from "react-router-dom";
import {WellDomainResult} from "../../types/takedown/wellDomainResultsAreaState";
import {OSDSpinner} from "../OSDSpinner";
import {
    convertDate,
    createLinkToDownload,
    IndexingState,
    tableGetParams,
    TakedownState,
    TakedownStateMap,
} from "../../utils/commons";
import {WellDomain} from "../../types/takedown/wellDomainsState";
import {User} from "../../types/main";
import {GlobalActionHandlerConfig} from "@kopjra/uikit/dist/src/lib/kptable/types";
import {Col, Row} from "react-bootstrap";
import {getNUrlsDeletable, GetWellDomainResultsParams} from "../../api/takedown/wellDomainResultsArea";
import {ResultNote} from "../ResultNote";


export interface WellDomainResultsProps {
    currentUser?: User;
    wellDomainResults?: WellDomainResult[];
    total: number;
    currentWellDomain?: WellDomain;
    curNResultsWillChange?: number;
    onGetWellDomainResults: (wellId: string, domainName: string, query: GetParams) => Promise<void>;
    onGetNResultsWillChange: (wellId: string, domainName: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => Promise<void>;
    resetNResultsWillChange: () => void;
    onUpdateTakedownState: (wellId: string, domainName: string, data: WellDomainResult[], newState: string, all: boolean, query: GetParams) => Promise<void>;
    onRequestIndexing: (wellId: string, domainName: string, data: WellDomainResult[], all: boolean, searchEngine: "google" | "bing", queryParams?: GetParams) => Promise<void>;
    onDeleteWellUrls: (wellId: string, domainName: string, urls: string[], all: boolean, queryParams: GetWellDomainResultsParams) => Promise<void>;
    onCreateScreenshotArchiveLink: (wellId: string, domainName?: string) => Promise<string>;
    onRequestScreenshot: (wellId: string, domainName: string, data: WellDomainResult[], all: boolean, queryParams?: GetParams) => Promise<void>;
    getScreenDownloadUrl: (wellId: string, domainName: string, url: string) => Promise<void>;
    onSetIndexingState: (wellId: string, urlsArray: string[], searchEngine: string, indexingState: string, query: GetParams, stateDate?: string, requestId?: string, domainName?: string) => Promise<void>;
    onUpdateNotes: (wellId: string, domain: string, url: string, notes?: string) => Promise<void>;
}

export const WellDomainResults: React.FC<WellDomainResultsProps> = (
    {
        currentUser,
        wellDomainResults,
        total,
        currentWellDomain,
        curNResultsWillChange,
        onGetWellDomainResults,
        onGetNResultsWillChange,
        resetNResultsWillChange,
        onUpdateTakedownState,
        onRequestIndexing,
        onDeleteWellUrls,
        onCreateScreenshotArchiveLink,
        onRequestScreenshot,
        getScreenDownloadUrl,
        onSetIndexingState,
        onUpdateNotes
    }
) => {
    // @ts-ignore
    const {wellId, domainName} = useParams();
    const superUser = currentUser?.scope?.includes("wi:unlimitedcredits");

    const [opened, setOpened] = useState<boolean>(false);
    const [curTableDataAndConfig, setCurTableDataAndConfig] = useState<{
        data: WellDomainResult[],
        config: GlobalActionHandlerConfig
    } | undefined>(undefined);
    const [changingState, setChangingState] = useState<boolean>(false);
    const [openedIndexingBox, setOpenedIndexingBox] = useState<boolean>(false);
    const [openedNotes, setOpenedNotes] = React.useState(false);
    const [curResult, setCurResult] = React.useState<WellDomainResult | undefined>(undefined);


    const wellDomainResultsRetriever = async (domainName: string, query: GetParams) => {
        await onGetWellDomainResults(wellId, domainName, query);
    };

    const stateFilterMap = [TakedownState.NOT_NOTICED, TakedownState.NOTICED, TakedownState.REMOVED, TakedownState.NOT_REMOVED, TakedownState.REMOVED_REDIRECT].map(s => {
        const l = `${s.replaceAll("_", "").toLowerCase()}State`;
        return {value: s, label: I18n.t(`wells.static.${l}`)};
    });

    const delistingStateMap = Object.values(IndexingState).map(s => {
        const l = `${s.replaceAll("_", "").toLowerCase()}State`;
        return {value: s, label: I18n.t(`wells.static.${l}`)};
    });

    const filters = [
        new FreeTextFilter("url", I18n.t("wells.results.table.url")),
        new FixedSelectionFilter("takedownState", I18n.t("wells.results.table.takedownState"), stateFilterMap),
        new FixedSelectionFilter("indexedGoogle", "google delisting", delistingStateMap),
        new FixedSelectionFilter("indexedBing", "bing delisting", delistingStateMap),
        new FreeTextFilter("notes", I18n.t("results.table.filters.notes")),
    ];

    function renderUrl(datum: object) {
        return <div style={{wordBreak: "break-all"}}><a target="_blank" rel="noopener noreferrer"
                                                        href={`${(datum as WellDomainResult).url}`}>{(datum as WellDomainResult).url}</a>
        </div>
    }

    function renderIndexedGoogle(datum: WellDomainResult) {
        const currentState = datum.indexedGoogle;
        return tooltipate(<KSelect id={datum.url} size={KSELECT_SIZE.sm}
                                   options={delistingStateMap}
                                   value={I18n.t(`wells.static.${currentState.replaceAll("_", "").toLowerCase()}State`)}
                                   onChange={async val => onSetIndexingState(wellId, [datum.url], "google", val as string, tableGetParams("wellResults/list"), new Date().toISOString(), undefined, domainName).catch()}
        />, datum.indexedGoogleLastUpdate ? convertDate(datum.indexedGoogleLastUpdate) : undefined);
    }

    function renderIndexedBing(datum: WellDomainResult) {
        const currentState = datum.indexedBing;
        return tooltipate(<KSelect id={datum.url} size={KSELECT_SIZE.sm}
                                   options={delistingStateMap}
                                   value={I18n.t(`wells.static.${currentState.replaceAll("_", "").toLowerCase()}State`)}
                                   onChange={async val => onSetIndexingState(wellId, [datum.url], "bing", val as string, tableGetParams("wellResults/list"), new Date().toISOString(), undefined, domainName).catch()}
        />, datum.indexedBingLastUpdate ? convertDate(datum.indexedBingLastUpdate) : undefined);
    }

    function renderScreenshotDownload(datum: WellDomainResult) {
        console.log(datum.screenshotAvailable);
        return datum.screenshotAvailable
            ? <i style={{cursor: "pointer"}} className="fa-solid fa-file-arrow-down fa-lg validationCheckAuto"
                 onClick={() => getScreenDownloadUrl(wellId, domainName, datum.url)}/>
            : <i className="fa-solid fa-file-arrow-down fa-lg" style={{color: "#8C9AB1"}}/>
    }

    function getTDStateOptions(): Array<{ value: string, label: string }> {
        const options = [TakedownState.REMOVED, TakedownState.REMOVED_REDIRECT, TakedownState.NOT_REMOVED, TakedownState.NOTICED];
        const globalTDStateOptions = options.map(s => {
            const l = `${s.replaceAll("_", "").toLowerCase()}State`;
            return {value: s, label: I18n.t(`wells.static.${l}`)};
        });
        return globalTDStateOptions;
    }

    function renderTakedownState(datum: object): ReactChild {
        const result = datum as WellDomainResult;
        const currentState = result.takedownState;
        const availableOptions = TakedownStateMap[currentState].map(s => {
            const l = `${s.replaceAll("_", "").toLowerCase()}State`;
            return {value: s, label: <Translate value={`wells.static.${l}`}/>};
        });
        if (availableOptions.length > 0) {
            return <div className={"table-select"}>
                <KSelect id={"tdState"} options={availableOptions}
                         value={I18n.t(`wells.static.${currentState.replaceAll("_", "").toLowerCase()}State`)}
                         onChange={async val => onUpdateTakedownState(wellId, domainName, [result], val as string, false, tableGetParams("wellResults/list")).catch()}
                         size={KSELECT_SIZE.sm}
                /></div>
        } else {
            return <span><Translate
                value={`wells.static.${currentState.replaceAll("_", "").toLowerCase()}State`}/></span>
        }
    }

    function renderNotes(datum: WellDomainResult): ReactChild {
        const color = datum.notes ? "#5198f4" : "#8c9ab1";
        return <>
            <div onClick={() => {
                setCurResult(datum);
                setOpenedNotes(true);
            }}>
                <i className={`fa-light fa-note-sticky fa-2x`} style={{cursor: "pointer", color}}/>
            </div>
        </>;
    }

    const globalActions: GlobalActionType[] = [
        {
            name: <><i className="fal fa-trash validationCross action-icon"/></>,
            handler: async (data: WellDomainResult[], config) => {
                const urls = data.map(r => r.url);
                const n = await getNUrlsDeletable(wellId, urls, config.globalCheckedAll, domainName, config.queryParams);
                if (n > 0) {
                    const confirmBoxConf = {
                        noText: I18n.t("generic.no"),
                        yesText: I18n.t("generic.yes"),
                        dark: false,
                        message: I18n.t("wells.static.deletableUrlsMessage", {nUrls: n})
                    };
                    const deleteUrls = await confirmBox(confirmBoxConf);
                    if (deleteUrls) {
                        await onDeleteWellUrls(wellId, domainName, urls, config.globalCheckedAll, config.queryParams);
                    }
                } else {
                    await alertBox({message: I18n.t("wells.static.notDeletableUrlsMessage")});
                }
            },
            bulk: true
        },
        {
            name: <div className="fa-light fa-diagram-next fa-sm validationCheckAuto action-icon"/>,
            handler: async (data: object[], config) => {
                setCurTableDataAndConfig({data: data as WellDomainResult[], config: config});
                resetNResultsWillChange();
                setOpened(true);
            },
            bulk: true
        }
    ];

    if (superUser) {
        globalActions.push(
            {
                name: <div className="far fa-spider fa-sm validationCheckAuto action-icon"/>,
                handler: async (data: object[], config) => {
                    setCurTableDataAndConfig({data: data as WellDomainResult[], config: config});
                    setOpenedIndexingBox(true);
                },
                bulk: true
            },
            {
                name: <div className="fa-light fa-camera-viewfinder fa-sm validationCheckAuto action-icon"/>,
                handler: async (data: object[], config) => {
                    const all = config.globalCheckedAll;
                    const confirmBoxConf = {
                        noText: I18n.t("kptable.confirm.no"),
                        yesText: I18n.t("kptable.confirm.yes"),
                        dark: false,
                        message: I18n.t("wells.domains.table.screenshotConfirm")
                    };
                    const send = await confirmBox(confirmBoxConf);
                    if (send) {
                        await onRequestScreenshot(wellId as string, domainName as string, data as WellDomainResult[], all, config.queryParams);
                    }
                },
                bulk: true
            },
            {
                name: <><i className="fal fa-file-zipper"/>&nbsp;<Translate
                    value="wells.domains.table.screenshotArchive"/></>,
                handler: async () => {
                    const confirmBoxConf = {
                        noText: I18n.t("kptable.confirm.no"),
                        yesText: I18n.t("kptable.confirm.yes"),
                        dark: false,
                        message: I18n.t("wells.domains.table.screenshotArchiveConfirm")
                    };
                    const download = await confirmBox(confirmBoxConf);
                    if (download) {
                        try {
                            const link = await onCreateScreenshotArchiveLink(wellId as string, domainName as string);
                            createLinkToDownload(`screenshotArchive_${wellId}`, link);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
                bulk: false,
            },
        );
    }

    return (
        <>
            {wellDomainResults && currentWellDomain ? (
                <Table
                    checkboxes={true}
                    filterDefinition={filters}
                    globalActions={globalActions}
                    globalWaiter={<OSDSpinner size={200}/>}
                    waiter={<OSDSpinner size={100} variant="dark"/>}
                    id={`wellResults/list`}
                    total_count={total}
                    loaderFunc={(q: GetParams) => wellDomainResultsRetriever(domainName as string, q)}
                    data={wellDomainResults}
                    hideColumnSelector={true}
                    hideFilters={false}
                    keyField={"url"}
                    hidePager={false}
                >
                    <Column classes="colBound text-start" colid="url" name="wells.results.table.url"
                            type={DataType.GENERIC} sort={"url"} render={renderUrl} colspan={5}/>
                    <Column classes="colBound text-center" colid="indexedGoogle" name="google delisting"
                            type={DataType.GENERIC}
                            sort={"indexedGoogle"} colspan={1} visible={superUser} render={renderIndexedGoogle}/>
                    <Column classes="colBound text-center" colid="indexedBing" name="bing delisting"
                            type={DataType.GENERIC}
                            sort={"indexedBing"} colspan={1} visible={superUser} render={renderIndexedBing}/>
                    <Column classes="colBound text-center" colid="screenshotAvailable" name="screenshot"
                            sort={"screenshotLocation"}
                            type={DataType.GENERIC} colspan={1} visible={superUser} render={renderScreenshotDownload}/>
                    <Column classes="colBound text-center" colid="noticeAttempts"
                            name="wells.results.table.noticeAttempts"
                            type={DataType.GENERIC} sort={"noticeAttempts"} colspan={1}/>
                    <Column classes="colBound text-end" colid="lastNotice" name="wells.results.table.lastNotice"
                            type={DataType.DATE} sort={"lastNotice"} colspan={2}/>
                    <Column classes="colBound text-center" colid="notes" name="wells.static.notes"
                            type={DataType.GENERIC} sort={"notes"} colspan={1} render={renderNotes}/>
                    <Column classes="colBound text-end" colid="takedownState" name="wells.results.table.takedownState"
                            type={DataType.GENERIC} sort={"takedownState"} render={renderTakedownState} colspan={1}/>
                </Table>
            ) : (
                <KTableLoader/>
            )}
            <KModal variant={KModalVariant.secondary} size={KModalSize.lg} show={opened} onHide={() => setOpened(false)}
                    header={I18n.t("wells.results.table.changeStateTitle")} footer={<></>}>
                <KForm onSubmit={async values => {
                    setChangingState(true);
                    await onUpdateTakedownState(wellId, domainName, curTableDataAndConfig!.data, values.tdStateGlobal as string, curTableDataAndConfig!.config.globalCheckedAll, curTableDataAndConfig!.config.queryParams);
                    await wellDomainResultsRetriever(domainName as string, curTableDataAndConfig!.config.queryParams);
                    setOpened(false);
                    setChangingState(false);
                }}>
                    <Row><Col className={"text-center"}><KLabel
                        text={<Translate value={"wells.results.table.changeStateConfirm1"}/>}/></Col></Row>
                    <KSpace/>
                    <Row><Col className={"text-center"}><KLabel
                        text={<Translate value={"wells.results.table.changeStateConfirm2"}/>}/></Col></Row>
                    <KSpace spaces={2}/>
                    <Row><Col className={"text-center"} md={6}><KSelect id={"tdStateGlobal"}
                                                                        options={getTDStateOptions()}
                                                                        onChange={async val => {
                                                                            await onGetNResultsWillChange(wellId, domainName, curTableDataAndConfig!.data, val as string, curTableDataAndConfig!.config.globalCheckedAll, curTableDataAndConfig!.config.queryParams);
                                                                        }}
                    /></Col></Row>
                    <KSpace/>
                    <Row><Col className={"text-center"}><KLabel text={<><Translate
                        value={"wells.results.table.changeStateConfirm3"}/>{` ${curNResultsWillChange || 0} / ${total}`}</>}/></Col></Row>
                    <KSpace spaces={2}/>
                    <Row>
                        <Col md={4}><KButton variant={KBUTTON_VARIANT.secondary} fill={true}
                                             text={<><i className="fal fa-times"/>&nbsp;<Translate
                                                 value={`wells.static.cancel`}/></>}
                                             onClick={() => setOpened(false)}/>
                        </Col>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                             disabled={!curNResultsWillChange || curNResultsWillChange === 0}
                                             text={changingState ? <KSpinner/> : <><i
                                                 className="fal fa-chevron-double-right"/>&nbsp;<Translate
                                                 value={`wells.static.confirm`}/></>}/></Col>
                    </Row>
                </KForm>
            </KModal>
            <KModal variant={KModalVariant.secondary} size={KModalSize.lg} show={openedIndexingBox}
                    onHide={() => setOpenedIndexingBox(false)}
                    header={I18n.t("wells.domains.table.changeIndexingTitle")} footer={<></>}>
                <Row><Col className={"text-center"}><KLabel
                    text={<Translate value={"wells.domains.table.indexingConfirm"}/>}/></Col></Row>
                <KSpace/>
                <KForm onSubmit={async (values) => {
                    const params = tableGetParams("wellResults/list");
                    await onRequestIndexing(wellId as string, domainName as string, curTableDataAndConfig!.data as WellDomainResult[], curTableDataAndConfig!.config.globalCheckedAll, values.searchEngine as "google" | "bing", params);
                    setOpenedIndexingBox(false);
                }}>
                    <Row>
                        <Col md={4}><KSelect label={<Translate value="wells.domains.table.searchEngine"/>}
                                             required={true} id={"searchEngine"} size={KSELECT_SIZE.sm}
                                             placeholder={<Translate value="wells.static.select"/>}
                                             options={["Google", "Bing"].map(se => {
                                                 return {value: se.toLowerCase(), label: se};
                                             })}/>
                        </Col>
                    </Row>
                    <KSpace spaces={2}/>
                    <Row>
                        <Col md={4}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                             text={<><i className="fal fa-chevron-double-right"/>&nbsp;
                                                 <Translate value={`wells.static.confirm`}/></>}/></Col>
                    </Row>
                </KForm>
            </KModal>
            <ResultNote notes={curResult?.notes} onUpdate={(notes) => onUpdateNotes(wellId, domainName, curResult?.url || "", notes)} openedNotes={openedNotes} setOpenedNotes={setOpenedNotes}/>
        </>
    );
}
