import {connect} from "react-redux";
import {DispatchProps, StateProps, InnerProps, StatsArea} from "../../components/detection/StatsArea";
import {RootState, ThunkDispatch} from "../../types";
import {unregister} from "@kopjra/uikit";
import {routerTools, Routes} from "../../utils/router";
import {
    doGetCurrentSearch,
    doGetSearchStats
} from "../../actions/thunks/detection/statsArea";
import {resetDomainsState} from "../../actions/detection/domainsArea";
import {resetResultUGCState} from "../../actions/detection/resultUGC";
import { resetResultsState } from "../../actions/detection/resultsArea";


function mapStateToProps({statsArea}: RootState): StateProps {
    return {
        currentSearch: statsArea.currentSearch,
        stats: statsArea.stats
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCurrentSearch: (searchId: string) => dispatch(doGetCurrentSearch(searchId)),
        onGetStats: (searchId: string) => dispatch(doGetSearchStats(searchId)),
        onOpenDomains: (productType: string, searchId: string) => {
            dispatch(unregister("domains/list"));
            dispatch(resetDomainsState());
            routerTools.push(Routes.DOMAINS(productType, searchId));
        },
        onOpenDomain: (productType: string, searchId: string, domainId: string) => {
            dispatch(unregister("results/list"));
            dispatch(resetResultsState());
            routerTools.push(Routes.RESULTS(productType, searchId, domainId));
        },
        onOpenResult: (productType: string, searchId: string, domainId: string, resultId: string) => {
            dispatch(resetResultUGCState());
            routerTools.push(Routes.RESULT(productType, searchId, domainId, resultId));
        },
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(StatsArea);
